import { JoonUIColor, SPACING } from "@joonapp/web-shared"
import { Tooltip } from "react-tooltip"

const CustomTooltip = ({
  children,
  id,
}: {
  children: JSX.Element | JSX.Element[]
  id: string
}) => {
  return (
    <Tooltip
      style={{
        padding: SPACING.space2,
        background: JoonUIColor.background.inverted,
        borderRadius: SPACING.space2,
        color: JoonUIColor.text.inverted,
        zIndex: 500,
      }}
      id={id}
      arrowColor="transparent"
    >
      {children}
    </Tooltip>
  )
}

export default CustomTooltip
