import { useMutation } from "@tanstack/react-query"

import { createToast } from "../../../../components/toast/Toast"
import { editTargetBehavior } from "../../../../networking/behaviors"
import { ToastType } from "../../../../types"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../../util/analytics"

const useEditTargetBehaviorMutation = () => {
  return useMutation({
    mutationFn: editTargetBehavior,
    onSuccess: () => {
      createToast({
        title: "Target behavior updated!",
        type: ToastType.SUCCESS,
      })
      trackAnalyticEvent(ANALYTIC_EVENTS.edit_target_behavior)
    },
    onError: () => {
      createToast({
        title: "Failed to update target behavior",
        type: ToastType.ERROR,
      })
    },
  })
}

export default useEditTargetBehaviorMutation
