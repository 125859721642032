import { FlexBox } from "@joonapp/web-shared"
import useMediaQuery from "@mui/material/useMediaQuery"
import { Outlet } from "react-router-dom"

const PatientInfoContent = () => {
  const isMobile = useMediaQuery("(max-width: 800px)")
  return (
    <FlexBox
      direction="row"
      justify="unset"
      align="unset"
      wrap={false}
      style={{
        flex: 1,
        overflow: "hidden",
        height: isMobile ? undefined : "calc(100dvh - 54px)",
      }}
    >
      <Outlet />
    </FlexBox>
  )
}

export default PatientInfoContent
