import {
  FlexBox,
  JoonColorExpanded,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import { useMediaQuery } from "@mui/material"
import { useEffect } from "react"
import { useLocation } from "react-router-dom"

import EmailPw from "./EmailPw"
import OnboardBox from "./OnboardBox"
import UserInfo from "./UserInfo"
import useNewClinicMember from "../../hooks/useNewClinicMember"
import useOnboarding from "../../hooks/useOnboarding"
import { OnboardingSteps } from "../../hooks/useOnboarding"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"
import { requireNoAuth } from "../../util/auth"

const Onboarding = () => {
  const { step, setStep } = useOnboarding()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)

  const isMobile = useMediaQuery("(max-width:800px)")
  const isShortScreen = useMediaQuery("(max-height: 750px)")

  useNewClinicMember()

  // Reset onboarding step to 0 when user navigates away from onboarding
  useEffect(() => () => setStep(0), [setStep])

  useEffect(() => {
    trackAnalyticEvent(ANALYTIC_EVENTS.view_onboarding_page, {
      utm_source: queryParams.get("utm_source"),
      utm_campaign: queryParams.get("utm_campaign"),
      utm_content: queryParams.get("utm_content"),
      utm_term: queryParams.get("utm_term"),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <FlexBox
      direction={isMobile ? "column" : "row"}
      wrap={false}
      align={isShortScreen && !isMobile ? "flex-start" : "center"}
      justify={isMobile ? "flex-start" : "center"}
      style={{
        overflow: "hidden",
        overflowY: "auto",
        height: "100dvh",
        maxWidth: "100vw",
        width: "100vw",
        paddingBottom: SPACING.space14,
        paddingTop: SPACING.space4,
        background: `linear-gradient(180deg, rgba(255, 255, 255, 0.80) 2.83%, rgba(255, 255, 255, 0.00) 51.53%, rgba(255, 255, 255, 0.80) 99.81%), ${JoonColorExpanded.indigo100}`,
      }}
    >
      <FlexBox
        direction="column"
        align="center"
        justify={isMobile ? "flex-start" : "center"}
        wrap={false}
        style={{
          minHeight: "700px",
          gap: "6vw",
          maxHeight: "700px",
          overflow: "visible",
          padding: SPACING.space6,
        }}
      >
        <FlexBox
          direction="column"
          gap={isMobile ? SPACING.space2 : SPACING.space6}
          wrap={false}
          align="center"
        >
          <FlexBox
            align="center"
            wrap={false}
            justify="center"
            gap={SPACING.space1}
          >
            <Typography variant="h3">Welcome to </Typography>
            <img
              src="/images/logos/JoonHealth.svg"
              alt="Joon Health Logo"
              style={{ height: "24px", marginBottom: SPACING.space1 }}
            />
          </FlexBox>
          <OnboardBox>
            {step === OnboardingSteps.userInfo ? (
              <UserInfo />
            ) : step === OnboardingSteps.emailpw ? (
              <EmailPw />
            ) : (
              <div>Error</div>
            )}
          </OnboardBox>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  )
}

export default requireNoAuth(Onboarding)
