import {
  FrequencyTargetPeriod,
  Button,
  CalloutBox,
  FlexBox,
  InfoIcon,
  InputSectionBox,
  JoonUIColor,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  QuestType,
  SPACING,
  SelectInput,
  Slider,
  TextInput,
  Typography,
  UserRole,
  TextButton,
} from "@joonapp/web-shared"
import { useQueryClient } from "@tanstack/react-query"
import { useState } from "react"
import { useParams } from "react-router-dom"
import { useShallow } from "zustand/react/shallow"

import { useAddEditQuestModalStore } from "./useAddEditQuestModalStore"
import OpenPDFButton from "../../../../components/buttons/OpenPDFButton"
import { CoinRewardBadge } from "../../../../components/coinRewardBadge/CoinRewardBadge"
import { Toasts, createToast } from "../../../../components/toast/Toast"
import CustomTooltip from "../../../../components/tooltip/CustomTooltip"
import { targetFrequencyDrodownOptions } from "../../../../constants"
import {
  QUERY_KEYS,
  useCurrentPatientQuery,
  usePatientWeeklyProgressQuery,
  useQuestQuery,
} from "../../../../networking/queries"
import {
  deleteTarget,
  deleteQuest,
  editQuest,
  updateTarget,
} from "../../../../networking/quests"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../../util/analytics"

const AddEditQuestModal = () => {
  const [isSaving, setIsSaving] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const {
    isOpen,
    onClose,
    selectedQuestId,
    frequency,
    setFrequency,
    reward,
    setReward,
    questName,
    setQuestName,
  } = useAddEditQuestModalStore(
    useShallow((state) => ({
      isOpen: state.isOpenEditQuest,
      onClose: state.onClose,
      selectedQuestId: state.selectedQuestId,
      frequency: state.frequency,
      setFrequency: state.setFrequency,
      reward: state.reward,
      setReward: state.setReward,
      questName: state.questName,
      setQuestName: state.setQuestName,
    }))
  )

  const { userId } = useParams()
  const { data: questDetails } = useQuestQuery(selectedQuestId as number)
  const { data: currentChild } = useCurrentPatientQuery()
  const queryClient = useQueryClient()

  const isExamplePatient = Number(userId) === 0
  const mostRecentTarget = questDetails?.current_frequency_target

  const onClickSaveBehaviorTarget = async () => {
    if (!selectedQuestId || !userId || !questDetails) return
    setIsSaving(true)
    try {
      const updatedQuest = {
        title: questName,
        redeemable_reward: reward,
        user_id: userId,
        start_date: questDetails.start_date,
        recurrence: questDetails.recurrence,
        occurrence_limit: questDetails.occurrence_limit,
        type: QuestType.REPEATING,
      }

      await Promise.all([
        editQuest({
          seriesId: selectedQuestId,
          questInfo: updatedQuest,
        }),
        updateTarget({
          seriesId: selectedQuestId,
          target: {
            frequency: frequency.value as number,
            period: FrequencyTargetPeriod.SEVEN_DAYS,
            role: UserRole.THERAPIST,
          },
        }),
      ])
      queryClient.invalidateQueries([QUERY_KEYS.CHILD_QUEST_DATA])
      onClose()
      if (!!mostRecentTarget) {
        createToast(Toasts.EDIT_TARGET_SUCCESS)
        trackAnalyticEvent(ANALYTIC_EVENTS.edit_target, {
          isTherapistCreated:
            questDetails.assigner_profile?.role === UserRole.THERAPIST,
        })
      } else {
        createToast(Toasts.ADD_TARGET_SUCCESS)
        trackAnalyticEvent(ANALYTIC_EVENTS.add_target)
      }
    } catch (error) {
      createToast(Toasts.GENERAL_ERROR)
    }
    setIsSaving(false)
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} mobileFull>
      <ModalHeader
        title={
          (
            <FlexBox direction="column" gap={SPACING.space1}>
              <Typography variant="h3">
                {!!mostRecentTarget
                  ? "Edit a frequency target"
                  : "Set a frequency target"}
              </Typography>
              <OpenPDFButton
                text="Preview parent experience"
                imageUrl="/images/frequencyTargetInfoSheet.pdf"
              />
            </FlexBox>
          ) as any
        }
        onClose={onClose}
        showBorderBottom
      />
      <ModalContent>
        <FlexBox
          style={{
            padding: `${SPACING.space4} ${SPACING.space6} ${SPACING.space0}`,
            width: "min(480px, 100vw)",
          }}
          direction="column"
          gap={SPACING.space6}
          wrap={false}
        >
          {/* If this is a clinician-created behavior, then they can edit the title */}
          {questDetails?.assigner_profile?.role === UserRole.THERAPIST ? (
            <FlexBox direction="column" gap={SPACING.space2}>
              <Typography variant="bodyBold">Routine</Typography>
              <TextInput
                name="Behavior"
                type="text"
                placeholder="Enter behavior name"
                value={questName}
                onChange={(e) => setQuestName(e.target.value)}
                fullWidth
                maxLength={100}
              />
            </FlexBox>
          ) : (
            <div>
              <Typography variant="body">Skill: </Typography>
              <Typography variant="bodyBold">{questName}</Typography>
            </div>
          )}

          <div>
            <Typography
              variant="bodyBold"
              style={{ marginBottom: SPACING.space2 }}
              textAlign="left"
            >
              Specify target frequency
            </Typography>
            <FlexBox align="center" gap={SPACING.space2}>
              <SelectInput
                isSearchable={false}
                name="Frequency"
                isMulti={false}
                selectedOption={frequency}
                setSelectedOption={setFrequency}
                options={targetFrequencyDrodownOptions}
              />
              <Typography variant="bodySmall">per 7 days</Typography>
            </FlexBox>
          </div>
          <div style={{ width: "100%" }}>
            <Typography
              variant="bodyBold"
              textAlign="left"
              style={{ marginBottom: SPACING.space2 }}
            >
              Difficulty
            </Typography>
            <Slider value={reward} setValue={setReward} />
            {!!reward && (
              <InputSectionBox
                style={{ minHeight: "84px", marginTop: SPACING.space2 }}
              >
                <FlexBox
                  direction="column"
                  gap={SPACING.space2}
                  align="center"
                  justify="center"
                >
                  <Typography variant="caption" textAlign="center">
                    For completing this, {currentChild?.user?.name} will earn:{" "}
                    <span data-tooltip-id="reward-tooltip">
                      <InfoIcon
                        color={JoonUIColor.text.primaryAccent}
                        size={14}
                      />
                    </span>
                    <CustomTooltip id="reward-tooltip">
                      <Typography
                        variant="bodySmall"
                        color={JoonUIColor.text.inverted}
                        textAlign="left"
                        style={{ maxWidth: "250px" }}
                      >
                        “Coins” are used for in-game items and parent custom
                        rewards.
                      </Typography>
                    </CustomTooltip>
                  </Typography>
                  <CoinRewardBadge coinReward={reward} />
                </FlexBox>
              </InputSectionBox>
            )}
          </div>
          <CalloutBox>
            <Typography variant="bodySmall">
              Once set, you will be able to track how often this specific skill
              occurs every 7 days.
            </Typography>
          </CalloutBox>
        </FlexBox>
      </ModalContent>
      <ModalFooter>
        <FlexBox
          justify="space-between"
          align="center"
          direction="row"
          wrap={false}
          style={{
            whiteSpace: "nowrap",
            width: "100%",
            padding: SPACING.space6,
          }}
        >
          {mostRecentTarget && (
            <Button
              buttonType="redPrimary"
              onClick={() => setShowDeleteModal(true)}
              text="Remove"
              disabled={isExamplePatient}
            />
          )}
          <FlexBox
            direction="row"
            align="center"
            gap={SPACING.space2}
            wrap={false}
            justify="flex-end"
          >
            <TextButton onClick={onClose}>
              <Typography
                variant="bodyBold"
                color={JoonUIColor.text.primaryAccent}
              >
                Cancel
              </Typography>
            </TextButton>
            <Button
              text={mostRecentTarget ? "Update" : "Set target"}
              onClick={onClickSaveBehaviorTarget}
              isLoading={isSaving}
              disabled={isExamplePatient}
            />
          </FlexBox>
        </FlexBox>
      </ModalFooter>
      <DeleteBehaviorTargetConfirmationModal
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
      />
    </Modal>
  )
}

const DeleteBehaviorTargetConfirmationModal = ({ isOpen, onClose }: any) => {
  const [isDeleting, setIsDeleting] = useState(false)
  const { selectedQuestId, onClose: closeAddEditQuestModal } =
    useAddEditQuestModalStore(
      useShallow((state) => ({
        selectedQuestId: state.selectedQuestId,
        onClose: state.onClose,
      }))
    )
  const { data } = usePatientWeeklyProgressQuery()
  const selectedQuest = data?.find(
    (questWithDates) => questWithDates.quest.id === selectedQuestId
  )?.quest

  const queryClient = useQueryClient()

  const isTherapistCreated =
    selectedQuest?.assigner_profile?.role === UserRole.THERAPIST
  // If this is a therapist-created target, also delete the quest
  const onClickDeleteBehaviorTarget = async () => {
    if (!selectedQuest) return
    setIsDeleting(true)
    try {
      const promises = [deleteTarget(selectedQuest.id)]
      const isTherapistCreated =
        selectedQuest.assigner_profile?.role === UserRole.THERAPIST
      if (isTherapistCreated) promises.push(deleteQuest(selectedQuest.id))
      await Promise.all(promises)
      queryClient.invalidateQueries([QUERY_KEYS.CHILD_QUEST_DATA])
      closeAddEditQuestModal()
      if (isTherapistCreated) {
        createToast(Toasts.DELETE_BEHAVIOR_SUCCESS)
      } else {
        createToast(Toasts.DELETE_TARGET_SUCCESS)
      }
      trackAnalyticEvent(ANALYTIC_EVENTS.delete_target, { isTherapistCreated })
    } catch (error) {
      createToast(Toasts.GENERAL_ERROR)
    } finally {
      setIsDeleting(false)
      onClose()
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalHeader
        title="Are you sure?"
        onClose={onClose}
        style={{ borderBottom: `1px solid ${JoonUIColor.border.default}` }}
      />
      <ModalContent>
        <FlexBox
          style={{
            padding: `${SPACING.space4} ${SPACING.space6} ${SPACING.space0}`,
            width: "min(480px, 100vw)",
          }}
          direction="column"
          gap={SPACING.space6}
        >
          <Typography variant="body">
            {isTherapistCreated
              ? "This action will remove the behavior and frequency target."
              : "This action will remove the current frequency target for this behavior. You can add a new target in the future."}
          </Typography>
        </FlexBox>
      </ModalContent>
      <ModalFooter>
        <FlexBox
          justify="flex-end"
          align="center"
          direction="row"
          gap={SPACING.space2}
          wrap={false}
          style={{
            whiteSpace: "nowrap",
            width: "100%",
            padding: SPACING.space6,
          }}
        >
          <button onClick={onClose}>
            <Typography
              variant="bodyBold"
              color={JoonUIColor.text.primaryAccent}
            >
              Cancel
            </Typography>
          </button>
          <Button
            buttonType="redPrimary"
            text="Delete target"
            isLoading={isDeleting}
            onClick={onClickDeleteBehaviorTarget}
          />
        </FlexBox>
      </ModalFooter>
    </Modal>
  )
}

export default AddEditQuestModal
