import {
  FlexBox,
  JoonColorExpanded,
  JoonUIColor,
  Shadow,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import { useState } from "react"

const SummarySectionEmptyState = ({
  onClick,
  title,
  description,
}: {
  onClick: () => void
  title: string
  description: string
}) => {
  const [isHovered, setIsHovered] = useState(false)

  return (
    <button style={{ padding: 0, width: "100%" }} onClick={onClick}>
      <FlexBox
        direction="column"
        gap={SPACING.space1}
        style={{
          padding: SPACING.space4,
          border: `1px solid ${JoonUIColor.border.default}`,
          borderRadius: SPACING.space2,
          boxShadow: Shadow.high,
          background: JoonUIColor.background.primaryNeutral,
          outline: isHovered
            ? `2px solid ${JoonColorExpanded.indigo300}`
            : "none",
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <FlexBox align="center" gap={SPACING.space2} wrap={false}>
          <Typography variant="bodyBold" color={JoonUIColor.semantic.primary}>
            +
          </Typography>
          <Typography variant="bodyBold" textAlign="left">
            {title}
          </Typography>
        </FlexBox>
        <Typography variant="bodySmall" textAlign="left">
          <Typography variant="caption">Best for: </Typography>
          {description}
        </Typography>
      </FlexBox>
    </button>
  )
}

export default SummarySectionEmptyState
