import {
  FlexBox,
  JoonUIColor,
  Modal,
  ModalHeader,
  SPACING,
  TextButton,
  Typography,
  UserRole,
  Button,
  capitalizeFirstLetter,
} from "@joonapp/web-shared"
import { create } from "zustand"

import useDeleteClientMutation from "./useDeleteClientMutation"
import { usePatientGroupsQuery } from "../../networking/queries"
import { PracticeMemberProfile } from "../../types"
import { FamilyMemberProfile } from "../../types"

type DeleteClientModalStore = {
  isOpen: boolean
  onOpen: (clientId: number) => void
  onClose: () => void
  patientGroupId: number
}

export const useDeleteClientModalStore = create<DeleteClientModalStore>(
  (set) => ({
    isOpen: false,
    onOpen: (patientGroupId) => set({ isOpen: true, patientGroupId }),
    onClose: () => set({ isOpen: false, patientGroupId: 0 }),
    patientGroupId: 0,
  })
)

const DeleteClientModal = () => {
  const { isOpen, onClose, patientGroupId } = useDeleteClientModalStore()
  const { data: patientGroups } = usePatientGroupsQuery()

  const deleteClientMutation = useDeleteClientMutation()

  if (!patientGroups) return null

  const patientGroup = patientGroups.find((g) => g.id === patientGroupId)
  const patient = patientGroup?.profiles.find(
    (member: FamilyMemberProfile | PracticeMemberProfile) =>
      member.role === UserRole.PATIENT
  )

  const clientName = patient?.user.name || "this client"

  const onClickDelete = async () => {
    await deleteClientMutation.mutateAsync(patientGroupId)
    onClose()
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      style={{ width: "min(495px, 95vw)" }}
    >
      <ModalHeader
        onClose={onClose}
        title={`Discharge ${clientName}`}
        showBorderBottom
      />
      <FlexBox
        style={{ padding: SPACING.space6, paddingTop: SPACING.space4 }}
        gap={SPACING.space4}
      >
        <FlexBox direction="column" gap={SPACING.space2}>
          <Typography variant="body">
            Are you sure you’d like to discharge {clientName} from your Clinic?
            This action cannot be undone.
          </Typography>
          <Typography
            variant="bodySmall"
            style={{
              padding: SPACING.space2,
              background: JoonUIColor.background.lightGray,
              borderRadius: SPACING.space2,
            }}
          >
            Once discharged, your clinic will not be able to view {clientName}’s
            data anymore. {capitalizeFirstLetter(clientName)} and their family
            will still be able to use Joon, just without its connection with
            your clinic.
          </Typography>
        </FlexBox>
        <FlexBox
          direction="row"
          gap={SPACING.space2}
          wrap={false}
          justify="flex-end"
        >
          <TextButton onClick={onClose}>
            <Typography
              variant="bodyBold"
              color={JoonUIColor.text.primaryAccent}
            >
              Cancel
            </Typography>
          </TextButton>
          <Button
            text="Discharge"
            onClick={onClickDelete}
            buttonType="redPrimary"
            isLoading={deleteClientMutation.isLoading}
          />
        </FlexBox>
      </FlexBox>
    </Modal>
  )
}

export default DeleteClientModal
