import { useMutation, useQueryClient } from "@tanstack/react-query"

import { deleteDataTask } from "../../../../networking/dataTasks"
import {
  QUERY_KEYS,
  useCurrentTrainingQuery,
} from "../../../../networking/queries"
import { ParentTrainingDataCollectionTask } from "../../../../types"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../../util/analytics"

const useDeleteDataTaskMutation = () => {
  const queryClient = useQueryClient()
  const { data: currentTraining } = useCurrentTrainingQuery()
  const queryKey = [QUERY_KEYS.PARENT_TRAINING_DATA_TASKS, currentTraining?.id]

  return useMutation({
    mutationFn: deleteDataTask,
    onMutate: async ({ dataTaskId }) => {
      const previousDataTasks =
        queryClient.getQueryData<ParentTrainingDataCollectionTask[]>(queryKey)

      if (!previousDataTasks) return

      const newDataTasks = previousDataTasks.filter(
        (task) => task.id !== dataTaskId
      )

      queryClient.setQueryData<ParentTrainingDataCollectionTask[]>(
        queryKey,
        newDataTasks
      )

      trackAnalyticEvent(ANALYTIC_EVENTS.delete_data_task)
      return { previousDataTasks }
    },
    onSettled: () => {
      queryClient.invalidateQueries(queryKey)
    },
  })
}

export default useDeleteDataTaskMutation
