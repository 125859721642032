import { create } from "zustand"

interface DeleteTargetBehaviorModalStore {
  isOpen: boolean
  onOpen: (targetBehaviorId: number) => void
  onClose: () => void

  targetBehaviorId: number | null
}

const useDeleteTargetBehaviorModalStore =
  create<DeleteTargetBehaviorModalStore>((set) => ({
    isOpen: false,
    onOpen: (targetBehaviorId) => set({ isOpen: true, targetBehaviorId }),
    onClose: () => set({ isOpen: false, targetBehaviorId: null }),

    targetBehaviorId: null,
  }))

export default useDeleteTargetBehaviorModalStore
