import { Button, FlexBox, SPACING, Typography } from "@joonapp/web-shared"

const SearchEmptyState = () => {
  return (
    <FlexBox
      align="center"
      justify="center"
      gap={SPACING.space6}
      style={{
        margin: "auto",
        width: "fit-content",
        padding: SPACING.space6,
        borderRadius: SPACING.space4,
      }}
    >
      <Typography variant="h3" textAlign="center">
        No resources found. Try adjusting your filters.
      </Typography>
      <FlexBox justify="center" gap={SPACING.space2}>
        <Typography variant="body" textAlign="center">
          Can't find what you're looking for?
        </Typography>
        <Button
          buttonType="secondary"
          text="Request a resource"
          style={{ whiteSpace: "nowrap" }}
          onClick={() =>
            window.open("https://forms.gle/ekkxQaqkpsVbahsg9", "_blank")
          }
        />
      </FlexBox>
    </FlexBox>
  )
}

export default SearchEmptyState
