import { faArrowLeft, faChevronLeft } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FlexBox, JoonUIColor, SPACING, Typography } from "@joonapp/web-shared"
import { useState } from "react"

type Props = {
  text: string
  onClick: () => void
}

export const BackButton2 = ({ text, onClick }: Props) => {
  const [isHovered, setIsHovered] = useState(false)
  return (
    <button
      onClick={onClick}
      style={{ padding: "0" }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <FlexBox align="center" gap={SPACING.space2}>
        <FontAwesomeIcon
          icon={isHovered ? faArrowLeft : faChevronLeft}
          size="sm"
          width={16}
        />
        <Typography variant="caption" color={JoonUIColor.text.secondary}>
          {text}
        </Typography>
      </FlexBox>
    </button>
  )
}
