import {
  Button,
  FlexBox,
  JoonColorExpanded,
  JoonUIColor,
  Modal,
  Shadow,
  SPACING,
  TextButton,
  Typography,
} from "@joonapp/web-shared"
import { useMediaQuery } from "@mui/material"
import { useEffect, useState } from "react"
import { createPortal } from "react-dom"
import { useLocation } from "react-router-dom"
import { create } from "zustand"

import useSubscription from "./useSubscription"
import { getStripeCheckoutUrl } from "../../networking/practice"
import { usePracticeQuery } from "../../networking/queries"
import { ToastType } from "../../types"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"
import { useAuth } from "../../util/auth"
import { createToast } from "../toast/Toast"

interface PaywallModalStore {
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
}

export const usePaywallModalStore = create<PaywallModalStore>((set) => ({
  isOpen: false,
  onClose: () => set({ isOpen: false }),
  onOpen: () => set({ isOpen: true }),
}))

const PaywallModal = () => {
  const { isOpen, onClose, onOpen } = usePaywallModalStore()
  const { practice } = usePracticeQuery()
  const { isNotSubscribed, isSubscribed } = useSubscription()
  const [isLoading, setIsLoading] = useState(false)
  const location = useLocation()
  const isMobile = useMediaQuery("(max-width: 800px)")
  const { signout } = useAuth()

  useEffect(() => {
    if (isNotSubscribed) {
      onOpen()
      trackAnalyticEvent(ANALYTIC_EVENTS.view_paywall_modal)
    } else if (isSubscribed) onClose()
  }, [isNotSubscribed, isSubscribed, onClose, onOpen, location])

  const onClick = async () => {
    if (!practice) return
    setIsLoading(true)
    try {
      const response = await getStripeCheckoutUrl(practice?.id)
      if (response === null) {
        onClose()
        createToast({
          type: ToastType.ERROR,
          title: "Subscription already exists",
        })
      } else {
        window.open(response, "_blank")
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  const onSignOut = () => {
    signout()
    onClose()
    trackAnalyticEvent(ANALYTIC_EVENTS.signout)
  }

  return (
    <Modal
      mandatory
      isOpen={isOpen}
      onClose={onClose}
      mobileFull
      style={{ overflowY: "auto" }}
    >
      <FlexBox
        direction="column"
        align="center"
        wrap={false}
        gap={SPACING.space4}
        style={{
          padding: SPACING.space6,
          borderBottom: `1px solid ${JoonUIColor.border.default}`,
        }}
      >
        <img src="/images/logos/JoonHealth.svg" alt="" />
        <Typography
          variant={isMobile ? "caption" : "bodyBold"}
          textAlign="center"
        >
          Unlock the easiest way to manage your parent training programs
        </Typography>
      </FlexBox>
      <FlexBox
        direction="column"
        align="center"
        wrap={false}
        gap={SPACING.space6}
        style={{ padding: SPACING.space6 }}
      >
        <div>
          <Typography
            variant={isMobile ? "caption" : "bodyBold"}
            textAlign="center"
          >
            Only pay for active clinicians.
          </Typography>
          <Typography
            variant={isMobile ? "caption" : "bodyBold"}
            textAlign="center"
          >
            Add unlimited clients.
          </Typography>
          <Typography
            variant={isMobile ? "caption" : "bodyBold"}
            textAlign="center"
          >
            Families linked get free access to Joon.
          </Typography>
        </div>
        <PricingBox />
        <div>
          <Typography variant="bodySmall" textAlign="center">
            For more than 20 clinician seats, contact us at{" "}
            <a href="mailto:brad@joonhealth.co">brad@joonhealth.co</a>
          </Typography>
          <Typography variant="bodySmall" textAlign="center">
            Plans come with full 30-day money back guarantee.
          </Typography>
        </div>
        <Button
          text="Start 14-day free trial"
          onClick={onClick}
          isLoading={isLoading}
          size={isMobile ? "medium" : "large"}
        />
        <TextButton onClick={onSignOut}>
          <Typography variant="caption" color={JoonUIColor.text.primaryAccent}>
            Sign out
          </Typography>
        </TextButton>
      </FlexBox>
    </Modal>
  )
}

export default PaywallModal

const PricingBox = () => {
  return (
    <FlexBox
      direction="row"
      align="center"
      justify="center"
      wrap={false}
      gap={SPACING.space6}
      style={{
        background: JoonColorExpanded.yellow100,
        position: "relative",
        overflow: "hidden",
        padding: `${SPACING.space6} ${SPACING.space4}`,
        borderRadius: SPACING.space2,
        maxWidth: "400px",
        boxShadow: Shadow.high,
      }}
    >
      <Typography variant="h1" style={{ zIndex: 1, whiteSpace: "nowrap" }}>
        US $40
      </Typography>
      <Typography variant="body" style={{ zIndex: 1 }}>
        per clinician per month
      </Typography>
      <div
        style={{
          position: "absolute",
          top: "-70%",
          left: "-13%",
          width: "57%",
          height: "300%",
          background: JoonColorExpanded.yellow300,
          borderRadius: "50%",
        }}
      />
    </FlexBox>
  )
}

// HOC to wrap a component and check if the user is not subscribed
export const withSubscriptionCheck = (
  WrappedComponent: React.ComponentType
) => {
  return (props: any) => {
    const { isNotSubscribed } = useSubscription()
    const { onOpen } = usePaywallModalStore()

    useEffect(() => {
      if (isNotSubscribed) onOpen()
    }, [isNotSubscribed, onOpen])

    if (isNotSubscribed) {
      return createPortal(<PaywallModal />, document.body)
    }

    return <WrappedComponent {...props} />
  }
}
