import { FlexBox, SPACING } from "@joonapp/web-shared"

import ClientManagementSection from "./ClientManagementSection"
import ClinicInformation from "./ClinicInformation"
import DeleteClinicianModal from "./DeleteClinicianModal"
import EditClinicianModal from "./EditClinicianModal"
import EditClinicInfoModal from "./EditClinicInfoModal"
import EditPersonalInfoModal from "./EditPersonalInfoModal"
import PersonalInformation from "./PersonalInformation"
import LogoutButton from "../../components/buttons/LogoutButton"
import PageContentWrapper from "../../components/layout/PageContentWrapper"
import PageWrapper from "../../components/pageWrapper/PageWrapper"
import SubscriptionSection from "../../components/subscription/SubscriptionSection"
import useDocumentTitle from "../../hooks/usePageTitle"
import { requireAuth } from "../../util/auth"

const Settings = requireAuth(() => {
  useDocumentTitle("Joon - Settings")

  return (
    <PageWrapper>
      <div
        style={{
          display: "flex",
          position: "relative",
          width: "100%",
          height: "100%",
        }}
      >
        <PageContentWrapper>
          <FlexBox
            direction="column"
            gap={SPACING.space8}
            style={{ width: "100%" }}
            align="unset"
          >
            <PersonalInformation />
            <ClinicInformation />
            <SubscriptionSection />
            <ClientManagementSection />
            <LogoutButton />
          </FlexBox>
        </PageContentWrapper>
        <EditClinicianModal />
        <DeleteClinicianModal />
        <EditPersonalInfoModal />
        <EditClinicInfoModal />
      </div>
    </PageWrapper>
  )
})

export default Settings
