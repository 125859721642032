import {
  Button,
  FlexBox,
  JoonColorExpanded,
  JoonUIColor,
  TextButton,
  Typography,
} from "@joonapp/web-shared"
import { SPACING } from "@joonapp/web-shared"
import { useMediaQuery } from "@mui/material"
import { useEffect, useState } from "react"
import Joyride, {
  ACTIONS,
  CallBackProps,
  EVENTS,
  Status,
  STATUS,
  Step,
  TooltipRenderProps,
} from "react-joyride"
import { useParams } from "react-router-dom"

import { localStorageItems } from "../../util/storage"
import useMobileSidebarStore from "../nav/useMobileSidebarStore"

const steps = [
  { placement: "center", target: "body" },
  { placement: "right", target: "#tour-1" },
  { placement: "right", target: "#tour-2" },
  { placement: "right", target: "#tour-3" },
  { placement: "right", target: "#tour-4" },
] as Step[]

const OnboardTutorial = () => {
  const [run, setRun] = useState(false)
  const [stepIndex, setStepIndex] = useState(0)
  const { onOpen: onOpenMobileSidebar } = useMobileSidebarStore()
  const { userId } = useParams()
  const isMobile = useMediaQuery("(max-width: 800px)")

  // Check if the user needs to see the tutorial
  useEffect(() => {
    const flag = localStorageItems.needsToSeeTutorial
    const needsToSeeTutorial = !!localStorage.getItem(flag)
    const isExamplePatient = Number(userId) === 0

    if (!needsToSeeTutorial) return
    if (!isExamplePatient) return

    if (isMobile) onOpenMobileSidebar()
    const timeout = setTimeout(() => setRun(true), 500)

    return () => clearTimeout(timeout)
    // eslint-disable-next-line
  }, [userId, setRun])

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status, type, action } = data
    const finishedStatuses: Status[] = [STATUS.FINISHED, STATUS.SKIPPED]

    if (finishedStatuses.includes(status)) {
      localStorage.removeItem(localStorageItems.needsToSeeTutorial)
      setRun(false)
    }

    if (type === EVENTS.STEP_AFTER) {
      if (action === ACTIONS.NEXT) setStepIndex(stepIndex + 1)
      else if (action === ACTIONS.PREV) setStepIndex(stepIndex - 1)
    }
  }

  return (
    <Joyride
      steps={steps}
      tooltipComponent={CustomTooltip}
      run={run}
      stepIndex={stepIndex}
      continuous
      showSkipButton
      showProgress
      callback={handleJoyrideCallback}
      styles={{
        options: {
          zIndex: 500,
          primaryColor: "#007bff",
        },
      }}
      floaterProps={{
        styles: {
          arrow: {
            color: JoonColorExpanded.indigo200,
            length: 8,
            spread: 16,
          },
        },
      }}
      disableOverlayClose
      disableCloseOnEsc
      disableOverlay={false}
    />
  )
}

export default OnboardTutorial

function CustomTooltip(props: TooltipRenderProps) {
  const {
    skipProps,
    primaryProps,
    backProps,
    continuous,
    index,
    tooltipProps,
  } = props

  const stepContent = () => {
    switch (index) {
      case 0:
        return (
          <FlexBox direction="column">
            <button
              style={{
                position: "absolute",
                top: SPACING.space2,
                right: SPACING.space2,
              }}
              {...skipProps}
            >
              <Typography
                variant="caption"
                color={JoonUIColor.text.primaryAccent}
              >
                Skip
              </Typography>
            </button>
            <FlexBox direction="column" gap={SPACING.space4}>
              <Typography variant="h3">
                👋 Welcome to Kevin's Client page.
              </Typography>
              <Typography variant="body">
                Taking a tour of our Sample Client (Kevin) page will give you a
                sense of the data you’ll get from Joon when you use it with your
                clients.
              </Typography>
              <Typography variant="body">Let me show you around!</Typography>
              <Button
                {...primaryProps}
                onClick={primaryProps.onClick as () => void}
                text="Let's go!"
                style={{ width: "200px", margin: "0 auto" }}
              />
            </FlexBox>
          </FlexBox>
        )
      case 1:
        return (
          <Typography variant="bodyBold">
            Quickly navigate between all of your Clients
          </Typography>
        )
      case 2:
        return (
          <FlexBox direction="column" gap={SPACING.space2}>
            <Typography variant="bodyBold" textAlign="left">
              Parent Training
            </Typography>
            <Typography variant="body">
              Create and manage all parent training here so you can include
              parents in your clients’ treatment journey. Help parents succeed
              at home with tools to practice skills from sessions.
            </Typography>
          </FlexBox>
        )
      case 3:
        return (
          <FlexBox direction="column" gap={SPACING.space2}>
            <Typography variant="bodyBold" textAlign="left">
              Behavior & Skills
            </Typography>
            <Typography variant="body">
              Client behaviors you are focused on decreasing or increasing
              through your work with parents. Once added, parents will be able
              to report when those behaviors occur outside of sessions and you’d
              be able to track information such as frequency, duration, and
              consequences.
            </Typography>
          </FlexBox>
        )
      case 4:
        return (
          <FlexBox direction="column" gap={SPACING.space2}>
            <Typography variant="bodyBold" textAlign="left">
              Home routines
            </Typography>
            <Typography variant="body">
              Assign your clients specific routines they can work on at home
              with their parent. Parents can manage the client's routine within
              the Quests tab of the Parent App. Optionally, Quests will appear
              inside of the{" "}
              <a
                href="https://www.joonapp.io/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Joon game
              </a>{" "}
              for the clients.
            </Typography>
          </FlexBox>
        )
    }
  }

  return (
    <div
      style={{
        background:
          "linear-gradient(270deg, rgba(255, 209, 154, 0.20) 42.39%, rgba(227, 201, 253, 0.20) 98.24%), #FFF",
        borderRadius: SPACING.space4,
        width: "min(400px, 95vw)",
        overflow: "hidden",
        border: `2px solid ${JoonColorExpanded.indigo200}`,
      }}
      {...tooltipProps}
    >
      <div style={{ padding: SPACING.space6 }}>{stepContent()}</div>
      {index !== 0 && (
        <CustomTooltipNav
          index={index}
          backProps={backProps}
          primaryProps={primaryProps}
          continuous={continuous}
        />
      )}
    </div>
  )
}

const CustomTooltipNav = ({
  index,
  backProps,
  primaryProps,
  continuous,
}: {
  index: number
  backProps: TooltipRenderProps["backProps"]
  primaryProps: TooltipRenderProps["primaryProps"]
  continuous: boolean
}) => {
  return (
    <FlexBox
      direction="row"
      wrap={false}
      align="center"
      gap={SPACING.space2}
      style={{
        padding: `${SPACING.space4} ${SPACING.space6}`,
        background: JoonUIColor.background.primaryNeutral,
        borderTop: `2px solid ${JoonColorExpanded.indigo200}`,
      }}
    >
      {index > 0 && (
        <TextButton {...backProps} onClick={backProps.onClick as () => void}>
          <Typography variant="caption" color={JoonUIColor.text.primaryAccent}>
            Back
          </Typography>
        </TextButton>
      )}

      <FlexBox direction="row" justify="center" gap={SPACING.space2}>
        {[...Array(steps.length)].map((_, i) => (
          <span
            key={i}
            style={{
              width: SPACING.space2,
              height: SPACING.space2,
              borderRadius: "50%",
              background: i === index ? "#5F3DC4" : "#E5DBFF",
              display: "inline-block",
            }}
          />
        ))}
      </FlexBox>

      {continuous && (
        <Button
          text={index === steps.length - 1 ? "Finish" : "Next"}
          size="small"
          style={{
            borderRadius: SPACING.space2,
            height: "36px",
            minHeight: "36px",
            width: "72px",
            minWidth: "72px",
          }}
          onClick={primaryProps.onClick as () => void}
        />
      )}
    </FlexBox>
  )
}
