import {
  FlexBox,
  JoonUIColor,
  SPACING,
  TextButton,
  Typography,
} from "@joonapp/web-shared"

import ModuleAssignmentDetailsModal from "./ModuleAssignmentDetailsModal"
import ModulesDndList from "./ModulesDndList"
import ModulesEmptyState from "./ModulesEmptyState"
import PageContentWrapper from "../../../../components/layout/PageContentWrapper"
import { useCurrentTrainingQuery } from "../../../../networking/queries"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../../util/analytics"
import AddTrainingModuleModal from "../../../resources/AddTrainingModuleModal"
import useAddModuleStore from "../../../resources/useAddModuleStore"

const ModulesTab = () => {
  const { data: currentTraining } = useCurrentTrainingQuery()
  const { onOpen: openAddTrainingModuleModal } = useAddModuleStore()

  const moduleAssignments = currentTraining?.assignments || []

  return (
    <PageContentWrapper scrollable={false}>
      {moduleAssignments && moduleAssignments.length > 0 ? (
        <FlexBox direction="column" wrap={false} gap={SPACING.space2}>
          <FlexBox
            direction="row"
            justify="space-between"
            wrap={false}
            align="center"
          >
            <Typography variant="bodyBold">
              {moduleAssignments.length} active module
              {moduleAssignments?.length > 1 ? "s" : ""}
            </Typography>
            <TextButton
              style={{ marginLeft: "auto", whiteSpace: "nowrap" }}
              onClick={() => {
                openAddTrainingModuleModal()
                trackAnalyticEvent(ANALYTIC_EVENTS.open_add_modules)
              }}
            >
              <Typography
                variant="bodyBold"
                color={JoonUIColor.text.primaryAccent}
              >
                + Add more
              </Typography>
            </TextButton>
          </FlexBox>
          <ModulesDndList />
        </FlexBox>
      ) : (
        <ModulesEmptyState />
      )}
      <AddTrainingModuleModal />
      <ModuleAssignmentDetailsModal />
    </PageContentWrapper>
  )
}

export default ModulesTab
