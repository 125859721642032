import { FlexBox } from "@joonapp/web-shared"
import { Outlet } from "react-router-dom"

import AddEditParentTrainingModal from "./AddEditParentTrainingModal"
import DeleteTrainingModal from "./DeleteTrainingModal"
import ParentTrainingHeader from "./ParentTrainingHeader"
import ParentTrainingStatusBanner from "./ParentTrainingStatusBanner"
import PageLoader from "../../../components/loading/PageLoader"
import { useCurrentTrainingQuery } from "../../../networking/queries"

const ParentTrainingPage = () => {
  const { isLoading } = useCurrentTrainingQuery()

  if (isLoading)
    return (
      <FlexBox justify="center">
        <PageLoader />
      </FlexBox>
    )

  return (
    <FlexBox
      direction="column"
      wrap={false}
      style={{ height: "100%", overflowY: "auto", overflowX: "hidden" }}
    >
      <ParentTrainingStatusBanner />
      <ParentTrainingHeader />
      <Outlet />
      <AddEditParentTrainingModal />
      <DeleteTrainingModal />
    </FlexBox>
  )
}

export default ParentTrainingPage
