import {
  faCircleInfo,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  CircleCheckboxChecked,
  FlexBox,
  JoonColorExpanded,
  JoonUIColor,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import toast, { Toaster } from "react-hot-toast"

import { ToastType } from "../../types"

export const CustomToaster = () => {
  return (
    <Toaster
      position="top-center"
      gutter={4}
      containerStyle={{ zIndex: 1500 }}
      toastOptions={{
        style: {
          padding: SPACING.space0,
          border: "none",
          background: "transparent",
        },
        duration: 5000,
      }}
      containerClassName="toaster-container"
    />
  )
}

export const createToast = ({
  type,
  title,
  description,
  duration,
}: {
  type: ToastType
  title: string
  description?: string
  duration?: number
}) => {
  const toastColors = getToastColors(type)
  const statusIcon = getToastIcon(type)
  return toast(
    <div
      style={{
        border: `1px solid ${toastColors.color}`,
        borderRadius: SPACING.space2,
        width: "fit-content",
        maxWidth: "min(95vw, 420px)",
        overflow: "hidden",
        margin: 0,
      }}
    >
      <FlexBox
        align="center"
        wrap={false}
        gap={SPACING.space2}
        style={{
          padding: SPACING.space2,
          background: toastColors.background,
        }}
      >
        {statusIcon}
        <Typography variant="caption" color={toastColors.color}>
          {title}
        </Typography>
      </FlexBox>
      {description && (
        <FlexBox
          style={{
            padding: SPACING.space2,
            background: JoonUIColor.background.primaryNeutral,
          }}
        >
          <Typography variant="bodySmall" color={JoonUIColor.text.primary}>
            {description}
          </Typography>
        </FlexBox>
      )}
    </div>,
    { duration }
  )
}

const getToastColors = (type: ToastType) => {
  switch (type) {
    case ToastType.SUCCESS:
      return {
        background: JoonColorExpanded.viridian100,
        color: JoonUIColor.semantic.success,
      }
    case ToastType.ERROR:
      return {
        background: JoonColorExpanded.red100,
        color: JoonUIColor.semantic.destructive,
      }
    case ToastType.INFO:
      return {
        background: JoonColorExpanded.blue100,
        color: JoonUIColor.semantic.info,
      }
    default:
      return {
        background: JoonUIColor.background.primaryNeutral,
        color: JoonUIColor.border.default,
      }
  }
}

const getToastIcon = (type: ToastType) => {
  switch (type) {
    case ToastType.SUCCESS:
      return (
        <CircleCheckboxChecked size={14} color={JoonUIColor.semantic.success} />
      )
    case ToastType.ERROR:
      return (
        <FontAwesomeIcon
          icon={faTriangleExclamation}
          fontSize={14}
          color={JoonUIColor.semantic.destructive}
        />
      )
    case ToastType.INFO:
      return (
        <FontAwesomeIcon
          icon={faCircleInfo}
          fontSize={14}
          color={JoonUIColor.semantic.info}
        />
      )
  }
}

export const Toasts = {
  GENERAL_ERROR: {
    title: "An error occurred. Refresh and try again.",
    type: ToastType.ERROR,
  },
  ADD_TARGET_SUCCESS: {
    title: "Frequency target added!",
    type: ToastType.SUCCESS,
  },
  ADD_BEHAVIOR_SUCCESS: {
    title: "Behavior added!",
    type: ToastType.SUCCESS,
  },
  EDIT_TARGET_SUCCESS: {
    title: "Behavior updated!",
    type: ToastType.SUCCESS,
  },
  DELETE_TARGET_SUCCESS: {
    title: "Frequency target deleted!",
    type: ToastType.SUCCESS,
  },
  DELETE_BEHAVIOR_SUCCESS: {
    title: "Behavior deleted!",
    type: ToastType.SUCCESS,
  },
  TARGET_MISSING_NAME: {
    title: "Please input a behavior name.",
    type: ToastType.ERROR,
  },
  TARGET_MISSING_REWARD: {
    title: "Please select a difficulty.",
    type: ToastType.ERROR,
  },
  ADD_TASK_SUCCESS: {
    title: "Task added!",
    type: ToastType.SUCCESS,
  },
  EDIT_TASK_SUCCESS: {
    title: "Task updated!",
    type: ToastType.SUCCESS,
  },
} as const
