import {
  CaretDownIcon,
  FlexBox,
  GearIcon,
  JoonUIColor,
  SPACING,
  TextButton,
  Typography,
} from "@joonapp/web-shared"
import { useMediaQuery } from "@mui/material"
import dayjs from "dayjs"
import { useState } from "react"
import { useShallow } from "zustand/react/shallow"

import { useAddEditDataTaskStore } from "./useAddEditDataTaskStore"
import DataViewSegmentedControl from "../../../../components/dataViewSegmentedControl/DataViewSegmentedControl"
import { DataSCView, ParentTrainingDataCollectionTask } from "../../../../types"
import IncidentLogDataContainer from "../../behaviorsIncidents/IncidentLogDataContainer"
import NewIncidentNotification from "../../behaviorsIncidents/NewIncidentNotification"

const DataTaskItem = ({ task }: { task: ParentTrainingDataCollectionTask }) => {
  const [viewType, setViewType] = useState<DataSCView>(DataSCView.TABLE)
  const { onOpen: openEditDataTask } = useAddEditDataTaskStore(
    useShallow((state) => ({ onOpen: state.onOpen }))
  )
  const [showIncidentLog, setShowIncidentLog] = useState(false)
  const isMobile = useMediaQuery("(max-width: 800px)")

  const toggleViewType = (view: DataSCView) => {
    setViewType(view)
    setShowIncidentLog(true)
  }

  return (
    <FlexBox
      direction="column"
      align="unset"
      style={{
        width: "100%",
        padding: SPACING.space4,
        borderRadius: SPACING.space2,
        border: `1px solid ${JoonUIColor.border.default}`,
        background: JoonUIColor.background.primaryNeutral,
      }}
    >
      <FlexBox
        direction="row"
        justify="space-between"
        wrap={false}
        align="center"
        gap={SPACING.space2}
      >
        <button
          onClick={() => setShowIncidentLog(!showIncidentLog)}
          style={{
            display: "flex",
            gap: SPACING.space2,
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            position: "relative",
          }}
        >
          <CaretDownIcon
            size={9}
            color={JoonUIColor.icon.neutral}
            style={{
              transition: "transform 0.2s ease-out",
              transform: showIncidentLog ? "rotate(90deg)" : "rotate(0deg)",
            }}
          />

          <FlexBox
            align={isMobile ? "flex-start" : "center"}
            direction={isMobile ? "column" : "row"}
            wrap={false}
          >
            <FlexBox
              direction="column"
              style={{ overflow: "hidden" }}
              wrap={false}
            >
              <Typography
                variant="bodyBold"
                style={{
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              >
                {task.title}
              </Typography>
              <Typography variant="bodyXSmall">
                Started on: {dayjs(task.date_assigned).format("MMM D, YYYY")}
              </Typography>
            </FlexBox>
            <NewIncidentNotification id={task.id} />
          </FlexBox>
        </button>
        {task.data_type && (
          <DataViewSegmentedControl
            view={viewType}
            setView={toggleViewType}
            order={[DataSCView.TABLE, DataSCView.GRAPH]}
          />
        )}
        <TextButton onClick={() => openEditDataTask(task)}>
          <GearIcon size={16} color={JoonUIColor.icon.neutral} />
        </TextButton>
      </FlexBox>
      <IncidentLogDataContainer
        showIncidentLog={showIncidentLog}
        dataView={viewType}
        id={task.id}
        isDataTask={true}
      />
    </FlexBox>
  )
}

export default DataTaskItem
