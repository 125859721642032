import { JoonUIColor, SPACING, Typography } from "@joonapp/web-shared"

import { ResourceTag as ResourceTagType } from "../../types"

const ResourceTag = ({ tag }: { tag: ResourceTagType }) => {
  return (
    <div
      style={{
        padding: `${SPACING.space1} ${SPACING.space2}`,
        borderRadius: SPACING.space2,
        background: JoonUIColor.background.lightGray,
        height: "fit-content",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Typography variant="bodyXSmall">{tag.title}</Typography>
    </div>
  )
}

export default ResourceTag
