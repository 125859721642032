import { JoonUIColor, SPACING, Typography } from "@joonapp/web-shared"
import CircularProgress from "@mui/material/CircularProgress"
import { useState } from "react"
import { useNavigate } from "react-router-dom"

import { useCreatePractice } from "../../hooks/useOnboarding"
import {
  signInWithGoogle,
  signUpWithGoogle,
} from "../../networking/authentication"
import { AlertInterface } from "../../types"

interface Props {
  type: "signin" | "signup"
  setFormAlert?: (alert: AlertInterface) => void
}

function GoogleAuth({ setFormAlert = () => {}, type }: Props) {
  const [pending, setPending] = useState<boolean>(false)
  const navigate = useNavigate()
  const { createUserAndPractice } = useCreatePractice()

  const onSigninWithGoogle = async () => {
    setPending(true)
    try {
      await signInWithGoogle()
      navigate("/patients")
    } catch (err) {
      // this is assuming that the error is because the user does not exist
      setFormAlert({
        type: "error",
        message: "User does not exist. Please sign up.",
      })
    } finally {
      setPending(false)
    }
  }

  const onSignUpWithGoogle = async () => {
    setPending(true)
    try {
      await signUpWithGoogle()
      await createUserAndPractice()
      navigate("/patients")
    } catch (error) {
      console.error(error)
    } finally {
      setPending(false)
    }
  }

  return (
    <button
      type="submit"
      disabled={pending}
      onClick={type === "signin" ? onSigninWithGoogle : onSignUpWithGoogle}
      style={{
        borderRadius: "40px",
        width: "100%",
        display: "flex",
        alignItems: "center",
        border: `1px solid #ddd`,
        position: "relative",
        textAlign: "center",
        justifyContent: "center",
        padding: `10px ${SPACING.space4}`,
        background: JoonUIColor.background.primaryNeutral,
      }}
    >
      <div
        style={{
          position: "absolute",
          alignItems: "center",
          display: "inline-flex",
          justifyContent: "center",
          width: "1.5em",
          height: "1.5em",
          left: SPACING.space4,
        }}
      >
        <img
          src={`https://uploads.divjoy.com/icon-google.svg`}
          alt={"Google"}
          style={{ display: "block", width: "100%" }}
        />
      </div>

      {pending ? (
        <CircularProgress size={24} />
      ) : (
        <Typography
          variant="body"
          color={JoonUIColor.text.secondary}
          style={{ fontWeight: "600" }}
        >
          {type === "signin" ? "Sign in" : "Sign up"} with Google
        </Typography>
      )}
    </button>
  )
}

export default GoogleAuth
