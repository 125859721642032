import { FlexBox, JoonUIColor, SPACING, Typography } from "@joonapp/web-shared"
import { useMediaQuery } from "@mui/material"
import { useId } from "react"

import { SummaryTableRow } from "./data"
import { useOverTimeProgressFilterStore } from "./OverTimeProgressFilters"
import { useInitialQuestToggle } from "./useInitialQuestToggle"
import { useOverTimeProgressStore } from "./useOverTimeProgressStore"
import { SkeletonLoaderRow } from "../../../../components/loading/SkeletonLoader"
import TherapistIconTooltip from "../../../../components/therapistIconTooltip/TherapistIconTooltip"
import CustomTooltip from "../../../../components/tooltip/CustomTooltip"
import { useOverTimeProgressQuery } from "../../../../networking/queries"
import { capitalizeFirstLetter } from "../../../../util/util"

export default function ChildQuestSummaryTable() {
  const isMobile = useMediaQuery("(max-width:800px)")
  const { data } = useOverTimeProgressQuery()
  const { filteredSummaryData } = useOverTimeProgressFilterStore()

  const { isLoading } = useOverTimeProgressQuery()
  useInitialQuestToggle()

  return (
    <>
      <table className="recurring-table">
        <thead
          style={{
            background: isMobile ? JoonUIColor.background.lightGray : undefined,
          }}
        >
          <tr>
            <th
              style={{
                width: "50px",
                padding: `${SPACING.space2} ${SPACING.space4}`,
              }}
            >
              <Typography variant="caption">Plot</Typography>
            </th>
            <th style={{ padding: `${SPACING.space2} 0` }}>
              <Typography variant="caption">Behavioral target</Typography>
            </th>
            {!isMobile && (
              <th style={{ padding: `${SPACING.space2} 0` }}>
                <Typography variant="caption">Time of day</Typography>
              </th>
            )}
            <th
              style={{
                textAlign: "right",
                padding: `${SPACING.space2} ${SPACING.space4}`,
                maxWidth: isMobile ? "100px" : undefined,
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
            >
              <Typography variant="caption">Avg. frequency</Typography>
            </th>
          </tr>
        </thead>
        {!isLoading && (
          <tbody>
            {filteredSummaryData?.map((row: any, i: number) => (
              <Row key={i} row={row} />
            ))}
          </tbody>
        )}
      </table>
      {isLoading && <SkeletonLoaderRow numRows={5} height={40} />}
      {!isLoading && filteredSummaryData?.length === 0 && (
        <FlexBox
          style={{
            padding: SPACING.space4,
            justifyContent: "center",
            alignItems: "center",
            width: "fit-content",
            borderRadius: SPACING.space4,
            background: JoonUIColor.background.lightAccent,
            margin: `${SPACING.space4} auto`,
          }}
        >
          <Typography variant="body">
            {data?.summary?.length === 0
              ? "No data available for selected time period."
              : "No results. Try removing some of the filters above to try again."}
          </Typography>
        </FlexBox>
      )}
    </>
  )
}

const RowCheckbox = ({ checked }: { checked: boolean }) => {
  const { selectedQuests } = useOverTimeProgressStore()
  const hasMaxSelected = selectedQuests.length >= 8
  const tooltipId = useId()

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "16px",
          width: "16px",
          background: checked ? JoonUIColor.background.accent : "white",
          border: `1px solid ${JoonUIColor.border.accent}`,
          borderRadius: "50%",
          margin: "0 auto",
        }}
        data-tooltip-id={tooltipId}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="11"
          height="12"
          viewBox="0 0 11 12"
          fill="none"
        >
          <path
            d="M9.15606 3.33398L4.26714 8.22285L2.04492 6.00065"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
      {hasMaxSelected && (
        <CustomTooltip id={tooltipId}>
          <Typography
            variant="caption"
            color={JoonUIColor.text.inverted}
            textAlign="left"
          >
            You can only plot up to 8 datapoints.
          </Typography>
          <Typography
            variant="bodySmall"
            color={JoonUIColor.text.inverted}
            textAlign="left"
          >
            Remove a datapoint to plot this one.
          </Typography>
        </CustomTooltip>
      )}
    </>
  )
}

const Row = ({ row }: { row: SummaryTableRow }) => {
  const { selectedQuests, toggleSelectedQuest, setHighlightedQuest } =
    useOverTimeProgressStore()
  const isMobile = useMediaQuery("(max-width:800px)")
  const questColor = selectedQuests.find((quest) => quest.id === row.id)?.color
  const isSelected = !!questColor

  return (
    <>
      <tr
        className="recurring-table-row"
        onMouseEnter={() => isSelected && setHighlightedQuest(row.id)}
        onMouseLeave={() => setHighlightedQuest(null)}
        onClick={() => toggleSelectedQuest({ id: row.id, name: row.quest })}
      >
        <td
          style={{
            padding: isMobile
              ? `${SPACING.space2} ${SPACING.space05}`
              : `${SPACING.space4} ${SPACING.space05}`,
          }}
        >
          <RowCheckbox
            checked={selectedQuests.some((quest) => quest.id === row.id)}
          />
        </td>
        <td
          style={{
            padding: isMobile
              ? `${SPACING.space2} ${SPACING.space05}`
              : `${SPACING.space4} ${SPACING.space05}`,
          }}
        >
          <FlexBox
            align="center"
            gap={SPACING.space2}
            direction="row"
            wrap={false}
          >
            {questColor && (
              <div
                style={{
                  minWidth: SPACING.space3,
                  minHeight: SPACING.space3,
                  borderRadius: "50%",
                  background: questColor,
                }}
              />
            )}
            <FlexBox align="center" wrap={false} gap={SPACING.space2}>
              {(row.isTherapistCreated || !!row.hasTarget) && (
                <TherapistIconTooltip
                  text={
                    row.isTherapistCreated
                      ? "Therapist created"
                      : "Parent created behavioral target"
                  }
                />
              )}
              <Typography variant={"caption"} textAlign="left">
                {row.quest}
              </Typography>
            </FlexBox>
          </FlexBox>
        </td>
        {!isMobile && (
          <td>
            <Typography variant="bodySmall">
              {capitalizeFirstLetter(row.routine)}
            </Typography>
          </td>
        )}
        <td
          style={{
            padding: isMobile
              ? `${SPACING.space2} ${SPACING.space4}`
              : `${SPACING.space4}`,
            textAlign: "right",
          }}
        >
          <Typography variant="caption">
            {row.total ? `${(row.completed / 12).toFixed(2)}` : "-"}
          </Typography>
        </td>
      </tr>
    </>
  )
}
