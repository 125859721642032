import { ParentTraining } from "@joonapp/web-shared"
import { useMutation } from "@tanstack/react-query"
import { useParams } from "react-router-dom"

import { editParentTraining } from "../../../../networking/parentTraining"
import {
  QUERY_KEYS,
  useCurrentTrainingQuery,
} from "../../../../networking/queries"
import { queryClient } from "../../../../queryClient"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../../util/analytics"

const useEditTrainingMutation = () => {
  const { trainingId } = useParams()
  const { data: currentTraining } = useCurrentTrainingQuery()

  const { userId } = useParams()

  const queryKey = [QUERY_KEYS.PARENT_TRAINING, Number(trainingId)]
  const trainingsQueryKey = [
    QUERY_KEYS.PARENT_TRAININGS,
    userId,
    currentTraining?.user.id,
  ]

  return useMutation({
    mutationFn: editParentTraining,
    onMutate: async ({
      status,
      title,
      mastery_criteria,
      mastery_criteria_measurement,
      module_ids,
    }) => {
      // Optimistically update the parent training list
      // Snapshot the previous value
      const previousTraining =
        queryClient.getQueryData<ParentTraining>(queryKey)

      const newTraining = {
        ...previousTraining,
        ...(title && { title }),
        ...(mastery_criteria !== undefined && { mastery_criteria }),
        ...(mastery_criteria_measurement && { mastery_criteria_measurement }),
        ...(status && { status }),
      } as ParentTraining

      queryClient.setQueryData<ParentTraining>(queryKey, newTraining)

      const previousTrainings =
        queryClient.getQueryData<ParentTraining[]>(trainingsQueryKey)

      const newTrainings = previousTrainings
        ? previousTrainings.map((training) =>
            training.id === newTraining.id ? newTraining : training
          )
        : [newTraining]

      queryClient.setQueryData<ParentTraining[]>(
        trainingsQueryKey,
        newTrainings
      )

      return { previousTraining, moduleIds: module_ids }
    },
    onSuccess: (response: ParentTraining, variables: any, context: any) => {
      // If updating modules, then update the cache with response data

      if (context.moduleIds) {
        queryClient.setQueryData<ParentTraining>(queryKey, response)
      }

      if (
        variables.mastery_criteria_measurement !==
        context.previousTraining?.mastery_criteria_measurement
      ) {
        if (context.previousTraining?.mastery_criteria_measurement) {
          trackAnalyticEvent(ANALYTIC_EVENTS.edit_mastery_criteria)
        } else {
          trackAnalyticEvent(ANALYTIC_EVENTS.add_mastery_criteria)
        }
      }
      trackAnalyticEvent(ANALYTIC_EVENTS.edit_parent_training)
    },
    onError: (
      _err: any,
      _variables: any,
      context: { previousTraining: ParentTraining | undefined } | undefined
    ) => {
      // If the mutation fails,
      // use the context returned from onMutate to roll back
      if (context?.previousTraining) {
        queryClient.setQueryData<ParentTraining>(
          queryKey,
          context.previousTraining
        )
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries(queryKey)
      queryClient.invalidateQueries(trainingsQueryKey)
    },
  })
}

export default useEditTrainingMutation
