import {
  Button,
  CalloutBox,
  FlexBox,
  JoonUIColor,
  Loader,
  SPACING,
  TextInput,
  Typography,
} from "@joonapp/web-shared"
import { useNavigate } from "react-router-dom"

import useErrors from "../../hooks/useErrors"
import useOnboarding, {
  OnboardingSteps,
  useCreatePractice,
} from "../../hooks/useOnboarding"
import {
  createErrorFromSignupResponse,
  isAnEmail,
  isUsingInAppBrowser,
} from "../../util/util"
import GoogleAuth from "../auth/GoogleAuth"

const EmailPw = () => {
  const { submitting, formInfo, setFormInfo, setSubmitting } = useOnboarding()
  const { formErrors, setFormError } = useErrors()
  const { signupAndCreatePractice } = useCreatePractice()

  const navigate = useNavigate()
  const formIsValid = isAnEmail(formInfo.email) && formInfo.password.length > 0

  const saveFormAndLogin = async () => {
    setSubmitting(true)
    try {
      await signupAndCreatePractice()
      navigate("/patients")
    } catch (error: any) {
      const errorMessage = createErrorFromSignupResponse(error)
      setFormError(OnboardingSteps.emailpw, errorMessage)
    } finally {
      setSubmitting(false)
    }
  }

  if (submitting)
    return (
      <FlexBox
        align="center"
        justify="center"
        gap={SPACING.space4}
        direction="column"
        style={{ height: "60vh" }}
      >
        <Loader color={JoonUIColor.semantic.primary} />
        <Typography variant="h3">Creating your account...</Typography>
      </FlexBox>
    )

  return (
    <FlexBox direction="column" gap={SPACING.space6}>
      <Typography
        variant="h2"
        textAlign="center"
        color={JoonUIColor.text.primaryAccent}
      >
        Create your Joon account
      </Typography>

      <FlexBox direction="column" gap={SPACING.space4}>
        <FlexBox>
          <Typography variant="caption">Email</Typography>
          <TextInput
            name="email"
            value={formInfo.email}
            fullWidth
            icon="email"
            placeholder="example@gmail.com"
            onChange={(e) => setFormInfo("email", e.target.value)}
          />
        </FlexBox>
        <FlexBox>
          <Typography variant="caption">Password</Typography>
          <TextInput
            name="password"
            value={formInfo.password}
            type="password"
            icon="password"
            placeholder="Password"
            fullWidth
            onChange={(e) => setFormInfo("password", e.target.value)}
          />
        </FlexBox>
      </FlexBox>
      <FlexBox direction="column" gap={SPACING.space4} justify="center">
        <Button
          text="Sign Up"
          isLoading={submitting}
          fullWidth
          onClick={() => {
            if (formIsValid) saveFormAndLogin()
          }}
        />

        {!isUsingInAppBrowser && (
          <>
            <Typography textAlign="center" variant="bodyXSmall">
              OR
            </Typography>

            <GoogleAuth type="signup" />
          </>
        )}

        {Object.keys(formErrors).length > 0 && (
          <CalloutBox type="error" fullWidth>
            <Typography variant="bodySmall">
              {formErrors[OnboardingSteps.emailpw]}
            </Typography>
          </CalloutBox>
        )}

        <Typography variant="bodyXSmall">
          By continuing, you agree to the{" "}
          <a target="_blank" rel="noreferrer" href="https://www.joonapp.io/baa">
            Business Associate Agreement
          </a>
          ,{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.joonapp.io/terms-of-service"
          >
            Terms of Service
          </a>
          , &{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.joonapp.io/privacy-policy"
          >
            Privacy Policy
          </a>
        </Typography>
      </FlexBox>
    </FlexBox>
  )
}

export default EmailPw
