import {
  Button,
  FlexBox,
  JoonUIColor,
  Modal,
  SPACING,
  TextButton,
  Typography,
  UserRole,
} from "@joonapp/web-shared"
import { useEffect, useState } from "react"
import { create } from "zustand"

import useSubscription from "./useSubscription"
import { getStripeBillingUrl } from "../../networking/practice"
import { usePracticeQuery } from "../../networking/queries"
import useAdminUser from "../../pages/settings/useAdminUser"
import { useAuth } from "../../util/auth"

interface SubscriptionExpiredModalStore {
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
}

export const useSubscriptionExpiredModalStore =
  create<SubscriptionExpiredModalStore>((set) => ({
    isOpen: false,
    onClose: () => set({ isOpen: false }),
    onOpen: () => set({ isOpen: true }),
  }))

const SubscriptionExpiredModal = () => {
  const { isOpen, onClose, onOpen } = useSubscriptionExpiredModalStore()
  const { practice } = usePracticeQuery()
  const [isLoading, setIsLoading] = useState(false)
  const userIsAdmin = useAdminUser()
  const { signout } = useAuth()
  const { isExpiredSubscription } = useSubscription()

  const firstAdmin = practice?.profiles.find(
    (profile) => profile.role === UserRole.ADMIN
  )

  useEffect(() => {
    if (isExpiredSubscription) onOpen()
    else onClose()
  }, [isExpiredSubscription, onClose, onOpen])

  const onClickUpdateSubscription = async () => {
    if (!practice) return
    setIsLoading(true)
    const response = await getStripeBillingUrl(practice.id)
    window.open(response, "_blank")
    setIsLoading(false)
  }

  return (
    <Modal mandatory isOpen={isOpen} onClose={onClose}>
      <FlexBox
        direction="column"
        align="stretch"
        wrap={false}
        gap={SPACING.space4}
        style={{
          padding: SPACING.space6,
          maxWidth: "400px",
          width: "100%",
        }}
      >
        <Typography variant="h2">Your subscription is expired!</Typography>
        <Typography variant="body">
          Your subscription is <strong>currently inactive</strong> and you no
          longer have access to any client data. To regain access, please{" "}
          {userIsAdmin
            ? "update your subscription below."
            : "request an upgrade through your Clinic Admin."}
        </Typography>

        {!userIsAdmin && (
          <FlexBox
            direction="column"
            align="stretch"
            style={{
              background: JoonUIColor.background.lightBlue,
              padding: SPACING.space4,
              borderRadius: SPACING.space2,
            }}
          >
            <Typography variant="bodyBold">
              Contact your clinic admin to request an upgrade:
            </Typography>
            <Typography variant="body">
              Admin: {firstAdmin?.user.name} {firstAdmin?.user.last_name}
            </Typography>
            <Typography variant="body">
              Email: {firstAdmin?.user.email}
            </Typography>
          </FlexBox>
        )}

        <Typography variant="bodySmall">
          Or if you believe this is an error, contact Joon Health Customer
          Support at <a href="mailto:brad@joonapp.io">brad@joonapp.io</a>
        </Typography>

        <FlexBox
          direction="row"
          justify="space-between"
          align="center"
          wrap={false}
          gap={SPACING.space4}
          style={{ marginTop: SPACING.space4 }}
        >
          <TextButton onClick={signout}>
            <Typography
              variant="bodyBold"
              color={JoonUIColor.text.primaryAccent}
            >
              Log out instead
            </Typography>
          </TextButton>
          {userIsAdmin && (
            <Button
              text="Update subscription"
              onClick={onClickUpdateSubscription}
              isLoading={isLoading}
            />
          )}
        </FlexBox>
      </FlexBox>
    </Modal>
  )
}

export default SubscriptionExpiredModal
