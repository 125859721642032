import {
  Button,
  FlexBox,
  JoonUIColor,
  Modal,
  ModalHeader,
  SPACING,
  TextButton,
  TextInput,
  Typography,
} from "@joonapp/web-shared"
import { useState } from "react"
import { create } from "zustand"

import useEditPersonalInfoMutation from "./useEditPersonalInfoMutation"
import { usePracticeQuery, useUserQuery } from "../../networking/queries"
import { getCurrentUserProfileInfo } from "../../util/util"

interface EditPersonalInfoModalStore {
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
}

export const useEditPersonalInfoModalStore = create<EditPersonalInfoModalStore>(
  (set) => ({
    isOpen: false,
    onClose: () => set({ isOpen: false }),
    onOpen: () => set({ isOpen: true }),
  })
)

const EditPersonalInfoModal = () => {
  const { onClose, isOpen } = useEditPersonalInfoModalStore()
  const { user } = useUserQuery()
  const { practice } = usePracticeQuery()
  const currentUserProfile =
    practice && getCurrentUserProfileInfo(practice, user)

  const [profileInfo, setProfileInfo] = useState({
    name: user.name,
    last_name: user.last_name,
    nickname: currentUserProfile?.nickname ?? "",
  })

  const editPersonalInfoMutation = useEditPersonalInfoMutation()

  const onClickSaveProfile = async () => {
    if (!user || !practice) return
    await editPersonalInfoMutation.mutateAsync({
      userId: user.id,
      practiceId: practice.id,
      profileInfo,
    })
    onClose()
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      style={{ width: "min(95vw, 400px)" }}
    >
      <ModalHeader
        title="Edit Personal Info"
        onClose={onClose}
        showBorderBottom
      />
      <div style={{ padding: SPACING.space6, paddingTop: SPACING.space4 }}>
        <FlexBox direction="column" gap={SPACING.space6}>
          <FlexBox direction="column" gap={SPACING.space1}>
            <Typography variant="bodyBold">First Name</Typography>
            <TextInput
              name={"First Name"}
              placeholder="First Name"
              value={profileInfo.name}
              onChange={(e) =>
                setProfileInfo({ ...profileInfo, name: e.target.value })
              }
              fullWidth
            />
          </FlexBox>
          <FlexBox direction="column" gap={SPACING.space1}>
            <Typography variant="bodyBold">Last Name</Typography>
            <TextInput
              name={"Last Name"}
              placeholder="Last Name"
              value={profileInfo.last_name}
              onChange={(e) =>
                setProfileInfo({ ...profileInfo, last_name: e.target.value })
              }
              fullWidth
            />
          </FlexBox>
          <FlexBox direction="column" gap={SPACING.space1}>
            <Typography variant="bodyBold">
              Name your clients call you
            </Typography>
            <TextInput
              name={"Name your clients call you"}
              placeholder="Name your clients call you"
              value={profileInfo.nickname}
              onChange={(e) =>
                setProfileInfo({ ...profileInfo, nickname: e.target.value })
              }
              fullWidth
            />
          </FlexBox>
        </FlexBox>
      </div>
      <FlexBox
        align="center"
        justify="space-between"
        wrap={false}
        style={{
          position: "sticky",
          bottom: 0,
          marginTop: "auto",
          padding: SPACING.space6,
        }}
      >
        <TextButton onClick={onClose}>
          <Typography variant="bodyBold" color={JoonUIColor.text.primaryAccent}>
            Cancel
          </Typography>
        </TextButton>
        <Button
          text="Save"
          onClick={onClickSaveProfile}
          isLoading={editPersonalInfoMutation.isLoading}
        />
      </FlexBox>
    </Modal>
  )
}

export default EditPersonalInfoModal
