import { FlexBox, SPACING, Typography } from "@joonapp/web-shared"

const FavoritesEmptyState = () => {
  return (
    <FlexBox direction="column" align="center" gap={SPACING.space2}>
      <Typography variant="body" textAlign="center">
        You don’t have anything favorited yet.
      </Typography>
      <Typography
        variant="body"
        style={{ marginBottom: SPACING.space4 }}
        textAlign="center"
      >
        Click the heart icon on a resource to favorite it!
      </Typography>
      <img
        src={"/images/emptyFavoritesImage.svg"}
        alt="Resource item with arrow pointing to Heart icon"
        style={{
          maxWidth: "90vw",
          transform: "translateX(20px)",
        }}
      />
    </FlexBox>
  )
}

export default FavoritesEmptyState
