import {
  Button,
  FlexBox,
  JoonUIColor,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import { useCalendlyEventListener, InlineWidget } from "react-calendly"
import { create } from "zustand"

import JoonButton from "../../components/buttons/JoonButton"
import CustomModal from "../../components/modals/CustomModal"
import { usePracticeQuery, useUserQuery } from "../../networking/queries"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"
import { getCurrentUserProfileInfo } from "../../util/util"

interface WebinarModalStore {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
  modalView: "default" | "success" | "timesDontWork"
  setModalView: (view: "default" | "success" | "timesDontWork") => void
}

export const useWebinarModalStore = create<WebinarModalStore>((set) => ({
  isOpen: false,
  onOpen: () => set({ isOpen: true }),
  onClose: () => set({ isOpen: false, modalView: "default" }),
  modalView: "default",
  setModalView: (view) => set({ modalView: view }),
}))

const Webinar = () => {
  const { onOpen } = useWebinarModalStore()
  const { user } = useUserQuery()
  return (
    <FlexBox
      direction="column"
      gap={SPACING.space2}
      align="center"
      style={{
        background: JoonUIColor.background.lightAccent,
        padding: SPACING.space3,
        borderRadius: SPACING.space2,
      }}
    >
      <Typography variant="h3" textAlign="center">
        Need help getting started?
      </Typography>
      <Typography
        variant="bodySmall"
        textAlign="center"
        color={JoonUIColor.text.secondary}
      >
        Get all of your Joon questions answered by our resident therapist{" "}
        <Typography variant="caption" color={JoonUIColor.text.primaryAccent}>
          Joe Raiker, PhD.
        </Typography>
      </Typography>
      <Button
        onClick={() => {
          trackAnalyticEvent(ANALYTIC_EVENTS.webinar_click_interested, {
            email: user?.email,
          })
          onOpen()
        }}
        // @ts-ignore
        style={{ width: "fit-content" }}
        text="Schedule a Call"
        buttonType="secondary"
      />
    </FlexBox>
  )
}

export const WebinarModal = () => {
  const { modalView, setModalView, isOpen, onClose } = useWebinarModalStore()
  const { user } = useUserQuery()
  const { practice } = usePracticeQuery()

  useCalendlyEventListener({
    onEventScheduled: () => {
      trackAnalyticEvent(ANALYTIC_EVENTS.webinar_scheduled, {
        name: user?.name,
        email: user?.email,
      })
    },
  })

  if (!user || !practice) return null

  const practiceProfile = getCurrentUserProfileInfo(practice, user)

  return (
    <CustomModal isOpen={isOpen} handleClose={onClose}>
      <div
        className="modal"
        style={{ width: "min(500px, 100vw)", overflow: "hidden" }}
      >
        {modalView === "default" ? (
          <>
            <InlineWidget
              url="https://calendly.com/joonforkids/meeting-with-joon?hide_gdpr_banner=1"
              prefill={{
                email: user?.email,
                name: user?.name,
                customAnswers: {
                  a1: practiceProfile?.profession,
                },
              }}
            />
            <button
              className="blue-text body-text"
              style={{ textDecoration: "underline", padding: "8px 0 16px 0" }}
              onClick={() => setModalView("timesDontWork")}
            >
              I'm interested, but these times don't work for me.
            </button>
          </>
        ) : (
          modalView === "timesDontWork" && <WebinarTimesDontWork />
        )}
      </div>
    </CustomModal>
  )
}

export default Webinar

const WebinarTimesDontWork = () => {
  const { onClose } = useWebinarModalStore()
  const { user } = useUserQuery()

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: SPACING.space6,
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginRight: "10px",
          }}
        >
          <img
            src="/images/icons/check-blue.svg"
            alt="checkmark"
            style={{ height: "30px", width: "30px", marginRight: "10px" }}
          />
          <div
            style={{
              fontSize: "20px",
              fontWeight: "500",
              lineHeight: "22px",
            }}
          >
            <div className="mb-12">
              We will send an email to "{user?.email}" shortly with more details
              for scheduling a better time.
            </div>
          </div>
        </div>
        <JoonButton
          onClick={() => {
            onClose()
            trackAnalyticEvent(ANALYTIC_EVENTS.webinar_times_dont_work, {
              name: user?.name,
              email: user?.email,
            })
          }}
          buttonType="blueSecondary"
          style={{
            maxWidth: "130px",
            padding: "10px 20px",
            margin: "12px auto 0 auto",
          }}
        >
          OK!
        </JoonButton>
      </div>
    </>
  )
}
