import { create } from "zustand"

import useResourcesFilterStore from "./useResourceFilterStore"

interface AddModuleStore {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
  selectedModuleId: number | null
  setSelectedModuleId: (id: number | null) => void

  modulesIdsToAdd: number[]
  setModulesIdsToAdd: (ids: number[]) => void
  toggleModuleIdToAdd: (id: number) => void
  clearModulesIdsToAdd: () => void
}

const useAddModuleStore = create<AddModuleStore>((set) => ({
  isOpen: false,
  onOpen: () => set({ isOpen: true, selectedModuleId: null }),
  onClose: () => {
    useResourcesFilterStore.getState().clearFilters()
    set({ isOpen: false, modulesIdsToAdd: [], selectedModuleId: null })
  },
  selectedModuleId: null,
  setSelectedModuleId: (selectedModuleId) => set({ selectedModuleId }),

  modulesIdsToAdd: [],
  setModulesIdsToAdd: (modulesIdsToAdd) => set({ modulesIdsToAdd }),
  toggleModuleIdToAdd: (id) =>
    set((state) => ({
      modulesIdsToAdd: state.modulesIdsToAdd.includes(id)
        ? state.modulesIdsToAdd.filter((moduleId) => moduleId !== id)
        : [...state.modulesIdsToAdd, id],
    })),
  clearModulesIdsToAdd: () => set({ modulesIdsToAdd: [] }),
}))

export default useAddModuleStore
