import { SegmentedControl } from "@joonapp/web-shared"
import { useMediaQuery } from "@mui/material"
import { create } from "zustand"

import {
  useReceivedInvitesQuery,
  useSentInvitesQuery,
} from "../../networking/queries"

export enum ClientPageToggleOption {
  CLIENTS = "all",
  INVITES = "active",
}

interface ClientPageToggleStore {
  selectedOption: ClientPageToggleOption
  setSelectedOption: (option: ClientPageToggleOption) => void
}

export const useClientPageToggleStore = create<ClientPageToggleStore>(
  (set) => ({
    selectedOption: ClientPageToggleOption.CLIENTS,
    setSelectedOption: (option) => set({ selectedOption: option }),
  })
)

const ClientPageToggle = () => {
  const { selectedOption, setSelectedOption } = useClientPageToggleStore()
  const { data: parentInvites } = useReceivedInvitesQuery()
  const { data: sentInvites } = useSentInvitesQuery()
  const isMobile = useMediaQuery("(max-width: 800px)")
  const totalInvites = (parentInvites?.length || 0) + (sentInvites?.length || 0)

  const clientPageToggleOptions = [
    { label: "All Clients", value: ClientPageToggleOption.CLIENTS },
    {
      label: `Invites (${totalInvites})`,
      value: ClientPageToggleOption.INVITES,
    },
  ]

  return (
    <SegmentedControl
      options={clientPageToggleOptions}
      value={selectedOption}
      setValue={(option) => setSelectedOption(option as ClientPageToggleOption)}
      fullWidth={isMobile}
    />
  )
}

export default ClientPageToggle
