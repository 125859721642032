import { Button } from "@joonapp/web-shared"

import { useAuth } from "../../util/auth"

const LogoutButton = () => {
  const { signout } = useAuth()

  return (
    <Button
      onClick={signout}
      buttonType="redPrimary"
      style={{
        margin: "5vh auto 10vh auto",
        width: "150px",
      }}
      text="Logout"
    >
      Logout
    </Button>
  )
}

export default LogoutButton
