import {
  Button,
  Checkbox,
  FlexBox,
  JoonUIColor,
  ModalHeader,
  SelectInput,
  SPACING,
  TextButton,
  Typography,
  UserRole,
} from "@joonapp/web-shared"
import { useMemo } from "react"
import { useNavigate } from "react-router-dom"

import { useResourcesSidePanelStore } from "./ResourcesSidePanel"
import useAssignModuleSidePanelStore from "./useAssignModulesSidePanelStore"
import OpenPDFButton from "../../components/buttons/OpenPDFButton"
import {
  QUERY_KEYS,
  useParentTrainingsQuery,
  usePatientGroupsQuery,
  usePatientParentsQuery,
} from "../../networking/queries"
import { queryClient } from "../../queryClient"
import { ParentTrainingTab, PatientInfoTab } from "../../types"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"
import { useAddTrainingMutation } from "../patientInfo/parentTraining/mutations/useAddTrainingMutation"
import useEditTrainingMutation from "../patientInfo/parentTraining/mutations/useEditTrainingMutation"
import useParentTrainingStore from "../patientInfo/parentTraining/useParentTrainingStore"
import SidePanel from "../patientInfo/SidePanel"

const AssignModuleSidePanel = () => {
  const {
    isOpen,
    onClose: closeAssignResource,
    setSelectedPatientId,
    selectedPatientId,
    selectedTrainingId,
    setSelectedTrainingId,
  } = useAssignModuleSidePanelStore()
  const { data: patientGroups } = usePatientGroupsQuery()
  const { selectedParentId, setSelectedParentId } = useParentTrainingStore()

  const { selectedModuleId } = useResourcesSidePanelStore()
  const patients = patientGroups?.flatMap((group: any) =>
    group.profiles.filter((profile: any) => profile.role === UserRole.PATIENT)
  )
  const selectedFamilyId = patients?.find(
    (patient: any) => patient.user.id === selectedPatientId
  )?.user.family_id
  const { data: parents, isLoading: isLoadingParents } = usePatientParentsQuery(
    selectedFamilyId || undefined
  )
  const { data: parentTrainings, isLoading: isLoadingParentTrainings } =
    useParentTrainingsQuery()
  const addParentTrainingMutation = useAddTrainingMutation()
  const editTrainingMutation = useEditTrainingMutation()
  const navigate = useNavigate()

  const parentTrainingDropdownOptions = useMemo(() => {
    return (
      parentTrainings
        ?.map((training) => ({ value: training.id, label: training.title }))
        .concat({ value: null as any, label: "Create new training" }) || []
    )
  }, [parentTrainings])

  const onClickPatient = (patientId: number) => {
    setSelectedPatientId(patientId)
    setSelectedParentId(null)
    trackAnalyticEvent(ANALYTIC_EVENTS.select_patient_for_task)
  }

  const onClickAssign = async () => {
    if (!selectedParentId || !selectedModuleId || !selectedPatientId) return

    if (selectedTrainingId) {
      const existingModuleIds =
        parentTrainings
          ?.find((parentTraining) => parentTraining.id === selectedTrainingId)
          ?.assignments?.map((module) => module.learning_module.id) || []
      await editTrainingMutation.mutateAsync({
        id: selectedTrainingId,
        module_ids: [...existingModuleIds, selectedModuleId],
      })
      navigate(
        `/patients/${selectedPatientId}/${PatientInfoTab.PARENT_TRAINING}/${selectedTrainingId}/${ParentTrainingTab.MODULES}`
      )
    } else {
      const newTraining = await addParentTrainingMutation.mutateAsync({
        user_id: selectedParentId,
        child_id: selectedPatientId,
        title: "Untitled",
        mastery_criteria: "",
        module_ids: [selectedModuleId],
      })

      navigate(
        `/patients/${selectedPatientId}/${PatientInfoTab.PARENT_TRAINING}/${newTraining.id}/${ParentTrainingTab.MODULES}`
      )
    }

    queryClient.invalidateQueries([QUERY_KEYS.PARENT_TRAININGS])
    closeAssignResource()
    trackAnalyticEvent(ANALYTIC_EVENTS.assign_module_to_training)
  }

  return (
    <SidePanel isOpen={isOpen}>
      <ModalHeader
        title={
          (
            <FlexBox direction="column" gap={SPACING.space1}>
              <Typography variant="h3">Assign Resource</Typography>
              <OpenPDFButton
                text="Preview parent experience"
                imageUrl="/images/parentTraining.pdf"
              />
            </FlexBox>
          ) as any
        }
        onClose={closeAssignResource}
        showBorderBottom
      />
      <FlexBox
        direction="column"
        wrap={false}
        style={{
          padding: SPACING.space6,
          paddingTop: SPACING.space4,
          overflowY: "auto",
        }}
        gap={SPACING.space4}
      >
        <FlexBox direction="column" gap={SPACING.space2}>
          <Typography variant="bodyBold">Select client</Typography>
          <FlexBox direction="column" gap={SPACING.space1}>
            {patients
              ?.sort((a: any, b: any) => a.user.name.localeCompare(b.user.name))
              .map((patient: any, i: number) => (
                <Checkbox
                  hideBorder
                  inputType="radio"
                  name="Client"
                  selected={selectedPatientId === patient.user.id}
                  onChange={() => onClickPatient(patient.user.id)}
                  key={i}
                  label={
                    <Typography
                      textAlign="left"
                      variant="body"
                      style={{ marginLeft: SPACING.space1 }}
                    >
                      {patient.user.name}
                    </Typography>
                  }
                />
              ))}
          </FlexBox>
        </FlexBox>
        <FlexBox direction="column" gap={SPACING.space2}>
          {selectedPatientId && (
            <Typography variant="bodyBold">Select client's parent:</Typography>
          )}

          <FlexBox direction="column" gap={SPACING.space1}>
            {selectedFamilyId && isLoadingParents && (
              <Typography variant="body" style={{ marginLeft: SPACING.space1 }}>
                Loading parents...
              </Typography>
            )}
            {parents?.map((parent: any, i: number) => (
              <Checkbox
                hideBorder
                inputType="radio"
                name="Parent"
                selected={selectedParentId === parent.user.id}
                onChange={() => setSelectedParentId(parent.user.id)}
                key={i}
                label={
                  <Typography
                    textAlign="left"
                    variant="body"
                    style={{ marginLeft: SPACING.space1 }}
                  >
                    {parent.user.name}
                  </Typography>
                }
              />
            ))}
          </FlexBox>
        </FlexBox>
        {selectedParentId &&
          (isLoadingParentTrainings ? (
            <Typography variant="body" style={{ marginLeft: SPACING.space1 }}>
              Loading trainings...
            </Typography>
          ) : (
            <SelectInput
              name="Training Selection"
              label="Select training"
              isMulti={false}
              options={parentTrainingDropdownOptions}
              selectedOption={
                parentTrainingDropdownOptions.find(
                  (option) => option.value === selectedTrainingId
                ) as any
              }
              setSelectedOption={(option) => {
                if (option) setSelectedTrainingId(option.value as number)
              }}
              fullWidth
            />
          ))}
      </FlexBox>

      <FlexBox
        direction="row"
        wrap={false}
        justify="space-between"
        style={{
          padding: SPACING.space6,
          marginTop: "auto",
          position: "sticky",
          bottom: 0,
        }}
      >
        <TextButton onClick={closeAssignResource}>
          <Typography
            variant="bodyBold"
            style={{ color: JoonUIColor.semantic.primary }}
          >
            Cancel
          </Typography>
        </TextButton>
        <Button
          text={selectedTrainingId ? "Assign" : "Set up training"}
          onClick={onClickAssign}
          isLoading={
            addParentTrainingMutation.isLoading ||
            editTrainingMutation.isLoading
          }
        />
      </FlexBox>
    </SidePanel>
  )
}

export default AssignModuleSidePanel
