import {
  ChevronLeftIcon,
  CloseIcon,
  FlexBox,
  JoonUIColor,
  SPACING,
  TextButton,
  Typography,
} from "@joonapp/web-shared"
import { useMediaQuery } from "@mui/material"
import { useRef, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"

import FeedbackButton from "./FeedbackButton"
import { PatientNavSelect } from "./PatientInfoNav"
import useMobileSidebarStore from "./useMobileSidebarStore"
import useHiddenElement from "../../hooks/useHiddenElement"

export const MobileSidebar = () => {
  const { isOpen, onClose } = useMobileSidebarStore()
  const { userId } = useParams()
  const sidebarRef = useRef<HTMLDivElement>(null)
  const navigate = useNavigate()
  useHiddenElement(sidebarRef, !isOpen)
  const isMobile = useMediaQuery("(max-width: 800px)")

  const isViewingPatient = Number.isFinite(Number(userId))

  if (!isMobile) return null

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        position: "absolute",
        top: 0,
        left: isOpen ? 0 : "-100%",
        height: "100dvh",
        width: "100vw",
        zIndex: 500,
        background: JoonUIColor.background.primaryNeutral,
        pointerEvents: isOpen ? "auto" : "none",
        transition: "left 0.2s cubic-bezier(0.25, 0.1, 0.25, 1.0)",
      }}
      ref={sidebarRef}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          background: JoonUIColor.background.xlightGray,
          padding: `${SPACING.space2} ${SPACING.space4}`,
          borderBottom: `1px solid ${JoonUIColor.border.default}`,
        }}
      >
        <img
          src={"/images/logos/JoonHealth.svg"}
          alt="Joon logo"
          style={{ height: SPACING.space6 }}
        />
        <TextButton onClick={onClose}>
          <CloseIcon size={20} color={JoonUIColor.icon.neutral} />
        </TextButton>
      </div>
      {isViewingPatient ? (
        <FlexBox direction="column" style={{ padding: SPACING.space4 }}>
          <button
            onClick={() => {
              navigate("/patients")
              onClose()
            }}
          >
            <FlexBox gap={SPACING.space2} wrap={false} align="center">
              <ChevronLeftIcon size={20} color={JoonUIColor.text.secondary} />
              <Typography variant="caption" color={JoonUIColor.text.secondary}>
                All clients
              </Typography>
            </FlexBox>
          </button>
          <FlexBox
            direction="column"
            gap={SPACING.space2}
            style={{ padding: SPACING.space4 }}
          >
            <PatientNavSelect />
          </FlexBox>
        </FlexBox>
      ) : (
        <NavButton2 to="/patients" label="All clients" />
      )}
      <NavButton2 to="/resources" label="Module library" />
      <NavButton2 to="/security" label="HIPAA" />
      <div
        style={{
          width: "100%",
          borderBottom: `1px solid ${JoonUIColor.border.default}`,
        }}
      />
      <FeedbackButton />
    </div>
  )
}

const NavButton2 = ({ to, label }: { to: string; label: string }) => {
  const [isHovered, setIsHovered] = useState(false)
  const { onClose } = useMobileSidebarStore()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const isMobile = useMediaQuery("(max-width: 800px)")
  const isActive = pathname.includes(to)

  const onClick = () => {
    navigate(to)
    onClose()
  }

  return (
    <button
      onClick={onClick}
      style={{
        border: "none",
        padding: `${SPACING.space5} ${SPACING.space6}`,
        cursor: "pointer",
        width: "100%",
        background:
          (isActive || isHovered) && !isMobile
            ? JoonUIColor.background.lightAccent
            : "none",
        borderTop: `1px solid ${JoonUIColor.border.default}`,
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Typography
        variant="caption"
        textAlign="left"
        color={JoonUIColor.text.secondary}
      >
        {label}
      </Typography>
    </button>
  )
}
