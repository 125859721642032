import { FlexBox, SPACING, Typography } from "@joonapp/web-shared"
import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
  getPaginationRowModel,
} from "@tanstack/react-table"
import dayjs from "dayjs"
import { useState } from "react"

import IncidentLogItemUnreadDot from "./IncidentLogItemUnreadDot"
import useIncidentLog from "./useIncidentLog"
import CustomTable from "../../../components/table/CustomTable"
import TableHeaderCell from "../../../components/table/TableHeaderCell"
import { Incident, IncidentLogDataType } from "../../../types"
import { formatTimeString } from "../../../util/util"

const columnHelper = createColumnHelper<Incident>()

const createColumns = ({
  incidentLogDataType,
  isDataTask,
  isTableVisible,
}: {
  incidentLogDataType: IncidentLogDataType | undefined | null
  isDataTask: boolean
  isTableVisible: boolean
}) => {
  const columns = {
    dateTime: columnHelper.accessor((row) => row.incident_datetime, {
      id: "Date Time",
      cell: (info) => (
        <FlexBox
          direction="row"
          align="center"
          wrap={false}
          gap={SPACING.space1}
        >
          <IncidentLogItemUnreadDot
            id={info.row.original.id}
            isTableVisible={isTableVisible}
          />
          {info.getValue() ? (
            <div>
              <Typography
                variant="bodySmall"
                textAlign="left"
                style={{ whiteSpace: "nowrap" }}
              >
                {dayjs(info.getValue()).format("MMM D")}
              </Typography>
              <Typography variant="bodyXSmall" textAlign="left">
                {dayjs(info.getValue()).format("h A")}
              </Typography>
            </div>
          ) : (
            <Typography variant="bodySmall">-</Typography>
          )}
        </FlexBox>
      ),
      header: () => <TableHeaderCell>Date</TableHeaderCell>,
      size: 80,
    }),
    reporter: columnHelper.accessor((row) => row.reporter?.name, {
      id: "Reporter",
      cell: (info) => (
        <Typography variant="bodySmall">{info.getValue()}</Typography>
      ),
      header: () => <TableHeaderCell>Reporter</TableHeaderCell>,
      size: 80,
    }),
    behavior: columnHelper.accessor(
      (row) => row.target_behavior?.title || row.freeform_target_behavior,
      {
        id: "Target Behavior",
        cell: (info) => (
          <Typography variant="bodySmall">{info.getValue()}</Typography>
        ),
        header: () => <TableHeaderCell>Behavior</TableHeaderCell>,
      }
    ),
    duration: columnHelper.accessor((row) => row.duration, {
      id: "Duration",
      cell: (info) => (
        <Typography variant="bodySmall">
          {info.getValue() ? formatTimeString(info.getValue()!) : "-"}
        </Typography>
      ),
      header: () => <TableHeaderCell>Duration</TableHeaderCell>,
      size: 100,
    }),
    frequency: columnHelper.accessor((row) => row.frequency, {
      id: "Frequency",
      cell: (info) => (
        <Typography variant="bodySmall">{info.getValue()}</Typography>
      ),
      header: () => <TableHeaderCell>Frequency</TableHeaderCell>,
      size: 100,
    }),
    antecedent: columnHelper.accessor((row) => row.antecedent, {
      id: "Antecedent",
      cell: (info) => (
        <Typography variant="bodySmall">{info.getValue()}</Typography>
      ),
      header: () => <TableHeaderCell>Antecedent</TableHeaderCell>,
    }),
    consequence: columnHelper.accessor((row) => row.consequence, {
      id: "Consequence",
      cell: (info) => (
        <Typography variant="bodySmall">{info.getValue()}</Typography>
      ),
      header: () => <TableHeaderCell>Consequence</TableHeaderCell>,
    }),
    reflection: columnHelper.accessor((row) => row.reflection, {
      id: "Reflection",
      cell: (info) => (
        <Typography variant="bodySmall">{info.getValue()}</Typography>
      ),
      header: () => <TableHeaderCell>Reflection</TableHeaderCell>,
    }),
    strategy: columnHelper.accessor(
      (row) =>
        row.preventive_strategy?.title || row.freeform_preventive_strategy,
      {
        id: "Strategy",
        cell: (info) => (
          <Typography variant="bodySmall">{info.getValue()}</Typography>
        ),
        header: () => <TableHeaderCell>Strategy applied</TableHeaderCell>,
      }
    ),
    accuracy: columnHelper.accessor(
      (row) => {
        const successes = row.accuracy_successes || 0
        const attempts = row.accuracy_attempts || 0
        return attempts > 0
          ? `${successes}/${attempts} (${Math.round(
              (successes / attempts) * 100
            )}%)`
          : "N/A"
      },
      {
        id: "Accuracy",
        cell: (info) => (
          <Typography variant="bodySmall">{info.getValue()}</Typography>
        ),
        header: () => <TableHeaderCell>Accuracy</TableHeaderCell>,
        size: 100,
      }
    ),
  }

  const {
    dateTime,
    reporter,
    behavior,
    duration,
    frequency,
    antecedent,
    reflection,
    strategy,
    consequence,
    accuracy,
  } = columns

  if (isDataTask) {
    switch (incidentLogDataType) {
      case IncidentLogDataType.DURATION:
        return [dateTime, duration, reflection]
      case IncidentLogDataType.FREQUENCY:
        return [dateTime, frequency, reflection]
      case IncidentLogDataType.ANTECEDENT:
        return [dateTime, behavior, antecedent, reflection]
      case IncidentLogDataType.ACCURACY:
        return [dateTime, accuracy, reflection]
      default:
        return [dateTime, reporter, reflection]
    }
  } else {
    switch (incidentLogDataType) {
      case IncidentLogDataType.DURATION:
        return [dateTime, reporter, duration, strategy]
      case IncidentLogDataType.FREQUENCY:
        return [dateTime, reporter, frequency, strategy]
      case IncidentLogDataType.ANTECEDENT:
        return [dateTime, reporter, antecedent, consequence, strategy]
      default:
        return [
          dateTime,
          reporter,
          behavior,
          antecedent,
          consequence,
          duration,
          frequency,
          strategy,
        ]
    }
  }
}

const IncidentLogTable = ({
  id,
  isDataTask,
  maxRows = 5,
  showIncidentLog,
  onPageChange,
}: {
  id: number | null
  isDataTask: boolean
  maxRows?: number
  showIncidentLog: boolean
  onPageChange?: () => void
}) => {
  const { filteredIncidents, incidentLogDataType } = useIncidentLog(
    id,
    isDataTask
  )
  const [sorting, setSorting] = useState([{ id: "Date", desc: true }])
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: maxRows || 100,
  })

  const table = useReactTable({
    data: filteredIncidents || [],
    columns: createColumns({
      incidentLogDataType: incidentLogDataType,
      isDataTask: isDataTask,
      isTableVisible: showIncidentLog,
    }),
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    onPaginationChange: (updater) => {
      setPagination(updater)
      onPageChange?.()
    },
    state: { sorting, pagination },
    defaultColumn: {
      minSize: 0,
      size: Number.MAX_SAFE_INTEGER,
      maxSize: Number.MAX_SAFE_INTEGER,
    },
  })

  return <CustomTable table={table} />
}

export default IncidentLogTable
