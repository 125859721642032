import create from "zustand"

interface TaskDetailsModalStore {
  isOpen: boolean
  onClose: () => void
  onOpen: (selectedTaskId: number) => void

  selectedTaskId: number | null
  setSelectedTaskId: (selectedTaskId: number) => void
}

const useTaskDetailsModalStore = create<TaskDetailsModalStore>((set) => ({
  isOpen: false,
  onClose: () => set({ isOpen: false }),
  onOpen: (selectedTaskId) => set({ isOpen: true, selectedTaskId }),

  selectedTaskId: null,
  setSelectedTaskId: (taskId) => set({ selectedTaskId: taskId }),
}))

export default useTaskDetailsModalStore
