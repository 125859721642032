import {
  Button,
  FlexBox,
  JoonUIColor,
  Modal,
  ModalHeader,
  SPACING,
  TextButton,
  Typography,
} from "@joonapp/web-shared"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { create } from "zustand"

import { rejectInvitations } from "../../../networking/invitations"
import { QUERY_KEYS } from "../../../networking/queries"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../util/analytics"

interface RejectInvitationsModalStore {
  isOpen: boolean
  onOpen: (selectedInvitationIds: number[]) => void
  onClose: () => void

  selectedInvitationIds: number[] | null
}

export const useRejectInvitationsModalStore =
  create<RejectInvitationsModalStore>((set) => ({
    isOpen: false,
    onOpen: (selectedInvitationIds) =>
      set({ isOpen: true, selectedInvitationIds }),
    onClose: () => set({ isOpen: false }),

    selectedInvitationIds: null,
  }))

const RejectInvitationsModal = () => {
  const { isOpen, onClose, selectedInvitationIds } =
    useRejectInvitationsModalStore()
  const rejectInvitationsMutation = useMutation(rejectInvitations)

  const queryClient = useQueryClient()

  const onClickReject = async () => {
    if (!selectedInvitationIds) return
    await rejectInvitationsMutation.mutateAsync(selectedInvitationIds)
    onClose()
    queryClient.invalidateQueries([QUERY_KEYS.RECEIVED_INVITES])
    trackAnalyticEvent(ANALYTIC_EVENTS.reject_parent_invite)
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalHeader title="Are you sure?" onClose={onClose} showBorderBottom />
      <FlexBox
        direction="column"
        gap={SPACING.space6}
        style={{ padding: SPACING.space6, width: "min(450px, 95vw)" }}
      >
        <Typography variant="body">
          Once rejected, you’d have to send or receive a new invite to get
          connected.
        </Typography>
        <FlexBox direction="row" justify="space-between">
          <TextButton onClick={onClose}>
            <Typography variant="bodyBold" color={JoonUIColor.semantic.primary}>
              Cancel
            </Typography>
          </TextButton>
          <Button
            buttonType="redPrimary"
            text="Reject invite"
            onClick={onClickReject}
            isLoading={rejectInvitationsMutation.isLoading}
          />
        </FlexBox>
      </FlexBox>
    </Modal>
  )
}

export default RejectInvitationsModal
