import { ParentTrainingMasteryProgress } from "@joonapp/web-shared"
import { useMutation } from "@tanstack/react-query"

import { deleteObservationalData } from "../../../../../networking/parentTraining"
import {
  QUERY_KEYS,
  useCurrentTrainingQuery,
} from "../../../../../networking/queries"
import { queryClient } from "../../../../../queryClient"
import {
  ANALYTIC_EVENTS,
  trackAnalyticEvent,
} from "../../../../../util/analytics"

const useDeleteObservationalDataMutation = () => {
  const { data: currentTraining } = useCurrentTrainingQuery()

  const queryKey = [QUERY_KEYS.TRAINING_MASTERY_PROGRESS, currentTraining?.id]

  return useMutation({
    mutationFn: (id: number) => deleteObservationalData(id),
    onMutate: (id: number) => {
      const previousData =
        queryClient.getQueryData<ParentTrainingMasteryProgress[]>(queryKey)

      queryClient.setQueryData<ParentTrainingMasteryProgress[]>(
        queryKey,
        (oldData) => {
          return oldData?.filter((item) => item.id !== id)
        }
      )
      return previousData
    },
    onSuccess: () => {
      trackAnalyticEvent(ANALYTIC_EVENTS.delete_observational_data)
    },
    onError: (_error, _variables, context) => {
      queryClient.setQueryData(queryKey, context)
    },
    onSettled: () => {
      queryClient.invalidateQueries(queryKey)
    },
  })
}

export default useDeleteObservationalDataMutation
