import {
  Button,
  Checkbox,
  FlexBox,
  JoonUIColor,
  Modal,
  ModalHeader,
  SPACING,
  TextArea,
  TextButton,
  TextInput,
  Typography,
} from "@joonapp/web-shared"
import { useParams } from "react-router-dom"

import useAddDataTaskMutation from "./useAddDataTaskMutation"
import { useAddEditDataTaskStore } from "./useAddEditDataTaskStore"
import { useDeleteDataTaskModalStore } from "./useDeleteDataTaskModalStore"
import useEditDataTaskMutation from "./useEditDataTaskMutation"
import { createToast, Toasts } from "../../../../components/toast/Toast"
import { useCurrentTrainingQuery } from "../../../../networking/queries"
import { IncidentLogDataType, ToastType } from "../../../../types"

const AddEditDataTaskModal = () => {
  const {
    isOpen,
    onClose,
    title,
    setTitle,
    description,
    setDescription,
    dataType,
    setDataType,
    dataTaskId,
  } = useAddEditDataTaskStore((state) => ({
    isOpen: state.isOpen,
    onClose: state.onClose,
    title: state.title,
    setTitle: state.setTitle,
    description: state.description,
    setDescription: state.setDescription,
    dataType: state.dataType,
    setDataType: state.setDataType,
    dataTaskId: state.dataTaskId,
  }))
  const { onOpen: onOpenDeleteDataTaskModal } = useDeleteDataTaskModalStore()
  const { data: currentTraining } = useCurrentTrainingQuery()
  const { userId } = useParams()
  const isExamplePatient = Number(userId) === 0

  const editDataTaskMutation = useEditDataTaskMutation()
  const addDataTaskMutation = useAddDataTaskMutation()

  const isEditing = !!dataTaskId

  const onClickAddDataTask = async () => {
    if (!currentTraining?.id) return createToast(Toasts.GENERAL_ERROR)
    if (!title)
      return createToast({
        title: "Please enter a task name",
        type: ToastType.ERROR,
      })

    await addDataTaskMutation.mutateAsync({
      title,
      description,
      dataType,
      trainingId: currentTraining?.id,
    })
    onClose()
  }

  const onClickEditDataTask = () => {
    if (!currentTraining?.id || !dataTaskId)
      return createToast(Toasts.GENERAL_ERROR)
    if (!title)
      return createToast({
        title: "Please enter a task name",
        type: ToastType.ERROR,
      })

    editDataTaskMutation.mutate({
      title,
      description,
      dataType,
      dataTaskId,
    })
    onClose()
  }

  const onClickOpenDeleteDataTaskModal = () => {
    if (!dataTaskId) return
    onOpenDeleteDataTaskModal(dataTaskId)
    onClose()
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalHeader
        title={
          isEditing ? "Edit implementation task" : "Add an implementation task"
        }
        onClose={onClose}
        style={{
          width: "100%",
          borderBottom: `1px solid ${JoonUIColor.border.default}`,
        }}
      />

      <FlexBox
        style={{
          padding: `${SPACING.space4} ${SPACING.space6} ${SPACING.space6}`,
          width: "min(500px, 95vw)",
          overflowY: "scroll",
          height: "100%",
        }}
        direction="column"
        gap={SPACING.space6}
        wrap={false}
      >
        <FlexBox direction="column" gap={SPACING.space1}>
          <Typography variant="bodyBold">Task title</Typography>
          <Typography variant="bodySmall">
            Think about the types of skills you'd like parents to practice at
            home.
          </Typography>
          <TextInput
            name="Task name"
            placeholder="Name"
            fullWidth
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            maxLength={100}
          />
        </FlexBox>
        <FlexBox gap={SPACING.space1} direction="column">
          <Typography variant="bodyBold">
            Description or helpful instructions for parents
          </Typography>
          <Typography variant="bodySmall">
            Add detail about when parents should practice this task or share
            step-by-step instructions.
          </Typography>
          <TextArea
            name="Task description"
            placeholder="Description"
            fullWidth
            value={description || ""}
            onChange={(e) => setDescription(e.target.value)}
            style={{ width: "100%" }}
            showCounter
          />
        </FlexBox>
        {!isEditing && (
          <FlexBox direction="column" gap={SPACING.space2}>
            <div>
              <Typography variant="bodyBold">Measured by</Typography>
              <Typography variant="bodySmall" textAlign="left">
                How would you like to track progress toward this goal?
              </Typography>
            </div>
            <Checkbox
              style={{
                padding: SPACING.space2,
                borderRadius: SPACING.space2,
              }}
              label={
                <FlexBox
                  direction="column"
                  style={{ marginLeft: SPACING.space1 }}
                >
                  <Typography variant="caption">Frequency count</Typography>
                  <Typography variant="bodySmall">
                    Specify total count of this behavior incident
                  </Typography>
                </FlexBox>
              }
              selected={dataType === IncidentLogDataType.FREQUENCY}
              fullWidth
              name="logType"
              onChange={() => setDataType(IncidentLogDataType.FREQUENCY)}
              inputType="radio"
            />
            <Checkbox
              style={{
                padding: SPACING.space2,
                borderRadius: SPACING.space2,
              }}
              fullWidth
              label={
                <FlexBox
                  direction="column"
                  style={{ marginLeft: SPACING.space1, maxWidth: "80%" }}
                >
                  <Typography variant="caption">Accuracy</Typography>
                  <Typography variant="bodySmall" textAlign="left">
                    Specify the number of succeses and attempts per task
                  </Typography>
                </FlexBox>
              }
              selected={dataType === IncidentLogDataType.ACCURACY}
              name="logType"
              onChange={() => setDataType(IncidentLogDataType.ACCURACY)}
              inputType="radio"
            />
            <Checkbox
              style={{
                padding: SPACING.space2,
                borderRadius: SPACING.space2,
              }}
              fullWidth
              label={
                <FlexBox
                  direction="column"
                  style={{ marginLeft: SPACING.space1 }}
                >
                  <Typography variant="caption">Duration</Typography>
                  <Typography variant="bodySmall">
                    Specify the duration of each incident
                  </Typography>
                </FlexBox>
              }
              selected={dataType === IncidentLogDataType.DURATION}
              name="logType"
              onChange={() => setDataType(IncidentLogDataType.DURATION)}
              inputType="radio"
            />
            <Checkbox
              style={{
                padding: SPACING.space2,
                borderRadius: SPACING.space2,
              }}
              fullWidth
              label={
                <FlexBox
                  direction="column"
                  style={{ marginLeft: SPACING.space1 }}
                >
                  <Typography variant="caption">No tracking</Typography>
                  <Typography variant="bodySmall">
                    Parents will only be asked to provide task reflection{" "}
                  </Typography>
                </FlexBox>
              }
              selected={dataType === null}
              name="logType"
              onChange={() => setDataType(null)}
              inputType="radio"
            />
          </FlexBox>
        )}
      </FlexBox>

      <div style={{ position: "sticky", bottom: 0, width: "100%" }}>
        <FlexBox
          direction="row"
          align="center"
          justify="space-between"
          wrap={false}
          gap={SPACING.space2}
          style={{
            width: "100%",
            padding: SPACING.space6,
            whiteSpace: "nowrap",
          }}
        >
          {isEditing && (
            <TextButton onClick={onClickOpenDeleteDataTaskModal}>
              <Typography
                variant="bodyBold"
                color={JoonUIColor.semantic.destructive}
              >
                Delete
              </Typography>
            </TextButton>
          )}
          <FlexBox
            direction="row"
            align="center"
            justify="flex-end"
            wrap={false}
            gap={SPACING.space2}
            style={{ width: "100%" }}
          >
            <button onClick={onClose}>
              <Typography
                variant="bodyBold"
                color={JoonUIColor.text.primaryAccent}
              >
                Cancel
              </Typography>
            </button>
            <Button
              text={isEditing ? "Save changes" : "Add task"}
              onClick={isEditing ? onClickEditDataTask : onClickAddDataTask}
              isLoading={addDataTaskMutation.isLoading}
              disabled={isExamplePatient}
            />
          </FlexBox>
        </FlexBox>
      </div>
    </Modal>
  )
}

export default AddEditDataTaskModal
