import { ClinicalResource } from "@joonapp/web-shared"

import { createToast } from "../components/toast/Toast"
import { ResourceTag, ToastType } from "../types"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../util/analytics"
import { createJoonAPIClient } from "../util/joon-api"

export const getResourcesList = async (): Promise<ClinicalResource[]> => {
  const API = createJoonAPIClient()
  const response = await API.get("api/clinical-resources/")

  const resources = response.data.results
  const resourcesWithCorrectTags = resources.map(
    (resource: ClinicalResource) => ({
      ...resource,
      tag_ids: resource.tags?.map((tag) => tag.id),
    })
  )

  return resourcesWithCorrectTags
}

export const getTagsList = async (): Promise<ResourceTag[]> => {
  const API = createJoonAPIClient()
  const response = await API.get("api/learning-modules/tags/")
  return response.data
}


export const addEditResource = async ({
  title,
  description,
  selectedTags,
  files,
  resource,
}: {
  title: string
  description: string
  selectedTags: number[]
  files: File[]
  resource: ClinicalResource | null
}) => {
  const isEditing = !!resource
  const file = files[0]

  const data = new FormData()
  data.append("title", title)
  data.append("description", description)
  selectedTags.forEach((tag) => {
    data.append("tag_ids", `${tag}`)
  })
  if (file) data.append("resource_url", file)

  const API = createJoonAPIClient()
  try {
    if (isEditing) {
      await API.patch(`api/clinical-resources/${resource?.id}/`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      createToast({
        title: "Resource updated!",
        type: ToastType.SUCCESS,
      })
      trackAnalyticEvent(ANALYTIC_EVENTS.edit_resource)
    } else {
      await API.post("api/clinical-resources/", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      createToast({
        title: "Resource added!",
        type: ToastType.SUCCESS,
      })
      trackAnalyticEvent(ANALYTIC_EVENTS.add_resource)
    }
  } catch (error) {
    createToast({
      title: "Error creating resource. Please try again.",
      type: ToastType.ERROR,
    })
  }
}

export const deleteResource = async (resourceId: number) => {
  const API = createJoonAPIClient()
  const response = await API.delete(`api/clinical-resources/${resourceId}/`)
  return response.data
}
