import { ModuleType } from "@joonapp/web-shared"
import { useMemo } from "react"
import { create } from "zustand"
import { useShallow } from "zustand/react/shallow"

interface ResourcesFilterStore {
  searchValue: string
  setSearchValue: (value: string) => void

  selectedTags: number[]
  setSelectedTags: (category: number[]) => void
  toggleTag: (tag: number) => void

  selectedModuleTypes: ModuleType[]
  setSelectedModuleTypes: (moduleTypes: ModuleType[]) => void
  toggleModuleType: (moduleType: ModuleType) => void

  displayFavorites: boolean
  setDisplayFavorites: (displayFavorites: boolean) => void

  displayMadeByYourClinic: boolean
  setDisplayMadeByYourClinic: (displayMadeByYourClinic: boolean) => void

  clearFilters: () => void
}

const initialFilters = {
  searchValue: "",
  selectedTags: [],
  selectedModuleTypes: [],
  displayFavorites: false,
  displayMadeByYourClinic: false,
}

const useResourcesFilterStore = create<ResourcesFilterStore>((set, get) => ({
  ...initialFilters,

  setSelectedModuleTypes: (selectedModuleTypes) => set({ selectedModuleTypes }),
  toggleModuleType: (moduleType) => {
    const moduleTypes = get().selectedModuleTypes
    if (moduleTypes?.includes(moduleType)) {
      set({ selectedModuleTypes: moduleTypes?.filter((t) => t !== moduleType) })
    } else {
      set({ selectedModuleTypes: [...(moduleTypes || []), moduleType] })
    }
  },

  setSearchValue: (searchValue) => set({ searchValue }),
  setSelectedTags: (selectedTags) => {
    get().clearFilters()
    set({ selectedTags })
  },
  toggleTag: (tag) => {
    const selectedTags = get().selectedTags
    if (selectedTags?.includes(tag)) {
      set({ selectedTags: selectedTags?.filter((t) => t !== tag) })
    } else {
      set({ selectedTags: [...(selectedTags || []), tag] })
    }
  },
  setDisplayFavorites: (displayFavorites) => set({ displayFavorites }),
  setDisplayMadeByYourClinic: (displayMadeByYourClinic) =>
    set({ displayMadeByYourClinic }),
  clearFilters: () => set(initialFilters),
}))

export default useResourcesFilterStore

export const useIsFilteredModules = () => {
  const {
    searchValue,
    selectedTags,
    selectedModuleTypes,
    displayFavorites,
    displayMadeByYourClinic,
  } = useResourcesFilterStore(
    useShallow((state) => ({
      searchValue: state.searchValue,
      selectedTags: state.selectedTags,
      selectedModuleTypes: state.selectedModuleTypes,
      displayFavorites: state.displayFavorites,
      displayMadeByYourClinic: state.displayMadeByYourClinic,
    }))
  )

  const isFiltered = useMemo(
    () =>
      searchValue ||
      displayFavorites ||
      displayMadeByYourClinic ||
      selectedTags.length > 0 ||
      selectedModuleTypes.length > 0,
    [
      selectedTags,
      displayFavorites,
      displayMadeByYourClinic,
      searchValue,
      selectedModuleTypes,
    ]
  )

  return isFiltered
}
