import {
  FlexBox,
  JoonColorExpanded,
  JoonUIColor,
  SPACING,
  Typography,
} from "@joonapp/web-shared"

import { useAddEditQuestModalStore } from "./useAddEditQuestModalStore"
import { useQuestDetailsModalStore } from "./useQuestDetailsModalStore"
import TherapistIconTooltip from "../../../../components/therapistIconTooltip/TherapistIconTooltip"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../../util/analytics"
import { getMostRecentTarget } from "../../../../util/util"
import { QuestSeriesWithTargets } from "../overTimeProgress/data"

type Props = {
  quest: QuestSeriesWithTargets
}

const EditQuestButton = ({ quest }: Props) => {
  const { onOpen: openEditQuestModal } = useAddEditQuestModalStore()
  const { onClose: closeQuestDetailsModal } = useQuestDetailsModalStore()

  const currentTarget = getMostRecentTarget(quest?.frequency_targets)

  const onClickButton = () => {
    if (!!currentTarget) {
      trackAnalyticEvent(ANALYTIC_EVENTS.open_edit_target)
    } else {
      trackAnalyticEvent(ANALYTIC_EVENTS.open_add_target)
    }
    openEditQuestModal(quest)
    closeQuestDetailsModal()
  }

  return (
    <FlexBox
      direction="row"
      justify="space-between"
      align="center"
      wrap={false}
      style={{
        background: JoonColorExpanded.blue100,
        padding: SPACING.space2,
        borderRadius: SPACING.space2,
        cursor: "pointer",
      }}
      onClick={onClickButton}
    >
      <FlexBox wrap={false} direction="row" align="center" gap={SPACING.space2}>
        <TherapistIconTooltip hideTooltip />
        {currentTarget ? (
          <div>
            <Typography variant="bodySmall" color={JoonUIColor.text.secondary}>
              Target:{" "}
            </Typography>
            <Typography variant="bodySmall" color={JoonUIColor.text.primary}>
              {currentTarget.frequency}x every 7 days
            </Typography>
          </div>
        ) : (
          <Typography variant="caption" color={JoonUIColor.text.primaryAccent}>
            Set target
          </Typography>
        )}
      </FlexBox>
      {!!currentTarget && (
        <Typography variant="caption" color={JoonUIColor.text.primaryAccent}>
          Edit
        </Typography>
      )}
    </FlexBox>
  )
}

export default EditQuestButton
