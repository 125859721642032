import { create } from "zustand"

import { ParentTrainingStatus } from "../../../types"

interface TrainingStatusConfirmationModalStore {
  isOpen: boolean
  onClose: () => void
  onOpen: (status: ParentTrainingStatus) => void
  selectedStatus: ParentTrainingStatus | null
}

export const useTrainingStatusConfirmationModalStore =
  create<TrainingStatusConfirmationModalStore>((set) => ({
    isOpen: false,
    onOpen: (status) =>
      set({
        isOpen: true,
        selectedStatus: status,
      }),
    onClose: () => set({ isOpen: false, selectedStatus: null }),
    selectedStatus: null,
  }))
