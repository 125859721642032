import {
  FlexBox,
  JoonUIColor,
  LearningModuleAssignment,
  SPACING,
  TextButton,
  Typography,
} from "@joonapp/web-shared"
import { useMediaQuery } from "@mui/material"
import { useState } from "react"
import { useNavigate } from "react-router-dom"

import DataTasksEmptyState from "./dataTasks/DataTasksEmptyState"
import useDataTasksQuery from "./dataTasks/useDataTasksQuery"
import ModulesEmptyState from "./modules/ModulesEmptyState"
import { useSendTrainingReminderMutation } from "./mutations/useSendTrainingReminderMutation"
import ObservationalDataSection from "./observationalData/ObservationalDataSection"
import TodosEmptyState from "./todoTasks/TodosEmptyState"
import PageContentWrapper from "../../../components/layout/PageContentWrapper"
import NotificationDot from "../../../components/notificationDot/NotificationDot"
import {
  useCurrentTrainingQuery,
  useModulesToReviewQuery,
  useTrainingRemindersAllowedQuery,
} from "../../../networking/queries"
import { ParentTrainingReminderType, ParentTrainingTab } from "../../../types"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../util/analytics"

const TrainingSummaryTab = () => {
  const { data: currentTraining } = useCurrentTrainingQuery()

  const isMobile = useMediaQuery("(max-width: 800px)")

  if (!currentTraining) return <></>

  return (
    <PageContentWrapper scrollable={false}>
      <FlexBox
        direction={isMobile ? "column" : "row"}
        justify="space-between"
        gap={SPACING.space6}
        wrap={false}
      >
        <FlexBox direction="column" gap={SPACING.space2} align="unset">
          <Typography variant="bodyBold">Learning modules</Typography>
          <ModuleSummarySection />
        </FlexBox>
        <FlexBox direction="column" gap={SPACING.space2} align="unset">
          <Typography variant="bodyBold">Implementation tasks</Typography>
          <DataTaskSummarySection />
        </FlexBox>
        <FlexBox direction="column" gap={SPACING.space2} align="unset">
          <Typography variant="bodyBold">Quick to-dos</Typography>
          <QuickToDoSection />
        </FlexBox>
      </FlexBox>
      <ObservationalDataSection />
    </PageContentWrapper>
  )
}

const ModuleSummarySection = () => {
  const { data: currentTraining } = useCurrentTrainingQuery()
  const { data: numberOfModulesToReview } = useModulesToReviewQuery()
  const [reminderSent, setReminderSent] = useState(false)

  const navigate = useNavigate()

  const remainingModules = getRemainingModules(currentTraining?.assignments)

  const { data: remindersAllowed } = useTrainingRemindersAllowedQuery(
    ParentTrainingReminderType.PARENT_TRAINING_MODULES
  )

  const sendTrainingReminderMutation = useSendTrainingReminderMutation()

  const onClickSendReminder = () => {
    if (reminderSent) return
    if (!currentTraining) return

    sendTrainingReminderMutation.mutate({
      trainingId: currentTraining.id,
      type: ParentTrainingReminderType.PARENT_TRAINING_MODULES,
    })
    setReminderSent(true)
  }

  const onClickReviewModules = () => {
    const currentPath = window.location.pathname
    const newPath = currentPath.replace(
      ParentTrainingTab.SUMMARY,
      ParentTrainingTab.MODULES
    )
    navigate(newPath)
    trackAnalyticEvent(ANALYTIC_EVENTS.view_modules_tab)
  }

  if (!currentTraining) return <></>

  if (currentTraining.assignments.length === 0) return <ModulesEmptyState />

  return (
    <FlexBox
      direction="row"
      gap={SPACING.space2}
      align="center"
      justify="space-between"
      style={{
        border: `1px solid ${JoonUIColor.border.default}`,
        padding: SPACING.space2,
        paddingLeft: SPACING.space4,
        borderRadius: SPACING.space2,
        background: JoonUIColor.background.primaryNeutral,
      }}
    >
      {remainingModules.length > 0 ? (
        <>
          <Typography variant="caption">
            {remainingModules.length} remaining
          </Typography>
          {remindersAllowed ? (
            reminderSent ? (
              <Typography
                variant="caption"
                style={{ padding: SPACING.space2 }}
                color={JoonUIColor.semantic.success}
              >
                Reminder sent!
              </Typography>
            ) : (
              <TextButton onClick={onClickSendReminder}>
                <Typography
                  variant="caption"
                  color={JoonUIColor.text.primaryAccent}
                >
                  Send reminder
                </Typography>
              </TextButton>
            )
          ) : (
            <TextButton onClick={onClickReviewModules}>
              <Typography
                variant="caption"
                color={JoonUIColor.text.primaryAccent}
              >
                View
              </Typography>
            </TextButton>
          )}
        </>
      ) : !!numberOfModulesToReview ? (
        <>
          <span>
            <NotificationDot />
            <Typography
              variant="caption"
              style={{ marginLeft: SPACING.space2 }}
            >
              {numberOfModulesToReview} ready to review
            </Typography>
          </span>
          <TextButton onClick={onClickReviewModules}>
            <Typography
              variant="caption"
              color={JoonUIColor.text.primaryAccent}
            >
              Review
            </Typography>
          </TextButton>
        </>
      ) : currentTraining.assignments.length === 0 ? (
        <Typography variant="caption">No modules assigned</Typography>
      ) : (
        <>
          <Typography variant="caption">
            {currentTraining.assignments.length} assigned modules
          </Typography>
          <TextButton onClick={onClickReviewModules}>
            <Typography
              variant="caption"
              color={JoonUIColor.text.primaryAccent}
            >
              View
            </Typography>
          </TextButton>
        </>
      )}
    </FlexBox>
  )
}

const QuickToDoSection = () => {
  const { data: currentTraining } = useCurrentTrainingQuery()
  const tasks = currentTraining?.quests || []
  const navigate = useNavigate()
  const [reminderSent, setReminderSent] = useState(false)

  const { data: remindersAllowed } = useTrainingRemindersAllowedQuery(
    ParentTrainingReminderType.PARENT_TRAINING_TASKS
  )

  const sendTrainingReminderMutation = useSendTrainingReminderMutation()

  const onClickSendReminder = () => {
    if (reminderSent) return
    if (!currentTraining) return

    sendTrainingReminderMutation.mutate({
      trainingId: currentTraining.id,
      type: ParentTrainingReminderType.PARENT_TRAINING_TASKS,
    })
    setReminderSent(true)
  }

  const onClickViewTasks = () => {
    const currentPath = window.location.pathname
    const newPath = currentPath.replace(
      ParentTrainingTab.SUMMARY,
      ParentTrainingTab.TODO_TASKS
    )
    navigate(newPath)
    trackAnalyticEvent(ANALYTIC_EVENTS.view_tasks_tab)
  }

  if (tasks.length === 0) return <TodosEmptyState />

  return (
    <FlexBox
      direction="row"
      gap={SPACING.space2}
      align="center"
      justify="space-between"
      style={{
        border: `1px solid ${JoonUIColor.border.default}`,
        padding: SPACING.space2,
        paddingLeft: SPACING.space4,
        borderRadius: SPACING.space2,
        background: JoonUIColor.background.primaryNeutral,
      }}
    >
      <Typography variant="caption">
        {tasks.length} active task{tasks.length > 1 ? "s" : ""}
      </Typography>
      {remindersAllowed ? (
        reminderSent ? (
          <Typography
            variant="caption"
            style={{ padding: SPACING.space2 }}
            color={JoonUIColor.semantic.success}
          >
            Reminder sent!
          </Typography>
        ) : (
          <TextButton onClick={onClickSendReminder}>
            <Typography
              variant="caption"
              color={JoonUIColor.text.primaryAccent}
            >
              Send reminder
            </Typography>
          </TextButton>
        )
      ) : (
        <TextButton onClick={onClickViewTasks}>
          <Typography variant="caption" color={JoonUIColor.text.primaryAccent}>
            View
          </Typography>
        </TextButton>
      )}
    </FlexBox>
  )
}

const DataTaskSummarySection = () => {
  const navigate = useNavigate()
  const { data: dataTasks } = useDataTasksQuery()
  const { data: currentTraining } = useCurrentTrainingQuery()
  const [reminderSent, setReminderSent] = useState(false)

  const { data: remindersAllowed } = useTrainingRemindersAllowedQuery(
    ParentTrainingReminderType.PARENT_TRAINING_DATA_TASKS
  )

  const sendTrainingReminderMutation = useSendTrainingReminderMutation()

  const onClickSendReminder = () => {
    if (reminderSent) return
    if (!currentTraining) return

    sendTrainingReminderMutation.mutate({
      trainingId: currentTraining.id,
      type: ParentTrainingReminderType.PARENT_TRAINING_DATA_TASKS,
    })
    setReminderSent(true)
  }

  const onClickViewTasks = () => {
    const currentPath = window.location.pathname
    const newPath = currentPath.replace(
      ParentTrainingTab.SUMMARY,
      ParentTrainingTab.DATA_TASKS
    )
    navigate(newPath)
  }

  if (!dataTasks) return <></>
  if (dataTasks?.length === 0) return <DataTasksEmptyState />

  return (
    <FlexBox
      direction="row"
      gap={SPACING.space2}
      align="center"
      justify="space-between"
      style={{
        border: `1px solid ${JoonUIColor.border.default}`,
        padding: SPACING.space2,
        paddingLeft: SPACING.space4,
        borderRadius: SPACING.space2,
        background: JoonUIColor.background.primaryNeutral,
      }}
    >
      <Typography variant="caption">
        {dataTasks.length} implementation task{dataTasks.length > 1 ? "s" : ""}
      </Typography>
      {remindersAllowed ? (
        reminderSent ? (
          <Typography
            variant="caption"
            style={{ padding: SPACING.space2 }}
            color={JoonUIColor.semantic.success}
          >
            Reminder sent!
          </Typography>
        ) : (
          <TextButton onClick={onClickSendReminder}>
            <Typography
              variant="caption"
              color={JoonUIColor.text.primaryAccent}
            >
              Send reminder
            </Typography>
          </TextButton>
        )
      ) : (
        <TextButton onClick={onClickViewTasks}>
          <Typography variant="caption" color={JoonUIColor.text.primaryAccent}>
            View
          </Typography>
        </TextButton>
      )}
    </FlexBox>
  )
}

const getRemainingModules = (assignments: LearningModuleAssignment[] = []) =>
  assignments.filter(
    (assignment) => !assignment.assignment_completion.date_completed
  )

export default TrainingSummaryTab
