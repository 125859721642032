import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"

import useOnboarding from "./useOnboarding"

type FormErrors = { [x: number]: string }

const useErrors = () => {
  const [formErrors, setFormErrors] = useState<FormErrors>({})
  const location = useLocation()
  const { step, formInfo } = useOnboarding()

  const setFormError = (step: number, message: string) =>
    setFormErrors((formErrors) => ({ ...formErrors, [step]: message }))

  useEffect(() => {
    setFormErrors({})
  }, [location, step, formInfo])

  return { formErrors, setFormError }
}
export default useErrors
