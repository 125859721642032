import { FlexBox, JoonUIColor, SPACING, Typography } from "@joonapp/web-shared"
import { useMediaQuery } from "@mui/material"
import { useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"

import MobileNavbar from "./MobileNavbar"
import { UserInitials } from "./UserInitials"
import { useUserQuery } from "../../networking/queries"

const Navbar = () => {
  const isMobile = useMediaQuery("(max-width: 800px)")
  const navigate = useNavigate()

  if (isMobile) return <MobileNavbar />

  return (
    <FlexBox
      direction="row"
      justify="space-between"
      align="center"
      wrap={false}
      style={{
        padding: SPACING.space2,
        paddingLeft: isMobile ? SPACING.space4 : SPACING.space6,
        paddingRight: isMobile ? SPACING.space4 : SPACING.space6,
        backgroundColor: JoonUIColor.background.primaryNeutral,
        borderBottom: `1px solid ${JoonUIColor.border.default}`,
        zIndex: 10,
      }}
    >
      <button style={{ padding: 0 }} onClick={() => navigate("/patients")}>
        <img
          src={"/images/logos/JoonHealth.svg"}
          alt="Joon Logo"
          style={{ height: SPACING.space6 }}
        />
      </button>
      <FlexBox
        direction="row"
        gap={SPACING.space4}
        wrap={false}
        align="center"
        style={{ width: "fit-content" }}
      >
        <NavButton to="/patients" name="Clients" />
        <NavButton to="/resources" name="Module library" />
        <NavButton to="/security" name="HIPAA" />
        <div
          style={{
            height: SPACING.space6,
            borderRight: `1px solid ${JoonUIColor.border.default}`,
          }}
        />
        <NavUserDropdown />
      </FlexBox>
    </FlexBox>
  )
}

export default Navbar

const NavUserDropdown = () => {
  const [isHovered, setIsHovered] = useState(false)
  const { user } = useUserQuery()
  const navigate = useNavigate()

  if (!user) return null

  return (
    <button onClick={() => navigate("/settings")}>
      <FlexBox
        align="center"
        style={{
          height: SPACING.space9,
          backgroundColor: isHovered
            ? JoonUIColor.background.lightGray
            : "transparent",
          borderRadius: SPACING.space2,
          padding: SPACING.space2,
        }}
        gap={SPACING.space2}
        wrap={false}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <UserInitials />
        <Typography variant="caption" color={JoonUIColor.text.primary}>
          {user.name}
        </Typography>
      </FlexBox>
    </button>
  )
}

const NavButton = ({
  to,
  name,
  trackerEvent,
}: {
  to: string
  name: string
  trackerEvent?: () => void
}) => {
  const [isHovered, setIsHovered] = useState(false)
  const location = useLocation()

  const isOnDefaultPage = location.pathname === "/" && to === "/patients"
  const isActive = location.pathname.includes(to) || isOnDefaultPage

  return (
    <Link to={to} style={{ textDecoration: "none" }} onClick={trackerEvent}>
      <button
        style={{
          display: "flex",
          alignItems: "center",
          backgroundColor: isActive
            ? JoonUIColor.background.lightAccent
            : isHovered
            ? JoonUIColor.background.lightGray
            : "transparent",
          borderRadius: SPACING.space2,
          padding: SPACING.space2,
          whiteSpace: "nowrap",
          textDecoration: "none",
          position: "relative",
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <Typography
          variant="bodySmall"
          color={JoonUIColor.text.primary}
          style={{
            fontWeight: 600,
            visibility: isActive ? "visible" : "hidden",
          }}
        >
          {name}
        </Typography>
        <Typography
          variant="bodySmall"
          color={JoonUIColor.text.primary}
          style={{
            position: "absolute",
            fontWeight: "normal",
            visibility: isActive ? "hidden" : "visible",
          }}
        >
          {name}
        </Typography>
      </button>
    </Link>
  )
}
