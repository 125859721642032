import { Modal } from "@joonapp/web-shared"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import { Button } from "@mui/material"

import useOnboarding, { OnboardingSteps } from "../../hooks/useOnboarding"
import useOnboardModal from "../../hooks/useOnboardModal"
import { usePracticeQuery, useUserQuery } from "../../networking/queries"
import UserInfo from "../../pages/onboarding/UserInfo"

const OnboardModal = () => {
  const { isOpen } = useOnboardModal()
  const { step, setStep, previousStep } = useOnboarding()
  const { user } = useUserQuery()
  const { practice, practiceStatus } = usePracticeQuery()

  const firstStep = OnboardingSteps.userInfo
  const isOnFirstStep = step === firstStep
  const shouldDisplayModal = user && practiceStatus !== "loading" && !practice
  if (!shouldDisplayModal) return <></>
  if (step < firstStep || step > OnboardingSteps.userInfo) setStep(firstStep)

  return (
    <Modal isOpen={isOpen} onClose={() => {}} mandatory>
      <div className="onboard-modal">
        {!isOnFirstStep && (
          <Button
            onClick={previousStep}
            style={{
              width: "100px",
              position: "absolute",
              left: "10px",
              top: "20px",
              gap: "10px",
              display: "flex",
              alignItems: "center",
              fontSize: "16px",
            }}
          >
            <ArrowBackIcon /> Back
          </Button>
        )}
        <UserInfo />
      </div>
    </Modal>
  )
}

export default OnboardModal
