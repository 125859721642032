import {
  CloseIcon,
  FlexBox,
  JoonUIColor,
  Modal,
  ModalHeader,
  PhotoIcon,
  QuestStatus,
  SPACING,
  StickyNoteIcon,
  Typography,
} from "@joonapp/web-shared"
import dayjs, { Dayjs } from "dayjs"
import { useState } from "react"

import EditQuestButton from "./EditQuestButton"
import { useQuestDetailsModalStore } from "./useQuestDetailsModalStore"
import CustomTooltip from "../../../../components/tooltip/CustomTooltip"
import { usePatientWeeklyProgressQuery } from "../../../../networking/queries"
import { OneOffCompletedInstance } from "../../../../types"
import { QuestWithDates } from "../../../../util/quests"
import {
  capitalizeFirstLetter,
  dateIsToday,
  displayStringFromRole,
} from "../../../../util/util"
import { DateQuest } from "../overTimeProgress/data"

const QuestDetailsModal = () => {
  const { data } = usePatientWeeklyProgressQuery()
  const { questId, onClose, isOpen } = useQuestDetailsModalStore()

  const questWithDates = data?.find(
    (questWithDates) => questWithDates.quest.id === questId
  ) as QuestWithDates

  const quest = questWithDates?.quest
  const questDates = questWithDates?.dates

  const startDate = quest?.start_date
    ? dayjs(quest.start_date).format("MM/DD/YYYY")
    : "-"
  const assignedBy = quest?.assigner_profile?.role
    ? displayStringFromRole(quest?.assigner_profile.role)
    : "-"
  const lastUpdated = quest?.date_updated
    ? dayjs(quest.date_updated).format("MM/DD/YYYY")
    : "-"

  const getRewardDifference = (
    redeemableReward: number | null | undefined,
    reward: any | null | undefined
  ) => {
    if (redeemableReward === null || redeemableReward === undefined) {
      return null
    }
    if (reward === null || reward === undefined) {
      return null
    }
    return Number(reward - redeemableReward)
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalHeader title={quest?.title} onClose={onClose} showBorderBottom />
      <FlexBox
        style={{
          padding: SPACING.space6,
          paddingTop: SPACING.space4,
          overflowY: "auto",
        }}
        gap={SPACING.space4}
      >
        <EditQuestButton quest={quest} />
        <FlexBox direction="column">
          <div>
            <Typography variant="bodySmall" color={JoonUIColor.text.disabled}>
              Started on:{" "}
            </Typography>
            <Typography variant="bodySmall">{startDate}</Typography>
          </div>
          <div>
            <Typography variant="bodySmall" color={JoonUIColor.text.disabled}>
              Assigned by:{" "}
            </Typography>
            <Typography variant="bodySmall">{assignedBy}</Typography>
          </div>
          <div>
            <Typography variant="bodySmall" color={JoonUIColor.text.disabled}>
              Time of day:{" "}
            </Typography>
            <Typography variant="bodySmall">
              {capitalizeFirstLetter(quest?.routine || "-")}
            </Typography>
          </div>
          <div>
            <Typography variant="bodySmall" color={JoonUIColor.text.disabled}>
              Last updated:{" "}
            </Typography>
            <Typography variant="bodySmall">{lastUpdated}</Typography>
          </div>
          <div>
            <Typography variant="bodySmall" color={JoonUIColor.text.disabled}>
              Reward amount:{" "}
            </Typography>
            <Typography variant="bodySmall">
              {quest?.redeemable_reward || 0} coins
            </Typography>
          </div>
        </FlexBox>
        <table className="recurring-table">
          <thead>
            <tr>
              <th>
                <Typography
                  variant="bodySmall"
                  color={JoonUIColor.text.secondary}
                >
                  Date
                </Typography>
              </th>
              <th>
                <Typography
                  variant="bodySmall"
                  color={JoonUIColor.text.secondary}
                >
                  Status
                </Typography>
              </th>
              <th>
                <Typography
                  variant="bodySmall"
                  color={JoonUIColor.text.secondary}
                >
                  Coins
                </Typography>
              </th>
              <th>
                <Typography
                  variant="bodySmall"
                  color={JoonUIColor.text.secondary}
                >
                  Details
                </Typography>
              </th>
            </tr>
          </thead>
          <tbody>
            {questDates &&
              Object.entries(questDates).map(([date, completion], i) => {
                const rewardDifference = getRewardDifference(
                  quest?.redeemable_reward,
                  completion.reward
                )
                return (
                  <tr key={i}>
                    <td>
                      <Typography variant="bodySmall">
                        {dayjs(date, "MM-DD-YYYY").format("MM/DD")}
                      </Typography>
                    </td>
                    <td>
                      {/* Warning: passing this MM/DD/YYYY date in here might break dayjs */}
                      {getQuestPanelStatusLabel(completion.status, dayjs(date))}
                    </td>
                    <td data-tooltip-id={`instance-reward-${i}`}>
                      <Typography
                        variant={!!rewardDifference ? "caption" : "bodySmall"}
                        color={
                          !rewardDifference
                            ? JoonUIColor.text.primary
                            : rewardDifference > 0
                            ? JoonUIColor.semantic.primary
                            : JoonUIColor.semantic.destructive
                        }
                      >
                        {completion.reward || "-"}
                      </Typography>
                      {rewardDifference ? (
                        <CustomTooltip id={`instance-reward-${i}`}>
                          <Typography
                            variant="bodySmall"
                            color={JoonUIColor.text.inverted}
                          >
                            {rewardDifference > 0
                              ? `+${rewardDifference} extra reward`
                              : `${rewardDifference} reward`}
                          </Typography>
                        </CustomTooltip>
                      ) : undefined}
                    </td>
                    <td>
                      <FlexBox align="center" gap={SPACING.space1} wrap={false}>
                        <QuestNote questCompletion={completion} />
                        <QuestPic questCompletion={completion} />
                      </FlexBox>
                    </td>
                  </tr>
                )
              })}
          </tbody>
        </table>
      </FlexBox>
    </Modal>
  )
}

export default QuestDetailsModal

const QuestNote = ({
  questCompletion,
}: {
  questCompletion: DateQuest | OneOffCompletedInstance
}) => {
  const [showModal, setShowModal] = useState(false)

  if (!questCompletion.completion_notes && !questCompletion.verification_notes)
    return null

  return (
    <>
      <button onClick={() => setShowModal(true)}>
        <FlexBox
          style={{
            padding: `${SPACING.space05} ${SPACING.space1}`,
            background: JoonUIColor.background.lightGray,
            borderRadius: SPACING.space1,
            width: "fit-content",
          }}
          wrap={false}
          align="center"
          justify="center"
          gap={SPACING.space1}
        >
          <StickyNoteIcon size={16} color={JoonUIColor.icon.neutral} />
          <Typography variant="bodySmall">note</Typography>
        </FlexBox>
      </button>
      <Modal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        displayCloseIcon={false}
      >
        <FlexBox
          style={{
            padding: `${SPACING.space4} ${SPACING.space6}`,
            width: "min(95vw, 400px)",
          }}
          justify="space-between"
          align="center"
          wrap={false}
        >
          <Typography variant="h3">Completion notes</Typography>
          <button onClick={() => setShowModal(false)} style={{ padding: "0" }}>
            <CloseIcon color={JoonUIColor.text.primary} />
          </button>
        </FlexBox>
        <FlexBox
          style={{
            padding: `${SPACING.space0} ${SPACING.space6} ${SPACING.space6} ${SPACING.space6}`,
            width: "min(95vw, 400px)",
          }}
          gap={SPACING.space2}
          direction="column"
        >
          {questCompletion.completion_notes && (
            <FlexBox
              style={{
                padding: SPACING.space2,
                borderRadius: SPACING.space2,
                background: JoonUIColor.background.lightBlue,
              }}
            >
              <Typography
                variant="bodyBold"
                color={JoonUIColor.text.primary}
                textAlign="left"
              >
                Submitted completion notes
              </Typography>
              <Typography variant="body">
                {questCompletion.completion_notes}
              </Typography>
            </FlexBox>
          )}
          {questCompletion.verification_notes && (
            <FlexBox
              style={{
                padding: SPACING.space2,
                borderRadius: SPACING.space2,
                background: JoonUIColor.background.lightBlue,
              }}
            >
              <Typography
                variant="bodyBold"
                color={JoonUIColor.text.primary}
                textAlign="left"
              >
                Response from parents
              </Typography>
              <Typography variant="body">
                {questCompletion.verification_notes}
              </Typography>
            </FlexBox>
          )}
        </FlexBox>
      </Modal>
    </>
  )
}

const QuestPic = ({
  questCompletion,
}: {
  questCompletion: DateQuest | OneOffCompletedInstance
}) => {
  const [showModal, setShowModal] = useState(false)

  if (!questCompletion.completion_photo_url) return null

  return (
    <>
      <button onClick={() => setShowModal(true)}>
        <FlexBox
          style={{
            padding: `${SPACING.space05} ${SPACING.space1}`,
            background: JoonUIColor.background.lightGray,
            borderRadius: SPACING.space1,
            width: "fit-content",
          }}
          wrap={false}
          align="center"
          justify="center"
          gap={SPACING.space1}
        >
          <PhotoIcon size={16} color={JoonUIColor.icon.neutral} />
          <Typography variant="bodySmall">pic</Typography>
        </FlexBox>
      </button>
      <Modal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        displayCloseIcon={false}
      >
        <FlexBox
          style={{
            padding: `${SPACING.space4} ${SPACING.space6}`,
            width: "min(95vw, 600px)",
          }}
          justify="space-between"
          align="center"
          wrap={false}
        >
          <Typography variant="h3">Completion Photo</Typography>
          <button onClick={() => setShowModal(false)} style={{ padding: "0" }}>
            <CloseIcon color={JoonUIColor.text.primary} />
          </button>
        </FlexBox>
        <FlexBox
          style={{
            padding: `${SPACING.space0} ${SPACING.space6} ${SPACING.space6} ${SPACING.space6}`,
            width: "min(95vw, 600px)",
          }}
        >
          <img
            src={questCompletion.completion_photo_url}
            alt="completion"
            style={{ width: "100%" }}
          />
        </FlexBox>
      </Modal>
    </>
  )
}

const getQuestPanelStatusLabel = (status: QuestStatus, date: Dayjs) => {
  switch (status) {
    case QuestStatus.COMPLETED:
      return <Typography variant="bodySmall">Not yet verified</Typography>
    case QuestStatus.REDEEMED:
    case QuestStatus.VERIFIED:
      return <Typography variant="bodySmall">Completed</Typography>
    case QuestStatus.OPEN:
      if (dateIsToday(date)) {
        return <Typography variant="bodySmall">Open</Typography>
      } else return <Typography variant="bodySmall">Missed</Typography>
    case QuestStatus.REJECTED:
      return (
        <Typography variant="bodySmall" color={JoonUIColor.background.alert}>
          Rejected by Parents
        </Typography>
      )
    case QuestStatus.SKIPPED:
      return (
        <Typography variant="bodySmall" color={JoonUIColor.text.secondary}>
          Skipped
        </Typography>
      )
    default:
      return (
        <Typography variant="bodySmall" color={JoonUIColor.text.secondary}>
          -
        </Typography>
      )
  }
}
