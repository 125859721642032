import {
  Button,
  FlexBox,
  JoonUIColor,
  Modal,
  ModalHeader,
  SPACING,
  TextButton,
  Typography,
} from "@joonapp/web-shared"
import { useMutation, useQueryClient } from "@tanstack/react-query"

import useAddEditTargetBehaviorStore from "./store/useAddEditTargetBehaviorStore"
import useDeleteTargetBehaviorModalStore from "./store/useDeleteTargetBehaviorModalStore"
import { createToast } from "../../../components/toast/Toast"
import { deleteTargetBehavior } from "../../../networking/behaviors"
import { QUERY_KEYS } from "../../../networking/queries"
import { ToastType } from "../../../types"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../util/analytics"

const useDeleteTargetBehaviorMutation = () => {
  return useMutation({
    mutationFn: deleteTargetBehavior,
    onSuccess: () => {
      trackAnalyticEvent(ANALYTIC_EVENTS.delete_target_behavior)
      createToast({
        title: "Target behavior deleted!",
        type: ToastType.SUCCESS,
      })
    },
    onError: () => {
      createToast({
        title: "Failed to delete target behavior",
        type: ToastType.ERROR,
      })
    },
  })
}

const DeleteTargetBehaviorModal = () => {
  const { isOpen, onClose, targetBehaviorId } =
    useDeleteTargetBehaviorModalStore()
  const { onClose: closeEditTargetBehaviorModal } =
    useAddEditTargetBehaviorStore()

  const deleteTargetBehaviorMutation = useDeleteTargetBehaviorMutation()
  const queryClient = useQueryClient()

  const onClickDelete = async () => {
    if (!targetBehaviorId) return
    await deleteTargetBehaviorMutation.mutateAsync({ targetBehaviorId })
    onClose()
    closeEditTargetBehaviorModal()
    queryClient.invalidateQueries([QUERY_KEYS.TARGET_BEHAVIORS])
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalHeader title="Are you sure?" onClose={onClose} showBorderBottom />
      <FlexBox
        style={{ padding: SPACING.space6, paddingTop: SPACING.space4 }}
        direction="column"
        gap={SPACING.space6}
      >
        <Typography variant="body" textAlign="left">
          Are you sure you want to delete this target behavior?
        </Typography>
        <FlexBox justify="flex-end" gap={SPACING.space4}>
          <TextButton onClick={onClose}>
            <Typography
              variant="bodyBold"
              color={JoonUIColor.text.primaryAccent}
            >
              Cancel
            </Typography>
          </TextButton>
          <Button
            text="Delete"
            buttonType="redPrimary"
            onClick={onClickDelete}
            isLoading={deleteTargetBehaviorMutation.isLoading}
            disabled={deleteTargetBehaviorMutation.isLoading}
          />
        </FlexBox>
      </FlexBox>
    </Modal>
  )
}

export default DeleteTargetBehaviorModal
