import {
  Button,
  DateInput,
  FlexBox,
  JoonUIColor,
  MasteryCriteriaMeasurement,
  Modal,
  ModalHeader,
  SPACING,
  TextArea,
  TextButton,
  TextInput,
  Typography,
} from "@joonapp/web-shared"
import dayjs from "dayjs"
import { useParams } from "react-router-dom"

import useAddObservationalDataMutation from "./mutations/useAddObservationalDataMutation"
import useEditObservationalDataMutation from "./mutations/useEditObservationalDataMutation"
import useAddEditObservationalDataModalStore from "./store/useAddEditObservationalDataModalStore"
import { createToast } from "../../../../components/toast/Toast"
import { useCurrentTrainingQuery } from "../../../../networking/queries"
import { ToastType } from "../../../../types"

const AddEditObservationalDataModal = () => {
  const {
    isOpen,
    onClose,
    frequency,
    setFrequency,
    baseCount,
    setBaseCount,
    observationNotes,
    setObservationNotes,
    date,
    setDate,
    masteryProgressId,
  } = useAddEditObservationalDataModalStore()
  const { data: currentTraining } = useCurrentTrainingQuery()
  const addObservationalDataMutation = useAddObservationalDataMutation()
  const editObservationalDataMutation = useEditObservationalDataMutation()

  const { userId } = useParams()
  const isExampleClient = Number(userId) === 0

  const measurementType = currentTraining?.mastery_criteria_measurement

  const isEditing = !!masteryProgressId

  const onClickAdd = async () => {
    if (!currentTraining) return
    if (!date)
      return createToast({
        type: ToastType.ERROR,
        title: "Date is required",
      })
    if (!frequency)
      return createToast({
        type: ToastType.ERROR,
        title: "Count is required",
      })
    if (measurementType === MasteryCriteriaMeasurement.PERCENTAGE && !baseCount)
      return createToast({
        type: ToastType.ERROR,
        title: "Total is required",
      })

    await addObservationalDataMutation
      .mutateAsync({
        training_id: currentTraining.id,
        date: dayjs(date).format("YYYY-MM-DD"),
        frequency,
        base_count: baseCount || undefined,
        observation_notes: observationNotes || "",
      })
      .then(onClose)
      .catch((error) => {
        console.error(error)
        createToast({
          type: ToastType.ERROR,
          title:
            error.response.data?.non_field_errors?.[0] ||
            "Failed to add progress data",
        })
      })
  }

  const onClickEdit = async () => {
    if (!masteryProgressId) return
    if (!currentTraining) return
    if (!date)
      return createToast({
        type: ToastType.ERROR,
        title: "Date is required",
      })
    if (!frequency)
      return createToast({
        type: ToastType.ERROR,
        title: "Count is required",
      })
    if (measurementType === MasteryCriteriaMeasurement.PERCENTAGE && !baseCount)
      return createToast({
        type: ToastType.ERROR,
        title: "Total is required",
      })

    await editObservationalDataMutation
      .mutateAsync({
        id: masteryProgressId,
        date: undefined,
        frequency,
        base_count: baseCount || undefined,
        observation_notes: observationNotes || "",
      })
      .then(onClose)
      .catch((error) => {
        console.error(error)
        createToast({
          type: ToastType.ERROR,
          title:
            error.response.data?.non_field_errors?.[0] ||
            "Failed to edit progress data",
        })
      })
  }

  const onChangeFrequency = (e: React.ChangeEvent<HTMLInputElement>) => {
    const freq = parseInt(e.target.value)
    if (!freq) setFrequency(null)
    if (isNaN(freq)) return
    setFrequency(freq)
  }

  const onChangeBaseCount = (e: React.ChangeEvent<HTMLInputElement>) => {
    const base = parseInt(e.target.value)
    if (!base) setBaseCount(null)
    if (isNaN(base)) return
    setBaseCount(base)
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      style={{ width: "min(500px, 90vw)" }}
    >
      <ModalHeader
        title={isEditing ? "Edit progress data" : "Add new progress data"}
        onClose={onClose}
        showBorderBottom
      />
      <FlexBox style={{ padding: SPACING.space6, paddingTop: SPACING.space4 }}>
        <FlexBox direction="column" gap={SPACING.space4}>
          <FlexBox direction="row" gap={SPACING.space4} wrap={false}>
            <FlexBox
              direction="column"
              gap={SPACING.space1}
              style={{ width: "fit-content" }}
            >
              <Typography variant="bodyBold">Count</Typography>
              <TextInput
                name="Frequency"
                type="text"
                icon="code"
                onChange={onChangeFrequency}
                value={frequency?.toString() || ""}
                style={{ minWidth: "unset", width: "100px" }}
              />
            </FlexBox>
            {measurementType === MasteryCriteriaMeasurement.PERCENTAGE && (
              <>
                <FlexBox
                  direction="column"
                  gap={SPACING.space1}
                  style={{ width: "fit-content" }}
                >
                  <Typography variant="bodyBold">Total </Typography>
                  <TextInput
                    name="Base count"
                    type="text"
                    icon="code"
                    value={baseCount ? baseCount.toString() : ""}
                    style={{ minWidth: "100px", width: "100px" }}
                    onChange={onChangeBaseCount}
                  />
                </FlexBox>
                <FlexBox
                  direction="column"
                  justify="flex-end"
                  gap={SPACING.space1}
                >
                  {!!baseCount && frequency !== null && (
                    <Typography
                      variant="body"
                      style={{ lineHeight: "40px", marginTop: SPACING.space7 }}
                    >
                      = {`${((frequency / baseCount) * 100).toFixed(1)}%`}
                    </Typography>
                  )}
                </FlexBox>
              </>
            )}
          </FlexBox>
          <FlexBox direction="column" gap={SPACING.space1}>
            <Typography variant="bodyBold">Date</Typography>
            <DateInput
              modalTitle="Select date"
              name="Date"
              date={date}
              setDate={setDate}
              maxDate={new Date()}
            />
          </FlexBox>
          <FlexBox direction="column" gap={SPACING.space1}>
            <Typography variant="bodyBold">Observation notes</Typography>
            <TextArea
              name="Observation notes"
              value={observationNotes || ""}
              onChange={(e) => setObservationNotes(e.target.value)}
              fullWidth
              placeholder="ie. seeing some regression, pay attention to tantrums next session"
            />
          </FlexBox>
          <FlexBox
            direction="row"
            gap={SPACING.space2}
            align="center"
            justify="flex-end"
            wrap={false}
            style={{ marginTop: SPACING.space6 }}
          >
            <TextButton onClick={onClose}>
              <Typography
                variant="bodyBold"
                color={JoonUIColor.text.primaryAccent}
              >
                Cancel
              </Typography>
            </TextButton>
            <Button
              text="Save progress"
              onClick={isEditing ? onClickEdit : onClickAdd}
              isLoading={
                addObservationalDataMutation.isLoading ||
                editObservationalDataMutation.isLoading
              }
              disabled={isExampleClient}
            />
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </Modal>
  )
}

export default AddEditObservationalDataModal
