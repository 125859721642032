import { faArrowLeftLong } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  Button,
  FlexBox,
  JoonUIColor,
  Modal,
  SPACING,
  TextButton,
  Typography,
} from "@joonapp/web-shared"
import { useMediaQuery } from "@mui/material"
import { useMemo } from "react"
import { useParams } from "react-router-dom"
import { useShallow } from "zustand/react/shallow"

import FavoritesEmptyState from "./FavoritesEmptyState"
import ModuleFilterPanel from "./ModuleFilterPanel"
import { ModulePreview } from "./ModulePreview"
import ModuleSearchResultItem from "./ModuleSearchResultItem"
import { FilteredResultsCounter } from "./Resources"
import SearchEmptyState from "./ResourceSearchEmptyState"
import ResourceTag from "./ResourceTag"
import useAddModuleStore from "./useAddModuleStore"
import useResourcesFilterStore, {
  useIsFilteredModules,
} from "./useResourceFilterStore"
import { SkeletonLoaderRow } from "../../components/loading/SkeletonLoader"
import {
  useLearningModulesQuery,
  useCurrentTrainingQuery,
} from "../../networking/queries"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"
import useEditTrainingMutation from "../patientInfo/parentTraining/mutations/useEditTrainingMutation"

const AddTrainingModuleModal = () => {
  const { isOpen, onClose } = useAddModuleStore()

  const isMobile = useMediaQuery("(max-width: 768px)")

  if (isMobile)
    return (
      <Modal isOpen={isOpen} onClose={onClose}>
        <FlexBox
          direction="column"
          gap={SPACING.space6}
          style={{ padding: SPACING.space4, width: "min(340px, 95vw)" }}
        >
          <Typography variant="h3" textAlign="left">
            Parent training set up is currently only available in desktop.
          </Typography>
          <Typography variant="body" textAlign="left">
            Come back to Joon on your computer to set up parent training.
          </Typography>
          <Button
            style={{ marginLeft: "auto" }}
            text="Okay"
            fullWidth
            onClick={onClose}
          />
        </FlexBox>
      </Modal>
    )

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      mobileFull
      style={{
        width: !isMobile ? "min(1400px, 95vw)" : undefined,
        maxWidth: "1400px",
        height: !isMobile ? "min(760px, 95dvh)" : undefined,
        maxHeight: !isMobile ? "min(760px, 95dvh)" : undefined,
      }}
    >
      <ResourceSearchView />
    </Modal>
  )
}

const ResourceSearchView = () => {
  const { modulesIdsToAdd } = useAddModuleStore()
  const contentHeight = `calc(100% - ${
    modulesIdsToAdd.length > 0 ? "76px" : "0px"
  })`

  return (
    <>
      <FlexBox direction="row" wrap={false} style={{ height: contentHeight }}>
        <ModuleFilterPanel />
        <ModuleSearchResults />
        <ModuleDetailsSection />
      </FlexBox>
      <ModuleSelectedBar />
    </>
  )
}

export default AddTrainingModuleModal

const ModuleSearchResults = () => {
  const { selectedModuleId } = useAddModuleStore()
  const { displayFavorites } = useResourcesFilterStore(
    useShallow((state) => ({ displayFavorites: state.displayFavorites }))
  )
  const { data: currentTraining } = useCurrentTrainingQuery()
  const { data: filteredModules, isLoading } = useLearningModulesQuery()

  const isSmallScreen = useMediaQuery("(max-width: 1100px)")

  // Only display modules that are not already included
  const filteredModulesWithoutExistingModules = useMemo(() => {
    const existingModuleIds = currentTraining?.assignments.map(
      (module) => module.learning_module.id
    )
    return filteredModules?.filter(
      (module) => !existingModuleIds?.includes(module.id)
    )
  }, [filteredModules, currentTraining])

  const isFilteredModules = useIsFilteredModules()
  if (isSmallScreen && selectedModuleId) return null

  return (
    <FlexBox
      direction="column"
      style={{
        height: "100%",
        overflowY: "auto",
        padding: `${SPACING.space4} ${SPACING.space1}`,
        borderLeft: `1px solid ${JoonUIColor.border.default}`,
        borderRight: `1px solid ${JoonUIColor.border.default}`,
      }}
      wrap={false}
    >
      {isFilteredModules && (
        <div style={{ padding: `0 ${SPACING.space2}` }}>
          <FilteredResultsCounter />
        </div>
      )}
      {isLoading ? (
        <SkeletonLoaderRow height={48} numRows={8} />
      ) : filteredModulesWithoutExistingModules &&
        filteredModulesWithoutExistingModules?.length > 0 ? (
        filteredModulesWithoutExistingModules?.map((learningModule, i) => (
          <ModuleSearchResultItem key={i} learningModule={learningModule} />
        ))
      ) : displayFavorites ? (
        <FavoritesEmptyState />
      ) : (
        <SearchEmptyState />
      )}
    </FlexBox>
  )
}

const ModuleDetailsSection = () => {
  const { selectedModuleId, setSelectedModuleId } = useAddModuleStore()
  const { data: learningModules } = useLearningModulesQuery()
  const selectedModule = learningModules?.find(
    (module) => module.id === selectedModuleId
  )

  const isSmallScreen = useMediaQuery("(max-width: 1100px)")

  if (!selectedModule) return null

  return (
    <FlexBox
      direction="column"
      gap={SPACING.space4}
      wrap={false}
      style={{
        height: "100%",
        overflowY: "auto",
        minWidth: "200px",
        width: "80%",
        padding: SPACING.space4,
      }}
    >
      <FlexBox align="center" wrap={false}>
        {isSmallScreen && (
          <TextButton onClick={() => setSelectedModuleId(null)}>
            <FontAwesomeIcon
              icon={faArrowLeftLong}
              style={{ marginRight: SPACING.space2 }}
            />
          </TextButton>
        )}
        <Typography variant="h3">{selectedModule.title}</Typography>
      </FlexBox>
      <ModulePreview learningModule={selectedModule} />
      <FlexBox direction="column" gap={SPACING.space2}>
        <Typography variant="bodyBold" textAlign="left">
          Relevant tags
        </Typography>
        <FlexBox gap={SPACING.space2}>
          {selectedModule?.tags.map((tag) => (
            <ResourceTag tag={tag} key={tag.id} />
          ))}
        </FlexBox>
      </FlexBox>
      <FlexBox direction="column" gap={SPACING.space2}>
        <Typography variant="bodyBold" textAlign="left">
          Description
        </Typography>
        <Typography variant="bodySmall" textAlign="left">
          {selectedModule?.description}
        </Typography>
      </FlexBox>
    </FlexBox>
  )
}

const ModuleSelectedBar = () => {
  const { onClose, modulesIdsToAdd, clearModulesIdsToAdd } = useAddModuleStore()
  const { trainingId } = useParams()
  const { data: currentTraining } = useCurrentTrainingQuery()

  const { userId } = useParams()
  const isExamplePatient = Number(userId) === 0

  const editTrainingMutation = useEditTrainingMutation()

  if (modulesIdsToAdd.length === 0) return null

  const onClickAddModules = async () => {
    const existingModuleIds =
      currentTraining?.assignments.map((module) => module.learning_module.id) ||
      []
    await editTrainingMutation.mutateAsync({
      module_ids: [...existingModuleIds, ...modulesIdsToAdd],
      id: Number(trainingId),
    })
    clearModulesIdsToAdd()
    onClose()
    trackAnalyticEvent(ANALYTIC_EVENTS.save_modules)
  }

  return (
    <FlexBox
      direction="row"
      wrap={false}
      justify="space-between"
      align="center"
      style={{
        padding: SPACING.space4,
        background: JoonUIColor.background.lightGray,
      }}
    >
      <FlexBox direction="row" wrap={false} align="center" gap={SPACING.space2}>
        <Typography variant="bodyBold" color={JoonUIColor.text.secondary}>
          {modulesIdsToAdd.length} modules selected
        </Typography>
        <TextButton onClick={clearModulesIdsToAdd}>
          <Typography variant="bodyBold" color={JoonUIColor.text.primaryAccent}>
            Clear
          </Typography>
        </TextButton>
      </FlexBox>
      <FlexBox
        direction="row"
        wrap={false}
        gap={SPACING.space2}
        justify="flex-end"
      >
        <TextButton onClick={onClose}>
          <Typography variant="bodyBold" color={JoonUIColor.text.primaryAccent}>
            Cancel
          </Typography>
        </TextButton>
        <Button
          text="Add Modules"
          onClick={onClickAddModules}
          isLoading={editTrainingMutation.isLoading}
          disabled={isExamplePatient}
        >
          Add Modules
        </Button>
      </FlexBox>
    </FlexBox>
  )
}
