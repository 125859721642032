import {
  Button,
  FlexBox,
  JoonUIColor,
  Modal,
  ModalHeader,
  SPACING,
  TextButton,
  Typography,
} from "@joonapp/web-shared"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { create } from "zustand"

import { deleteInvitation } from "../../../networking/invitations"
import { QUERY_KEYS } from "../../../networking/queries"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../util/analytics"

interface DeleteInvitationModalStore {
  isOpen: boolean
  onOpen: (selectedInvitationId: number) => void
  onClose: () => void

  selectedInvitationId: number | null
}

export const useDeleteInvitationModalStore = create<DeleteInvitationModalStore>(
  (set) => ({
    isOpen: false,
    onOpen: (selectedInvitationId) =>
      set({ isOpen: true, selectedInvitationId }),
    onClose: () => set({ isOpen: false }),

    selectedInvitationId: null,
  })
)

const DeleteInvitationModal = () => {
  const { isOpen, onClose, selectedInvitationId } =
    useDeleteInvitationModalStore()
  const deleteInvitationMutation = useMutation(deleteInvitation)

  const queryClient = useQueryClient()

  const onClickDelete = async () => {
    if (!selectedInvitationId) return
    await deleteInvitationMutation.mutateAsync(selectedInvitationId)
    onClose()
    queryClient.invalidateQueries([QUERY_KEYS.SENT_INVITES])
    trackAnalyticEvent(ANALYTIC_EVENTS.delete_outgoing_invite)
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalHeader title="Are you sure?" onClose={onClose} showBorderBottom />
      <FlexBox
        direction="column"
        gap={SPACING.space6}
        style={{ padding: SPACING.space6, width: "min(450px, 95vw)" }}
      >
        <Typography variant="body">
          Once deleted, you’d have to send or receive a new invite to get
          connected.
        </Typography>
        <FlexBox direction="row" justify="space-between">
          <TextButton onClick={onClose}>
            <Typography variant="bodyBold" color={JoonUIColor.semantic.primary}>
              Cancel
            </Typography>
          </TextButton>
          <Button
            buttonType="redPrimary"
            text="Delete invite"
            onClick={onClickDelete}
            isLoading={deleteInvitationMutation.isLoading}
          />
        </FlexBox>
      </FlexBox>
    </Modal>
  )
}

export default DeleteInvitationModal
