import {
  FlexBox,
  JoonColorExpanded,
  JoonUIColor,
  QuestInstance,
  QuestStatus,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import { useQueryClient } from "@tanstack/react-query"
import dayjs from "dayjs"
import { useEffect } from "react"

import { QUERY_KEYS } from "../../../../networking/queries"
import { verifyQuest } from "../../../../networking/quests"

const ParentTaskCompletion = ({ task }: { task: QuestInstance }) => {
  const queryClient = useQueryClient()

  const { color: difficultyColor, text: difficultyText } =
    getTaskDifficultyLabel(task.completion_difficulty)

  useEffect(() => {
    if (task.status === QuestStatus.COMPLETED) {
      verifyQuest({
        instanceId: task.id,
      }).then(() => {
        queryClient.invalidateQueries([QUERY_KEYS.PARENT_TASK_INSTANCES])
      })
    }
  }, [])

  return (
    <FlexBox direction="row" gap={SPACING.space2} wrap={false}>
      <TaskCompletionDot color={difficultyColor} />
      <div style={{ width: "100%" }}>
        {task.completion_date && (
          <Typography
            variant="bodySmall"
            style={{ marginLeft: SPACING.space3 }}
          >
            {dayjs.unix(+task.completion_date).format("MMM D")}
          </Typography>
        )}
        <FlexBox
          direction="column"
          gap={SPACING.space1}
          wrap={false}
          style={{
            background: JoonUIColor.background.primaryNeutral,
            borderRadius: SPACING.space2,
            padding: `${SPACING.space2} ${SPACING.space3}`,
          }}
        >
          {task.completion_difficulty !== null && (
            <Typography
              variant="caption"
              textAlign="left"
              color={difficultyColor}
            >
              {difficultyText}
            </Typography>
          )}
          {task.completion_notes && (
            <Typography variant="bodySmall" textAlign="left">
              {task.completion_notes}
            </Typography>
          )}
          {task.completion_difficulty === null && !task.completion_notes && (
            <Typography variant="bodySmall" textAlign="left">
              Completed with no reflections
            </Typography>
          )}
        </FlexBox>
      </div>
    </FlexBox>
  )
}

const getTaskDifficultyLabel = (
  difficulty: 0 | 1 | 2 | 3 | 4 | 5 | 6 | null
) => {
  switch (difficulty) {
    case 0:
      return {
        text: "Exceeded expectations",
        color: JoonUIColor.semantic.success,
      }
    case 1:
      return {
        text: "Better than expected",
        color: JoonColorExpanded.viridian300,
      }
    case 2:
      return {
        text: "A little better than expected",
        color: JoonColorExpanded.viridian300,
      }
    case 3:
      return { text: "As expected", color: JoonColorExpanded.green300 }
    case 4:
      return { text: "Could be better", color: JoonUIColor.semantic.warning }
    case 5:
      return {
        text: "Could be much better",
        color: JoonUIColor.semantic.warning,
      }
    case 6:
      return { text: "Needs work", color: JoonUIColor.semantic.alert }
    case null:
    default:
      return { text: null, color: JoonUIColor.background.disabled }
  }
}

export default ParentTaskCompletion

const TaskCompletionDot = ({ color }: { color: JoonColorExpanded }) => {
  return (
    <div style={{ position: "relative" }}>
      <div
        style={{
          minWidth: SPACING.space4,
          minHeight: SPACING.space4,
          borderRadius: "50%",
          marginTop: SPACING.space9,
          background: color,
          border: `2px solid ${JoonUIColor.background.primaryNeutral}`,
          zIndex: 10,
          position: "relative",
        }}
      />
      <div
        style={{
          position: "absolute",
          left: "50%",
          bottom: 0,
          transform: "translateX(-50%)",
          height: "100vh",
          backgroundColor: "white",
          width: "3px",
          zIndex: 0,
        }}
      />
    </div>
  )
}
