import { useMutation, useQueryClient } from "@tanstack/react-query"
import dayjs from "dayjs"
import { useParams } from "react-router-dom"

import { markIncidentAsRead } from "../../../../networking/incidents"
import { QUERY_KEYS } from "../../../../networking/queries"
import { Incident } from "../../../../types"

const useMarkIncidentAsReadMutation = () => {
  const queryClient = useQueryClient()
  const { userId } = useParams()

  const queryKey = [QUERY_KEYS.UNREAD_INCIDENTS, Number(userId)]

  return useMutation({
    mutationFn: markIncidentAsRead,
    onMutate: (incidentId: number) => {
      const previousIncidents = queryClient.getQueryData<Incident[]>(queryKey)
      const newIncidents = previousIncidents?.map((incident) => {
        if (incident.id === incidentId) {
          return { ...incident, date_read: dayjs().toISOString() }
        }
        return incident
      })
      queryClient.setQueryData(queryKey, newIncidents)
      return { previousIncidents }
    },
    onSettled: () => {
      queryClient.invalidateQueries(queryKey)
    },
    onError: (
      _err: any,
      _variables: any,
      context: { previousIncidents: Incident[] | undefined } | undefined
    ) => {
      if (context?.previousIncidents) {
        queryClient.setQueryData(queryKey, context.previousIncidents)
      }
    },
  })
}

export default useMarkIncidentAsReadMutation
