// Container for the incident log data, including the graph and table views
// as well as functionality to trigger a resize when the data changes

import { JoonUIColor, SPACING } from "@joonapp/web-shared"
import { useCallback, useState } from "react"

import IncidentLogEmptyState from "./IncidentLogEmptyState"
import IncidentLogTable from "./IncidentLogTable"
import useIncidentLog from "./useIncidentLog"
import { DataSCView } from "../../../types"
import IncidentLogGraph from "../IncidentLogGraph"
import DrawerContainer from "../parentTraining/dataTasks/DrawerContainer"

type Props = {
  showIncidentLog: boolean
  dataView: DataSCView
  id: number | null
  isDataTask: boolean
}

const IncidentLogDataContainer = ({
  showIncidentLog,
  dataView,
  id,
  isDataTask,
}: Props) => {
  const [resizeTrigger, setResizeTrigger] = useState(0)
  const { filteredIncidents, incidentLogDataType } = useIncidentLog(
    id,
    isDataTask
  )

  const triggerResize = useCallback(() => {
    setResizeTrigger((prev) => prev + 1)
  }, [])

  return (
    <DrawerContainer
      showDrawer={showIncidentLog}
      resizeDependencies={[resizeTrigger, dataView]}
    >
      <div
        style={{
          borderBottom: `1px solid ${JoonUIColor.border.default}`,
          marginTop: SPACING.space3,
          width: "100%",
        }}
      />
      {filteredIncidents?.length === 0 ? (
        <IncidentLogEmptyState isDataTask={false} />
      ) : (
        <>
          {dataView === DataSCView.TABLE && (
            <IncidentLogTable
              id={id}
              isDataTask={isDataTask}
              showIncidentLog={showIncidentLog}
              onPageChange={triggerResize}
            />
          )}
          {dataView === DataSCView.GRAPH && id && incidentLogDataType && (
            <IncidentLogGraph
              id={id}
              dataType={incidentLogDataType}
              isDataTask={isDataTask}
            />
          )}
        </>
      )}
    </DrawerContainer>
  )
}

export default IncidentLogDataContainer
