import {
  FlexBox,
  JoonUIColor,
  parseLineBreaks,
  ReadingTextModule,
  Shadow,
  SPACING,
  Typography,
} from "@joonapp/web-shared"

const ReadingModulePreview = ({
  learningModule,
}: {
  learningModule: ReadingTextModule
}) => {
  return (
    <FlexBox
      direction="column"
      wrap={false}
      gap={SPACING.space6}
      style={{
        maxHeight: "480px",
        overflowY: "auto",
        border: `1px solid ${JoonUIColor.border.default}`,
        padding: SPACING.space6,
        boxShadow: Shadow.high,
        borderRadius: SPACING.space2,
        background: JoonUIColor.background.primaryNeutral,
      }}
    >
      {learningModule.reading_texts.map((text, i) => (
        <FlexBox direction="column" wrap={false} gap={SPACING.space2} key={i}>
          <Typography variant="h3">{text.header}</Typography>
          <Typography variant="body">{parseLineBreaks(text.body)}</Typography>
        </FlexBox>
      ))}
    </FlexBox>
  )
}

export default ReadingModulePreview
