import { create } from "zustand"

interface ArchiveModuleModalStore {
  isOpen: boolean
  onClose: () => void
  onOpen: (id: number) => void

  selectedModuleAssignmentId: number | null
  setSelectedModuleAssignmentId: (id: number) => void
}

export const useArchiveModuleModalStore = create<ArchiveModuleModalStore>(
  (set) => ({
    isOpen: false,
    onClose: () => set({ isOpen: false, selectedModuleAssignmentId: null }),
    onOpen: (id) => set({ isOpen: true, selectedModuleAssignmentId: id }),

    selectedModuleAssignmentId: null,
    setSelectedModuleAssignmentId: (id) =>
      set({ selectedModuleAssignmentId: id }),
  })
)
