import {
  ArrowRightIcon,
  CheckIcon,
  FlexBox,
  InfoSolidIcon,
  JoonColorExpanded,
  JoonUIColor,
  SPACING,
  Typography,
  WarningTriangleIcon,
} from "@joonapp/web-shared"
import { CSSProperties, ReactNode, useMemo, useState } from "react"

export const Reminder = ({
  text,
  type,
  button,
  style,
  ...props
}: {
  text: string
  type: "alert" | "warning"
  button?: ReactNode
  style?: CSSProperties
}) => {
  const { colorPrimary, colorSecondary, Icon } = useMemo(() => {
    if (type === "alert") {
      return {
        colorPrimary: JoonUIColor.semantic.destructive,
        colorSecondary: JoonColorExpanded.red100,
        Icon: WarningTriangleIcon,
      }
    }
    return {
      colorPrimary: JoonUIColor.semantic.warning,
      colorSecondary: JoonColorExpanded.orange100,
      Icon: InfoSolidIcon,
    }
  }, [type])

  return (
    <FlexBox
      direction="row"
      align="center"
      wrap={false}
      style={{
        width: "fit-content",
        borderRadius: SPACING.space2,
        ...style,
      }}
      onClick={(e) => e.stopPropagation()}
      {...props}
    >
      <FlexBox
        align="center"
        gap={SPACING.space2}
        wrap={false}
        style={{ width: "fit-content" }}
      >
        <FlexBox
          align="center"
          justify="center"
          style={{
            width: "fit-content",
            padding: SPACING.space2,
            borderRadius: SPACING.space2,
            background: colorSecondary,
          }}
        >
          <Icon color={colorPrimary} size={12} />
        </FlexBox>
        <Typography
          variant="caption"
          color={JoonUIColor.text.secondary}
          style={{ marginRight: "auto", fontWeight: 600 }}
        >
          {text}
        </Typography>
      </FlexBox>
      {button}
    </FlexBox>
  )
}

export const ReminderButton = ({
  reminderSent = false,
  text = "Send reminder",
  onClick,
}: {
  reminderSent?: boolean
  text?: string
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void
}) => {
  const [isHovered, setIsHovered] = useState(false)

  return (
    <button
      style={{
        background: JoonUIColor.background.primaryNeutral,
        borderRadius: "100px",
        marginLeft: SPACING.space2,
        padding: 0,
        cursor: reminderSent ? "default" : "pointer",
      }}
      onClick={onClick}
      disabled={reminderSent}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <FlexBox align="center" gap={SPACING.space1} wrap={false}>
        <div
          style={{
            width: reminderSent ? "14px" : 0,
            transform: reminderSent ? "scale(1)" : "scale(0)",
            height: "14px",
            transition: "transform 0.3s",
          }}
        >
          <CheckIcon color={JoonUIColor.semantic.success} size={14} />
        </div>
        <Typography
          variant="bodySmall"
          color={
            reminderSent
              ? JoonUIColor.semantic.success
              : JoonUIColor.text.primaryAccent
          }
          style={{
            whiteSpace: "nowrap",
            textDecoration: reminderSent ? undefined : "underline",
          }}
        >
          {reminderSent ? "Reminder sent!" : text}
        </Typography>
        {isHovered && !reminderSent && (
          <ArrowRightIcon color={JoonUIColor.text.primaryAccent} size={14} />
        )}
      </FlexBox>
    </button>
  )
}
