import { JoonUIColor, Typography } from "@joonapp/web-shared"
import { PropsWithChildren } from "react"

const TableHeaderCell = (props: PropsWithChildren) => {
  return (
    <Typography
      variant="bodyXSmall"
      style={{ fontWeight: "700" }}
      color={JoonUIColor.text.secondary}
    >
      {props.children}
    </Typography>
  )
}

export default TableHeaderCell
