import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth"

import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../util/analytics"
import { firebaseApp } from "../util/firebase"
import { createJoonAPIClient } from "../util/joon-api"
import { sessionManager } from "../util/storage"

export async function signin(email: string, password: string) {
  trackAnalyticEvent(ANALYTIC_EVENTS.therapist_login)
  const joonApiClient = createJoonAPIClient()
  return joonApiClient
    .post("api/auth/signin/", { email: email, password: password })
    .then((res) => {
      trackAnalyticEvent(ANALYTIC_EVENTS.therapist_login_approved)
      return res
    })
}

// Sign in with google, and return the refresh token
export function signInWithGoogle(): Promise<any> {
  const auth = getAuth(firebaseApp)
  const googleAuthProvider = new GoogleAuthProvider().setCustomParameters({
    prompt: "select_account",
  })
  return signInWithPopup(auth, googleAuthProvider)
    .then(async (response) => {
      const refreshToken = response.user.refreshToken
      sessionManager.storeTokens(refreshToken, null)

      const user = await loadUser()
      if (!user) throw new Error("User does not exist. Please sign up.")

      trackAnalyticEvent(ANALYTIC_EVENTS.google_login_approved)
      return new Promise((resolve) => resolve(refreshToken))
    })
    .catch((error) => {
      sessionManager.clearAuthTokens()
      throw error
    })
}

export function signUpWithGoogle(): Promise<string> {
  const auth = getAuth(firebaseApp)
  const googleAuthProvider = new GoogleAuthProvider().setCustomParameters({
    prompt: "select_account",
  })
  return signInWithPopup(auth, googleAuthProvider).then((response) => {
    const refreshToken = response.user.refreshToken
    sessionManager.storeTokens(refreshToken, null)
    trackAnalyticEvent(ANALYTIC_EVENTS.google_signup_approved)
    return new Promise((resolve) => resolve(refreshToken))
  })
}

export async function signup(email: string, password: string): Promise<any> {
  const joonAPIClient = createJoonAPIClient()
  return joonAPIClient
    .post("api/auth/signup/", { email: email, password: password })
    .then((res) => {
      trackAnalyticEvent(ANALYTIC_EVENTS.create_login)
      return res
    })
}

export async function loadUser() {
  const joonApiClient = createJoonAPIClient()
  return joonApiClient.get("api/users/me", {
    params: { update_last_login: true },
  })
}

export async function resetPassword(email: string) {
  const joonApiClient = createJoonAPIClient()
  return joonApiClient.post(`api/auth/reset-password/`, { email })
}

export const sendEmailInvite = async (email: string, authCodeId: number) => {
  const API = createJoonAPIClient()
  return API.post(`api/auth-codes/${authCodeId}/send-email-invite/`, {
    email,
  })
}
