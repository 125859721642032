import { ParentTraining } from "@joonapp/web-shared"
import { useMutation } from "@tanstack/react-query"
import { useParams } from "react-router-dom"

import { createToast } from "../../../../components/toast/Toast"
import { addParentTraining } from "../../../../networking/parentTraining"
import { QUERY_KEYS } from "../../../../networking/queries"
import { queryClient } from "../../../../queryClient"
import { ToastType } from "../../../../types"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../../util/analytics"

export const useAddTrainingMutation = () => {
  const { userId } = useParams()

  return useMutation({
    mutationFn: addParentTraining,
    onSuccess: (response: ParentTraining) => {
      queryClient.invalidateQueries([QUERY_KEYS.PARENT_TRAININGS, userId])

      const previousTrainings = queryClient.getQueryData<ParentTraining[]>([
        QUERY_KEYS.PARENT_TRAININGS,
        userId,
      ])

      const newTrainings = previousTrainings
        ? [...previousTrainings, response]
        : [response]

      queryClient.setQueryData<ParentTraining[]>(
        [QUERY_KEYS.PARENT_TRAININGS, userId],
        newTrainings
      )

      trackAnalyticEvent(ANALYTIC_EVENTS.add_parent_training)

      return response
    },

    onError: (err: any) => {
      createToast({
        title: err?.response?.data?.message || "An error occurred",
        type: ToastType.ERROR,
      })
    },
  })
}
