import { DestinationPlugin, Event, Result } from "@amplitude/analytics-types"
import { default as hj } from "@hotjar/browser"
export class HotjarPlugin implements DestinationPlugin {
  name = "hotjar"
  type = "destination" as const
  siteId: number
  hotjarVersion: number

  constructor(siteId: number, hotjarVersion: number) {
    this.siteId = siteId
    this.hotjarVersion = hotjarVersion
  }

  async setup(): Promise<void> {
    hj.init(this.siteId, this.hotjarVersion)
  }

  async execute(event: Event): Promise<Result> {
    if (event.event_type === "$identify") {
      const { user_id, device_id, user_properties } = event
      const hotjarId = user_id || device_id || ""
      // @ts-ignore
      hj.identify(hotjarId, user_properties || {})
    } else {
      hj.event(event.event_type)
    }
    return {
      code: 0,
      event: event,
      message: "Event forwarded to Hotjar API",
    }
  }
}
