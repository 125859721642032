import { useMutation, useQueryClient } from "@tanstack/react-query"

import { createToast } from "../../components/toast/Toast"
import { QUERY_KEYS } from "../../networking/queries"
import { PatientGroup, ToastType } from "../../types"
import { createJoonAPIClient } from "../../util/joon-api"

const useDeleteClientMutation = () => {
  const queryClient = useQueryClient()

  const queryKeyMyPatients = [QUERY_KEYS.PATIENT_GROUPS, null]
  const queryKeyAllPatients = [QUERY_KEYS.PATIENT_GROUPS, true]

  return useMutation({
    mutationFn: (patientGroupId: number) => deleteClient(patientGroupId),
    onMutate: (patientGroupId: number) => {
      // Update my patients cache
      const previousMyPatients =
        queryClient.getQueryData<PatientGroup[]>(queryKeyAllPatients)
      const newMyPatients = previousMyPatients?.filter(
        (patientGroup) => patientGroup.id !== patientGroupId
      )
      queryClient.setQueryData<PatientGroup[]>(
        queryKeyMyPatients,
        newMyPatients
      )

      // Update all patients cache
      const previousAllPatients =
        queryClient.getQueryData<PatientGroup[]>(queryKeyAllPatients)

      const newAllPatients = previousAllPatients?.filter(
        (patientGroup) => patientGroup.id !== patientGroupId
      )
      queryClient.setQueryData<PatientGroup[]>(
        queryKeyAllPatients,
        newAllPatients
      )

      return { previousMyPatients, previousAllPatients }
    },
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeyMyPatients)
      queryClient.invalidateQueries(queryKeyAllPatients)

      createToast({
        title: "Client removed successfully",
        type: ToastType.SUCCESS,
      })
    },
    onError: (_error, _patientGroupId, context) => {
      queryClient.setQueryData<PatientGroup[]>(
        queryKeyMyPatients,
        context?.previousMyPatients
      )
      queryClient.setQueryData<PatientGroup[]>(
        queryKeyAllPatients,
        context?.previousAllPatients
      )
    },
  })
}

export default useDeleteClientMutation

const deleteClient = async (patientGroupId: number) => {
  const API = createJoonAPIClient()
  const { data } = await API.delete(`api/patient-groups/${patientGroupId}`)
  return data
}
