import { useNavigate, useParams } from "react-router-dom"

import { ParentTrainingTab, PatientInfoTab } from "../../../../types"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../../util/analytics"
import { useAddEditParentTaskStore } from "../../useAddEditParentTaskStore"
import SummarySectionEmptyState from "../SummarySectionEmptyState"

const TodosEmptyState = () => {
  const { onOpen: openAddTodoTaskModal } = useAddEditParentTaskStore()

  const navigate = useNavigate()
  const { userId, trainingId } = useParams()

  const onClickAdd = () => {
    navigate(
      `/patients/${userId}/${PatientInfoTab.PARENT_TRAINING}/${trainingId}/${ParentTrainingTab.TODO_TASKS}`
    )
    openAddTodoTaskModal()
    trackAnalyticEvent(ANALYTIC_EVENTS.open_add_modules)
  }

  return (
    <SummarySectionEmptyState
      onClick={onClickAdd}
      title="Add quick to-do"
      description="Any one off things you’d like your parents to do outside of session!"
    />
  )
}

export default TodosEmptyState
