import { Button, ButtonProps, CircularProgress } from "@mui/material"

import { JoonColor } from "../../util/theme"

interface Props extends ButtonProps {
  children: React.ReactNode
  isLoading?: boolean
  disabled?: boolean
  onSubmit?: () => void
  buttonType?:
    | "bluePrimary"
    | "blueSecondary"
    | "orangePrimary"
    | "questionnaireAnswerPrimary"
    | "questionnaireAnswerSecondary"
    | "redPrimary"
    | "greenPrimary"
    | "cancel"
}

const JoonButton = ({
  children,
  isLoading = false,
  disabled,
  onSubmit = () => {},
  buttonType = "bluePrimary",
  style,
  ...props
}: Props) => {
  return (
    <Button
      size="small"
      type="submit"
      disabled={isLoading || disabled}
      onClick={onSubmit}
      onSubmit={onSubmit}
      style={{ ...JoonButtonStyle[buttonType], ...style }}
      {...props}
    >
      {isLoading ? <CircularProgress size={28} color="secondary" /> : children}
    </Button>
  )
}

export default JoonButton

const buttonBaseStyles = {
  width: "100%",
  height: 50,
  fontSize: "16px",
  fontWeight: "600",
  textTransform: "none" as const,
  transition: "0s",
  border: "none",
  boxShadow: "none",
  padding: "0 12px",
  borderRadius: "25px",
}

export const JoonButtonStyle = {
  bluePrimary: {
    ...buttonBaseStyles,
    backgroundColor: JoonColor.blue,
    color: JoonColor.white,
  },
  blueSecondary: {
    ...buttonBaseStyles,
    backgroundColor: "white",
    border: `2px solid ${JoonColor.blue}`,
    color: JoonColor.blue,
  },
  orangePrimary: {
    ...buttonBaseStyles,
    backgroundColor: JoonColor.orange,
    color: JoonColor.white,
  },
  questionnaireAnswerPrimary: {
    ...buttonBaseStyles,
    borderRadius: "16px",
    background: JoonColor.secondaryPurple,
    color: JoonColor.white,
  },
  questionnaireAnswerSecondary: {
    ...buttonBaseStyles,
    borderRadius: "16px",
    padding: "0 12px",
    color: JoonColor.text01,
    background: JoonColor.input,
  },
  redPrimary: {
    ...buttonBaseStyles,
    backgroundColor: JoonColor.red,
    color: JoonColor.white,
  },
  greenPrimary: {
    ...buttonBaseStyles,
    backgroundColor: JoonColor.green,
    color: JoonColor.white,
  },
  cancel: {
    ...buttonBaseStyles,
    border: "1px solid #ccc",
    backgroundColor: JoonColor.white,
    color: "#555",
  },
}
