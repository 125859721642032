import {
  CheckIcon,
  FlexBox,
  JoonUIColor,
  SPACING,
  Typography,
} from "@joonapp/web-shared"

import ButtonSmall from "../../../components/buttons/ButtonSmall"
import { useInvitationAcceptModalStore } from "../../../components/modals/InvitationAcceptModal"
import { InvitationGroup } from "../../../types"

type Props = {
  invitationGroup: InvitationGroup
}

const AcceptInviteButton = ({ invitationGroup }: Props) => {
  const { onOpen: onOpenAcceptInvitationModal } =
    useInvitationAcceptModalStore()

  return (
    <ButtonSmall onClick={() => onOpenAcceptInvitationModal(invitationGroup)}>
      <FlexBox direction="row" gap={SPACING.space2} wrap={false} align="center">
        <CheckIcon color={JoonUIColor.semantic.success} size={16} />
        <Typography variant="bodySmall">Accept</Typography>
      </FlexBox>
    </ButtonSmall>
  )
}

export default AcceptInviteButton
