import { FlexBox, JoonUIColor, SPACING } from "@joonapp/web-shared"
import { useMediaQuery } from "@mui/material"

import OnboardHeader from "./OnboardHeader"

type Props = {
  children: JSX.Element | JSX.Element[]
}

const OnboardBox = ({ children }: Props) => {
  const isMobile = useMediaQuery("(max-width:800px)")
  return (
    <FlexBox
      style={{
        width: "min(100vw, 450px)",
        boxShadow: isMobile
          ? "none"
          : "-6px -6px 16px #F0F3F8, 6px 6px 16px #F0F3F8",
        background: isMobile
          ? "transparent"
          : JoonUIColor.background.primaryNeutral,
        position: "relative",
        borderRadius: SPACING.space4,
        padding: isMobile ? SPACING.space8 : SPACING.space12,
        paddingTop: isMobile ? SPACING.space4 : SPACING.space12,
      }}
    >
      <OnboardHeader />
      {children}
    </FlexBox>
  )
}

export default OnboardBox
