import {
  ChartLineIcon,
  FlexBox,
  JoonUIColor,
  SegmentedControl,
  SPACING,
  TableIcon,
} from "@joonapp/web-shared"

import { DataSCView } from "../../types"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"

// Segmented control that consists of icons and toggles between graph and table views

const DataViewSegmentedControl = ({
  view,
  setView,
  order = [DataSCView.GRAPH, DataSCView.TABLE],
}: {
  view: DataSCView
  setView: (view: DataSCView) => void
  order?: DataSCView[]
}) => {
  const IconLabel = ({ labelView }: { labelView: DataSCView }) => {
    const Icon = DataViewIcons[labelView]
    return (
      <FlexBox
        align="center"
        justify="center"
        style={{ padding: SPACING.space05 }}
      >
        <Icon
          color={
            labelView === view
              ? JoonUIColor.icon.accent
              : JoonUIColor.icon.neutral
          }
          size={18}
        />
      </FlexBox>
    )
  }

  return (
    <SegmentedControl
      options={order.map((view) => ({
        label: <IconLabel labelView={view} />,
        value: view,
      }))}
      value={view}
      setValue={(value) => {
        trackAnalyticEvent(ANALYTIC_EVENTS.toggle_observational_data, {
          view: value,
        })
        setView(value as DataSCView)
      }}
    />
  )
}

export default DataViewSegmentedControl

const DataViewIcons = {
  [DataSCView.GRAPH]: ChartLineIcon,
  [DataSCView.TABLE]: TableIcon,
}
