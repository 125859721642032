import {
  FrequencyTarget,
  ParentTrainingMasteryProgress,
  QuestDifficulty,
  TherapistUser,
  UserRole,
} from "@joonapp/web-shared"
import dayjs, { Dayjs } from "dayjs"
import { CSSProperties } from "react"
import { RRule } from "rrule"

import { JoonColor } from "./theme"
import { DATE_RANGES } from "../constants"
import { SummaryTableRow } from "../pages/patientInfo/homeRoutines/overTimeProgress/data"
import {
  DateRange,
  Invitation,
  InvitationGroup,
  PatientGroup,
  Practice,
  TrainingMasteryProgressGraphDataPoint,
  TrendLine,
} from "../types"

export const isUsingInAppBrowser = (() => {
  // @ts-ignore
  var ua = navigator.userAgent || navigator.vendor || window.opera || ""
  return (
    /FBAN|FBAV|FBBV|FB|FBDV|FBMD|FBSV|FBSS|FBCR|FBID|FBLC|FBOP|FBCA/i.test(
      ua
    ) ||
    /Instagram/i.test(ua) ||
    /Tiktok|TT/i.test(ua)
  )
})()

export const isCustomDateRange = (dateRange: DateRange) =>
  !Object.values(DATE_RANGES).some(
    (range) =>
      range.startDate.format("DD-MM-YYYY") ===
        dateRange.startDate?.format("DD-MM-YYYY") &&
      range.endDate.format("DD-MM-YYYY") ===
        dateRange.endDate?.format("DD-MM-YYYY")
  )

export const toPercentage = (val: number) => {
  return `${Math.floor(val * 100)}`
}

export const getColor = (num: number, reverse = false) => {
  if (reverse) num = 1 - num
  if (num >= 0.66) return JoonColor.green
  else if (num >= 0.33) return JoonColor.additionalOrange
  else return JoonColor.red
}

export const getDayAbbreviations = (repetitionArray: boolean[]) =>
  repetitionArray
    .map((day: boolean, i: number) => (day ? dayjs().day(i).format("dd") : ""))
    .join("")

export const isAfterToday = (date: string) =>
  dayjs(date).isAfter(dayjs(), "day")

export const capitalizeFirstLetter = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1)

export const displayStringFromRole = (role: UserRole): string => {
  switch (role) {
    case UserRole.MEMBER:
      return "Clinician"
    case UserRole.ADMIN:
    case UserRole.PARENT:
    case UserRole.CHILD:
    case UserRole.THERAPIST:
    case UserRole.PATIENT:
      return capitalizeFirstLetter(role)
  }
}

export const isAnEmail = (email: string) => {
  return /\S+@\S+\.\S+/.test(email)
}
export function calculateQuestSums(data: SummaryTableRow[], fieldName: string) {
  return data.reduce((result: any, obj: any) => {
    const { quest } = obj
    result[quest] = (result[quest] || 0) + obj[fieldName]
    return result
  }, {})
}

export const getCurrentUserProfileInfo = (
  practice: Practice,
  user: TherapistUser
) => practice.profiles.find((profile: any) => profile.user.id === user.id)

export const getCurrentChild = (patients: any, userId: string | undefined) =>
  patients?.profiles.find((profile: any) => profile.user.id === Number(userId))

export const createErrorFromResponse = (err: any) => {
  let error = err?.response?.data || err.message || err
  if (typeof error == "string") return error
  if (Object.values(error).length > 0) {
    error = Object.values(error)[0]
    if (error.length > 0) return error[0]
    else return error
  }
}

export const createErrorFromSignupResponse = (err: any): string => {
  const error = err?.response?.data || err

  if (typeof error == "string") return error
  if (Object.entries(error).length > 0) {
    const errorKey = Object.entries(error)[0][0]
    const errorMessage = Object.entries(error)[0][1] as string | string[]
    if (errorMessage?.constructor === Array) {
      if (["email", "password"].includes(errorKey))
        return `${errorKey}: ${errorMessage[0]}`
      else return `${errorMessage[0]}`
    } else return errorMessage as string
  }
  return "Unknown error occurred. Please try again."
}

export const getElementHeight = (
  id: string,
  fallback: CSSProperties["maxHeight"] = "0px"
) => {
  const element = document.getElementById(id)
  return element?.offsetHeight ? `${element.offsetHeight}px` : fallback
}

export const dateIsToday = (date: Dayjs) =>
  date.format("MM-DD-YYYY") === dayjs().format("MM-DD-YYYY")

export const generateDateArray = (startDate: Dayjs, endDate: Dayjs) => {
  let currentDate = startDate
  const result = []

  while (currentDate.isBefore(endDate) || currentDate.isSame(endDate, "day")) {
    result.push({ date: currentDate, dayOfWeek: currentDate.format("dd") })
    currentDate = currentDate.add(1, "day")
  }

  return result
}

export const groupInvitesByInviter = (
  invites: Invitation[]
): InvitationGroup[] => {
  const therapistInvites: { [key: string]: InvitationGroup } = {}
  for (const invite of invites) {
    const inviterId = invite.inviter.id
    if (!therapistInvites[inviterId]) therapistInvites[inviterId] = []
    therapistInvites[inviterId].push(invite)
  }

  return Object.values(therapistInvites)
}

export const groupInvitesByInvitee = (
  invites: Invitation[]
): InvitationGroup[] => {
  const therapistInvites: { [key: string]: InvitationGroup } = {}
  for (const invite of invites) {
    const inviteeId = invite.invitee_email
    if (!therapistInvites[inviteeId]) therapistInvites[inviteeId] = []
    therapistInvites[inviteeId].push(invite)
  }

  return Object.values(therapistInvites)
}

export const questRepeatSelectionOptions = {
  noRepeat: "Does not repeat",
  repeat: "On day(s)",
} as const

export const getRepetitionArrayFromRrule = (rruleString: string): boolean[] => {
  const rrule = RRule.fromString(rruleString)
  const weekdays = [6, 0, 1, 2, 3, 4, 5] // Mapping to RRule's internal representation

  return weekdays.map((day) => rrule.options.byweekday?.includes(day) || false)
}

export const getReptitionIntervalFromRrule = (rruleString: string): number => {
  return RRule.fromString(rruleString).options.interval
}

export const getQuestDifficulty = (numCoins: number): QuestDifficulty => {
  if (numCoins <= 5) return QuestDifficulty.EASY
  if (numCoins < 17) return QuestDifficulty.MEDIUM
  else return QuestDifficulty.HARD
}

export const getMostRecentTarget = (targets: FrequencyTarget[] | undefined) => {
  if (!targets) return null
  const currentDay = targets.filter(
    (target) => target.date_deleted === null
  )?.[0]
  return currentDay || null
}

export const getPatientGroupByUserId = (
  patientGroups: PatientGroup[] | undefined,
  patientId: number
) => {
  if (patientGroups === undefined) return undefined
  return patientGroups.find((group) =>
    group.profiles.some((profile) => profile.user.id === patientId)
  )
}

// Function converts the data to the format that the graph expects
// With percentage if base_count is present
// and date as a timestamp so recharts can better understand it
export const addDatesToProgressData = (data: ParentTrainingMasteryProgress[]) =>
  data
    ?.map((item) => ({
      ...item,
      date: dayjs(item.date, "YYYY-MM-DD").valueOf() as any, // Convert to timestamp
      ...(item.base_count && {
        percentage: (item.frequency / item.base_count) * 100,
      }),
    }))
    .sort((a, b) => a.date - b.date) as TrainingMasteryProgressGraphDataPoint[]

export const calculateTrendLine = (
  data: TrainingMasteryProgressGraphDataPoint[] | undefined
): TrendLine => {
  if (!data || data.length === 0) {
    return {
      start: { x: 0, y: 0 },
      end: { x: 0, y: 0 },
    }
  }

  const n = data.length
  let sumX = 0,
    sumY = 0,
    sumXY = 0,
    sumX2 = 0

  data.forEach((point) => {
    sumX += point?.date
    sumY += point.percentage || point.frequency
    sumXY += point.date * (point.percentage || point.frequency)
    sumX2 += point.date * point.date
  })

  const slope = (n * sumXY - sumX * sumY) / (n * sumX2 - sumX * sumX)
  const intercept = (sumY - slope * sumX) / n

  return {
    start: {
      x: data[0].date,
      y: slope * data[0].date + intercept,
    },
    end: {
      x: data[n - 1].date,
      y: slope * data[n - 1].date + intercept,
    },
  }
}

export const createExampleMasteryProgressData =
  (): ParentTrainingMasteryProgress[] => {
    const data = []
    const endDate = dayjs()
    const startDate = endDate.subtract(3, "month")
    let currentDate = startDate

    while (
      currentDate.isBefore(endDate) ||
      currentDate.isSame(endDate, "day")
    ) {
      const progress =
        currentDate.diff(startDate, "day") / endDate.diff(startDate, "day")
      let base_count, frequency

      // Base count increases from 5 to 10 over time
      base_count = Math.round(5 + progress * 5)

      if (progress < 0.5) {
        // First half: success rate increases from 0% to 50%
        frequency = Math.round(base_count * (progress * 2 * 0.5))
      } else {
        // Second half: success rate increases from 50% to 80%
        frequency = Math.round(base_count * (0.5 + (progress - 0.5) * 2 * 0.3))
      }

      data.push({
        date: currentDate.format("YYYY-MM-DD"),
        frequency,
        base_count,
        id: Math.random(),
        training_id: 0,
        observation_notes: "Example notes",
      })
      currentDate = currentDate.add(1, "week")
    }

    return data
  }

export function formatTimeString(timeString: string): string {
  const [hours, minutes, seconds] = timeString.split(":").map(Number)

  const totalSeconds = hours * 3600 + minutes * 60 + seconds

  if (totalSeconds < 60) {
    return `${totalSeconds} sec`
  } else {
    const displayMinutes = Math.floor(totalSeconds / 60)
    const displaySeconds = totalSeconds % 60
    if (displaySeconds === 0) {
      return `${displayMinutes} min`
    } else {
      return `${displayMinutes} min ${displaySeconds} sec`
    }
  }
}
