import {
  CloseIcon,
  FlexBox,
  JoonColorExpanded,
  JoonUIColor,
  SPACING,
  Typography,
  UploadIcon,
} from "@joonapp/web-shared"
import React, { useEffect, useMemo, useState } from "react"
import { useDropzone } from "react-dropzone"

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: "20px",
  minHeight: "88px",
  borderRadius: SPACING.space2,
  border: `1px dashed ${JoonUIColor.border.default}`,
  background: JoonUIColor.background.lightGray,
  backgroundColor: "#fafafa",
  color: JoonUIColor.text.primary,
  outline: "none",
  transition: "border .24s ease-in-out",
  width: "100%",
  cursor: "pointer",
}

const validFileStyle = {
  border: `1px solid ${JoonUIColor.border.default}`,
  background: JoonUIColor.background.primaryNeutral,
}

const draggedStyle = {
  border: `1px dashed ${JoonUIColor.border.accent}`,
  background: JoonUIColor.background.lightBlue,
}

const rejectStyle = {
  border: `1px dashed ${JoonUIColor.semantic.destructive}`,
  background: JoonColorExpanded.red100,
}

const hoverStyle = {
  border: `1px dashed ${JoonUIColor.border.accent}`,
}

type Props = {
  files: any[]
  setFiles: (files: any[]) => void
}

const UploadArea = ({ files, setFiles }: Props) => {
  const [isHovered, setIsHovered] = useState(false)
  const { fileRejections, getRootProps, getInputProps, isDragActive } =
    useDropzone({
      accept: { "application/pdf": [".pdf"] },
      onDrop: (acceptedFiles) => {
        setFiles(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        )
      },
    })

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? draggedStyle : {}),
      ...(fileRejections.length > 0 ? rejectStyle : {}),
      ...(files.length > 0 ? validFileStyle : {}),
      ...(isHovered ? hoverStyle : {}),
    }),
    [isDragActive, fileRejections, files, isHovered]
  )

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files?.forEach((file) => URL.revokeObjectURL(file.preview))
  }, [])

  const removeFile = (
    e:
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
      | React.KeyboardEvent<HTMLButtonElement>
  ) => {
    e.stopPropagation()
    setFiles([])
  }

  return (
    <div
      {...getRootProps({ style } as any)}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <input {...getInputProps()} />
      {isDragActive ? (
        <FlexBox
          direction="column"
          gap={SPACING.space2}
          align="center"
          justify="center"
          fullWidth
        >
          <UploadIcon color={JoonUIColor.icon.accent} />
          <Typography variant="caption" color={JoonUIColor.text.primaryAccent}>
            Drop to upload
          </Typography>
        </FlexBox>
      ) : files?.length > 0 ? (
        <FlexBox
          align="center"
          gap={SPACING.space2}
          justify="space-between"
          wrap={false}
        >
          <Typography
            variant="caption"
            color={JoonUIColor.text.primary}
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {files?.[0]?.name}
          </Typography>
          <button
            style={{
              background: JoonUIColor.icon.neutral,
              borderRadius: "50%",
              width: "16px",
              height: "16px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "2px",
            }}
            onClick={removeFile}
          >
            <CloseIcon color={JoonUIColor.icon.inverted} size={16} />
          </button>
        </FlexBox>
      ) : fileRejections.length > 0 ? (
        <FlexBox align="center" direction="row" wrap={false}>
          <FlexBox direction="column" fullWidth style={{ overflow: "hidden" }}>
            <Typography
              variant="caption"
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "100%",
              }}
            >
              {/* @ts-ignore - File type does include "path" */}
              {fileRejections[0].file?.path}
            </Typography>
            <Typography
              variant="caption"
              color={JoonUIColor.semantic.destructive}
            >
              File type not supported (PDF only)
            </Typography>
          </FlexBox>
          <Typography variant="caption" color={JoonUIColor.text.primaryAccent}>
            Retry
          </Typography>
        </FlexBox>
      ) : (
        <FlexBox
          direction="column"
          gap={SPACING.space2}
          align="center"
          justify="center"
          fullWidth
        >
          <UploadIcon size={18} color={JoonUIColor.icon.neutral} />
          <Typography variant="caption">
            Drag & drop or click to upload a PDF
          </Typography>
        </FlexBox>
      )}
    </div>
  )
}

export default UploadArea
