import {
  DropdownSelect,
  FlexBox,
  SPACING,
  DropdownOption,
  JoonUIColor,
} from "@joonapp/web-shared"
import { useMediaQuery } from "@mui/material"
import dayjs from "dayjs"

import ChildQuestSummaryTable from "./ChildQuestSummaryTable"
import GraphTargetSwitch from "./GraphTargetSwitch"
import OverTimeProgressFilters, {
  useOverTimeProgressFilters,
} from "./OverTimeProgressFilters"
import QuestGraph from "./QuestGraph"
import { useOverTimeProgressStore } from "./useOverTimeProgressStore"
import { useDatePickerModal } from "../../../../components/modals/DatePickerModal"
import { DATE_RANGES } from "../../../../constants"
import {
  useCurrentPatientQuery,
  useOverTimeProgressQuery,
} from "../../../../networking/queries"
import { DateRange } from "../../../../types"

const OverTimeProgress = () => {
  const { dateRange, setDateRange } = useOverTimeProgressStore()
  const { data } = useOverTimeProgressQuery()
  const { data: currentChild } = useCurrentPatientQuery()
  const isMobile = useMediaQuery("(max-width:800px)")
  useOverTimeProgressFilters()

  const dateOptions = [
    DATE_RANGES.past4Weeks,
    DATE_RANGES.past8Weeks,
    DATE_RANGES.past12Weeks,
    {
      // @ts-ignore - TODO: date_joined exists, need to add to type
      startDate: dayjs(currentChild?.user?.date_joined || "2023-06-01"),
      endDate: dayjs(),
      label: "All Time",
      value: "allTime",
    },
    {
      startDate: null,
      endDate: null,
      label: "Custom",
      value: "custom",
    },
  ]

  const { onOpen: onOpenDatePickerModal } = useDatePickerModal()

  const selectDateOption = (option: DropdownOption) => {
    if (option.value === "custom") {
      onOpenDatePickerModal()
    } else {
      setDateRange(option as DateRange)
    }
  }

  return (
    <div
      style={{
        padding: isMobile ? SPACING.space0 : SPACING.space4,
        margin: isMobile ? `0 -${SPACING.space4}` : SPACING.space0,
        width: isMobile ? `calc(100vw - ${SPACING.space2})` : "100%",
        backgroundColor: JoonUIColor.background.primaryNeutral,
        border: `1px solid ${JoonUIColor.border.default}`,
        borderRadius: SPACING.space2,
      }}
    >
      <FlexBox
        direction="column"
        gap={isMobile ? SPACING.space4 : SPACING.space2}
        style={{ padding: isMobile ? SPACING.space4 : SPACING.space2 }}
      >
        <FlexBox wrap={false} gap={SPACING.space2} align="center">
          <DropdownSelect
            options={dateOptions}
            selectedOption={dateRange}
            setSelectedOption={selectDateOption}
            style={{ width: "150px" }}
            type="single"
            size="small"
          />
          <GraphTargetSwitch />
        </FlexBox>
        <QuestGraph data={data?.chartData} />
      </FlexBox>
      {!isMobile && <OverTimeProgressFilters />}
      <ChildQuestSummaryTable />
    </div>
  )
}

export default OverTimeProgress
