import { JoonUIColor, SPACING, Typography } from "@joonapp/web-shared"

import { useUserQuery } from "../../networking/queries"

export const UserInitials = () => {
  const { user } = useUserQuery()

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: SPACING.space6,
        height: SPACING.space6,
        minWidth: SPACING.space6,
        minHeight: SPACING.space6,
        borderRadius: "50%",
        backgroundColor: JoonUIColor.background.lightAccent,
        border: `1px solid ${JoonUIColor.border.default}`,
      }}
    >
      <Typography
        variant="bodyXSmall"
        color={JoonUIColor.text.primary}
        style={{ fontSize: "10px" }}
      >
        {user.name?.[0]?.toUpperCase()}
        {user.last_name?.[0]?.toUpperCase()}
      </Typography>
    </div>
  )
}
