import {
  Button,
  FlexBox,
  JoonUIColor,
  Modal,
  ModalContent,
  ModalHeader,
  SPACING,
  TextButton,
  TextInput,
  Typography,
} from "@joonapp/web-shared"
import { useState } from "react"
import { create } from "zustand"

import useUpdateClinicNameMutation from "./useEditClinicNameMutation"
import { usePracticeQuery } from "../../networking/queries"

interface EditClinicInfoModalStore {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
}

export const useEditClinicInfoModalStore = create<EditClinicInfoModalStore>(
  (set) => ({
    isOpen: false,
    onOpen: () => set({ isOpen: true }),
    onClose: () => set({ isOpen: false }),
  })
)

const EditClinicInfoModal = () => {
  const { isOpen, onClose } = useEditClinicInfoModalStore()
  const { practice } = usePracticeQuery()
  const [clinicName, setClinicName] = useState(practice?.name ?? "")

  const updateClinicNameMutation = useUpdateClinicNameMutation()

  const onClickSave = async () => {
    if (!practice) return
    await updateClinicNameMutation.mutateAsync({
      practiceId: practice.id,
      name: clinicName,
    })
    onClose()
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      style={{ width: "min(95vw, 400px)" }}
    >
      <ModalHeader
        title="Edit Clinic Info"
        onClose={onClose}
        showBorderBottom
      />
      <ModalContent
        style={{ padding: SPACING.space6, paddingTop: SPACING.space4 }}
      >
        <FlexBox direction="column" gap={SPACING.space1}>
          <Typography variant="bodyBold">Clinic Name</Typography>
          <TextInput
            name={"Clinic Name"}
            placeholder="Name"
            value={clinicName}
            onChange={(e) => setClinicName(e.target.value)}
            fullWidth
          />
        </FlexBox>
      </ModalContent>
      <FlexBox
        wrap={false}
        justify="space-between"
        style={{
          position: "sticky",
          bottom: 0,
          padding: SPACING.space6,
          marginTop: "auto",
        }}
      >
        <TextButton onClick={onClose}>
          <Typography variant="bodyBold" color={JoonUIColor.text.primaryAccent}>
            Cancel
          </Typography>
        </TextButton>
        <Button
          text="Save"
          onClick={onClickSave}
          isLoading={updateClinicNameMutation.isLoading}
        />
      </FlexBox>
    </Modal>
  )
}

export default EditClinicInfoModal
