import { ParentTraining } from "@joonapp/web-shared"
import { create } from "zustand"

import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../util/analytics"

interface AddEditParentTrainingStore {
  isOpen: boolean
  onOpen: (parentTraining?: ParentTraining) => void
  onClose: () => void

  selectedParentId: number | null
  setSelectedParentId: (id: number) => void

  selectedParentTrainingId: number | null
  setSelectedParentTrainingId: (id: number) => void

  title: string
  setTitle: (title: string) => void

  masteryCriteria: string | null
  setMasteryCriteria: (criteria: string) => void

  selectedPatientId: number | null // For when we want to fetch specific patient's family to show
  setSelectedPatientId: (patientId: number | null) => void
}

export const useAddEditParentTrainingStore = create<AddEditParentTrainingStore>(
  (set) => ({
    isOpen: false,
    onOpen: (parentTraining) => {
      if (parentTraining) {
        trackAnalyticEvent(ANALYTIC_EVENTS.open_edit_parent_training, {
          parentTrainingId: parentTraining.id,
        })
        set({
          selectedParentTrainingId: parentTraining.id,
          title: parentTraining.title,
          masteryCriteria: parentTraining.mastery_criteria,
        })
      } else {
        trackAnalyticEvent(ANALYTIC_EVENTS.open_add_parent_training)
      }
      set({ isOpen: true })
    },
    onClose: () =>
      set({
        isOpen: false,
        selectedParentId: null,
        selectedParentTrainingId: null,
        title: "",
        masteryCriteria: "",
      }),

    selectedParentId: null,
    setSelectedParentId: (id) => set({ selectedParentId: id }),

    selectedParentTrainingId: null,
    setSelectedParentTrainingId: (id) => set({ selectedParentTrainingId: id }),

    title: "",
    setTitle: (title) => set({ title }),

    masteryCriteria: "",
    setMasteryCriteria: (criteria) => set({ masteryCriteria: criteria }),

    selectedPatientId: null,
    setSelectedPatientId: (selectedPatientId) => set({ selectedPatientId }),
  })
)
