import { useEffect } from "react"

// This hook makes all children of the sidebar unfocusable when sidebar is hidden
const useHiddenElement = (
  elementRef: React.RefObject<HTMLElement>,
  isHidden: boolean
) => {
  useEffect(() => {
    if (elementRef.current) {
      const tabIndex = isHidden ? -1 : null
      setTabIndexRecursive(elementRef.current, tabIndex)
    }
  }, [isHidden, elementRef])
}

// This function sets the tab index (focusable state) of every child element
const setTabIndexRecursive = (element: Element, tabIndex: number | null) => {
  if (!element) return

  // Set tabIndex for current element
  if (element.setAttribute) {
    if (tabIndex === null) element.removeAttribute("tabIndex")
    else element.setAttribute("tabIndex", tabIndex.toString())
  }
  // Recursively set tabIndex for children
  if (element.children && element.children.length > 0) {
    for (let i = 0; i < element.children.length; i++) {
      setTabIndexRecursive(element.children[i], tabIndex)
    }
  }
}

export default useHiddenElement
