import { useMutation, useQueryClient } from "@tanstack/react-query"

import { updateDataTask } from "../../../../networking/dataTasks"
import {
  QUERY_KEYS,
  useCurrentTrainingQuery,
} from "../../../../networking/queries"
import { ParentTrainingDataCollectionTask } from "../../../../types"
import { ANALYTIC_EVENTS } from "../../../../util/analytics"
import { trackAnalyticEvent } from "../../../../util/analytics"

const useEditDataTaskMutation = () => {
  const queryClient = useQueryClient()
  const { data: currentTraining } = useCurrentTrainingQuery()
  const queryKey = [QUERY_KEYS.PARENT_TRAINING_DATA_TASKS, currentTraining?.id]

  return useMutation({
    mutationFn: (data) =>
      updateDataTask({ ...data, trainingId: currentTraining?.id }),
    onMutate: async (variables) => {
      queryClient.cancelQueries(queryKey)
      const previousTasks =
        queryClient.getQueryData<ParentTrainingDataCollectionTask[]>(queryKey)

      if (!previousTasks) return

      const newTasks = previousTasks?.map((task) =>
        task.id === variables.dataTaskId ? { ...task, ...variables } : task
      )

      queryClient.setQueryData<ParentTrainingDataCollectionTask[]>(
        queryKey,
        newTasks
      )
      return { previousTasks }
    },
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.PARENT_TRAINING_DATA_TASKS])
      trackAnalyticEvent(ANALYTIC_EVENTS.edit_data_task)
    },
    onError: (
      _err: any,
      _variables: any,
      context:
        | { previousTasks: ParentTrainingDataCollectionTask[] | undefined }
        | undefined
    ) => {
      if (context?.previousTasks) {
        queryClient.setQueryData<ParentTrainingDataCollectionTask[]>(
          queryKey,
          context.previousTasks
        )
      }
    },
  })
}

export default useEditDataTaskMutation
