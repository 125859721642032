import {
  Button,
  Checkbox,
  CloseIcon,
  FlexBox,
  JoonColorExpanded,
  JoonUIColor,
  Modal,
  ModalHeader,
  SPACING,
  SelectInput,
  Switch,
  TextArea,
  TextButton,
  TextInput,
  Typography,
  capitalizeFirstLetter,
} from "@joonapp/web-shared"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { CSSProperties, useId } from "react"
import { useParams } from "react-router-dom"
import { useShallow } from "zustand/react/shallow"

import useAddEditTargetBehaviorStore from "./store/useAddEditTargetBehaviorStore"
import OpenPDFButton from "../../../components/buttons/OpenPDFButton"
import { createToast } from "../../../components/toast/Toast"
import CustomTooltip from "../../../components/tooltip/CustomTooltip"
import { TargetBehaviorStatusColors } from "../../../constants"
import { addTargetBehavior } from "../../../networking/behaviors"
import { QUERY_KEYS } from "../../../networking/queries"
import {
  IncidentLogDataType,
  TargetBehaviorStatus,
  ToastType,
} from "../../../types"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../util/analytics"

const useAddTargetBehaviorMutation = () => {
  return useMutation({
    mutationFn: addTargetBehavior,
    onSuccess: () => {
      trackAnalyticEvent(ANALYTIC_EVENTS.add_target_behavior)
      createToast({
        title: "Target behavior created successfully",
        type: ToastType.SUCCESS,
      })
    },
    onError: () => {
      createToast({
        title: "Failed to create target behavior",
        type: ToastType.ERROR,
      })
    },
  })
}

const AddEditTargetBehaviorSidePanel = () => {
  const {
    isOpen,
    onClose,
    title,
    description,
    preventiveStrategies,
    status,
    usedInIncidentLogs,
    incidentLogDataType,
    resetTargetBehaviorData,
  } = useAddEditTargetBehaviorStore(
    useShallow((state) => ({
      isOpen: state.isOpenAddTargetBehavior,
      onClose: state.onClose,
      title: state.title,
      description: state.description,
      preventiveStrategies: state.preventiveStrategies,
      status: state.status,
      usedInIncidentLogs: state.usedInIncidentLogs,
      incidentLogDataType: state.incidentLogDataType,
      resetTargetBehaviorData: state.resetTargetBehaviorData,
    }))
  )
  const { userId } = useParams()

  const addTargetBehaviorMutation = useAddTargetBehaviorMutation()
  const queryClient = useQueryClient()

  const onClickAddTargetBehavior = async () => {
    if (!title) {
      return createToast({
        title: "Please enter a title",
        type: ToastType.ERROR,
      })
    }

    if (!status) {
      return createToast({
        title: "Please select a status",
        type: ToastType.ERROR,
      })
    }
    if (!userId) {
      return createToast({
        title: "User ID not found",
        type: ToastType.ERROR,
      })
    }
    const fitleredPreventiveStrategies = preventiveStrategies.filter(
      (strategy) => strategy.title.length > 0
    )
    if (!fitleredPreventiveStrategies.length) {
      return createToast({
        title: "Please add at least one strategy",
        type: ToastType.ERROR,
      })
    }

    await addTargetBehaviorMutation.mutateAsync({
      userId: Number(userId),
      title,
      description,
      preventiveStrategies: fitleredPreventiveStrategies,
      status,
      usedInIncidentLogs,
      incidentLogDataType,
    })
    resetTargetBehaviorData()
    queryClient.invalidateQueries([QUERY_KEYS.TARGET_BEHAVIORS])
    onClose()
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      style={{ height: "fit-content", maxHeight: "95vh" }}
      mobileFull
    >
      <ModalHeader
        title={
          (
            <FlexBox direction="column" gap={SPACING.space1}>
              <Typography variant="h3">Create a new Target Behavior</Typography>
              <OpenPDFButton
                text="Preview parent experience"
                imageUrl="/images/targetbehavior-preview.pdf"
              />
            </FlexBox>
          ) as any
        }
        onClose={onClose}
        showBorderBottom
      />
      <AddEditTargetBehaviorContent />

      <FlexBox
        direction="row"
        wrap={false}
        justify="space-between"
        style={{
          position: "sticky",
          bottom: "0",
          padding: SPACING.space6,
          width: "100%",
          borderTop: `1px solid ${JoonUIColor.border.default}`,
          // boxShadow: "0px 2px 10px 0px rgba(0, 0, 0, 0.3)",
          zIndex: 1000,
        }}
      >
        <TextButton onClick={onClose}>
          <Typography variant="bodyBold" color={JoonUIColor.text.primaryAccent}>
            Cancel
          </Typography>
        </TextButton>
        <Button
          text="Save"
          onClick={onClickAddTargetBehavior}
          isLoading={addTargetBehaviorMutation.isLoading}
        />
      </FlexBox>
    </Modal>
  )
}

export default AddEditTargetBehaviorSidePanel

const StatusLabel = ({ status }: { status: TargetBehaviorStatus }) => (
  <FlexBox align="center" direction="row" gap={SPACING.space2}>
    <StatusBar
      color={TargetBehaviorStatusColors[status].primary}
      tooltipText={status}
    />
    <Typography variant="body">{capitalizeFirstLetter(status)}</Typography>
  </FlexBox>
)

export const StatusBar = ({
  color,
  tooltipText,
  height = SPACING.space6,
}: {
  color: JoonColorExpanded
  tooltipText?: string
  height?: CSSProperties["height"]
}) => {
  const id = useId()
  return (
    <>
      <div
        style={{
          background: color,
          width: SPACING.space2,
          minWidth: SPACING.space2,
          height,
          borderRadius: SPACING.space2,
        }}
        data-tooltip-id={id}
      />
      {tooltipText && (
        <CustomTooltip id={id}>
          <Typography variant="caption" color={JoonUIColor.text.inverted}>
            {capitalizeFirstLetter(tooltipText)}
          </Typography>
        </CustomTooltip>
      )}
    </>
  )
}

export const AddEditTargetBehaviorContent = () => {
  const {
    title,
    description,
    preventiveStrategies,
    status,
    usedInIncidentLogs,
    incidentLogDataType,
    setTitle,
    setDescription,
    editPreventionStrategy,
    removePreventionStrategy,
    addPreventionStrategy,
    setStatus,
    setUsedInIncidentLogs,
    setIncidentLogDataType,
  } = useAddEditTargetBehaviorStore(
    useShallow((state) => ({
      title: state.title,
      description: state.description,
      preventiveStrategies: state.preventiveStrategies,
      status: state.status,
      usedInIncidentLogs: state.usedInIncidentLogs,
      incidentLogDataType: state.incidentLogDataType,
      setTitle: state.setTitle,
      setDescription: state.setDescription,
      editPreventionStrategy: state.editPreventionStrategy,
      removePreventionStrategy: state.removePreventionStrategy,
      addPreventionStrategy: state.addPreventionStrategy,
      setStatus: state.setStatus,
      setUsedInIncidentLogs: state.setUsedInIncidentLogs,
      setIncidentLogDataType: state.setIncidentLogDataType,
    }))
  )

  return (
    <FlexBox
      direction="column"
      wrap={false}
      gap={SPACING.space4}
      style={{
        padding: `${SPACING.space4} ${SPACING.space6}`,
        flex: 1,
        overflowY: "auto",
      }}
    >
      <Typography variant="bodySmall">
        Once set up, parents will be able to record and track incidents and the
        strategies they use
      </Typography>
      <FlexBox direction="column" gap={SPACING.space1}>
        <Typography variant="bodyBold">Target Behavior</Typography>
        <TextInput
          name="Target behavior"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Maladaptive behaviors you’re focusing..."
          fullWidth
          maxLength={100}
        />
      </FlexBox>
      <FlexBox direction="column" gap={SPACING.space1}>
        <Typography variant="bodyBold">Description</Typography>
        <TextArea
          name="Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="What this means to parents..."
          fullWidth
          maxLength={255}
        />
      </FlexBox>
      <FlexBox direction="column" gap={SPACING.space1}>
        <Typography variant="bodyBold">Strategies</Typography>
        {preventiveStrategies.map((strategy, index) => (
          <FlexBox
            direction="row"
            wrap={false}
            align="center"
            gap={SPACING.space1}
          >
            <TextInput
              name={`Strategy ${index + 1}`}
              value={strategy.title}
              onChange={(e) => editPreventionStrategy(index, e.target.value)}
              placeholder={`Strategy ${index + 1}`}
              fullWidth
            />
            <TextButton onClick={() => removePreventionStrategy(index)}>
              <CloseIcon color={JoonUIColor.icon.neutral} size={20} />
            </TextButton>
          </FlexBox>
        ))}
        {preventiveStrategies.length < 10 && (
          <TextButton onClick={addPreventionStrategy}>
            <Typography
              variant="bodyBold"
              color={JoonUIColor.text.primaryAccent}
            >
              + add strategy
            </Typography>
          </TextButton>
        )}
      </FlexBox>
      <SelectInput
        name="status"
        label="Status"
        // @ts-ignore
        selectedOption={
          status
            ? {
                value: status,
                label: (<StatusLabel status={status} />) as any,
              }
            : null
        }
        setSelectedOption={(option) => setStatus(option.value as any)}
        options={Object.values(TargetBehaviorStatus).map((val) => ({
          value: val,
          label: (<StatusLabel status={val} />) as any,
        }))}
        isMulti={false}
        placeholder="Select status"
        style={{ width: "200px" }}
      />
      <FlexBox
        direction="column"
        gap={SPACING.space2}
        style={{
          background: JoonUIColor.background.xlightGray,
          padding: SPACING.space4,
          borderRadius: SPACING.space2,
        }}
      >
        <FlexBox direction="row" gap={SPACING.space2} align="center">
          <Switch
            name="Add parent behavior logging"
            onChange={() => setUsedInIncidentLogs(!usedInIncidentLogs)}
            checked={usedInIncidentLogs}
          />
          <Typography variant="bodyBold">
            Add parent behavior logging
          </Typography>
        </FlexBox>
        {usedInIncidentLogs && (
          <>
            <hr />

            <Typography variant="bodyBold">Specify log type</Typography>
            <Checkbox
              style={{
                padding: SPACING.space2,
                borderRadius: SPACING.space2,
              }}
              label={
                <FlexBox
                  direction="column"
                  style={{ marginLeft: SPACING.space1 }}
                >
                  <Typography variant="caption">Frequency count</Typography>
                  <Typography variant="bodySmall">
                    Specify total count of this behavior incident
                  </Typography>
                </FlexBox>
              }
              selected={incidentLogDataType === IncidentLogDataType.FREQUENCY}
              fullWidth
              name="logType"
              onChange={() =>
                setIncidentLogDataType(IncidentLogDataType.FREQUENCY)
              }
              inputType="radio"
            />
            <Checkbox
              style={{
                padding: SPACING.space2,
                borderRadius: SPACING.space2,
              }}
              fullWidth
              label={
                <FlexBox
                  direction="column"
                  style={{ marginLeft: SPACING.space1 }}
                >
                  <Typography variant="caption">ABCs</Typography>
                  <Typography variant="bodySmall">
                    Specify the Antecedent and Consequence of this behavior
                  </Typography>
                </FlexBox>
              }
              selected={incidentLogDataType === IncidentLogDataType.ANTECEDENT}
              name="logType"
              onChange={() =>
                setIncidentLogDataType(IncidentLogDataType.ANTECEDENT)
              }
              inputType="radio"
            />
            <Checkbox
              style={{
                padding: SPACING.space2,
                borderRadius: SPACING.space2,
              }}
              fullWidth
              label={
                <FlexBox
                  direction="column"
                  style={{ marginLeft: SPACING.space1 }}
                >
                  <Typography variant="caption">Duration</Typography>
                  <Typography variant="bodySmall">
                    Specify the duration of each incident
                  </Typography>
                </FlexBox>
              }
              selected={incidentLogDataType === IncidentLogDataType.DURATION}
              name="logType"
              onChange={() =>
                setIncidentLogDataType(IncidentLogDataType.DURATION)
              }
              inputType="radio"
            />
          </>
        )}
      </FlexBox>
    </FlexBox>
  )
}
