import {
  CaretDownIcon,
  FlexBox,
  JoonUIColor,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import { useState } from "react"

import IncidentLogDataContainer from "./IncidentLogDataContainer"
import NewIncidentNotification from "./NewIncidentNotification"
import { useIncidentsQuery } from "../../../networking/queries"
import { DataSCView } from "../../../types"

const FreeformTargetBehaviorItem = () => {
  const { data: incidents } = useIncidentsQuery()

  const incidentsWithoutTargetBehavior = incidents?.filter(
    (incident) => !!incident.freeform_target_behavior
  )

  const [showIncidentLog, setShowIncidentLog] = useState(false)

  if (!incidentsWithoutTargetBehavior) return null

  return (
    <FlexBox
      direction="column"
      align="unset"
      style={{
        width: "100%",
        padding: SPACING.space4,
        borderRadius: SPACING.space2,
        border: `1px solid ${JoonUIColor.border.default}`,
        background: JoonUIColor.background.primaryNeutral,
      }}
    >
      <FlexBox
        direction="row"
        justify="space-between"
        wrap={false}
        align="center"
      >
        <button
          onClick={() => setShowIncidentLog(!showIncidentLog)}
          style={{
            display: "flex",
            gap: SPACING.space2,
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <CaretDownIcon
            size={9}
            color={JoonUIColor.icon.neutral}
            style={{
              transition: "transform 0.2s ease-out",
              transform: showIncidentLog ? "rotate(90deg)" : "rotate(0deg)",
            }}
          />
          <FlexBox
            direction="column"
            style={{ overflow: "hidden" }}
            wrap={false}
          >
            <Typography
              variant="bodyBold"
              style={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
            >
              Other reported incidents
            </Typography>
            <Typography variant="bodyXSmall" textAlign="left">
              Additional caregivers reported incidents unrelated to specified
              Target Behaviors
            </Typography>
          </FlexBox>
          <NewIncidentNotification id={null} />
        </button>
      </FlexBox>
      <IncidentLogDataContainer
        showIncidentLog={showIncidentLog}
        dataView={DataSCView.TABLE}
        id={null} // null for freeform target behavior
        isDataTask={false}
      />
    </FlexBox>
  )
}

export default FreeformTargetBehaviorItem
