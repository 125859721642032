import {
  HeartIcon,
  JoonColorExpanded,
  JoonUIColor,
  LearningModule,
  SPACING,
  TextButton,
} from "@joonapp/web-shared"
import { useQueryClient } from "@tanstack/react-query"
import { useState } from "react"

import {
  favoriteLearningModule,
  unfavoriteLearningModule,
} from "../../networking/learningModules"
import { QUERY_KEYS } from "../../networking/queries"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"

const FavoriteButton = ({
  resource,
  style,
}: {
  resource: LearningModule
  style?: React.CSSProperties
}) => {
  const [forceUpdate, setForceUpdate] = useState(false)

  const queryClient = useQueryClient()

  const onClickFavorite = async () => {
    try {
      if (resource.favorite) {
        resource.favorite = false
        setForceUpdate(!forceUpdate)
        await unfavoriteLearningModule(resource.id)
        trackAnalyticEvent(ANALYTIC_EVENTS.unfavorite_resource)
      } else {
        resource.favorite = true
        setForceUpdate(!forceUpdate)
        await favoriteLearningModule(resource.id)
        trackAnalyticEvent(ANALYTIC_EVENTS.favorite_resource)
      }
    } catch (error) {
      queryClient.invalidateQueries([QUERY_KEYS.RESOURCES])
    }
  }

  return (
    <TextButton
      // @ts-ignore
      onClick={(e: any) => {
        e.stopPropagation()
        onClickFavorite()
      }}
      style={{
        border: `1px solid ${JoonUIColor.background.primaryNeutral}`,
        background: resource.favorite
          ? JoonColorExpanded.red100
          : "rgba(123, 131, 142, 0.60)",
        borderRadius: SPACING.space2,
        padding: SPACING.space1,
        ...style,
      }}
    >
      <HeartIcon
        size={12}
        color={
          resource.favorite
            ? JoonUIColor.semantic.alert
            : JoonUIColor.icon.inverted
        }
        // @ts-ignore - should accept styles
        style={{
          transform: resource.favorite ? "scale(1.15)" : "scale(1)",
          transition: "transform 0.2s",
        }}
      />
    </TextButton>
  )
}

export default FavoriteButton
