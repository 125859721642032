import { useMutation, useQueryClient } from "@tanstack/react-query"

import { createToast, Toasts } from "../../components/toast/Toast"
import { QUERY_KEYS } from "../../networking/queries"
import { saveClinicName } from "../../networking/user"
import { ToastType } from "../../types"

const useUpdateClinicNameMutation = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: saveClinicName,
    onSuccess: () => {
      queryClient.refetchQueries([QUERY_KEYS.PRACTICE])
      createToast({
        title: "Clinic name updated successfully",
        type: ToastType.SUCCESS,
      })
    },
    onError: () => createToast(Toasts.GENERAL_ERROR),
  })
}

export default useUpdateClinicNameMutation
