import {
  FlexBox,
  GearIcon,
  JoonUIColor,
  SPACING,
  Typography,
  DropdownMenu,
  TextButton,
  Button,
} from "@joonapp/web-shared"
import { useMediaQuery } from "@mui/material"
import dayjs from "dayjs"
import { useNavigate, useParams } from "react-router-dom"

import GenerateNotesModal, {
  useGenerateNotesModalStore,
} from "./GenerateNotesModal"
import ParentTrainingTabNav from "./ParentTrainingTabNav"
import TrainingStatusDropdown from "./TrainingStatusDropdown"
import { useAddEditParentTrainingStore } from "./useAddEditParentTrainingStore"
import useDeleteTrainingStore from "./useDeleteTrainingStore"
import { BackButton2 } from "../../../components/buttons/BackButton2"
import PageHeader2 from "../../../components/pageHeader/PageHeader2"
import { PAGE_CONTENT_WIDTH } from "../../../constants"
import { useCurrentTrainingQuery } from "../../../networking/queries"
import { PatientInfoTab } from "../../../types"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../util/analytics"

const ParentTrainingHeader = () => {
  const { data: currentTraining } = useCurrentTrainingQuery()
  const { userId } = useParams()
  const navigate = useNavigate()

  const { onOpen: openEditParentTrainingModal } =
    useAddEditParentTrainingStore()
  const { onOpen: openDeleteTrainingModal } = useDeleteTrainingStore()

  const { onOpen: openGenerateNotesModal } = useGenerateNotesModalStore()

  const isMobile = useMediaQuery("(max-width: 800px)")

  const onClickEdit = () => {
    if (!currentTraining) return
    openEditParentTrainingModal(currentTraining)
  }

  const onClickDelete = () => {
    if (!currentTraining) return
    openDeleteTrainingModal(currentTraining.id)
    trackAnalyticEvent(ANALYTIC_EVENTS.open_delete_parent_training)
  }

  return (
    <PageHeader2>
      <FlexBox
        direction="column"
        gap={SPACING.space2}
        style={{
          width: `min(${PAGE_CONTENT_WIDTH}, 100vw)`,
          margin: "0 auto",
        }}
      >
        <BackButton2
          text="All trainings"
          onClick={() =>
            navigate(`/patients/${userId}/${PatientInfoTab.PARENT_TRAINING}`)
          }
        />
        <FlexBox
          direction="row"
          gap={SPACING.space2}
          wrap={false}
          justify="space-between"
          align="center"
        >
          <FlexBox direction="column">
            <Typography variant="h2">{currentTraining?.title}</Typography>
            <Typography variant="bodySmall">
              Started on {dayjs(currentTraining?.date_created).format("MMM D")},
              assigned to {currentTraining?.user?.name}
            </Typography>
          </FlexBox>
          <FlexBox
            direction="row"
            gap={SPACING.space2}
            wrap={false}
            justify="unset"
            align="center"
            style={{ width: "fit-content" }}
          >
            <TrainingStatusDropdown />
            {!isMobile && (
              <Button
                text="Generate notes"
                style={{ whiteSpace: "nowrap" }}
                onClick={openGenerateNotesModal}
              />
            )}
            <DropdownMenu
              triggerButton={
                <TextButton onClick={() => {}}>
                  <GearIcon size={20} color={JoonUIColor.icon.neutral} />
                </TextButton>
              }
              menuItems={[
                ...(isMobile
                  ? [
                      {
                        text: "Generate notes",
                        onClick: openGenerateNotesModal,
                      },
                    ]
                  : []),
                { text: "Edit details", onClick: onClickEdit },
                { text: "Delete", onClick: onClickDelete },
              ]}
            />
          </FlexBox>
        </FlexBox>
        <ParentTrainingTabNav />
      </FlexBox>
      <GenerateNotesModal />
    </PageHeader2>
  )
}

export default ParentTrainingHeader
