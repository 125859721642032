import {
  FlexBox,
  JoonUIColor,
  SPACING,
  Switch,
  Typography,
} from "@joonapp/web-shared"
import { useShallow } from "zustand/react/shallow"

import { useOverTimeProgressStore } from "./useOverTimeProgressStore"
import CustomTooltip from "../../../../components/tooltip/CustomTooltip"

const GraphTargetSwitch = () => {
  const { showTargets, setShowTargets, selectedQuests } =
    useOverTimeProgressStore(
      useShallow((state) => ({
        showTargets: state.showTargets,
        setShowTargets: state.setShowTargets,
        selectedQuests: state.selectedQuests,
      }))
    )

  const onClickSwitch = () => {
    if (selectedQuests.length > 1) return
    setShowTargets(!showTargets)
  }

  return (
    <FlexBox
      align="center"
      justify="flex-end"
      gap={SPACING.space2}
      wrap={false}
    >
      <Switch
        checked={showTargets}
        name="Display target"
        onChange={onClickSwitch}
        style={{ transform: "scale(0.52)", marginRight: "-16px" }}
        disabled={selectedQuests.length > 1}
      />
      <button style={{ padding: 0 }} onClick={onClickSwitch}>
        <Typography variant="bodyXSmall">Show target</Typography>
      </button>

      <svg
        width={16}
        height={16}
        viewBox="0 0 27 27"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-tooltip-id="show-target-tooltip"
      >
        <g clip-path="url(#clip0_160_9311)">
          <path
            d="M13.0756 26.8145C16.529 26.8145 19.8409 25.4427 22.2828 23.0008C24.7247 20.5589 26.0965 17.247 26.0965 13.7936C26.0965 10.3403 24.7247 7.02834 22.2828 4.58644C19.8409 2.14455 16.529 0.772705 13.0756 0.772705C9.62224 0.772705 6.31032 2.14455 3.86842 4.58644C1.42653 7.02834 0.0546875 10.3403 0.0546875 13.7936C0.0546875 17.247 1.42653 20.5589 3.86842 23.0008C6.31032 25.4427 9.62224 26.8145 13.0756 26.8145ZM11.0411 17.8626H12.2618V14.6074H11.0411C10.3646 14.6074 9.82037 14.0632 9.82037 13.3867C9.82037 12.7102 10.3646 12.166 11.0411 12.166H13.4825C14.159 12.166 14.7032 12.7102 14.7032 13.3867V17.8626H15.1101C15.7866 17.8626 16.3308 18.4069 16.3308 19.0834C16.3308 19.7598 15.7866 20.3041 15.1101 20.3041H11.0411C10.3646 20.3041 9.82037 19.7598 9.82037 19.0834C9.82037 18.4069 10.3646 17.8626 11.0411 17.8626ZM13.0756 7.28316C13.5073 7.28316 13.9213 7.45464 14.2265 7.75988C14.5317 8.06511 14.7032 8.4791 14.7032 8.91077C14.7032 9.34244 14.5317 9.75643 14.2265 10.0617C13.9213 10.3669 13.5073 10.5384 13.0756 10.5384C12.6439 10.5384 12.2299 10.3669 11.9247 10.0617C11.6195 9.75643 11.448 9.34244 11.448 8.91077C11.448 8.4791 11.6195 8.06511 11.9247 7.75988C12.2299 7.45464 12.6439 7.28316 13.0756 7.28316Z"
            fill="#7B838E"
          />
        </g>
        <defs>
          <clipPath id="clip0_160_9311">
            <rect
              width="26.0418"
              height="26.0418"
              fill="white"
              transform="translate(0.0546875 0.772705)"
            />
          </clipPath>
        </defs>
      </svg>
      <CustomTooltip id="show-target-tooltip">
        <Typography variant="bodySmall" color={JoonUIColor.text.inverted}>
          Only available for 1 behavior at a time
        </Typography>
      </CustomTooltip>
    </FlexBox>
  )
}

export default GraphTargetSwitch
