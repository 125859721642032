import {
  Button,
  FilterIcon,
  FlexBox,
  JoonUIColor,
  SPACING,
  TextButton,
  TextInput,
  Typography,
} from "@joonapp/web-shared"
import { useMediaQuery } from "@mui/material"
import { useShallow } from "zustand/react/shallow"

import AddEditResourceSidePanel from "./AddEditResourceSidePanel"
import AssignModuleSidePanel from "./AssignModuleSidePanel"
import FavoritesEmptyState from "./FavoritesEmptyState"
import ModuleFilterPanel, {
  useMobileModuleFilterPanelStore,
} from "./ModuleFilterPanel"
import ResourceItem from "./ResourceItem"
import SearchEmptyState from "./ResourceSearchEmptyState"
import ResourcesSidePanel from "./ResourcesSidePanel"
import useResourcesFilterStore, {
  useIsFilteredModules,
} from "./useResourceFilterStore"
import PageContentWrapper from "../../components/layout/PageContentWrapper"
import { SkeletonLoaderRow } from "../../components/loading/SkeletonLoader"
import PageWrapper from "../../components/pageWrapper/PageWrapper"
import { useLearningModulesQuery } from "../../networking/queries"
import { requireAuth } from "../../util/auth"

const Resources = requireAuth(() => {
  const { displayFavorites } = useResourcesFilterStore(
    useShallow((state) => ({
      displayFavorites: state.displayFavorites,
    }))
  )
  const { data: filteredModules, isLoading } = useLearningModulesQuery()

  const isFilteredModules = useIsFilteredModules()

  return (
    <PageWrapper style={{ background: JoonUIColor.background.xlightGray }}>
      <MobileSearchBar />
      <FlexBox
        direction="row"
        wrap={false}
        style={{ flex: 1, overflow: "hidden" }}
      >
        <ModuleFilterPanel />
        <FlexBox
          direction="column"
          align="center"
          wrap={false}
          style={{
            flex: 1,
            height: "100%",
            overflowY: "auto",
          }}
        >
          <PageContentWrapper>
            {isFilteredModules ? <FilteredResultsCounter /> : <WelcomeHeader />}
            <FlexBox
              direction="column"
              gap={SPACING.space2}
              style={{ maxWidth: "1200px", margin: "0 auto" }}
            >
              {isLoading ? (
                <SkeletonLoaderRow numRows={5} style={{ width: "100%" }} />
              ) : filteredModules && filteredModules.length > 0 ? (
                filteredModules?.map((resource, i) => (
                  <ResourceItem key={i} resource={resource} />
                ))
              ) : displayFavorites ? (
                <FavoritesEmptyState />
              ) : (
                <SearchEmptyState />
              )}
            </FlexBox>
          </PageContentWrapper>
        </FlexBox>
      </FlexBox>
      <AddEditResourceSidePanel />
      <ResourcesSidePanel />
      <AssignModuleSidePanel />
    </PageWrapper>
  )
})

export default Resources

export const FilteredResultsCounter = () => {
  const { clearFilters } = useResourcesFilterStore(
    useShallow((state) => ({ clearFilters: state.clearFilters }))
  )
  const { data: filteredModules, isLoading } = useLearningModulesQuery()

  if (isLoading) return <div style={{ height: SPACING.space5 }} />

  return (
    <FlexBox
      direction="row"
      align="center"
      wrap={false}
      style={{ marginBottom: SPACING.space4, maxWidth: "1200px" }}
    >
      {!isLoading && (
        <Typography variant="body">
          {filteredModules?.length} results.
        </Typography>
      )}
      <button style={{ padding: 0 }} onClick={() => clearFilters()}>
        <Typography
          variant="bodyBold"
          style={{
            marginLeft: SPACING.space2,
            color: JoonUIColor.semantic.primary,
          }}
        >
          Reset
        </Typography>
      </button>
    </FlexBox>
  )
}

const WelcomeHeader = () => {
  const isMobile = useMediaQuery("(max-width: 800px)")

  return (
    <FlexBox direction="column" gap={SPACING.space2}>
      <Typography variant="h3" textAlign="left">
        Parent training resource library
      </Typography>
      <Typography variant="bodySmall" textAlign="left">
        Send articles, videos, quizzes, and interactive worksheets directly to
        parents. Track their completion over time and measure their progress
      </Typography>
      {!isMobile && (
        <Button
          buttonType="secondary"
          text="Request a resource"
          style={{ whiteSpace: "nowrap", marginTop: SPACING.space2 }}
          onClick={() =>
            window.open("https://forms.gle/ekkxQaqkpsVbahsg9", "_blank")
          }
        />
      )}
    </FlexBox>
  )
}

const MobileSearchBar = () => {
  const { searchValue, setSearchValue } = useResourcesFilterStore(
    useShallow((state) => ({
      searchValue: state.searchValue,
      setSearchValue: state.setSearchValue,
    }))
  )
  const { onOpen } = useMobileModuleFilterPanelStore()
  const isMobile = useMediaQuery("(max-width: 800px)")

  if (!isMobile) return null

  return (
    <FlexBox
      direction="row"
      align="center"
      wrap={false}
      gap={SPACING.space2}
      style={{
        background: JoonUIColor.background.primaryNeutral,
        borderBottom: `1px solid ${JoonUIColor.border.default}`,
        padding: SPACING.space2,
      }}
    >
      <TextButton onClick={onOpen}>
        <FilterIcon size={20} color={JoonUIColor.icon.neutral} />
      </TextButton>
      <TextInput
        name="Search modules"
        icon="search"
        placeholder="Search modules"
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        fullWidth
      />
    </FlexBox>
  )
}
