import { create } from "zustand"

import {
  IncidentLogDataType,
  TargetBehavior,
  TargetBehaviorStatus,
} from "../../../../types"

interface AddEditTargetBehaviorStore {
  isOpenAddTargetBehavior: boolean
  isOpenEditTargetBehavior: boolean
  onOpen: (targetBehavior?: TargetBehavior) => void
  onClose: () => void

  // data
  title: string
  description: string
  preventiveStrategies: { title: string; id?: number }[]
  status: TargetBehaviorStatus | null
  selectedTargetBehaviorId: number | null
  usedInIncidentLogs: boolean
  incidentLogDataType: IncidentLogDataType

  // methods
  setTitle: (title: string) => void
  setDescription: (description: string) => void
  editPreventionStrategy: (index: number, title: string) => void
  removePreventionStrategy: (index: number) => void
  addPreventionStrategy: () => void
  setStatus: (status: TargetBehaviorStatus) => void
  setUsedInIncidentLogs: (usedInIncidentLogs: boolean) => void
  setIncidentLogDataType: (incidentLogDataType: IncidentLogDataType) => void
  // reset
  resetTargetBehaviorData: () => void
}

const initialData = {
  title: "",
  description: "",
  preventiveStrategies: [{ title: "" }, { title: "" }] as {
    title: string
    id?: number
  }[],
  status: TargetBehaviorStatus.BASELINING,
  selectedTargetBehaviorId: null,
  usedInIncidentLogs: true,
  incidentLogDataType: IncidentLogDataType.FREQUENCY,
}

const useAddEditTargetBehaviorStore = create<AddEditTargetBehaviorStore>(
  (set, get) => ({
    isOpenAddTargetBehavior: false,
    isOpenEditTargetBehavior: false,
    onOpen: (targetBehavior) => {
      if (!!targetBehavior) {
        set({
          title: targetBehavior.title,
          description: targetBehavior.description,
          preventiveStrategies: targetBehavior.preventive_strategies,
          status: targetBehavior.status,
          selectedTargetBehaviorId: targetBehavior.id,
          usedInIncidentLogs: targetBehavior.used_in_incident_logs,
          incidentLogDataType: targetBehavior.incident_log_data_type,
        })
        set({ isOpenEditTargetBehavior: true })
      } else {
        set({ isOpenAddTargetBehavior: true })
      }
    },
    onClose: () => {
      set({
        isOpenAddTargetBehavior: false,
        isOpenEditTargetBehavior: false,
        ...initialData,
      })
    },

    // data
    ...initialData,

    // methods
    setTitle: (title) => set({ title }),
    setDescription: (description) => set({ description }),
    editPreventionStrategy: (index, title) => {
      const newPreventiveStrategies = [...get().preventiveStrategies]
      newPreventiveStrategies[index] = { title }
      set({ preventiveStrategies: newPreventiveStrategies })
    },
    removePreventionStrategy: (index) => {
      const newPreventiveStrategies = [...get().preventiveStrategies]
      newPreventiveStrategies.splice(index, 1)
      set({ preventiveStrategies: newPreventiveStrategies })
    },
    addPreventionStrategy: () =>
      set({
        preventiveStrategies: [...get().preventiveStrategies, { title: "" }],
      }),
    setStatus: (status) => set({ status }),
    setUsedInIncidentLogs: (usedInIncidentLogs) => set({ usedInIncidentLogs }),
    setIncidentLogDataType: (incidentLogDataType) =>
      set({ incidentLogDataType }),

    // reset
    resetTargetBehaviorData: () => set(initialData),
  })
)

export default useAddEditTargetBehaviorStore
