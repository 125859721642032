import { JoonUIColor, SPACING } from "@joonapp/web-shared"
import { ChevronLeft } from "@mui/icons-material"
import { useMediaQuery } from "@mui/material"

import useOnboarding from "../../hooks/useOnboarding"

const OnboardHeader = () => {
  const { step, previousStep, submitting } = useOnboarding()
  const isMobile = useMediaQuery("(max-width:800px)")

  return (
    <div>
      {step > 1 && !submitting && (
        <button
          onClick={previousStep}
          style={{
            position: "absolute",
            gap: "10px",
            display: "flex",
            alignItems: "center",
            padding: "5px",
            height: "35px",
            width: "35px",
            top: isMobile ? "0" : "-8px",
            left: isMobile ? "0" : "-8px",
            background: JoonUIColor.background.accent,
            color: "white",
            borderRadius: SPACING.space3,
          }}
        >
          <ChevronLeft />
        </button>
      )}
    </div>
  )
}

export default OnboardHeader
