import { DropdownOption, JoonColorExpanded } from "@joonapp/web-shared"
import { create } from "zustand"

import { DATE_RANGES } from "../../../../constants"
import { DateRange } from "../../../../types"

export const periodOptions = [
  { label: "Daily", value: "day" },
  { label: "Weekly", value: "week" },
  { label: "Monthly", value: "month" },
]

interface OverTimeProgressStore {
  selectedQuests: { id: number; color: JoonColorExpanded; name: string }[]
  toggleSelectedQuest: (quest: { id: number; name: string }) => void
  dateRange: DateRange
  setDateRange: (dateRange: DateRange) => void
  period: DropdownOption
  setPeriod: (period: DropdownOption) => void
  highlightedQuest: number | null
  setHighlightedQuest: (highlightedQuest: number | null) => void
  showTargets: boolean
  setShowTargets: (showTargets: boolean) => void
}

export const useOverTimeProgressStore = create<OverTimeProgressStore>(
  (set) => ({
    selectedQuests: [],
    toggleSelectedQuest: (selectedQuest: { id: number; name: string }) => {
      set((state) => {
        const questIsAlreadySelected = state.selectedQuests.some(
          (quest) => quest.id === selectedQuest.id
        )
        const selectedQuests = state.selectedQuests
        if (selectedQuests.length >= 8 && !questIsAlreadySelected) {
          return state
        } else if (questIsAlreadySelected) {
          const filteredQuests = selectedQuests.filter(
            (quest) => quest.id !== selectedQuest.id
          )
          return {
            selectedQuests: filteredQuests,
            showTargets: filteredQuests.length === 1,
          }
        } else {
          const nextColor = questColors.find(
            (color) => !selectedQuests.some((quest) => quest.color === color)
          ) as JoonColorExpanded
          const newQuest = {
            id: selectedQuest.id,
            name: selectedQuest.name,
            color: nextColor,
          }
          const newSelectedQuests = [...selectedQuests, newQuest]
          return {
            selectedQuests: newSelectedQuests,
            showTargets: newSelectedQuests.length === 1,
          }
        }
      })
    },

    dateRange: DATE_RANGES.past8Weeks,
    setDateRange: (dateRange: DateRange) => {
      set({ dateRange })
    },
    period: periodOptions[1],
    setPeriod: (period) => set({ period }),

    highlightedQuest: null,
    setHighlightedQuest: (highlightedQuest) => set({ highlightedQuest }),

    showTargets: true,
    setShowTargets: (showTargets) => set({ showTargets }),
  })
)

const questColors = [
  JoonColorExpanded.indigo400,
  JoonColorExpanded.orange400,
  JoonColorExpanded.viridian400,
  JoonColorExpanded.yellow400,
  JoonColorExpanded.pink400,
  JoonColorExpanded.blue400,
  JoonColorExpanded.viridian300,
  JoonColorExpanded.indigo700,
]
