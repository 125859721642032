import { ParentTrainingMasteryProgress } from "@joonapp/web-shared"
import { create } from "zustand"

import {
  ANALYTIC_EVENTS,
  trackAnalyticEvent,
} from "../../../../../util/analytics"

export interface AddEditObservationalDataModalStore {
  isOpen: boolean
  onClose: () => void
  onOpen: (progress?: ParentTrainingMasteryProgress) => void

  masteryProgressId: number | null
  date: Date
  frequency: number | null
  baseCount: number | null
  observationNotes: string | null

  setMasteryProgressId: (masteryProgressId: number) => void
  setDate: (date: Date) => void
  setFrequency: (frequency: number | null) => void
  setBaseCount: (baseCount: number | null) => void
  setObservationNotes: (observationNotes: string) => void
}

const initialData = {
  masteryProgressId: null,
  date: new Date(),
  frequency: null,
  baseCount: null,
  observationNotes: "",
}

const useAddEditObservationalDataModalStore =
  create<AddEditObservationalDataModalStore>((set) => ({
    isOpen: false,
    onClose: () => set({ isOpen: false, ...initialData }),
    onOpen: (progress) => {
      set({ isOpen: true })
      if (progress) {
        set({
          masteryProgressId: progress.id,
          date: new Date(progress.date),
          frequency: progress.frequency,
          baseCount: progress.base_count,
          observationNotes: progress.observation_notes,
        })
        trackAnalyticEvent(ANALYTIC_EVENTS.open_edit_observational_data)
      } else {
        trackAnalyticEvent(ANALYTIC_EVENTS.open_add_observational_data)
      }
    },

    ...initialData,

    setMasteryProgressId: (masteryProgressId) => set({ masteryProgressId }),
    setDate: (date) => set({ date }),
    setFrequency: (frequency) => set({ frequency }),
    setBaseCount: (baseCount) => set({ baseCount }),
    setObservationNotes: (observationNotes) => set({ observationNotes }),
  }))

export default useAddEditObservationalDataModalStore
