import {
  FlexBox,
  JoonUIColor,
  Modal,
  ModalHeader,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import { useMediaQuery } from "@mui/material"
import dayjs from "dayjs"
import { useParams } from "react-router-dom"

import ParentTaskCompletion from "./ParentTaskCompletion"
import ParentTaskDetailsBar from "./ParentTaskDetailsBar"
import useTaskDetailsModalStore from "./store/useTaskDetailsModalStore"
import PageLoader from "../../../../components/loading/PageLoader"
import {
  useParentTaskInstancesQuery,
  useCurrentTrainingQuery,
  usePatientParentsQuery,
} from "../../../../networking/queries"
import { getCompletedQuestInstances } from "../../../../util/quests"
import { exampleParentTasks } from "../../../patientsDashboard/exampleChild"

const ParentTaskDetailsModal = () => {
  const isMobile = useMediaQuery("(max-width: 800px)")
  const { userId } = useParams()
  const { isOpen, onClose, selectedTaskId } = useTaskDetailsModalStore()
  const { data: currentTraining } = useCurrentTrainingQuery()
  const parentTasks = currentTraining?.quests
  const { data: parents } = usePatientParentsQuery()
  const { data: questInstances, isLoading } = useParentTaskInstancesQuery({
    userIds: parents ? parents.map((parent) => parent.user.id) : [],
    seriesId: selectedTaskId || undefined,
    maxDate: dayjs().format("YYYY-MM-DD"),
  })

  const isExamplePatient = Number(userId) === 0
  const selectedTask = isExamplePatient
    ? exampleParentTasks.find((task) => task.series.id === selectedTaskId)
    : parentTasks?.find((task) => task.id === selectedTaskId)

  const exampleTaskInstances = exampleParentTasks.filter(
    (instance) => instance.series.id === selectedTaskId
  )
  const completedInstances = isExamplePatient
    ? getCompletedQuestInstances(exampleTaskInstances)
    : getCompletedQuestInstances(questInstances)

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      style={{
        width: "min(600px, 95vw)",
        height: isMobile ? "100dvh" : "95dvh",
        minHeight: isMobile ? "100dvh" : "700px",
        maxHeight: isMobile ? "100dvh" : "95dvh",
      }}
      mobileFull
    >
      <ModalHeader
        title="Review implementation task"
        onClose={onClose}
        showBorderBottom
      />
      <FlexBox
        direction="column"
        style={{
          background: JoonUIColor.background.lightGray,
          borderRight: `1px solid ${JoonUIColor.border.default}`,
          flex: 1,
          overflowY: "auto",
        }}
        wrap={false}
      >
        {selectedTask && <ParentTaskDetailsBar />}
        <>
          <div
            style={{
              minHeight: "250px",
              width: "100%",
              padding: SPACING.space4,
              background: JoonUIColor.background.lightGray,
              boxShadow: "2px 2px 6px 0px rgba(209, 220, 236, 0.60) inset",
              flex: 1,
              display: "flex",
              gap: SPACING.space4,
              flexDirection: "column",
              position: "relative",
            }}
          >
            {!isExamplePatient && isLoading ? (
              <PageLoader />
            ) : completedInstances.length > 0 ? (
              completedInstances?.map((task, i) => (
                <ParentTaskCompletion key={i} task={task} />
              ))
            ) : (
              <ParentTaskCompletionsEmptyState />
            )}
          </div>
        </>
      </FlexBox>
    </Modal>
  )
}

const ParentTaskCompletionsEmptyState = () => {
  return (
    <FlexBox
      direction="column"
      gap={SPACING.space4}
      align="center"
      wrap={false}
      justify="center"
      style={{
        width: "100%",
        height: "100%",
        padding: SPACING.space6,
      }}
    >
      <Typography variant="h3">No completions yet!</Typography>
      <Typography variant="bodySmall" textAlign="center">
        Upon submission, completion log and reflection notes will be shown here.
      </Typography>
    </FlexBox>
  )
}

export default ParentTaskDetailsModal
