import { ClinicalResource } from "@joonapp/web-shared"
import { create } from "zustand"

interface AddEditResourceSidePanelStore {
  isOpen: boolean
  onClose: () => void
  onOpen: (resource?: ClinicalResource) => void

  selectedResourceId: number | null

  title: string
  description: string
  selectedTags: number[]
  setTitle: (title: string) => void
  setDescription: (description: string) => void
  toggleSelectedTag: (tagId: number) => void

  files: File[]
  setFiles: (files: File[]) => void

  // For editing specifically
  resource: ClinicalResource | null
  showExistingResource: boolean
  setShowExistingResource: (show: boolean) => void
}

const useAddEditResourceSidePanelStore = create<AddEditResourceSidePanelStore>(
  (set) => ({
    isOpen: false,
    onClose: () =>
      set({
        isOpen: false,
        selectedResourceId: null,
        title: "",
        description: "",
        selectedTags: [],
        files: [],
        resource: null,
        showExistingResource: true,
      }),
    onOpen: (resource) => {
      set({ isOpen: true })
      if (!!resource) {
        set({
          selectedResourceId: resource.id,
          title: resource.title,
          description: resource.description,
          selectedTags: resource.tag_ids,
          resource,
        })
      }
    },

    selectedResourceId: null,

    title: "",
    description: "",
    selectedTags: [],

    setTitle: (title) => set({ title }),
    setDescription: (description) => set({ description }),
    toggleSelectedTag: (tagId) =>
      set(({ selectedTags }) => {
        if (selectedTags.includes(tagId)) {
          return { selectedTags: selectedTags.filter((id) => id !== tagId) }
        } else {
          return { selectedTags: [...selectedTags, tagId] }
        }
      }),

    files: [],
    setFiles: (files) => set({ files }),

    resource: null,
    showExistingResource: true,
    setShowExistingResource: (show) => set({ showExistingResource: show }),
  })
)

export default useAddEditResourceSidePanelStore
