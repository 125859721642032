import {
  Button,
  FlexBox,
  JoonUIColor,
  Modal,
  ModalHeader,
  SPACING,
  TextButton,
  Typography,
} from "@joonapp/web-shared"
import { useParams } from "react-router-dom"

import { useDeleteObservationalDataModalStore } from "./mutations/useDeleteObservationalDataModalStore"
import useDeleteObservationalDataMutation from "./mutations/useDeleteObservationalDataMutation"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../../util/analytics"

const DeleteObservationalDataModal = () => {
  const { isOpen, onClose, progressId } = useDeleteObservationalDataModalStore()

  const { userId } = useParams()
  const isExampleClient = Number(userId) === 0

  const deleteObservationalDataMutation = useDeleteObservationalDataMutation()

  const onClickDelete = async () => {
    if (!progressId) return
    deleteObservationalDataMutation.mutateAsync(progressId)
    onClose()
    trackAnalyticEvent(ANALYTIC_EVENTS.delete_observational_data)
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} animate>
      <ModalHeader
        onClose={onClose}
        title="Delete observational data"
        showBorderBottom
      />
      <FlexBox
        direction="column"
        gap={SPACING.space4}
        style={{
          padding: SPACING.space6,
          paddingTop: SPACING.space4,
        }}
      >
        <Typography variant="body">
          Are you sure you want to delete this data?
        </Typography>
        <FlexBox
          direction="row"
          gap={SPACING.space2}
          justify="flex-end"
          wrap={false}
        >
          <TextButton onClick={onClose}>
            <Typography
              variant="bodyBold"
              color={JoonUIColor.text.primaryAccent}
            >
              Cancel
            </Typography>
          </TextButton>
          <Button
            text="Delete"
            onClick={onClickDelete}
            buttonType="redPrimary"
            disabled={isExampleClient}
          />
        </FlexBox>
      </FlexBox>
    </Modal>
  )
}

export default DeleteObservationalDataModal
