import { JoonUIColor, SPACING, Typography } from "@joonapp/web-shared"
import { Link, useNavigate } from "react-router-dom"

import { BackButton2 } from "../../components/buttons/BackButton2"
import PageContentWrapper from "../../components/layout/PageContentWrapper"
import PageWrapper from "../../components/pageWrapper/PageWrapper"
import { requireAuth } from "../../util/auth"

const PrivacyPolicy = requireAuth(() => {
  const navigate = useNavigate()

  return (
    <PageWrapper>
      <PageContentWrapper>
        <BackButton2 text="Back" onClick={() => navigate("/security")} />
        <Typography variant="h2">
          Personal Identifiable Information (PII) Policy
        </Typography>
        <div
          style={{
            background: JoonUIColor.background.primaryNeutral,
            border: `1px solid ${JoonUIColor.border.default}`,
            padding: SPACING.space4,
            borderRadius: SPACING.space4,
          }}
        >
          <div className="body-text bold">Last updated March 3, 2023</div>
          <br />
          Thank you for choosing to be part of our community at Joon App, Inc (“
          <span className="bold">Company</span>”, “
          <span className="bold">we</span>”, “<span className="bold">us</span>”,
          or “<span className="bold">our</span>”). We are committed to
          protecting your personal information and your right to privacy. If you
          have any questions or concerns about our policy, or our practices with
          regards to your personal information, please contact us at{" "}
          <ContactEmail />. When you visit our website{" "}
          <a href="https://joonapp.io/" target="_blank" rel="noreferrer">
            https://joonapp.io/
          </a>
          , mobile application, or use our services, you trust us with your
          personal information. We take your privacy very seriously. In this
          privacy policy, we seek to explain to you in the clearest way possible
          what information we collect, how we use it and what rights you have in
          relation to it. We hope you take some time to read through it
          carefully, as it is important. If there are any terms in this privacy
          policy that you do not agree with, please discontinue use of our Sites
          or App and our services. This privacy policy applies to all
          information collected through our website (such as{" "}
          <a href="https://joonapp.io/" target="_blank" rel="noreferrer">
            https://joonapp.io/
          </a>
          ), mobile application, ("<span className="bold">App</span>"), and/or
          any related services, sales, marketing or events (we refer to them
          collectively in this privacy policy as the "
          <span className="bold">Services</span>").{" "}
          <span className="bold">
            Please read this privacy policy carefully as it will help you make
            informed decisions about sharing your personal information with us.
          </span>
          ‍<br />
          <br />
          <div className="bold">TABLE OF CONTENTS</div>
          <div>
            <a href="#1">‍1. WHAT INFORMATION DOES JOON COLLECT?</a>
          </div>
          <div>
            <a href="#2">‍2. WHY WE COLLECT YOUR INFORMATION?</a>
          </div>
          <div>
            <a href="#3">‍3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</a>
          </div>
          <div>
            <a href="#4">‍4. HOW LONG DO WE KEEP YOUR INFORMATION?</a>
          </div>
          <div>
            <a href="#5">‍5. WHAT ARE YOUR PRIVACY RIGHTS?</a>
          </div>
          <div>
            <a href="#6">‍6. CONTROLS FOR DO-NOT-TRACK FEATURES</a>
          </div>
          <div>
            <a href="#7">
              ‍7. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
            </a>
          </div>
          <div>
            <a href="#8">‍8. DO WE MAKE UPDATES TO THIS POLICY?</a>
          </div>
          <div>
            <a href="#9">‍9. HOW CAN YOU CONTACT US ABOUT THIS POLICY?</a>
          </div>
          <br />
          <div className="bold" id="1">
            1. WHAT INFORMATION DOES JOON COLLECT?
          </div>
          <span className="bold">Personal information you disclose to us</span>{" "}
          <span className="italic-text">
            <span className="bold">In Short: </span>
            We collect personal information that you voluntarily provide to us.{" "}
          </span>
          We collect personal information that you voluntarily provide to us
          when you create a Joon account, expressing an interest in obtaining
          information about us or our products and services, when participating
          in activities on Joon or otherwise contacting us. The personal
          information that we collect depends on the context of your
          interactions with Joon, the choices you make and the products and
          features you use. The personal information we collect may include the
          following (but is not required for use of app):
          <br />
          <br />
          <span className="bold">‍Account Information. </span>
          When you create an account on Joon, we collect your name, nickname,
          email address, and password. Additionally, you are required to
          complete a Basic Questionnaire and detailed Intake Questionnaire. Both
          Questionnaires request personal information but if you are not
          comfortable sharing the requested information, you may select "Prefer
          not to answer." The personal information that you voluntarily provide
          to us does not need to be true, complete or accurate. ‍<br />
          <br />
          Children are not permitted to create an account by themselves but
          instead must be invited to Joon by a parent, guardian, therapist, or
          educator. Where children have permission to use Joon, Joon only
          collects the child's first name as that is the only personally
          identifiable information that is necessary to provide the Service. A
          nickname may be used as an adequate substitute for the child's first
          name. A parent, guardian, or educator may also enter a child's date of
          birth, but note that this information is optional and not required for
          the Service. ‍
          <br />
          <br />
          <span className="bold">Information collected through Joon.</span>
          <span className="italic-text">
            <span className="bold"> In Short: </span>
            We may collect information regarding your push notifications when
            you use Joon.
          </span>{" "}
          If you use Joon, we may also collect the following information:
          <br />
          <br />
          <span className="italic-text">Push Notifications. </span>
          We may request to send you push notifications regarding your account
          or the mobile application. If you wish to opt-out from receiving these
          types of communications, you may turn them off in your device's
          settings. ‍<br />
          <br />
          <span className="bold" id="2">
            2. WHY WE COLLECT YOUR INFORMATION?
          </span>
          <span className="italic-text">
            <span className="bold"> In Short: </span>
            If your information is provided, we process your information for
            purposes based on legitimate business interests, the fulfillment of
            our contract with you, compliance with our legal obligations, and/or
            your consent.{" "}
          </span>
          We use personal information collected via our Services or App for a
          variety of business purposes described below. We process your personal
          information for these purposes in reliance on our legitimate business
          interests, in order to enter into or perform a contract with you, with
          your consent, and/or for compliance with our legal obligations. We
          indicate the specific processing grounds we rely on next to each
          purpose listed below:
          <br />
          <span className="bold">
            - To facilitate account creation and login process.
          </span>
          If you choose to link your account with us to a third party account
          (such as your Google, Apple, or Facebook account), we use the
          information you allowed us to collect from those third parties to
          facilitate account creation and login process for the performance of
          the contract.
          <br />
          <span className="bold">- To contact you. </span>For example, to send
          you relationship, transactional and commercial communications and
          other marketing and advertising. To administer sweepstakes, surveys or
          contests.
          <br />
          <span className="bold">- To enable user-to-user communications.</span>
          We may use your information in order to enable user-to-user
          communications.
          <br />
          <span className="bold">- For other Business Purposes.</span>
          We may use your information for other Business Purposes, such as
          sharing with third parties for direct marketing purposes, removing you
          from active marketing campaigns, data analysis and identifying usage
          trends. We may use and store this information in aggregated and
          anonymized form so that it is not associated with individual end users
          and does not include personal information.
          <br />
          <br />
          <span className="bold" id="3">
            3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
          </span>
          <span className="italic-text">
            <span className="bold"> In Short: </span>
            We only share information with your consent, to comply with laws, to
            provide you with services, to protect your rights, or to fulfill
            business obligations.
          </span>
          We may process or share data based on the following legal basis:
          <br />
          <span className="bold">Consent: </span>
          We may process your data if you have given us specific consent to use
          your personal information in a specific purpose. Consent may be
          granted via our website or mobile application.
          <br />
          <br />
          <span className="bold">Performance of a Contract: </span>
          Where we have entered into a contract with you, we may process your
          personal information to fulfill the terms of our contract.
          <br />
          <br />
          <span className="bold">Legal Obligations: </span>
          We may disclose your information where we are legally required to do
          so in order to comply with applicable law, governmental requests, a
          judicial proceeding, court order, or legal process, such as in
          response to a court order or a subpoena (including in response to
          public authorities to meet national security or law enforcement
          requirements).
          <br />
          <br />
          <span className="bold">Vital Interests: </span>
          We may disclose your information where we believe it is necessary to
          investigate, prevent, or take action regarding potential violations of
          our policies, suspected fraud, situations involving potential threats
          to the safety of any person and illegal activities, or as evidence in
          litigation in which we are involved. More specifically, we may need to
          process your data or share your personal information in the following
          situations:
          <br />
          <br />
          <span className="bold">Business Transfers. </span>
          We may share or transfer your information in connection with, or
          during negotiations of, any merger, sale of company assets, financing,
          or acquisition of all or a portion of our business to another company.
          ‍
          <br />
          <br />
          <span className="bold" id="4">
            4. HOW LONG DO WE KEEP YOUR INFORMATION?
          </span>
          <span className="italic-text">
            <span className="bold"> In Short: </span>
            We keep your information for as long as necessary to fulfill the
            purposes outlined in this privacy policy unless otherwise required
            by law.{" "}
          </span>
          We will only keep your personal information for as long as it is
          necessary for the purposes set out in this privacy policy, unless a
          longer retention period is required or permitted by law (such as tax,
          accounting or other legal requirements). No purpose in this policy
          will require us keeping your personal information for longer than the
          period of time in which users have an account with us. When we have no
          ongoing legitimate business need to process your personal information,
          we will either delete or anonymize it, or, if this is not possible
          (for example, because your personal information has been stored in
          backup archives), then we will securely store your personal
          information and isolate it from any further processing until deletion
          is possible.
          <br />
          <br />
          <span className="bold" id="5">
            5. WHAT ARE YOUR PRIVACY RIGHTS?{" "}
          </span>
          <span className="italic-text">
            <span className="bold">In Short: </span>
            You may review, change, or terminate your account at any time.
          </span>
          If you are resident in the European Economic Area and you believe we
          are unlawfully processing your personal information, you also have the
          right to complain to your local data protection supervisory authority.
          You can find their contact details here:{" "}
          <a
            href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
            target="_blank"
            rel="noreferrer"
          >
            http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.{" "}
          </a>
          <br />
          <span className="bold">Account Information: </span>
          If you would at any time like to review or change the information in
          your account or terminate your account, you can: ■ Contact us using
          the contact information provided. Upon your request to terminate your
          account, we will deactivate or delete your account and information
          from our active databases. However, some information may be retained
          in our files to prevent fraud, troubleshoot problems, assist with any
          investigations, enforce our Terms of Use and/or comply with legal
          requirements.
          <br />
          <span className="bold">Opting out of email marketing:</span>
          You can unsubscribe from our marketing email list at any time by
          clicking on the unsubscribe link in the emails that we send or by
          contacting us using the details provided below. You will then be
          removed from the marketing email list – however, we will still need to
          send you service-related emails that are necessary for the
          administration and use of your account. To otherwise opt-out, you may:
          ■ Contact us using the contact information provided.
          <br />
          <br />
          <span className="bold" id="6">
            6. CONTROLS FOR DO-NOT-TRACK FEATURES.{" "}
          </span>
          Most web browsers and some mobile operating systems and mobile
          applications include a Do-Not-Track (“DNT”) feature or setting you can
          activate to signal your privacy preference not to have data about your
          online browsing activities monitored and collected. No uniform
          technology standard for recognizing and implementing DNT signals has
          been finalized. As such, we do not currently respond to DNT browser
          signals or any other mechanism that automatically communicates your
          choice not to be tracked online. If a standard for online tracking is
          adopted that we must follow in the future, we will inform you about
          that practice in a revised version of this privacy policy. ‍
          <br />
          <br />
          <span className="bold" id="7">
            7. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
          </span>
          <span className="italic-text">
            <span className="bold"> In Short: </span>
            Yes, if you are a resident of California, you are granted specific
            rights regarding access to your personal information.
          </span>
          California Civil Code Section 1798.83, also known as the “Shine The
          Light” law, permits our users who are California residents to request
          and obtain from us, once a year and free of charge, information about
          categories of personal information (if any) we disclosed to third
          parties for direct marketing purposes and the names and addresses of
          all third parties with which we shared personal information in the
          immediately preceding calendar year. If you are a California resident
          and would like to make such a request, please submit your request in
          writing to us using the contact information provided below. If you are
          under 18 years of age, reside in California, and have a registered
          account with the Services or App, you have the right to request
          removal of unwanted data that you publicly post on the Services or
          App. To request removal of such data, please contact us using the
          contact information provided below, and include the email address
          associated with your account and a statement that you reside in
          California. We will make sure the data is not publicly displayed on
          the Services or App, but please be aware that the data may not be
          completely or comprehensively removed from our systems. ‍<br />
          <br />
          <span className="bold" id="8">
            8. DO WE MAKE UPDATES TO THIS POLICY?
          </span>
          <span className="italic-text">
            <span className="bold"> In Short: </span>
            Yes, we will update this policy as necessary to stay compliant with
            relevant laws.
          </span>
          We may update this privacy policy from time to time. The updated
          version will be indicated by an updated “Revised” date and the updated
          version will be effective as soon as it is accessible. If we make
          material changes to this privacy policy, we may notify you either by
          prominently posting a notice of such changes or by directly sending
          you a notification. We encourage you to review this privacy policy
          frequently to be informed of how we are protecting your information. ‍
          <br />
          <br />
          <span className="bold" id="9">
            9. HOW CAN YOU CONTACT US ABOUT THIS POLICY?{" "}
          </span>
          If you have questions or comments about this policy, you may email us
          at <ContactEmail /> or by post to: 23862 Pipit Ct, Laguna Niguel, CA
          92677 United States ‍<br />
          <br />
          <span className="bold">
            HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?{" "}
          </span>
          Based on the laws of some countries, you may have the right to request
          access to the personal information we collect from you, change that
          information, or delete it in some circumstances. To request to review,
          update, or delete your personal information, please email us at{" "}
          <ContactEmail />
        </div>
      </PageContentWrapper>
    </PageWrapper>
  )
})

const ContactEmail = () => {
  const sendContactEmail = () => {
    const recipientEmail = "contact@joonapp.io"
    const emailSubject = encodeURIComponent("Joon Health Help Request")
    const url = `mailto:${recipientEmail}?subject=${emailSubject}`
    window.open(url)
  }

  return (
    <Link to="#" onClick={sendContactEmail}>
      contact@joonapp.io
    </Link>
  )
}

export default PrivacyPolicy
