import {
  Typography,
  JoonUIColor,
  SPACING,
  ChatIcon,
  FlexBox,
} from "@joonapp/web-shared"
import { useState } from "react"

import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"

const FeedbackButton = () => {
  const [isHovered, setIsHovered] = useState(false)

  const openFeedbackForm = () => {
    window.open("https://forms.gle/kBaTjTHiarZ32Hwp9", "_blank")
    trackAnalyticEvent(ANALYTIC_EVENTS.open_feedback_form)
  }

  return (
    <button
      onClick={openFeedbackForm}
      style={{
        padding: `${SPACING.space1} ${SPACING.space2}`,
        margin: "auto auto 0",
        position: "sticky",
        bottom: SPACING.space4,
        borderRadius: SPACING.space2,
        background: isHovered
          ? JoonUIColor.background.xlightGray
          : JoonUIColor.background.primaryNeutral,
        border: `1px solid ${JoonUIColor.border.default}`,
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <FlexBox direction="row" gap={SPACING.space2} align="center">
        <ChatIcon color={JoonUIColor.icon.accent} size={12} />
        <Typography variant="bodySmall" color={JoonUIColor.text.secondary}>
          Submit feedback
        </Typography>
      </FlexBox>
    </button>
  )
}

export default FeedbackButton
