import EditTargetBehaviorModal from "./EditTargetBehaviorModal"
import TargetBehaviorSection from "./TargetBehaviorSection"
import PageContentWrapper from "../../../components/layout/PageContentWrapper"

const BehaviorsIncidentsPage = () => {
  return (
    <>
      <PageContentWrapper>
        <TargetBehaviorSection />
      </PageContentWrapper>
      <EditTargetBehaviorModal />
    </>
  )
}

export default BehaviorsIncidentsPage
