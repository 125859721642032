import {
  ChildUser,
  Family,
  FamilyChildProfile,
  FamilyParentProfile,
  FrequencyTarget,
  FrequencyTargetPeriod,
  GroupType,
  LearningModuleAssignment,
  MasteryCriteriaMeasurement,
  ModuleType,
  ParentAvatar,
  ParentTraining,
  ParentTrainingStatus,
  ParentUser,
  QuestDifficulty,
  QuestInstance,
  QuestRoutine,
  QuestSeries,
  QuestStatus,
  QuestType,
  SkillKey,
  User,
  UserRole,
} from "@joonapp/web-shared"
import dayjs from "dayjs"

import { REPETITIONS } from "../../constants"
import {
  Incident,
  IncidentLogDataType,
  OneOffQuest,
  TargetBehavior,
  TargetBehaviorStatus,
} from "../../types"
import { getRruleFromRepetitionArray } from "../../util/quests"
import {
  DateQuest,
  QuestSeriesWithTargets,
} from "../patientInfo/homeRoutines/overTimeProgress/data"

export const dummyTargets: FrequencyTarget[] = [
  {
    frequency: 6,
    date_created: "2024-04-14",
    date_deleted: null,
    period: FrequencyTargetPeriod.SEVEN_DAYS,
  },
  {
    frequency: 5,
    date_created: "2024-04-10",
    date_deleted: "2024-04-14",
    period: FrequencyTargetPeriod.SEVEN_DAYS,
  },
  {
    frequency: 4,
    date_created: "2024-04-02",
    date_deleted: "2024-04-10",
    period: FrequencyTargetPeriod.SEVEN_DAYS,
  },
  {
    frequency: 3,
    date_created: "2024-03-15",
    date_deleted: "2024-04-02",
    period: FrequencyTargetPeriod.SEVEN_DAYS,
  },
  {
    frequency: 2,
    date_created: "2024-03-01",
    date_deleted: "2024-03-15",
    period: FrequencyTargetPeriod.SEVEN_DAYS,
  },
  {
    frequency: 1,
    date_created: "2024-02-25",
    date_deleted: "2024-03-01",
    period: FrequencyTargetPeriod.SEVEN_DAYS,
  },
]

const exampleQuests: QuestSeriesWithTargets[] = [
  {
    id: 1,
    title: "🛏 Make your bed",
    difficulty: QuestDifficulty.EASY,
    type: QuestType.REPEATING,
    skill: SkillKey.FOCUS,
    routine: QuestRoutine.MORNING,
    // @ts-ignore
    repetition: REPETITIONS.daily,
    redeemable_reward: 10,
    start_date: "2023-06-02",
    date_updated: 1717372800.0,
    assigner_profile: {
      user_id: 1,
      nickname: "Dad",
      role: UserRole.PARENT,
    },
    frequency_targets: dummyTargets,
  },
  {
    id: 2,
    title: "💊 Take morning medication",
    difficulty: QuestDifficulty.EASY,
    skill: SkillKey.FOCUS,
    routine: QuestRoutine.MORNING,
    type: QuestType.REPEATING,
    redeemable_reward: 10,
    // @ts-ignore
    repetition: REPETITIONS.daily,
    start_date: "2023-06-02",
    date_updated: 1717372800.0,
    assigner_profile: {
      user_id: 2,
      nickname: "Dad",
      role: UserRole.PARENT,
    },
    frequency_targets: dummyTargets,
  },
  {
    id: 3,
    title: "🎒 Complete all homework",
    difficulty: QuestDifficulty.MEDIUM,
    skill: SkillKey.KNOWLEDGE,
    routine: QuestRoutine.AFTERNOON,
    type: QuestType.REPEATING,
    redeemable_reward: 30,
    // @ts-ignore
    repetition: REPETITIONS.weekdays,
    start_date: "2023-06-02",
    date_updated: 1717372800.0,
    assigner_profile: {
      user_id: 1,
      nickname: "Dr. Liang",
      role: UserRole.THERAPIST,
    },
    frequency_targets: dummyTargets,
  },
  {
    id: 4,
    title: "🐶 Feed dog",
    difficulty: QuestDifficulty.EASY,
    skill: SkillKey.KNOWLEDGE,
    routine: QuestRoutine.AFTERNOON,
    type: QuestType.REPEATING,
    redeemable_reward: 10,
    // @ts-ignore
    repetition: REPETITIONS.daily,
    start_date: "2023-06-02",
    date_updated: 1717372800.0,
    assigner_profile: {
      user_id: 2,
      nickname: "Dr. Liang",
      role: UserRole.THERAPIST,
    },
    frequency_targets: dummyTargets,
  },
  {
    id: 5,
    title: "📝 Journal about your day",
    difficulty: QuestDifficulty.MEDIUM,
    skill: SkillKey.FOCUS,
    type: QuestType.REPEATING,
    routine: QuestRoutine.NIGHT,
    redeemable_reward: 15,
    // @ts-ignore
    repetition: REPETITIONS.daily,
    start_date: "2023-06-02",
    date_updated: 1717372800.0,
    assigner_profile: {
      user_id: 1,
      nickname: "Dad",
      role: UserRole.PARENT,
    },
    frequency_targets: dummyTargets,
  },
  {
    id: 6,
    title: "🧹 Clean your room",
    difficulty: QuestDifficulty.HARD,
    skill: SkillKey.FOCUS,
    type: QuestType.REPEATING,
    routine: QuestRoutine.NIGHT,
    redeemable_reward: 20,
    // @ts-ignore
    repetition: REPETITIONS.weekends,
    start_date: "2023-06-02",
    date_updated: 1717372800.0,
    assigner_profile: {
      user_id: 2,
      nickname: "Dr. Liang",
      role: UserRole.THERAPIST,
    },
    frequency_targets: dummyTargets,
  },
  {
    id: 7,
    title: "😊 Be nice to siblings",
    difficulty: QuestDifficulty.MEDIUM,
    type: QuestType.REPEATING,
    skill: SkillKey.KINDNESS,
    routine: QuestRoutine.ANYTIME,
    redeemable_reward: 10,
    // @ts-ignore
    repetition: REPETITIONS.daily,
    start_date: "2023-06-02",
    date_updated: 1717372800.0,
    assigner_profile: {
      user_id: 1,
      nickname: "Dad",
      role: UserRole.PARENT,
    },
    frequency_targets: dummyTargets,
  },
  {
    id: 8,
    title: "📖 Read for 15 minutes",
    difficulty: QuestDifficulty.MEDIUM,
    skill: SkillKey.FOCUS,
    type: QuestType.REPEATING,
    routine: QuestRoutine.ANYTIME,
    redeemable_reward: 10,
    // @ts-ignore
    repetition: REPETITIONS.weekdays,
    start_date: "2023-06-02",
    date_updated: 1717372800.0,
    assigner_profile: {
      user_id: 2,
      nickname: "Dr. Liang",
      role: UserRole.THERAPIST,
    },
    frequency_targets: dummyTargets,
  },
  {
    id: 9,
    title: "🗣️ Speak respectfully to caregivers",
    difficulty: QuestDifficulty.MEDIUM,
    skill: SkillKey.STAMINA,
    type: QuestType.REPEATING,
    routine: QuestRoutine.ANYTIME,
    redeemable_reward: 10,
    // @ts-ignore
    repetition: REPETITIONS.daily,
    start_date: "2023-06-02",
    date_updated: 1717372800.0,
    assigner_profile: {
      user_id: 1,
      nickname: "Dad",
      role: UserRole.PARENT,
    },
    frequency_targets: dummyTargets,
  },
]

const convertToQuestSeries = (
  quests: QuestSeriesWithTargets[]
): QuestSeries[] => {
  const questSeries: QuestSeries[] = []
  quests.forEach((quest) => {
    const questInstance = {
      ...quest,
      // @ts-ignore
      recurrence: getRruleFromRepetitionArray(quest.repetition),
    } as any
    questSeries.push(questInstance)
  })
  return questSeries
}
export const exampleQuestBoardData = {
  data: { results: convertToQuestSeries(exampleQuests) },
}

export const exampleParentUser: ParentUser = {
  email: "renee@joonapp.io",
  avatar: ParentAvatar.FRUMPULOUS,
  legacy_parent_id: null,
  name: "Renee",
  phone_number: "1234567890",
  last_name: "L",
  date_joined: "2024-02-05",
  id: -1,
}

export const exampleParentProfile: FamilyParentProfile = {
  nickname: "Mom",
  role: UserRole.PARENT,
  user: exampleParentUser,
}

const exampleChildUser = {
  id: 0,
  name: "Kevin",
  last_name: null,
  email: null,
  date_joined: "2023-06-02T18:40:24.968994Z",
  birthdate: "2023-08-25",
  username: "Kevin",
  legacy_child_id: 559140,
  coins: 179,
  parent: exampleParentUser,
  active_doter: {
    id: 0,
    name: "Dad",
    type: "bear",
    is_paused: false,
    active_doter: true,
    color: "red",
  },
  gender: "male",
  preferences: {
    morning_limit: false,
    afternoon_limit: false,
    night_limit: false,
    afternoon_start_time: "12:00PM",
    night_start_time: "6:00PM",
    hides_future_routines: false,
  },
  last_login_date: "2024-02-05",
} as ChildUser

export const examplePatientData: FamilyChildProfile = {
  nickname: null,
  role: UserRole.CHILD,
  user: exampleChildUser,
}

const generateQuestsForDate = (
  quests: QuestSeriesWithTargets[],
  progress: number,
  date: string
): DateQuest[] => {
  const questsForDate: DateQuest[] = []
  const dayOfWeek = dayjs(date, "MM-DD-YYYY").day() // 0 is Sunday, 6 is Saturday

  const filteredQuests = quests.filter(
    // @ts-ignore
    (quest) => quest.repetition[dayOfWeek] === true
  )

  for (const quest of filteredQuests) {
    const statusChance = Math.random()
    const baseRate = 0
    const maxIncrease = 0.8
    const completionRate = baseRate + maxIncrease * Math.sqrt(progress) // Using square root for slower growth

    let status: QuestStatus = QuestStatus.OPEN
    if (statusChance < completionRate) status = QuestStatus.REDEEMED
    else if (statusChance < completionRate + 0.1) status = QuestStatus.SKIPPED

    const questForDate = {
      series_id: quest.id,
      status: status,
    } as DateQuest

    if (status === QuestStatus.REDEEMED || status === QuestStatus.SKIPPED) {
      questForDate.completion_difficulty = Math.floor((1 - completionRate) * 7)
      questForDate.reward = quest.redeemable_reward
      if (Math.random() < 0.2) questForDate.reward = quest.redeemable_reward + 5
    }

    if (status === QuestStatus.REDEEMED) {
      questForDate.completion_notes = "I did my best!"
      questForDate.completion_photo_url =
        "https://images.custommade.com/b9hP6IwLgGZsab-fnJimHDoJokQ=/fit-in/510x382/custommade-photosets/78007/78007.226090.jpg"
      questForDate.verification_notes = "Great job!"
    }

    questsForDate.push(questForDate)
  }
  return questsForDate
}
const generateDates = (): string[] => {
  const dates: string[] = []
  let currentDate = dayjs()
  for (let i = 0; i < 84; i++) {
    // 12 weeks * 7 days
    dates.push(currentDate.format("MM-DD-YYYY"))
    currentDate = currentDate.subtract(1, "day")
  }
  return dates.reverse()
}

const generateExampleQuestData = () => {
  const output = {
    quests: exampleQuests,
    dates: {},
  } as {
    quests: QuestSeriesWithTargets[]
    dates: { [date: string]: DateQuest[] }
  }

  const allDates = generateDates()

  for (let i = 0; i < allDates.length; i++) {
    const currentDate = allDates[i] as string
    output.dates[currentDate] = generateQuestsForDate(
      output.quests,
      i / allDates.length,
      currentDate
    )
  }

  return output
}
const exampleQuestData = generateExampleQuestData()

const filterExampleQuestDataByDate = (
  startDate: string,
  endDate: string
): any => {
  const { quests, dates } = exampleQuestData
  const start = dayjs(startDate).subtract(1, "day")
  const end = dayjs(endDate)
  const result = {} as any

  // Add missing dates with empty arrays
  let currentDate = start
  while (currentDate.isBefore(end) || currentDate.isSame(end)) {
    if (!dates[currentDate.format("MM-DD-YYYY")])
      result[currentDate.format("MM-DD-YYYY")] = []
    currentDate = currentDate.add(1, "day")
  }

  // Filter the data by date range
  Object.keys(dates)
    .filter((date) => {
      const current = dayjs(date, "MM-DD-YYYY")
      return (
        (current.isAfter(start) || current.isSame(start)) &&
        (current.isBefore(end) || current.isSame(end))
      )
    })
    .forEach((date) => (result[date] = dates[date]))

  return { quests, dates: result }
}

export const getExampleRoutineStats = ({ dateRange }: any) => {
  const startDate = dateRange.startDate
  const endDate = dateRange.endDate

  return { data: filterExampleQuestDataByDate(startDate, endDate) }
}

export const getExampleOneOffStats = ({ dateRange }: any): { data: any } => {
  const startDate = dateRange.startDate
  const endDate = dateRange.endDate
  const numDays = dayjs(endDate).diff(dayjs(startDate), "day") + 1

  const dates: string[] = []
  let currentDate = startDate
  for (let i = 0; i < numDays; i++) {
    dates.push(currentDate.format("MM-DD-YYYY"))
    currentDate = currentDate.subtract(1, "day")
  }
  const output = dates.map((date, i) => {
    const exampleQuest = exampleOneOff[i % exampleOneOff.length]
    return { ...exampleQuest, start_date: date }
  })

  return { data: { quests: output } }
}

const exampleOneOff: OneOffQuest[] = [
  {
    id: 3555300,
    title: "Talk about your feelings with mom / dad",
    difficulty: QuestDifficulty.EASY,
    routine: QuestRoutine.ANYTIME,
    deleted: false,
    start_date: "2024-01-09",
    date_updated: "2024-01-09T23:40:20.913267Z",
    redeemable_reward: 10,
    skill: SkillKey.FOCUS,
    assigner_profile: {
      user_id: 640817,
      nickname: "Mom",
      role: UserRole.PARENT,
    },
    completed_instance: {
      status: QuestStatus.COMPLETED,
      completion_notes: "I felt better after talking about it",
      completion_photo_url:
        "https://images.custommade.com/b9hP6IwLgGZsab-fnJimHDoJokQ=/fit-in/510x382/custommade-photosets/78007/78007.226090.jpg",
      reward: 10,
      date: "2024-02-05",
      verification_notes: "I'm proud of you",
    },
  },
  {
    id: 3555305,
    title: "Write down what makes you happy",
    difficulty: QuestDifficulty.HARD,
    routine: QuestRoutine.ANYTIME,
    deleted: false,
    start_date: "2024-01-09",
    date_updated: "2024-01-09T23:40:46.941198Z",
    redeemable_reward: 10,
    skill: SkillKey.FOCUS,
    assigner_profile: {
      user_id: 640817,
      nickname: "Mom",
      role: UserRole.PARENT,
    },
    completed_instance: {
      status: QuestStatus.COMPLETED,
      completion_notes: "I felt better after talking about it",
      completion_photo_url:
        "https://images.custommade.com/b9hP6IwLgGZsab-fnJimHDoJokQ=/fit-in/510x382/custommade-photosets/78007/78007.226090.jpg",
      reward: 10,
      date: "2024-02-05",
      verification_notes: "I'm proud of you",
    },
  },
  {
    id: 3555318,
    title: "Take out the garbage",
    difficulty: QuestDifficulty.HARD,
    routine: QuestRoutine.ANYTIME,
    deleted: false,
    start_date: "2024-01-09",
    date_updated: "2024-01-09T23:41:53.978045Z",
    redeemable_reward: 10,
    skill: SkillKey.STRENGTH,
    assigner_profile: {
      user_id: 640817,
      nickname: "Mom",
      role: UserRole.PARENT,
    },
    completed_instance: {
      status: QuestStatus.COMPLETED,
      completion_notes: "Smelly!",
      completion_photo_url:
        "https://images.custommade.com/b9hP6IwLgGZsab-fnJimHDoJokQ=/fit-in/510x382/custommade-photosets/78007/78007.226090.jpg",
      reward: 10,
      date: "2024-02-05",
      verification_notes: "I'm proud of you",
    },
  },
  {
    id: 3555346,
    title: "Do science project",
    difficulty: QuestDifficulty.HARD,
    routine: QuestRoutine.ANYTIME,
    deleted: false,
    start_date: "2024-01-09",
    date_updated: "2024-01-09T23:44:45.150862Z",
    redeemable_reward: 10,
    skill: SkillKey.KNOWLEDGE,
    assigner_profile: {
      user_id: 640817,
      nickname: "Mom",
      role: UserRole.PARENT,
    },
    completed_instance: {
      status: QuestStatus.COMPLETED,
      completion_notes: "This was really fun to do!",
      completion_photo_url:
        "https://images.custommade.com/b9hP6IwLgGZsab-fnJimHDoJokQ=/fit-in/510x382/custommade-photosets/78007/78007.226090.jpg",
      reward: 10,
      date: "2024-02-05",
      verification_notes: "I'm proud of you",
    },
  },
  {
    id: 3629611,
    title: "Draw a map of your house (then show mom / dad)",
    difficulty: QuestDifficulty.EASY,
    routine: QuestRoutine.AFTERNOON,
    deleted: false,
    start_date: "2024-01-17",
    date_updated: "2024-01-17T19:30:59.520171Z",
    redeemable_reward: 10,
    skill: SkillKey.KNOWLEDGE,
    assigner_profile: {
      user_id: 640817,
      nickname: "Mom",
      role: UserRole.PARENT,
    },
    completed_instance: null,
  },
  {
    id: 3789912,
    title: "Clean your room",
    difficulty: QuestDifficulty.EASY,
    routine: QuestRoutine.ANYTIME,
    deleted: false,
    start_date: "2024-02-05",
    date_updated: "2024-02-05T16:34:50.559703Z",
    redeemable_reward: 10,
    skill: SkillKey.FOCUS,
    assigner_profile: {
      user_id: 640817,
      nickname: "Mom",
      role: UserRole.PARENT,
    },
    completed_instance: {
      status: QuestStatus.COMPLETED,
      completion_notes: "I hate you mom I want to run away",
      completion_photo_url:
        "https://images.custommade.com/b9hP6IwLgGZsab-fnJimHDoJokQ=/fit-in/510x382/custommade-photosets/78007/78007.226090.jpg",
      reward: 10,
      date: "2024-02-05",
      verification_notes: "I'm proud of you",
    },
  },
  {
    id: 3792429,
    title: "Walk the dog",
    difficulty: QuestDifficulty.EASY,
    routine: QuestRoutine.ANYTIME,
    deleted: false,
    start_date: "2024-02-05",
    date_updated: "2024-02-05T22:02:41.044286Z",
    redeemable_reward: 10,
    skill: null,
    assigner_profile: {
      user_id: 640817,
      nickname: "Mom",
      role: UserRole.PARENT,
    },
    completed_instance: null,
  },
  {
    id: 3792429,
    title: "Write down what you want for your birthday",
    difficulty: QuestDifficulty.EASY,
    routine: QuestRoutine.ANYTIME,
    deleted: false,
    start_date: "2024-02-05",
    date_updated: "2024-02-05T22:02:41.044286Z",
    redeemable_reward: 10,
    skill: null,
    assigner_profile: {
      user_id: 640817,
      nickname: "Mom",
      role: UserRole.PARENT,
    },
    completed_instance: {
      status: QuestStatus.COMPLETED,
      completion_notes: "Lambo or Masarati",
      completion_photo_url:
        "https://images.custommade.com/b9hP6IwLgGZsab-fnJimHDoJokQ=/fit-in/510x382/custommade-photosets/78007/78007.226090.jpg",
      reward: 10,
      date: "2024-02-05",
      verification_notes: "I'm proud of you",
    },
  },
  {
    id: 3792429,
    title: "Make yourself breakfast",
    difficulty: QuestDifficulty.EASY,
    routine: QuestRoutine.ANYTIME,
    deleted: false,
    start_date: "2024-02-05",
    date_updated: "2024-02-05T22:02:41.044286Z",
    redeemable_reward: 10,
    skill: SkillKey.STRENGTH,
    assigner_profile: {
      user_id: 640817,
      nickname: "Mom",
      role: UserRole.PARENT,
    },
    completed_instance: {
      status: QuestStatus.COMPLETED,
      completion_notes: "I burnt down the kitchen",
      completion_photo_url:
        "https://images.custommade.com/b9hP6IwLgGZsab-fnJimHDoJokQ=/fit-in/510x382/custommade-photosets/78007/78007.226090.jpg",
      reward: 15,
      date: "2024-02-05",
      verification_notes: "I'm proud of you",
    },
  },
]

export const exampleParentTasks: QuestInstance[] = [
  {
    id: 1,
    series: {
      id: 1,
    } as QuestSeries,
    status: QuestStatus.VERIFIED,
    // @ts-ignore - need to change in type
    completion_date: dayjs().unix(),
    completion_notes:
      "We played with building blocks. My child loved leading the game, and I really enjoyed seeing how creative he could be without my input",
    completion_difficulty: 1,
    completion_photo_url: "https://via.placeholder.com/150",
    date: new Date().toISOString(),
    date_created: dayjs().unix(),
    date_updated: dayjs().unix(),
    user_id: 0,
  },
  {
    id: 2,
    series: {
      id: 2,
    } as QuestSeries,
    status: QuestStatus.OPEN,
    // @ts-ignore - need to change in type
    completion_date: dayjs().subtract(1, "day").unix(),
    completion_notes: null,
    completion_difficulty: 5,
    completion_photo_url: "https://via.placeholder.com/150",
    date: new Date().toISOString(),
    date_created: dayjs().unix(),
    date_updated: dayjs().unix(),
    user_id: 0,
  },
  {
    id: 3,
    series: {
      id: 3,
    } as QuestSeries,
    status: QuestStatus.REDEEMED,
    // @ts-ignore - need to change in type
    completion_date: dayjs().subtract(1, "day").unix(),
    completion_notes: null,
    completion_difficulty: 0,
    completion_photo_url: "https://via.placeholder.com/150",
    date: new Date().toISOString(),
    date_created: dayjs().unix(),
    date_updated: dayjs().unix(),
    user_id: 0,
  },
  {
    id: 4,
    series: {
      id: 1,
    } as QuestSeries,
    status: QuestStatus.VERIFIED,
    // @ts-ignore - need to change in type
    completion_date: dayjs().subtract(2, "day").unix(),
    completion_notes:
      "We spent the time drawing. It was amazing to just talk and laugh without any distractions. I felt we really connected.",
    completion_difficulty: 0,
    completion_photo_url: "https://via.placeholder.com/150",
    date: new Date().toISOString(),
    date_created: dayjs().unix(),
    date_updated: dayjs().unix(),
    user_id: 0,
  },
  {
    id: 5,
    series: {
      id: 1,
    } as QuestSeries,
    status: QuestStatus.VERIFIED,
    // @ts-ignore - need to change in type
    completion_date: dayjs().subtract(3, "day").unix(),
    completion_notes:
      "We played a board game. It was hard not to correct some of her moves, but I managed, and we had a good time",
    completion_difficulty: 3,
    completion_photo_url: "https://via.placeholder.com/150",
    date: new Date().toISOString(),
    date_created: dayjs().unix(),
    date_updated: dayjs().unix(),
    user_id: 0,
  },
  {
    id: 6,
    series: {
      id: 1,
    } as QuestSeries,
    status: QuestStatus.VERIFIED,
    // @ts-ignore - need to change in type
    completion_date: dayjs().subtract(6, "day").unix(),
    completion_notes:
      "We baked cookies, and it was difficult for me not to step in, especially with the oven involved. But he did great, and the cookies were delicious!",
    completion_difficulty: 4,
    completion_photo_url: "https://via.placeholder.com/150",
    date: new Date().toISOString(),
    date_created: dayjs().unix(),
    date_updated: dayjs().unix(),
    user_id: 0,
  },
  {
    id: 7,
    series: {
      id: 1,
    } as QuestSeries,
    status: QuestStatus.VERIFIED,
    // @ts-ignore - need to change in type
    completion_date: dayjs().subtract(7, "day").unix(),
    completion_notes:
      "We went for a walk. I found it hard not to give directions and just follow his lead instead. We ended up taking a longer route, but he was happy exploring",
    completion_difficulty: 5,
    completion_photo_url: "https://via.placeholder.com/150",
    date: new Date().toISOString(),
    date_created: dayjs().unix(),
    date_updated: dayjs().unix(),
    user_id: 0,
  },
  {
    id: 8,
    series: {
      id: 1,
    } as QuestSeries,
    status: QuestStatus.VERIFIED,
    // @ts-ignore - need to change in type
    completion_date: dayjs().subtract(11, "day").unix(),
    completion_notes:
      "We did some gardening. It was very relaxing to just be outdoors and chat while we worked. He was very proud to show me what he knows about plants.",
    completion_difficulty: 0,
    completion_photo_url: "https://via.placeholder.com/150",
    date: new Date().toISOString(),
    date_created: dayjs().unix(),
    date_updated: dayjs().unix(),
    user_id: 0,
  },
  {
    id: 9,
    series: {
      id: 1,
    } as QuestSeries,
    status: QuestStatus.VERIFIED,
    // @ts-ignore - need to change in type
    completion_date: dayjs().subtract(11, "day").unix(),
    completion_notes: null,
    completion_difficulty: 2,
    completion_photo_url: "https://via.placeholder.com/150",
    date: new Date().toISOString(),
    date_created: dayjs().unix(),
    date_updated: dayjs().unix(),
    user_id: 0,
  },
  {
    id: 10,
    series: {
      id: 2,
    } as QuestSeries,
    status: QuestStatus.VERIFIED,
    // @ts-ignore - need to change in type
    completion_date: dayjs().subtract(2, "day").unix(),
    completion_notes:
      "I made an effort to praise specific actions like cleaning up toys and getting ready for bed on time. It's becoming a more natural part of our interactions.",
    completion_difficulty: 1,
    completion_photo_url: "https://via.placeholder.com/150",
    date: new Date().toISOString(),
    date_created: dayjs().unix(),
    date_updated: dayjs().unix(),
    user_id: 0,
  },
  {
    id: 10,
    series: {
      id: 2,
    } as QuestSeries,
    status: QuestStatus.VERIFIED,
    // @ts-ignore - need to change in type
    completion_date: dayjs().subtract(3, "day").unix(),
    completion_notes:
      "I struggled to remember to do it every day, and sometimes it felt a bit forced. I need to work on making this feel more genuine.",
    completion_difficulty: 5,
    completion_photo_url: "https://via.placeholder.com/150",
    date: new Date().toISOString(),
    date_created: dayjs().unix(),
    date_updated: dayjs().unix(),
    user_id: 0,
  },
  {
    id: 11,
    series: {
      id: 2,
    } as QuestSeries,
    status: QuestStatus.VERIFIED,
    // @ts-ignore - need to change in type
    completion_date: dayjs().subtract(6, "day").unix(),
    completion_notes:
      "I consistently praised his efforts in cleaning up after play and being polite at dinner. It's becoming a joyful part of our day.",
    completion_difficulty: 0,
    completion_photo_url: "https://via.placeholder.com/150",
    date: new Date().toISOString(),
    date_updated: dayjs().unix(),
    user_id: 0,
  },
]

// @ts-ignore
export const exampleParentTaskSeries = [
  {
    id: 1,
    title: "Engage in 1:1 time with your child daily",
    description:
      "Spend 10-15 minutes of uninterrupted time with your child engaging in an activity of their choice. While you are interacting with them during this activity, avoid giving any commands, correcting them, or providing excessive feedback. The goal is to have fun with your kid!",
    user_id: 0,
    date_created: dayjs().subtract(14, "day").unix(),
    type: QuestType.REPEATING,
  },
  {
    id: 2,
    title: "Use positive labeled praise 4x per week",
    description:
      'Use positive labeled praise by acknowledging each time your child does something praiseworthy, like putting dishes away or washing hands. Promptly praise them, e.g., "Great job for putting your dishes in the sink. I appreciate your independence." This encourages more such behavior.',
    user_id: 0,
    date_created: dayjs().subtract(14, "day").unix(),
    type: QuestType.REPEATING,
  },
  {
    id: 3,
    title: 'Complete "Giving Effective Commands" worksheet',
    description:
      "Complete the attached worksheet that describes some of the themes we discussed in session today. Feel free to refer back to this throughout the week to remind yourself how best to practice this skill.",
    date_created: dayjs().subtract(7, "day").unix(),
    user_id: 0,
  },
] as QuestSeries[]

export const exampleTargetBehaviors: TargetBehavior[] = [
  {
    id: 1,
    title: "Throwing a temper tantrum when told to do homework",
    description: "Eating more than the recommended portion size",
    creator: null,
    user: {
      id: 0,
    },
    date_created: dayjs().subtract(14, "day").format("YYYY-MM-DD"),
    preventive_strategies: [
      {
        id: 1,
        title: "Use a forced-choice option for homework completion",
      },
      {
        id: 2,
        title:
          "Use praise for any behavior exhibited towards homework completion (e.g., picking up pencil; writing on homework)",
      },
      {
        id: 3,
        title:
          "Set a timer and provide 5 minute breaks as reward for working through full duration of timer",
      },
    ],
    status: TargetBehaviorStatus.BASELINING,
    recent_7D_incident_count: 3,
    previous_7D_incident_count: 4,
    used_in_incident_logs: true,
    incident_log_data_type: IncidentLogDataType.DURATION,
  },
  {
    id: 2,
    title: "Refusing to get dressed in the morning",
    description: "Hitting others when upset",
    creator: null,
    user: {
      id: 0,
    },
    date_created: dayjs().subtract(14, "day").format("YYYY-MM-DD"),
    preventive_strategies: [
      {
        id: 4,
        title:
          "Use a transitional warning (e.g., in 5 minutes it will be time to put on your clothes)",
      },
      {
        id: 5,
        title:
          "Use visual checklist that child checks off as they complete each item of the morning routine (e.g., brush teeth, get dressed)",
      },
      {
        id: 6,
        title:
          "Use behavior chaining to gradually encourage child to get dressed independently",
      },
    ],
    status: TargetBehaviorStatus.IMPROVING,
    recent_7D_incident_count: 1,
    previous_7D_incident_count: 2,
    used_in_incident_logs: true,
    incident_log_data_type: IncidentLogDataType.DURATION,
  },
  {
    id: 3,
    title: "Aggression towards siblings",
    description: "Not following directions when asked",
    creator: null,
    user: {
      id: 0,
    },
    date_created: dayjs().subtract(14, "day").format("YYYY-MM-DD"),
    preventive_strategies: [
      {
        id: 7,
        title:
          "Reinforce use of alternative behaviors (e.g., praising child when they seek help from an adult instead of hitting)",
      },
      {
        id: 8,
        title:
          "Reinforce use of alternative behaviors (e.g., praising child when they seek help from an adult instead of hitting)",
      },
    ],
    status: TargetBehaviorStatus.MASTERED,
    recent_7D_incident_count: 2,
    previous_7D_incident_count: 3,
    used_in_incident_logs: true,
    incident_log_data_type: IncidentLogDataType.DURATION,
  },
]

export const exampleStrategies = [
  {
    id: 1,
    title: "Use a forced-choice option for homework completion",
  },
  {
    id: 2,
    title:
      "Use praise for any behavior exhibited towards homework completion (e.g., picking up pencil; writing on homework)",
  },
  {
    id: 3,
    title:
      "Set a timer and provide 5 minute breaks as reward for working through full duration of timer",
  },
  {
    id: 4,
    title:
      "Use a transitional warning (e.g., in 5 minutes it will be time to put on your clothes)",
  },
  {
    id: 5,
    title:
      "Use visual checklist that child checks off as they complete each item of the morning routine (e.g., brush teeth, get dressed)",
  },
  {
    id: 6,
    title:
      "Use behavior chaining to gradually encourage child to get dressed independently",
  },
  {
    id: 7,
    title:
      "Reinforce use of alternative behaviors (e.g., praising child when they seek help from an adult instead of hitting)",
  },
  {
    id: 8,
    title:
      "Time out implemented following each instance of physical aggression",
  },
]

export const exampleIncidents: Incident[] = [
  {
    date_created: "2024-02-05",
    duration: null,
    id: 1,
    incident_datetime: "2024-02-05T12:00:00Z",
    intensity: 5,
    response_intensity: 0,
    target_behavior: exampleTargetBehaviors[0],
    preventive_strategy: exampleStrategies[0],
    freeform_preventive_strategy: null,
    freeform_target_behavior: null,
    reporter: {
      id: 0,
      name: "Renee",
      last_name: "L",
    },
    user: {
      id: 0,
      name: "Kevin",
      last_name: "L",
    },
    reflection: null,
    frequency: null,
    accuracy_successes: null,
    accuracy_attempts: null,
    antecedent: null,
    consequence: null,
    training_data_collection_task: null,
    date_read: null,
  },
  {
    date_created: "2024-02-05",
    duration: null,
    id: 2,
    incident_datetime: "2024-02-05T12:00:00Z",
    intensity: 5,
    response_intensity: 3,
    target_behavior: exampleTargetBehaviors[0],
    preventive_strategy: exampleStrategies[1],
    freeform_preventive_strategy: null,
    freeform_target_behavior: null,
    reporter: {
      id: 0,
      name: "Renee",
      last_name: "L",
    },
    user: {
      id: 0,
      name: "Kevin",
      last_name: "L",
    },
    reflection: null,
    frequency: null,
    accuracy_successes: null,
    accuracy_attempts: null,
    antecedent: null,
    consequence: null,
    training_data_collection_task: null,
    date_read: null,
  },
  {
    date_created: "2024-02-05",
    duration: null,
    id: 3,
    incident_datetime: "2024-02-05T12:00:00Z",
    intensity: 5,
    response_intensity: 6,
    target_behavior: exampleTargetBehaviors[0],
    preventive_strategy: exampleStrategies[2],
    freeform_preventive_strategy: null,
    freeform_target_behavior: null,
    reporter: {
      id: 0,
      name: "Renee",
      last_name: "L",
    },
    user: {
      id: 0,
      name: "Kevin",
      last_name: "L",
    },
    reflection: null,
    frequency: null,
    accuracy_successes: null,
    accuracy_attempts: null,
    antecedent: null,
    consequence: null,
    training_data_collection_task: null,
    date_read: null,
  },
  {
    date_created: "2024-02-05",
    duration: null,
    id: 4,
    incident_datetime: "2024-02-05T12:00:00Z",
    intensity: 6,
    response_intensity: 3,
    target_behavior: exampleTargetBehaviors[1],
    preventive_strategy: exampleStrategies[3],
    freeform_preventive_strategy: null,
    freeform_target_behavior: null,
    reporter: {
      id: 0,
      name: "Renee",
      last_name: "L",
    },
    user: {
      id: 0,
      name: "Kevin",
      last_name: "L",
    },
    reflection: null,
    frequency: null,
    accuracy_successes: null,
    accuracy_attempts: null,
    antecedent: null,
    consequence: null,
    training_data_collection_task: null,
    date_read: null,
  },
  {
    date_created: "2024-02-05",
    duration: null,
    id: 5,
    incident_datetime: "2024-02-05T12:00:00Z",
    intensity: 3,
    response_intensity: 2,
    target_behavior: exampleTargetBehaviors[1],
    preventive_strategy: exampleStrategies[4],
    freeform_preventive_strategy: null,
    freeform_target_behavior: null,
    reporter: {
      id: 0,
      name: "Renee",
      last_name: "L",
    },
    user: {
      id: 0,
      name: "Kevin",
      last_name: "L",
    },
    reflection: null,
    frequency: null,
    accuracy_successes: null,
    accuracy_attempts: null,
    antecedent: null,
    consequence: null,
    training_data_collection_task: null,
    date_read: null,
  },
  {
    date_created: "2024-02-05",
    duration: null,
    id: 6,
    incident_datetime: "2024-02-05T12:00:00Z",
    intensity: 3,
    response_intensity: 2,
    target_behavior: exampleTargetBehaviors[1],
    preventive_strategy: exampleStrategies[5],
    freeform_preventive_strategy: null,
    freeform_target_behavior: null,
    reporter: {
      id: 0,
      name: "Renee",
      last_name: "L",
    },
    user: {
      id: 0,
      name: "Kevin",
      last_name: "L",
    },
    reflection: null,
    frequency: null,
    accuracy_successes: null,
    accuracy_attempts: null,
    antecedent: null,
    consequence: null,
    training_data_collection_task: null,
    date_read: null,
  },
  {
    date_created: "2024-02-05",
    duration: null,
    id: 7,
    incident_datetime: "2024-02-05T12:00:00Z",
    intensity: 3,
    response_intensity: 2,
    target_behavior: exampleTargetBehaviors[2],
    preventive_strategy: exampleStrategies[6],
    freeform_preventive_strategy: null,
    freeform_target_behavior: null,
    reporter: {
      id: 0,
      name: "Renee",
      last_name: "L",
    },
    user: {
      id: 0,
      name: "Kevin",
      last_name: "L",
    },
    reflection: null,
    frequency: null,
    accuracy_successes: null,
    accuracy_attempts: null,
    antecedent: null,
    consequence: null,
    training_data_collection_task: null,
    date_read: null,
  },
  {
    date_created: "2024-02-05",
    duration: null,
    id: 8,
    incident_datetime: "2024-02-05T12:00:00Z",
    intensity: 3,
    response_intensity: 2,
    target_behavior: exampleTargetBehaviors[2],
    preventive_strategy: exampleStrategies[7],
    freeform_preventive_strategy: null,
    freeform_target_behavior: null,
    reporter: {
      id: 0,
      name: "Renee",
      last_name: "L",
    },
    user: {
      id: 0,
      name: "Kevin",
      last_name: "L",
    },
    reflection: null,
    frequency: null,
    accuracy_successes: null,
    accuracy_attempts: null,
    antecedent: null,
    consequence: null,
    training_data_collection_task: null,
    date_read: null,
  },
]

export const examplePatientFamily: Family = {
  date_created: dayjs().subtract(100, "day").unix(),
  id: 1,
  legacy_family_id: null,
  subscriber_id: null,
  profiles: [examplePatientData, exampleParentProfile],
  type: GroupType.FAMILY,
}

const exampleModuleAssignments1: LearningModuleAssignment[] = [
  {
    id: 793,
    learning_module: {
      id: 262,
      type: ModuleType.VIDEO,
      title: "ABC's of ABA (7 min)",
      description:
        "Explains the Antecedent-Behavior-Consequence (ABC) model in ABA therapy. Covers how to observe and understand what causes a child's behavior, and how to collect ABC data.\r\n\r\nOriginal Content by: Behavior Nation, https://www.youtube.com/@BehaviorNation",
      date_created: "2024-07-30T02:41:23.488052Z",
      num_favorited: 0,
      num_assigned: 2,
      favorite: false,
      // @ts-ignore
      creator: null,
      tags: [
        { id: 40, title: "Target behaviors" },
        { id: 44, title: "ABCs" },
        { id: 46, title: "ABA Introduction" },
        { id: 47, title: "Strategies for parent" },
      ],
      video_url: "https://www.youtube.com/watch?v=KzsCHDLelYk",
    },
    assignment_completion: {
      id: 859,
      date_completed: "2024-09-01",
      date_assigned: "2024-7-30",
      date_reviewed: null,
    },
  },
  {
    id: 794,
    learning_module: {
      id: 252,
      type: ModuleType.READING_TEXTS,
      title: "Understanding the ABC Model",
      description:
        "An introduction to the Antecedent-Behavior-Consequence model for parents.",
      date_created: "2024-07-30T01:35:46.081683Z",
      num_favorited: 0,
      num_assigned: 1,
      favorite: false,
      // @ts-ignore
      creator: null,
      tags: [
        { id: 40, title: "Target behaviors" },
        { id: 44, title: "ABCs" },
        { id: 46, title: "ABA Introduction" },
        { id: 47, title: "Strategies for parent" },
      ],
      reading_texts: [
        {
          header: "What is the ABC Model?",
          body: "The ABC Model is a simple but powerful tool to understand your child's behavior. ABC stands for Antecedent, Behavior, and Consequence. By breaking down situations into these three parts, you can better understand why certain behaviors happen and how to change them.",
        },
        {
          header: "Breaking Down the ABC Model",
          body: "Antecedent: This is what happens right before the behavior. It could be a request, a change in the environment, or even a thought or feeling.\r\n\r\nBehavior: This is the actual action or reaction we see. It's what your child does in response to the antecedent.\r\n\r\nConsequence: This is what happens immediately after the behavior. It can either encourage the behavior to happen again or discourage it.",
        },
        {
          header: "Using the ABC Model at Home",
          body: "By identifying these three components in your daily interactions with your child, you can start to see patterns. For example, if your child throws a tantrum (behavior) when asked to turn off the TV (antecedent), and you let them watch for five more minutes (consequence), your child learns that tantrums lead to more TV time.\r\n\r\nUsing this knowledge, you can make changes to either the antecedents or consequences to encourage positive behaviors and discourage negative ones. Remember, consistency is key when applying this model to change behavior.",
        },
      ],
    },
    assignment_completion: {
      id: 860,
      date_completed: "2024-09-01",
      date_assigned: "2024-07-30",
      date_reviewed: null,
    },
  },
  {
    id: 795,
    learning_module: {
      id: 232,
      type: ModuleType.MULTIPLE_CHOICE,
      title: "Understanding the ABC Model Quiz",
      description:
        "Test your understanding of the Antecedent-Behavior-Consequence model.",
      date_created: "2024-07-30T01:26:56.759789Z",
      num_favorited: 0,
      num_assigned: 2,
      favorite: false,
      // @ts-ignore
      creator: null,
      tags: [
        { id: 40, title: "Target behaviors" },
        { id: 44, title: "ABCs" },
        { id: 46, title: "ABA Introduction" },
        { id: 47, title: "Strategies for parent" },
      ],
      multiple_choice_questions: [
        {
          id: 34,
          text: "What does the 'A' in the ABC Model stand for?",
          options: [
            { id: 34, text: "Action", is_correct: false },
            { id: 35, text: "Antecedent", is_correct: true },
            { id: 36, text: "Aftermath", is_correct: false },
            { id: 37, text: "Analysis", is_correct: false },
          ],
        },
        {
          id: 35,
          text: "Which of the following is an example of a consequence in the ABC Model?",
          options: [
            {
              id: 38,
              text: "A child is asked to clean their room",
              is_correct: false,
            },
            { id: 39, text: "A child throws a toy", is_correct: false },
            {
              id: 40,
              text: "A parent gives extra TV time after the child cleans their room",
              is_correct: true,
            },
            {
              id: 41,
              text: "A child sees their favorite toy",
              is_correct: false,
            },
          ],
        },
      ],
    },
    assignment_completion: {
      id: 861,
      date_completed: "2024-09-01",
      multiple_choice_answers: [
        { id: 0, question_id: 34, answer_id: 35, is_correct: true },
        { id: 1, question_id: 35, answer_id: 41, is_correct: false },
      ],
      date_assigned: "2024-07-30",
      date_reviewed: null,
    },
  },
  {
    id: 796,
    learning_module: {
      id: 242,
      type: ModuleType.SHORT_ANSWERS,
      title: "ABC Model Application",
      description:
        "Short answer questions to help parents apply the ABC Model to their child's behavior.",
      date_created: "2024-07-30T01:28:15.186841Z",
      num_favorited: 0,
      num_assigned: 1,
      favorite: false,
      // @ts-ignore
      creator: null,
      tags: [
        { id: 40, title: "Target behaviors" },
        { id: 44, title: "ABCs" },
        { id: 46, title: "ABA Introduction" },
        { id: 47, title: "Strategies for parent" },
      ],
      short_answer_questions: [
        {
          id: 34,
          text: "Describe a recent challenging behavior your child exhibited. What was the antecedent (what happened right before), the behavior itself, and the consequence (what happened right after)?",
          min_answer_length: 1,
          max_answer_length: 255,
        },
        {
          id: 35,
          text: "Based on your ABC analysis, what change could you make to either the antecedent or consequence to encourage a more positive behavior?",
          min_answer_length: 1,
          max_answer_length: 255,
        },
      ],
    },
    assignment_completion: {
      id: 862,
      date_completed: null,
      short_answer_answers: [],
      date_assigned: "2024-07-30",
      date_reviewed: null,
    },
  },
]

export const exampleParentTrainings: ParentTraining[] = [
  {
    id: 1,
    title: "ABC Model of Behavior",
    status: ParentTrainingStatus.IN_PROGRESS,
    mastery_criteria:
      "Parent correctly identifies the antecedent of child's behavior on 80% of opportunities across 3 consecutive sessions.",
    mastery_criteria_measurement: MasteryCriteriaMeasurement.PERCENTAGE,
    user: exampleParentUser,
    creator: {
      id: 0,
      name: "Renee",
    } as User,
    date_created: "2024-02-05",
    quests: [exampleParentTaskSeries[0]],
    assignments: exampleModuleAssignments1,
    child: exampleChildUser,
  },
]
