import {
  Button,
  FlexBox,
  JoonUIColor,
  SPACING,
  TextInput,
  Typography,
} from "@joonapp/web-shared"
import { Alert, Box } from "@mui/material"
import React, { useState } from "react"
import { useNavigate } from "react-router-dom"

import AuthFooter from "./AuthFooter"
import GoogleAuth from "./GoogleAuth"
import { signin } from "../../networking/authentication"
import { AlertInterface } from "../../types"
import { sessionManager } from "../../util/storage"
import { createErrorFromResponse } from "../../util/util"

const SignIn = () => {
  const [formAlert, setFormAlert] = useState<AlertInterface | null>(null)
  const [pending, setPending] = useState(false)
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")

  const navigate = useNavigate()

  const hasFormError = (response: any) => {
    const errorMessage = response.error ?? response.data?.error
    if (errorMessage) {
      setPending(false)
      setFormAlert({ type: "error", message: errorMessage })
      return true
    } else {
      setFormAlert(null)
      return false
    }
  }

  function signIn(e: React.FormEvent) {
    setPending(true)
    e.preventDefault()
    signin(email, password)
      .then((response: any) => {
        if (hasFormError(response)) return

        sessionManager.storeTokens(
          response.data.refresh_token,
          response.data.id_token
        )
        setPending(false)
        navigate("/patients")
      })
      .catch((err) => {
        const errorMessage = createErrorFromResponse(err)
        if (errorMessage) setFormAlert({ type: "error", message: errorMessage })
        setPending(false)
      })
  }

  return (
    <FlexBox direction="column" gap={SPACING.space6} align="center">
      <Typography variant="h3">Welcome Back</Typography>
      {formAlert && (
        <Box mb={3}>
          <Alert severity={formAlert.type}>{formAlert.message}</Alert>
        </Box>
      )}

      <form onSubmit={signIn} style={{ width: "100%" }}>
        <FlexBox gap={SPACING.space4} direction="column">
          <TextInput
            type="email"
            label="Email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            icon="email"
            fullWidth={true}
          />

          <TextInput
            type="password"
            icon="password"
            placeholder="Password"
            label="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            name="pass"
            fullWidth={true}
          />

          <Button
            isLoading={pending}
            text="Sign In"
            fullWidth
            type="submit"
            style={{ marginTop: SPACING.space2 }}
          />
        </FlexBox>
      </form>

      <Typography
        textAlign="center"
        variant="bodySmall"
        color={JoonUIColor.text.secondary}
      >
        OR
      </Typography>
      <GoogleAuth type="signin" setFormAlert={setFormAlert} />

      <AuthFooter type="signin" />
    </FlexBox>
  )
}

export default SignIn
