import { JoonUIColor, SPACING, Typography } from "@joonapp/web-shared"
import { useState } from "react"

export const ActionButton = ({ text, icon, onClick }: any) => {
  const [isHovered, setIsHovered] = useState(false)

  return (
    <button
      style={{
        border: `1px solid ${JoonUIColor.border.default}`,
        background: isHovered
          ? JoonUIColor.background.lightGray
          : JoonUIColor.background.primaryNeutral,
        padding: `${SPACING.space1} ${SPACING.space2}`,
        borderRadius: SPACING.space2,
        display: "flex",
        gap: SPACING.space2,
        alignItems: "center",
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={onClick}
    >
      {icon}
      <Typography variant="bodySmall" color={JoonUIColor.text.secondary}>
        {text}
      </Typography>
    </button>
  )
}
