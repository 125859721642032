import { useMutation } from "@tanstack/react-query"

import { sendTrainingReminder } from "../../../../networking/parentTraining"
import { QUERY_KEYS } from "../../../../networking/queries"
import { queryClient } from "../../../../queryClient"

export const useSendTrainingReminderMutation = () => {
  return useMutation({
    mutationFn: sendTrainingReminder,
    onMutate: ({ trainingId, type }) => {
      queryClient.setQueryData(
        [QUERY_KEYS.PARENT_TRAINING_REMINDERS_ALLOWED, trainingId, type],
        true
      )
    },
  })
}
