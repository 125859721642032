import { JoonUIColor, SPACING } from "@joonapp/web-shared"
import { flexRender, HeaderGroup } from "@tanstack/react-table"

const CustomTableHeader = <TData,>({
  headerGroup,
}: {
  headerGroup: HeaderGroup<TData>
}) => {
  return (
    <tr
      key={headerGroup.id}
      style={{
        textAlign: "left",
        borderBottom: `1px solid ${JoonUIColor.background.disabled}`,
      }}
    >
      {headerGroup.headers.map((header) => (
        <th
          key={header.id}
          style={{
            padding: `${SPACING.space4} ${SPACING.space2} ${SPACING.space2}`,
          }}
        >
          {header.isPlaceholder
            ? null
            : flexRender(header.column.columnDef.header, header.getContext())}
        </th>
      ))}
    </tr>
  )
}

export default CustomTableHeader
