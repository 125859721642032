import {
  FrequencyTargetPeriod,
  QuestInstance,
  QuestSeries,
  UserRole,
} from "@joonapp/web-shared"
import dayjs from "dayjs"

import {
  DataFormat,
  QuestSeriesWithTargets,
} from "../pages/patientInfo/homeRoutines/overTimeProgress/data"
import { createJoonAPIClient } from "../util/joon-api"

export const getQuestById = (id: number) => {
  const API = createJoonAPIClient()
  return API.get<QuestSeriesWithTargets>(`api/quest-series/${id}/`)
}

export async function addQuest({
  userId,
  questInfo,
}: {
  userId?: number
  questInfo: any
}) {
  const API = createJoonAPIClient()
  return API.post(`api/quest-series/`, {
    user_id: userId,
    role: UserRole.THERAPIST,
    ...questInfo,
  })
}

export async function editQuest({
  seriesId,
  questInfo,
}: {
  seriesId: number
  questInfo: any
}) {
  const API = createJoonAPIClient()

  return API.put(`api/quest-series/${seriesId}/`, {
    role: UserRole.THERAPIST,
    ...questInfo,
  })
}

export async function deleteQuest(questId: number) {
  const joonApiClient = createJoonAPIClient()
  return joonApiClient.delete(`api/quest-series/${questId}/`, {
    params: { role: UserRole.THERAPIST },
  })
}

export const getRoutineStats = ({ childId, dateRange }: any) => {
  const API = createJoonAPIClient()
  return API.get<DataFormat>("api/quest-instances/routine-stats/", {
    params: {
      user_id: childId,
      start_date: dayjs(dateRange.startDate).format("YYYY-MM-DD"),
      end_date: dayjs(dateRange.endDate).format("YYYY-MM-DD"),
      role: UserRole.THERAPIST,
    },
  })
}

export const updateTarget = ({
  seriesId,
  target,
}: {
  seriesId: number
  target: {
    frequency: number
    period: FrequencyTargetPeriod.SEVEN_DAYS
    role: UserRole
  }
}) => {
  const API = createJoonAPIClient()
  return API.post(`api/quest-series/${seriesId}/frequency-target/`, target)
}

export const deleteTarget = (seriesId: number) => {
  const API = createJoonAPIClient()
  return API.delete(`api/quest-series/${seriesId}/frequency-target/`)
}

export const getQuestLog = async ({
  seriesId,
}: {
  seriesId: number
}): Promise<QuestInstance[]> => {
  const API = createJoonAPIClient()
  return API.get(`api/quest-instances/`, {
    params: {
      role: UserRole.THERAPIST,
      series_id: seriesId,
      max_date: dayjs().format("YYYY-MM-DD"),
    },
  }).then((res) => res.data.results)
}

export async function verifyQuest({ instanceId }: { instanceId: number }) {
  const joonApiClient = createJoonAPIClient()
  return joonApiClient.post(`api/quest-instances/${instanceId}/verify/`, {
    role: UserRole.THERAPIST,
  })
}

export const getParentTasks = async (
  userIds: number[]
): Promise<QuestSeries[]> => {
  const API = createJoonAPIClient()
  const getTaskPromises = userIds.map((userId) =>
    API.get(`api/quest-series/`, {
      params: { user_id: userId, role: UserRole.THERAPIST },
    }).then((res) => res.data.results)
  )
  return Promise.all(getTaskPromises)
}

export const getParentTaskInstances = async ({
  userIds,
  seriesId,
  minDate,
  maxDate,
  status,
}: {
  userIds: number[]
  seriesId?: number
  minDate?: string
  maxDate?: string
  status?: string
}) => {
  const API = createJoonAPIClient()
  const parentTaskInstancePromises = userIds.map((userId) =>
    API.get("api/quest-instances/", {
      params: {
        user_id: userId,
        series_id: seriesId,
        min_date: minDate,
        max_date: maxDate,
        role: UserRole.THERAPIST,
        status,
      },
    }).then((res) => res.data.results)
  )
  return Promise.all(parentTaskInstancePromises)
}
