import { setDeviceId } from "@amplitude/analytics-browser"
import { useEffect } from "react"
import { useLocation, useSearchParams } from "react-router-dom"

import useSubscription from "../components/subscription/useSubscription"
import { createToast } from "../components/toast/Toast"
import { isVercelDeployment } from "../constants"
import { usePracticeQuery, useUserQuery } from "../networking/queries"
import { SubscriptionStatus, ToastType } from "../types"
import {
  ANALYTIC_EVENTS,
  FBPixelEvent,
  GaEvent,
  analyticsUserDidLogin,
  trackAnalyticEvent,
  trackGAEvent,
  useFBPixelEvent,
} from "../util/analytics"
import { localStorageItems } from "../util/storage"

export const useAnalytics = () => {
  const location = useLocation()
  const { user, userStatus } = useUserQuery()
  const { practice, practiceStatus } = usePracticeQuery()
  const [searchParams] = useSearchParams()
  const { subscriptionInfo } = useSubscription()
  const { trackFBPixelEvent } = useFBPixelEvent()
  const deviceId = searchParams.get("deviceId")
  const stripeSuccess = searchParams.get("stripe_success")

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      // @ts-ignore
      const navType = event.currentTarget?.performance?.navigation?.type
      if (navType === 0) trackAnalyticEvent(ANALYTIC_EVENTS.page_close)
      else if (navType === 1) trackAnalyticEvent(ANALYTIC_EVENTS.page_refresh)
      else if (navType === 2)
        trackAnalyticEvent(ANALYTIC_EVENTS.page_back_forward)
    }

    // Add the event listener
    window.addEventListener("beforeunload", handleBeforeUnload)

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload)
    }
  }, [])

  useEffect(() => {
    if (!deviceId) return
    setDeviceId(deviceId)
    // masking the url if user is not logged in
    // because otherwise it will already redirect
    if (!user)
      window.history.replaceState(null, "Joon - Welcome", location.pathname)
  }, [deviceId, user, location])

  useEffect(() => {
    if (import.meta.env.DEV || isVercelDeployment) return
    trackAnalyticEvent("page:shown", { page: location.pathname })
  }, [location.pathname])

  useEffect(() => {
    if (userStatus === "success" && practiceStatus === "success") {
      if (!user || !practice) return
      const parentId = user.id
      const practiceId = practice.id

      analyticsUserDidLogin(parentId, practiceId)
    }
  }, [practiceStatus, userStatus, user, practice])

  // track complet registration event after signup
  useEffect(() => {
    const needsToFireRegistrationEvent = !!localStorage.getItem(
      localStorageItems.needsToFireRegistrationEvent
    )
    if (!needsToFireRegistrationEvent || !user) return
    trackFBPixelEvent(FBPixelEvent.CompleteRegistration, {
      email: user.email,
      id: user.id,
    })
    trackGAEvent(GaEvent.CREATE_ACCOUNT)
    localStorage.removeItem(localStorageItems.needsToFireRegistrationEvent)
  }, [user, trackFBPixelEvent])

  useEffect(() => {
    if (!stripeSuccess) return
    if (!subscriptionInfo) return

    if (stripeSuccess === "true") {
      if (subscriptionInfo.status === SubscriptionStatus.TRIAL) {
        trackAnalyticEvent(ANALYTIC_EVENTS.trial_started)
        trackGAEvent(GaEvent.START_TRIAL)
        trackFBPixelEvent(FBPixelEvent.START_TRIAL)
        createToast({
          type: ToastType.SUCCESS,
          title: "Your 14-day trial has started",
        })
      } else if (subscriptionInfo.status === SubscriptionStatus.PAYING) {
        trackAnalyticEvent(ANALYTIC_EVENTS.purchase_completed)
        trackFBPixelEvent(FBPixelEvent.PURCHASE)
        createToast({
          type: ToastType.SUCCESS,
          title: "Subscription updated",
        })
      }
    } else {
      trackAnalyticEvent(ANALYTIC_EVENTS.purchase_cancelled)
    }
    window.history.replaceState(null, "", location.pathname)
  }, [
    practiceStatus,
    practice,
    subscriptionInfo,
    stripeSuccess,
    location.pathname,
    trackFBPixelEvent,
  ])
}
