import {
  CheckIcon,
  FlexBox,
  JoonUIColor,
  Loader,
  PaperPlaneIcon,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import { useMediaQuery } from "@mui/material"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import dayjs from "dayjs"
import { useState } from "react"

import ButtonSmall from "../../../components/buttons/ButtonSmall"
import { createToast } from "../../../components/toast/Toast"
import { sendInviteReminder } from "../../../networking/invitations"
import { QUERY_KEYS } from "../../../networking/queries"
import { InvitationGroup, ToastType } from "../../../types"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../util/analytics"

const SendReminderButton = ({
  invitationGroup,
}: {
  invitationGroup: InvitationGroup
}) => {
  const reminderSentInPastDay = dayjs(
    invitationGroup[0].last_reminder_sent
  ).isAfter(dayjs().subtract(1, "day"))

  const [reminderSent, setReminderSent] = useState(reminderSentInPastDay)
  const queryClient = useQueryClient()
  const sendReminderMutation = useMutation(sendInviteReminder)
  const isMobile = useMediaQuery("(max-width: 800px)")

  const wasInvitedInPastDay = dayjs(invitationGroup[0].date_created).isAfter(
    dayjs().subtract(1, "day")
  )

  const onClickSendReminder = async () => {
    if (reminderSent || wasInvitedInPastDay) return
    try {
      await sendReminderMutation.mutateAsync(invitationGroup[0].id)
      queryClient.invalidateQueries([QUERY_KEYS.SENT_INVITES])
      trackAnalyticEvent(ANALYTIC_EVENTS.send_outgoing_invite_reminder)
      setReminderSent(true)
    } catch (e) {
      createToast({
        title: "Failed to send reminder",
        type: ToastType.ERROR,
      })
      throw new Error("Failed to send reminder")
    }
  }

  if (wasInvitedInPastDay) return null

  return (
    <ButtonSmall onClick={onClickSendReminder}>
      <FlexBox direction="row" gap={SPACING.space2} wrap={false} align="center">
        <div style={{ width: 16 }}>
          <div
            style={{
              width: reminderSent ? "14px" : 0,
              transform: reminderSent ? "scale(1)" : "scale(0)",
              height: reminderSent ? "14px" : 0,
              transition: "transform 0.3s",
            }}
          >
            <CheckIcon size={16} color={JoonUIColor.semantic.success} />
          </div>
          {sendReminderMutation.isLoading ? (
            <Loader size={16} color={JoonUIColor.icon.neutral} thickness={2} />
          ) : (
            !reminderSent && (
              <PaperPlaneIcon
                size={12}
                color={JoonUIColor.text.primaryAccent}
              />
            )
          )}
        </div>
        <Typography variant="bodySmall">
          {reminderSent
            ? isMobile
              ? "Sent"
              : "Reminder sent!"
            : isMobile
            ? "Remind"
            : "Send reminder"}
        </Typography>
      </FlexBox>
    </ButtonSmall>
  )
}

export default SendReminderButton
