import { useMediaQuery } from "@mui/material"
import { useQueryClient } from "@tanstack/react-query"
import { useState } from "react"

import { QUERY_KEYS } from "../../networking/queries"
import { FamilyMemberProfile } from "../../types"
import "react-loading-skeleton/dist/skeleton.css"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"
import { createJoonAPIClient } from "../../util/joon-api"
import { Reminder, ReminderButton } from "../reminder/Reminder"

enum ReminderType {
  ONBOARD_INSTRUCTIONS = "onboard_instructions",
  USE_JOONITY = "use_joonity",
  COMPLETE_PARENT_TASKS = "complete_parent_tasks",
}

enum AlertNotificationType {
  NO_RECENT_LOGINS = "no_recent_logins",
}

const PatientAlert = ({ patient }: { patient: FamilyMemberProfile }) => {
  // @ts-ignore
  const usageNotification = (patient.user.usage_notification || {}) as {
    reminder_type: ReminderType
    type: AlertNotificationType
  }

  const [reminderSent, setReminderSent] = useState(
    !usageNotification.reminder_type
  )
  const isExamplePatient = !patient.user.id
  const queryClient = useQueryClient()

  const isMobile = useMediaQuery("(max-width:800px)")

  const sendReminder = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    queryClient.cancelQueries([QUERY_KEYS.PATIENT_GROUPS])
    const API = createJoonAPIClient()
    setReminderSent(true)
    await API.post("api/reminders/", {
      reminded_user_id: patient.user.id,
      type: usageNotification.reminder_type,
    })
    trackAnalyticEvent(ANALYTIC_EVENTS.send_reminder, {
      type: usageNotification.type,
    })
    queryClient.invalidateQueries([QUERY_KEYS.PATIENT_GROUPS])
  }

  if (isExamplePatient) return null

  switch (usageNotification.type) {
    case AlertNotificationType.NO_RECENT_LOGINS:
      return (
        <Reminder
          text={
            isMobile
              ? "No activity (last 7D)"
              : "No parent activity in the last 7 days!"
          }
          type="alert"
          style={{ width: isMobile ? "100%" : "fit-content" }}
          button={
            <ReminderButton
              reminderSent={reminderSent}
              onClick={sendReminder}
            />
          }
        />
      )
    default:
      return null
  }
}

export default PatientAlert
