import { create } from "zustand"

import {
  ANALYTIC_EVENTS,
  trackAnalyticEvent,
} from "../../../../../util/analytics"

interface DeleteObservationalDataModalStore {
  isOpen: boolean
  onOpen: (progressId: number) => void
  onClose: () => void

  progressId: number | null
}

export const useDeleteObservationalDataModalStore =
  create<DeleteObservationalDataModalStore>((set) => ({
    isOpen: false,
    onOpen: (progressId) => {
      trackAnalyticEvent(ANALYTIC_EVENTS.open_delete_observational_data)
      set({ isOpen: true, progressId })
    },
    onClose: () => set({ isOpen: false, progressId: null }),

    progressId: null,
  }))
