import {
  Button,
  DateInput,
  FlexBox,
  JoonUIColor,
  Modal,
  ModalHeader,
  ParentTraining,
  SPACING,
  TextButton,
  Typography,
} from "@joonapp/web-shared"
import { useMutation } from "@tanstack/react-query"
import dayjs from "dayjs"
import { useState } from "react"
import { create } from "zustand"

import { generateProgressNote } from "../../../networking/parentTraining"
import { useCurrentTrainingQuery } from "../../../networking/queries"
import { ProgressNote } from "../../../types"

enum GenerateNotesModalView {
  SELECT_DATE,
  NOTES,
}

interface GenerateNotesModalStore {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
}

export const useGenerateNotesModalStore = create<GenerateNotesModalStore>(
  (set) => ({
    isOpen: false,
    onOpen: () => set({ isOpen: true }),
    onClose: () => set({ isOpen: false }),
  })
)

const GenerateNotes = () => {
  const { isOpen, onClose } = useGenerateNotesModalStore()
  const [assignedNotes, setAssignedNotes] = useState<ProgressNote[]>([])
  const [progressNotes, setProgressNotes] = useState<ProgressNote[]>([])
  const [notesToCopy, setNotesToCopy] = useState<string | null>(null)
  const [noteIsCopied, setNoteIsCopied] = useState(false)
  const [modalView, setModalView] = useState<GenerateNotesModalView>(
    GenerateNotesModalView.SELECT_DATE
  )
  const { data: currentTraining } = useCurrentTrainingQuery()

  const defaultStartDate = dayjs().subtract(1, "week").toDate()
  const defaultEndDate = dayjs().toDate()

  const [startDate, setStartDate] = useState<Date>(defaultStartDate)
  const [endDate, setEndDate] = useState<Date>(defaultEndDate)

  const generateNotesMutation = useMutation(generateProgressNote)

  const onClickGenerateNote = async () => {
    if (!currentTraining?.id) return

    const response = await generateNotesMutation.mutateAsync({
      parentTrainingId: currentTraining?.id,
      startDate,
      endDate,
    })

    setAssignedNotes(response.assigned_notes)
    setProgressNotes(response.progress_notes)

    setNotesToCopy(notesToCopy)

    setModalView(GenerateNotesModalView.NOTES)
  }

  const closeModal = () => {
    onClose()
    setModalView(GenerateNotesModalView.SELECT_DATE)
    setStartDate(defaultStartDate)
    setEndDate(defaultEndDate)
  }

  const copyNote = () => {
    if (!currentTraining) return

    const notesToCopy = formatProgressNotesForCopy(
      currentTraining,
      startDate,
      endDate,
      assignedNotes,
      progressNotes
    )

    navigator.clipboard.writeText(notesToCopy)
    setNoteIsCopied(true)
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={closeModal}
        style={{ width: "min(650px, 95vw)" }}
      >
        <ModalHeader
          title="Generate progress note"
          onClose={closeModal}
          showBorderBottom
        />

        {modalView === GenerateNotesModalView.SELECT_DATE ? (
          <FlexBox
            direction="column"
            gap={SPACING.space4}
            style={{ padding: SPACING.space6, paddingTop: SPACING.space4 }}
          >
            <Typography variant="body">
              Copy generated notes and paste them into your session summary
              notes in your Client Management System. You can also add them as
              progress evidence into your funder renewal process.
            </Typography>
            <FlexBox
              direction="row"
              gap={SPACING.space4}
              align="center"
              justify="unset"
            >
              <div style={{ width: "fit-content" }}>
                <DateInput
                  label="Start date"
                  date={startDate}
                  setDate={setStartDate}
                  name="startDate"
                  modalTitle="Select start date"
                  maxDate={dayjs().toDate()}
                />
              </div>
              <div style={{ width: "fit-content" }}>
                <DateInput
                  label="End date"
                  date={endDate}
                  setDate={setEndDate}
                  name="endDate"
                  modalTitle="Select end date"
                  maxDate={dayjs().toDate()}
                />
              </div>
            </FlexBox>
            <FlexBox
              direction="row"
              gap={SPACING.space4}
              align="center"
              justify="flex-end"
              wrap={false}
            >
              <TextButton onClick={closeModal}>
                <Typography
                  variant="bodyBold"
                  color={JoonUIColor.text.primaryAccent}
                >
                  Cancel
                </Typography>
              </TextButton>
              <Button
                text="Generate note"
                onClick={onClickGenerateNote}
                isLoading={generateNotesMutation.isLoading}
              />
            </FlexBox>
          </FlexBox>
        ) : (
          modalView === GenerateNotesModalView.NOTES && (
            <FlexBox
              direction="column"
              gap={SPACING.space4}
              style={{ padding: SPACING.space6, paddingTop: SPACING.space4 }}
            >
              <FlexBox
                direction="column"
                gap={SPACING.space2}
                wrap={false}
                style={{
                  background: JoonUIColor.background.lightGray,
                  borderRadius: SPACING.space2,
                  padding: SPACING.space2,
                  maxHeight: "min(500px, 60vh)",
                  overflowY: "auto",
                }}
              >
                <div>
                  <Typography variant="bodyBold" textAlign="left">
                    {currentTraining?.title} (
                    {dayjs(startDate).format("MMM D, YYYY")} -{" "}
                    {dayjs(endDate).format("MMM D, YYYY")})
                  </Typography>
                  <Typography variant="bodySmall" textAlign="left">
                    Created{" "}
                    {dayjs(currentTraining?.date_created).format("MMM D, YYYY")}
                  </Typography>
                  {currentTraining?.mastery_criteria && (
                    <Typography variant="bodySmall" textAlign="left">
                      Mastery criteria: {currentTraining?.mastery_criteria}
                    </Typography>
                  )}
                </div>
                {assignedNotes.length > 0 && (
                  <FlexBox
                    direction="column"
                    gap={SPACING.space2}
                    wrap={false}
                    style={{ marginTop: SPACING.space2 }}
                  >
                    <div>
                      <Typography variant="bodyBold" textAlign="left">
                        Assigned
                      </Typography>
                      <Typography variant="bodySmall">
                        Between {dayjs(startDate).format("MMM D, YYYY")} and{" "}
                        {dayjs(endDate).format("MMM D, YYYY")} caregiver was
                        assigned the following:
                      </Typography>
                    </div>
                    {assignedNotes.map((note) => (
                      <div>
                        {note.texts.map((text) => (
                          <FlexBox
                            direction="row"
                            gap={SPACING.space2}
                            align="center"
                            wrap={false}
                          >
                            <Typography variant="caption">
                              {text.key}:{" "}
                            </Typography>
                            <Typography variant="bodySmall">
                              {text.body}
                            </Typography>
                          </FlexBox>
                        ))}
                      </div>
                    ))}
                  </FlexBox>
                )}
                {progressNotes.length > 0 && (
                  <FlexBox
                    direction="column"
                    gap={SPACING.space2}
                    wrap={false}
                    style={{ marginTop: SPACING.space2 }}
                  >
                    <div>
                      <Typography variant="bodyBold" textAlign="left">
                        Progress
                      </Typography>
                      <Typography variant="bodySmall">
                        Between {dayjs(startDate).format("MMM D, YYYY")} and{" "}
                        {dayjs(endDate).format("MMM D, YYYY")} caregiver
                        successfully completed the following:
                      </Typography>
                    </div>
                    {progressNotes.map((note) => (
                      <div>
                        {note.texts.map((text) => (
                          <FlexBox
                            direction="row"
                            gap={SPACING.space2}
                            align="center"
                            wrap={false}
                          >
                            <Typography
                              variant="caption"
                              style={{ whiteSpace: "nowrap" }}
                            >
                              {text.key}:{" "}
                            </Typography>
                            <Typography variant="bodySmall">
                              {text.body}
                            </Typography>
                          </FlexBox>
                        ))}
                      </div>
                    ))}
                  </FlexBox>
                )}
                {assignedNotes.length === 0 && progressNotes.length === 0 && (
                  <Typography variant="body">
                    No activity found for selected date range.
                  </Typography>
                )}
              </FlexBox>
              <FlexBox
                direction="row"
                gap={SPACING.space4}
                align="center"
                justify="space-between"
                wrap={false}
              >
                <TextButton
                  onClick={() => {
                    setModalView(GenerateNotesModalView.SELECT_DATE)
                  }}
                >
                  <Typography
                    variant="bodyBold"
                    color={JoonUIColor.text.primaryAccent}
                  >
                    Update date range
                  </Typography>
                </TextButton>
                <Button
                  text={noteIsCopied ? "Copied!" : "Copy"}
                  onClick={copyNote}
                />
              </FlexBox>
            </FlexBox>
          )
        )}
      </Modal>
    </>
  )
}

export default GenerateNotes

const formatProgressNotesForCopy = (
  training: ParentTraining,
  startDate: Date,
  endDate: Date,
  assignedNotes: ProgressNote[],
  progressNotes: ProgressNote[]
) => {
  const start = dayjs(startDate).format("MMM D, YYYY")
  const end = dayjs(endDate).format("MMM D, YYYY")
  const assignedNotesText = assignedNotes
    .map((note) =>
      note.texts.map((text) => `${text.key}: ${text.body}`).join("\n")
    )
    .join("\n\n")
  const progressNotesText = progressNotes
    .map((note) =>
      note.texts.map((text) => `${text.key}: ${text.body}`).join("\n")
    )
    .join("\n\n")

  const noteToCopy = `${training.title} (${start} - ${end})
  \nCreated ${dayjs(training.date_created).format("MMM D, YYYY")}
  \nMastery criteria: ${training.mastery_criteria}
  \n\n${
    assignedNotes.length > 0
      ? `Assigned\nBetween ${start} and ${end} caregiver was assigned the following:\n\n${assignedNotesText}\n\n`
      : ""
  }${
    progressNotes.length > 0
      ? `Progress\nBetween ${start} and ${end} caregiver successfully completed the following:\n\n${progressNotesText}`
      : ""
  }`

  return noteToCopy
}
