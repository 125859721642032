import {
  CaretRightIcon,
  JoonUIColor,
  Shadow,
  SPACING,
  Typography,
  useOnClickOutside,
} from "@joonapp/web-shared"
import { useRef, useState } from "react"
import { useParams } from "react-router-dom"

import TrainingStatusConfirmationModal from "./TrainingStatusConfirmationModal"
import { useTrainingStatusConfirmationModalStore } from "./useTrainingStatusModalStore"
import {
  ParentTrainingStatusColors,
  ParentTrainingStatusText,
} from "../../../constants"
import { useCurrentTrainingQuery } from "../../../networking/queries"
import { ParentTrainingStatus } from "../../../types"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../util/analytics"
import { StatusBar } from "../behaviorsIncidents/AddTargetBehaviorModal"

const TrainingStatusDropdown = () => {
  const [showDropdown, setShowDropdown] = useState(false)
  const dropdownRef = useRef(null)
  useOnClickOutside(dropdownRef, () => setShowDropdown(false))

  const { data: currentTraining } = useCurrentTrainingQuery()

  const currentParentTrainingStatus =
    currentTraining?.status as ParentTrainingStatus

  if (!currentTraining) return <></>

  return (
    <div style={{ position: "relative" }} ref={dropdownRef}>
      <button
        style={{
          padding: `${SPACING.space2} ${SPACING.space4}`,
          display: "flex",
          background:
            ParentTrainingStatusColors[currentParentTrainingStatus].secondary,
          borderRadius: SPACING.space10,
          alignItems: "center",
          gap: SPACING.space2,
        }}
        onClick={() => setShowDropdown(!showDropdown)}
      >
        <Typography
          variant="caption"
          color={
            ParentTrainingStatusColors[currentParentTrainingStatus].primary
          }
        >
          {ParentTrainingStatusText[currentParentTrainingStatus]}
        </Typography>
        <div style={{ transform: "rotate(90deg)" }}>
          <CaretRightIcon color={JoonUIColor.icon.neutral} size={8} />
        </div>
      </button>
      {showDropdown && (
        <div
          style={{
            paddingTop: SPACING.space2,
            position: "absolute",
            zIndex: 2000,
            left: 0,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              border: `1px solid ${JoonUIColor.semantic.primary}`,
              borderRadius: SPACING.space4,
              background: JoonUIColor.background.primaryNeutral,
              whiteSpace: "nowrap",
              overflow: "hidden",
              boxShadow: Shadow.high,
            }}
          >
            <TrainingStatusDropdownItem
              status={ParentTrainingStatus.INACTIVE}
              closeMenu={() => setShowDropdown(false)}
            />
            <TrainingStatusDropdownItem
              status={ParentTrainingStatus.IN_PROGRESS}
              closeMenu={() => setShowDropdown(false)}
            />
            <TrainingStatusDropdownItem
              status={ParentTrainingStatus.MASTERED}
              closeMenu={() => setShowDropdown(false)}
            />
          </div>
        </div>
      )}
      <TrainingStatusConfirmationModal />
    </div>
  )
}

const TrainingStatusDropdownItem = ({
  status,
  closeMenu,
}: {
  status: ParentTrainingStatus
  closeMenu: () => void
}) => {
  const [isHovered, setIsHovered] = useState(false)

  const { onOpen: openTrainingStatusConfirmationModal } =
    useTrainingStatusConfirmationModalStore()
  const { trainingId } = useParams()

  const onClick = async () => {
    if (!trainingId) return
    closeMenu()
    openTrainingStatusConfirmationModal(status)
    trackAnalyticEvent(ANALYTIC_EVENTS.open_change_parent_training_status, {
      status,
    })
  }

  return (
    <button
      style={{
        display: "flex",
        alignItems: "center",
        gap: SPACING.space2,
        padding: SPACING.space3,
        borderBottom: `1px solid ${JoonUIColor.border.default}`,
        background: isHovered
          ? JoonUIColor.background.lightGray
          : JoonUIColor.background.primaryNeutral,
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={onClick}
    >
      <StatusBar
        color={ParentTrainingStatusColors[status].bar}
        tooltipText={status}
      />
      <Typography variant="bodySmall">
        {ParentTrainingStatusText[status]}
      </Typography>
    </button>
  )
}

export default TrainingStatusDropdown
