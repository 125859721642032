import {
  CloseIcon,
  FlexBox,
  JoonUIColor,
  SPACING,
  Typography,
} from "@joonapp/web-shared"

import { useRejectInvitationsModalStore } from "./RejectInvitationsModal"
import ButtonSmall from "../../../components/buttons/ButtonSmall"
import { InvitationGroup } from "../../../types"

type Props = {
  invitationGroup: InvitationGroup
}

const RejectInviteButton = ({ invitationGroup }: Props) => {
  const { onOpen: openRejectInvitationModal } = useRejectInvitationsModalStore()

  const onClickReject = () => {
    openRejectInvitationModal(
      invitationGroup.map((invitation) => invitation.id)
    )
  }

  return (
    <ButtonSmall onClick={onClickReject}>
      <FlexBox direction="row" gap={SPACING.space2} wrap={false} align="center">
        <CloseIcon color={JoonUIColor.semantic.destructive} size={16} />
        <Typography variant="bodySmall">Reject</Typography>
      </FlexBox>
    </ButtonSmall>
  )
}

export default RejectInviteButton
