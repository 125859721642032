import { useQuery } from "@tanstack/react-query"

import { getDataTasks } from "../../../../networking/dataTasks"
import {
  QUERY_KEYS,
  useCurrentTrainingQuery,
} from "../../../../networking/queries"

const useDataTasksQuery = () => {
  const { data: currentTraining } = useCurrentTrainingQuery()
  return useQuery({
    queryKey: [QUERY_KEYS.PARENT_TRAINING_DATA_TASKS, currentTraining?.id],
    queryFn: () => getDataTasks(currentTraining?.id),
    enabled: !!currentTraining?.id,
  })
}

export default useDataTasksQuery
