import { create } from "zustand"

interface ParentTrainingStore {
  selectedParentId: number | null
  setSelectedParentId: (parent: number | null) => void
}

const useParentTrainingStore = create<ParentTrainingStore>((set) => ({
  selectedParentId: null, // Selected parent for the parent training page
  setSelectedParentId: (parent) => set({ selectedParentId: parent }),
}))

export default useParentTrainingStore
