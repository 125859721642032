import { SubscriptionInfo } from "../types"
import { createJoonAPIClient } from "../util/joon-api"

export const getStripeCheckoutUrl = async (practiceId: number) => {
  const API = createJoonAPIClient()

  const response = await API.get(`api/practices/${practiceId}/checkout/`, {
    params: {
      success_url: `${window.location.href}?stripe_success=true`,
      cancel_url: `${window.location.href}?stripe_success=false`,
    },
  })

  return response.data.checkout_url
}

export const getSubscriptionInfo = async (practiceId: number) => {
  const API = createJoonAPIClient()

  const response = await API.get<SubscriptionInfo>(
    `api/practices/${practiceId}/subscription/`
  )

  return response.data
}

export const getStripeBillingUrl = async (practiceId: number) => {
  const API = createJoonAPIClient()
  const response = await API.get(`api/practices/${practiceId}/billing/`, {
    params: {
      return_url: window.location.href,
    },
  })
  return response.data.billing_url
}
