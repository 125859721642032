import { ParentTraining } from "@joonapp/web-shared"
import { useMutation } from "@tanstack/react-query"
import dayjs from "dayjs"

import { reviewModule } from "../../../../networking/learningModules"
import {
  QUERY_KEYS,
  useCurrentTrainingQuery,
} from "../../../../networking/queries"
import { queryClient } from "../../../../queryClient"

const useReviewModuleCompletionMutation = () => {
  const { data: currentTraining } = useCurrentTrainingQuery()

  const queryKey = [QUERY_KEYS.PARENT_TRAINING, currentTraining?.id]

  return useMutation({
    mutationFn: (completionId: number) => reviewModule(completionId),
    // optimstic update
    onMutate: async (completionId) => {
      queryClient.cancelQueries({ queryKey })
      queryClient.cancelQueries([QUERY_KEYS.PARENT_TRAININGS])

      const previousTraining =
        queryClient.getQueryData<ParentTraining>(queryKey)

      queryClient.setQueryData<ParentTraining>(queryKey, (prev) => {
        if (!prev) return prev

        const updatedAssignments = prev.assignments.map((assignment) => {
          if (assignment.assignment_completion.id === completionId) {
            return {
              ...assignment,
              assignment_completion: {
                ...assignment.assignment_completion,
                date_reviewed: dayjs().format("YYYY-MM-DD"),
              },
            }
          }
          return assignment
        })

        return {
          ...prev,
          assignments: updatedAssignments,
        }
      })

      return { previousTraining }
    },
    onError: (_error, _variables, context) => {
      queryClient.setQueryData<ParentTraining>(
        queryKey,
        context?.previousTraining
      )
    },
    onSettled: () => {
      queryClient.invalidateQueries(queryKey)
      queryClient.invalidateQueries([QUERY_KEYS.PARENT_TRAININGS])
    },
  })
}

export default useReviewModuleCompletionMutation
