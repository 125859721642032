import { useMutation, useQueryClient } from "@tanstack/react-query"

import { createToast } from "../../../components/toast/Toast"
import { QUERY_KEYS } from "../../../networking/queries"
import { updateClinicians } from "../../../networking/user"
import { ToastType } from "../../../types"

// TODO: Add optimsitic updates
const useUpdateCliniciansMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: updateClinicians,

    onSuccess: () => {
      createToast({
        title: "Clinicians updated successfully",
        type: ToastType.SUCCESS,
      })
      queryClient.invalidateQueries([QUERY_KEYS.PATIENT_GROUPS])
    },
  })
}

export default useUpdateCliniciansMutation
