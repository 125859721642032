import { FlexBox } from "@joonapp/web-shared"
import React from "react"

type Props = {
  children: React.ReactNode | React.ReactNode[]
  style?: React.CSSProperties
}

const PageWrapper = ({ children, style }: Props) => {
  return (
    <FlexBox
      direction="column"
      style={{
        flex: 1,
        maxWidth: "100vw",
        height: "100%",
        overflow: "hidden",
        ...style,
      }}
      wrap={false}
    >
      {children}
    </FlexBox>
  )
}

export default PageWrapper
