import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

import AuthSection from "./AuthSection"
import useDocumentTitle from "../../hooks/usePageTitle"
import { sessionManager } from "../../util/storage"

function AuthPage() {
  const navigate = useNavigate()

  useDocumentTitle("Joon - Sign In")

  useEffect(() => {
    if (sessionManager.getRefreshToken()) navigate("/patients")
  })

  return <AuthSection />
}

export default AuthPage
