import { JoonUIColor, SPACING } from "@joonapp/web-shared"
import { useState } from "react"

// Note this is a temporary button with reduced functionality
//  that will replaced if we decide to keep this and add this to design system

const ButtonSmall = ({
  text,
  children,
  onClick,
}: {
  text?: string | React.ReactNode
  children?: React.ReactNode
  onClick: () => void
}) => {
  const [isHovered, setIsHovered] = useState(false)
  return (
    <button
      onClick={onClick}
      style={{
        padding: `${SPACING.space1} ${SPACING.space2}`,
        background: isHovered
          ? JoonUIColor.background.xlightGray
          : JoonUIColor.background.primaryNeutral,
        border: `1px solid ${JoonUIColor.border.default}`,
        borderRadius: SPACING.space2,
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {children || text}
    </button>
  )
}

export default ButtonSmall
