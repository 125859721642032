import { JoonUIColor, SPACING } from "@joonapp/web-shared"
import { flexRender, Row } from "@tanstack/react-table"

const CustomTableRow = <TData,>({
  row,
  isHovered,
  onMouseEnter,
  onMouseLeave,
  isLastRow,
}: {
  row: Row<TData>
  isHovered: boolean
  onMouseEnter: () => void
  onMouseLeave: () => void
  isLastRow: boolean
}) => {
  return (
    <tr
      key={row.id}
      style={{
        backgroundColor: isHovered ? "#F9FAFC" : "inherit",
        borderBottom: isLastRow
          ? "none"
          : `1px solid ${JoonUIColor.border.default}`,
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {row.getVisibleCells().map((cell) => (
        <td
          key={cell.id}
          style={{
            padding: `${SPACING.space4} ${SPACING.space2}`,
            width:
              cell.column.getSize() === Number.MAX_SAFE_INTEGER
                ? "auto"
                : cell.column.getSize(),
            ...(cell.column.id === "actions"
              ? { width: "1%", whiteSpace: "nowrap" }
              : {}),
          }}
        >
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </td>
      ))}
    </tr>
  )
}

export default CustomTableRow
