import { useMediaQuery } from "@mui/material"

import RecurringTable from "./RecurringTable"
import RecurringTableLegend from "./RecurringTableLegend"

const WeeklyProgress = () => {
  const isMobile = useMediaQuery("(max-width:800px)")

  return (
    <>
      <RecurringTable />
      {!isMobile && <RecurringTableLegend />}
    </>
  )
}

export default WeeklyProgress
