import { FlexBox, SPACING, Typography } from "@joonapp/web-shared"
import { useMediaQuery } from "@mui/material"
import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table"

import AcceptInviteButton from "./AcceptInviteButton"
import RejectInviteButton from "./RejectInviteButton"
import CustomTable from "../../../components/table/CustomTable"
import TableHeaderCell from "../../../components/table/TableHeaderCell"
import { useReceivedInvitesQuery } from "../../../networking/queries"
import { InvitationGroup } from "../../../types"

const ReceivedInvitesTable = () => {
  const { data: parentInvites } = useReceivedInvitesQuery()
  const columnHelper = createColumnHelper<InvitationGroup>()
  const isMobile = useMediaQuery("(max-width:800px)")

  const columns = [
    columnHelper.accessor((group) => group[0].inviter.email, {
      id: "inviterEmail",
      header: () => <TableHeaderCell>From</TableHeaderCell>,
      cell: (info) => (
        <Typography variant="bodySmall">{info.getValue()}</Typography>
      ),
      size: 50,
    }),
    ...(!isMobile
      ? [
          columnHelper.accessor(
            (group) => {
              const children = group.flatMap(
                (invitation) => invitation.subject_user
              )
              return children.map((child) => child.name).join(", ")
            },
            {
              id: "clientNames",
              header: () => <TableHeaderCell>Client Names</TableHeaderCell>,
              cell: (info) => (
                <Typography variant="bodySmall">{info.getValue()}</Typography>
              ),
            }
          ),
        ]
      : []),
    columnHelper.display({
      id: "actions",
      cell: (info) => (
        <FlexBox direction="row" gap={SPACING.space2} wrap={false}>
          {!isMobile && (
            <RejectInviteButton invitationGroup={info.row.original} />
          )}
          <AcceptInviteButton invitationGroup={info.row.original} />
        </FlexBox>
      ),
    }),
  ]

  const table = useReactTable({
    data: parentInvites || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  })

  return <CustomTable table={table} />
}

export default ReceivedInvitesTable
