import {
  ChevronLeftIcon,
  ChevronRightIcon,
  FlexBox,
  JoonUIColor,
  SPACING,
  TextButton,
} from "@joonapp/web-shared"
import { useEffect, useRef, useState } from "react"
import { Document, Page, pdfjs } from "react-pdf"

import PageLoader from "../../components/loading/PageLoader"

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`

const PDFViewer = ({ pdfUrl }: { pdfUrl: string }) => {
  const [width, setWidth] = useState(0)
  const [numPages, setNumPages] = useState(0)
  const [page, setPage] = useState(1)

  const container = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const handleResize = () => {
      if (!container.current) return
      const width = container.current.clientWidth
      setWidth(width)
    }
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  const onLoadSuccess = async (pdfObject: any) => {
    if (!container.current) return
    const numPages = pdfObject.numPages
    setNumPages(numPages)
    const width = container?.current?.clientWidth
    if (width) setWidth(width)
  }

  const isAtEnd = page === numPages
  const isAtStart = page === 1

  return (
    <FlexBox direction="column" wrap={false} style={{ width: "100%" }}>
      <FlexBox align="center" direction="row" wrap={false} gap={SPACING.space2}>
        <TextButton
          onClick={() => (!isAtStart ? setPage(page - 1) : undefined)}
        >
          <ChevronLeftIcon
            color={
              isAtStart ? JoonUIColor.icon.light : JoonUIColor.text.primary
            }
            size={28}
          />
        </TextButton>
        <div
          style={{
            borderRadius: SPACING.space4,
            overflow: "hidden",
            boxShadow: `6px 6px 16px #F0F3F8,
            -6px -6px 16px #F0F3F8`,
            height: "100%",
            width: "100%",
            background: "white",
          }}
        >
          <div ref={container} style={{ width: "100%", height: "100%" }}>
            <Document
              file={pdfUrl}
              onLoadSuccess={onLoadSuccess}
              onLoadError={console.error}
              loading={<PageLoader />}
              error={<div>Failed to load PDF</div>}
            >
              <Page
                pageNumber={page}
                width={width}
                renderTextLayer={false}
                renderAnnotationLayer={false}
                loading={<PageLoader />}
              />
            </Document>
          </div>
        </div>
        <TextButton onClick={() => (!isAtEnd ? setPage(page + 1) : undefined)}>
          <ChevronRightIcon
            color={isAtEnd ? JoonUIColor.icon.light : JoonUIColor.text.primary}
            size={28}
          />
        </TextButton>
      </FlexBox>
      {numPages > 1 && (
        <FlexBox
          direction="row"
          justify="center"
          gap={SPACING.space1}
          style={{
            overflow: "scroll",
            maxWidth: "80%",
            width: "fit-content",
            margin: "0px auto",
            transform: "translateY(16px)",
          }}
        >
          {Array(numPages)
            .fill(0)
            .map((_, i) => (
              <button
                style={{
                  height: "10px",
                  width: "10px",
                  padding: 0,
                  backgroundColor:
                    i === page - 1
                      ? JoonUIColor.background.accent
                      : JoonUIColor.background.lightAccent,
                  borderRadius: "50%",
                }}
                onClick={() => setPage(i + 1)}
                key={i}
              />
            ))}
        </FlexBox>
      )}
    </FlexBox>
  )
}

export default PDFViewer
