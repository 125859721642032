import {
  Button,
  FlexBox,
  JoonUIColor,
  Modal,
  ModalHeader,
  SPACING,
  TextButton,
  Typography,
} from "@joonapp/web-shared"

import { useDeleteDataTaskModalStore } from "./useDeleteDataTaskModalStore"
import useDeleteDataTaskMutation from "./useDeleteDataTaskMutation"

const DeleteDataTaskModal = () => {
  const { isOpen, onClose, dataTaskId } = useDeleteDataTaskModalStore()
  const deleteDataTaskMutation = useDeleteDataTaskMutation()

  const onDelete = () => {
    deleteDataTaskMutation.mutateAsync({ dataTaskId })
    onClose()
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalHeader onClose={onClose} title="Delete task" showBorderBottom />
      <FlexBox
        direction="column"
        gap={SPACING.space4}
        style={{ padding: SPACING.space6, paddingTop: SPACING.space4 }}
      >
        <Typography variant="body">
          Are you sure you want to delete this task?
        </Typography>
        <FlexBox direction="row" gap={SPACING.space2} justify="space-between">
          <TextButton onClick={onClose}>
            <Typography
              variant="bodyBold"
              color={JoonUIColor.text.primaryAccent}
            >
              Cancel
            </Typography>
          </TextButton>
          <Button text="Delete" onClick={onDelete} buttonType="redPrimary" />
        </FlexBox>
      </FlexBox>
    </Modal>
  )
}

export default DeleteDataTaskModal
