import { create } from "zustand"

import {
  IncidentLogDataType,
  ParentTrainingDataCollectionTask,
} from "../../../../types"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../../util/analytics"

interface AddEditDataTaskStore {
  isOpen: boolean
  onOpen: (dataTask?: ParentTrainingDataCollectionTask) => void
  onClose: () => void

  title: string
  description: string | null
  dataType: IncidentLogDataType | null
  dataTaskId: number | null

  setTitle: (title: string) => void
  setDescription: (description: string) => void
  setDataType: (dataType: IncidentLogDataType | null) => void

  clearQuestDetails: () => void
}

const initialState = {
  isOpen: false,
  title: "",
  description: "",
  dataType: IncidentLogDataType.FREQUENCY,
  dataTaskId: null,
}

export const useAddEditDataTaskStore = create<AddEditDataTaskStore>((set) => ({
  ...initialState,

  onOpen: (taskSeries) => {
    const isEditing = !!taskSeries
    set({ isOpen: true })
    if (isEditing) {
      trackAnalyticEvent(ANALYTIC_EVENTS.open_edit_data_task)
      set({
        dataTaskId: taskSeries?.id,
        title: taskSeries?.title,
        description: taskSeries?.description,
        dataType: taskSeries?.data_type,
      })
    } else {
      trackAnalyticEvent(ANALYTIC_EVENTS.open_add_data_task)
    }
  },
  onClose: () => set(initialState),

  setTitle: (title) => set({ title }),
  setDescription: (description) => set({ description }),
  setDataType: (dataType) => set({ dataType }),

  clearQuestDetails: () => set(initialState),
}))
