import { create } from "zustand"

interface ModuleDetailsModalStore {
  isOpen: boolean
  onClose: () => void
  onOpen: (selectedModuleId: number) => void

  selectedModuleAssignmentId: number | null
  setSelectedModuleAssignmentId: (selectedModuleId: number) => void
}

const useModuleDetailsModalStore = create<ModuleDetailsModalStore>((set) => ({
  isOpen: false,
  onClose: () => set({ isOpen: false }),
  onOpen: (selectedModuleAssignmentId) =>
    set({ isOpen: true, selectedModuleAssignmentId }),

  selectedModuleAssignmentId: null,
  setSelectedModuleAssignmentId: (selectedModuleAssignmentId) =>
    set({ selectedModuleAssignmentId }),
}))

export default useModuleDetailsModalStore
