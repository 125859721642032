import {
  FlexBox,
  JoonUIColor,
  QuestStatus,
  SPACING,
  Typography,
  UserRole,
} from "@joonapp/web-shared"
import { useMediaQuery } from "@mui/material"
import dayjs from "dayjs"
import { CSSProperties, useMemo, useState } from "react"

import { StatusIndicator } from "./StatusIndicator"
import { useAddEditQuestModalStore } from "./useAddEditQuestModalStore"
import { useQuestDetailsModalStore } from "./useQuestDetailsModalStore"
import WeeklyFrequencyCount from "./WeeklyFrequencyCount"
import { SkeletonLoaderRow } from "../../../../components/loading/SkeletonLoader"
import TherapistIconTooltip from "../../../../components/therapistIconTooltip/TherapistIconTooltip"
import { DATE_RANGES } from "../../../../constants"
import { usePatientWeeklyProgressQuery } from "../../../../networking/queries"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../../util/analytics"
import { QuestWithDates } from "../../../../util/quests"
import {
  capitalizeFirstLetter,
  dateIsToday,
  generateDateArray,
  getMostRecentTarget,
} from "../../../../util/util"
import { QuestSeriesWithTargets } from "../overTimeProgress/data"

const dateRangeThisWeek = generateDateArray(
  DATE_RANGES.past7Days.startDate,
  DATE_RANGES.past7Days.endDate
)
const dateRangeLastWeek = generateDateArray(
  DATE_RANGES.previous7Days.startDate,
  DATE_RANGES.previous7Days.endDate
)

const RecurringTable = () => {
  const isMobile = useMediaQuery("(max-width:800px)")
  const { data: weeklyProgressData, isLoading } =
    usePatientWeeklyProgressQuery()

  const staticColumns = [
    {
      title: "Start date",
      displayMobile: false,
    },
    {
      title: "Routine",
      displayMobile: true,
    },
    {
      title: "Time of day",
      displayMobile: false,
    },
    {
      title: "7 day target",
      displayMobile: true,
      style: { textAlign: "center" } as CSSProperties,
    },
    {
      title: "Last 7 days",
      displayMobile: true,
      isLastMobileColumn: true,
      style: { textAlign: "center" } as CSSProperties,
    },
  ]
  const dateRangeColumns = dateRangeThisWeek.map((date) => ({
    title: date.dayOfWeek,
    isToday: dateIsToday(date.date),
  }))

  const sortedQuests = useMemo(() => {
    return weeklyProgressData?.sort((a) => {
      const currentTarget = getMostRecentTarget(a.quest.frequency_targets)
      const isTherapistCreated =
        a.quest.assigner_profile?.role === UserRole.THERAPIST
      const aHasTarget = !!currentTarget || isTherapistCreated
      if (aHasTarget) return -1
      return 1
    })
  }, [weeklyProgressData])

  return (
    <div
      style={{
        padding: isMobile ? SPACING.space0 : SPACING.space4,
        margin: isMobile ? `0 -${SPACING.space4}` : SPACING.space0,
        width: isMobile ? "100vw" : "100%",
        borderRadius: SPACING.space2,
        border: `1px solid ${JoonUIColor.border.default}`,
        background: JoonUIColor.background.primaryNeutral,
      }}
    >
      <table className="recurring-table">
        <thead>
          <tr>
            {staticColumns
              .filter((col) => (isMobile ? col.displayMobile : true))
              .map((columnProps, i: number) => (
                <ColumnHeader
                  {...columnProps}
                  key={i}
                  isFirstColumn={i === 0}
                  isLastColumn={columnProps.isLastMobileColumn}
                />
              ))}
            {!isMobile &&
              dateRangeColumns.map((column, i: number) => (
                <DayColumnHeader
                  title={column.title}
                  isToday={column.isToday}
                  key={i}
                />
              ))}
          </tr>
        </thead>
        <tbody style={{ position: "relative" }}>
          {sortedQuests?.map((row: any, i: number) => (
            <RecurringTableRow key={i} questWithDates={row} />
          ))}
        </tbody>
      </table>
      <FlexBox style={{ padding: `0 ${SPACING.space4}` }}>
        {isLoading && <SkeletonLoaderRow numRows={5} height={40} />}
        {!isLoading && weeklyProgressData?.length === 0 && (
          <FlexBox
            style={{
              padding: SPACING.space4,
              justifyContent: "center",
              alignItems: "center",
              width: "fit-content",
              borderRadius: SPACING.space4,
              background: JoonUIColor.background.lightAccent,
              margin: `${SPACING.space4} auto`,
            }}
          >
            {weeklyProgressData?.length === 0 && (
              <Typography variant="body">No data available.</Typography>
            )}
          </FlexBox>
        )}
      </FlexBox>
    </div>
  )
}

const RecurringTableRow = ({
  questWithDates,
}: {
  questWithDates: QuestWithDates
}) => {
  const questInfo = questWithDates.quest
  const dates = questWithDates.dates

  const { onOpen: openQuestDetails } = useQuestDetailsModalStore()

  const isMobile = useMediaQuery("(max-width:800px)")

  const { completedThisWeek, totalThisWeek, completedLastWeek, totalLastWeek } =
    useMemo(() => {
      let completedThisWeek = 0
      let totalThisWeek = 0
      let completedLastWeek = 0
      let totalLastWeek = 0

      const completeStatuses = [
        QuestStatus.COMPLETED,
        QuestStatus.REDEEMED,
        QuestStatus.VERIFIED,
      ]

      dateRangeThisWeek.forEach((date) => {
        if (dateIsToday(date.date)) return
        const formattedDate = date.date.format("MM-DD-YYYY")
        if (dates[formattedDate]) {
          totalThisWeek++
          if (completeStatuses.includes(dates[formattedDate].status)) {
            completedThisWeek++
          }
        }
      })

      dateRangeLastWeek.forEach((date) => {
        const formattedDate = date.date.format("MM-DD-YYYY")
        if (dates[formattedDate]) {
          totalLastWeek++
          if (completeStatuses.includes(dates[formattedDate].status)) {
            completedLastWeek++
          }
        }
      })

      return {
        completedThisWeek,
        totalThisWeek,
        completedLastWeek,
        totalLastWeek,
      }
    }, [dates])

  const currentTarget = getMostRecentTarget(questInfo.frequency_targets)
  const isTherapistCreated =
    questInfo.assigner_profile?.role === UserRole.THERAPIST

  const onOpenQuestDetails = () => {
    openQuestDetails(questInfo.id)
  }

  return (
    <tr
      className="recurring-table-row"
      onClick={onOpenQuestDetails}
      tabIndex={0}
      onKeyDown={(e) => e.key === "Enter" && onOpenQuestDetails()}
    >
      {!isMobile && (
        <td
          style={{
            padding: `${SPACING.space2} ${SPACING.space05} ${SPACING.space2} ${SPACING.space4}`,
          }}
        >
          <Typography variant="bodySmall">
            {questInfo.start_date
              ? dayjs(questInfo.start_date).format("MMM D")
              : "-"}
          </Typography>
        </td>
      )}
      <td
        style={{
          padding: isMobile
            ? `${SPACING.space2} ${SPACING.space05} ${SPACING.space2} ${SPACING.space4}`
            : `${SPACING.space2} ${SPACING.space05}`,
        }}
      >
        <FlexBox align="center" wrap={false} gap={SPACING.space2}>
          {(isTherapistCreated || !!currentTarget) && (
            <TherapistIconTooltip
              text={
                isTherapistCreated
                  ? "Therapist added routine and target"
                  : "Therapist added target"
              }
            />
          )}
          <Typography
            variant={
              isTherapistCreated || !!currentTarget ? "caption" : "bodySmall"
            }
          >
            {questInfo.title}
          </Typography>
        </FlexBox>
      </td>
      {!isMobile && (
        <td style={{ padding: `${SPACING.space2} ${SPACING.space05}` }}>
          <Typography variant="bodySmall">
            {capitalizeFirstLetter(questInfo.routine)}
          </Typography>
        </td>
      )}
      <td style={{ padding: `${SPACING.space2} ${SPACING.space05}` }}>
        <FlexBox align="center" justify="center">
          {!!currentTarget ? (
            <Typography variant="bodySmall">
              {currentTarget?.frequency}x
            </Typography>
          ) : (
            <FrequencyTargetButton quest={questInfo} />
          )}
        </FlexBox>
      </td>
      <td
        style={{
          padding: `${SPACING.space2} ${SPACING.space05}`,
          paddingRight: isMobile ? SPACING.space4 : SPACING.space05,
        }}
      >
        <WeeklyFrequencyCount
          questInfo={questInfo}
          completedThisWeek={completedThisWeek}
          completedLastWeek={completedLastWeek}
          totalThisWeek={totalThisWeek}
          totalLastWeek={totalLastWeek}
        />
      </td>
      {!isMobile &&
        dateRangeThisWeek.map((date, i) => {
          return (
            <td
              style={{
                maxWidth: "24px",
                padding: dateIsToday(date.date)
                  ? `${SPACING.space2} ${SPACING.space4} ${SPACING.space2} ${SPACING.space0}`
                  : SPACING.space0,
              }}
              key={i}
            >
              <div style={{ display: "flex", justifyContent: "center" }}>
                <StatusIndicator
                  status={dates[date.date.format("MM-DD-YYYY")]?.status}
                  date={date.date}
                />
              </div>
            </td>
          )
        })}
    </tr>
  )
}

const FrequencyTargetButton = ({
  quest,
}: {
  quest: QuestSeriesWithTargets
}) => {
  const [isHovered, setIsHovered] = useState(false)
  const { onOpen: onOpenAddEditBehaviorModal } = useAddEditQuestModalStore()

  const onClickButton = () => {
    onOpenAddEditBehaviorModal(quest)
  }

  return (
    <button
      style={{
        width: "fit-content",
        background: isHovered ? JoonUIColor.background.lightGray : "unset",
        borderRadius: SPACING.space2,
        border: "none",
        padding: `${SPACING.space1} ${SPACING.space2}`,
        cursor: "pointer",
        whiteSpace: "nowrap",
      }}
      onClick={(e) => {
        e.stopPropagation()
        onClickButton()
        trackAnalyticEvent(ANALYTIC_EVENTS.open_add_target)
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Typography variant="caption" color={JoonUIColor.text.primaryAccent}>
        Set target
      </Typography>
    </button>
  )
}

const ColumnHeader = ({
  title,
  style = {},
  isFirstColumn,
  isLastColumn,
}: {
  title: string
  style?: CSSProperties
  isFirstColumn: boolean
  isLastColumn?: boolean
}) => {
  return (
    <th
      style={{
        padding: `${SPACING.space2} ${SPACING.space05}`,
        paddingLeft: isFirstColumn ? SPACING.space4 : SPACING.space05,
        paddingRight: isLastColumn ? SPACING.space4 : SPACING.space05,
        ...style,
      }}
    >
      <Typography variant="caption">{title}</Typography>
    </th>
  )
}

const DayColumnHeader = ({
  title,
  isToday,
}: {
  title: string
  isToday: boolean
}) => {
  return (
    <th
      style={{
        padding: isToday
          ? `${SPACING.space2} ${SPACING.space4} ${SPACING.space2} ${SPACING.space0}`
          : SPACING.space0,
      }}
    >
      <Typography
        variant="bodySmall"
        textAlign="center"
        style={{
          background: isToday
            ? JoonUIColor.background.accent
            : JoonUIColor.background.lightGray,
          width: SPACING.space6,
          height: SPACING.space5,
          color: isToday ? JoonUIColor.text.inverted : JoonUIColor.text.primary,
          borderRadius: SPACING.space2,
          margin: "0 auto",
        }}
      >
        {title.slice(0, 1)}
      </Typography>
    </th>
  )
}

export default RecurringTable
