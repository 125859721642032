import { AxiosResponse } from "axios"

import { InvitationType, Invitation } from "../types"
import { createJoonAPIClient } from "../util/joon-api"

export const getReceivedInvites: {
  (): Promise<AxiosResponse<{ results: Invitation[] }>>
} = async () => {
  const API = createJoonAPIClient()
  return API.get("api/invitations/", {
    params: {
      is_sender: false,
      type: InvitationType.LINK_THERAPIST_PATIENT,
    },
  })
}

export const getSentInvites: {
  (): Promise<AxiosResponse<{ results: Invitation[] }>>
} = async () => {
  const API = createJoonAPIClient()
  return API.get("api/invitations/", {
    params: {
      is_sender: true,
      type: InvitationType.LINK_PATIENT_FROM_THERAPIST,
    },
  })
}

export const acceptReceivedInvite = async (invitations: number[]) => {
  const API = createJoonAPIClient()
  const promises = invitations.map((invitation) =>
    API.post(`api/invitations/${invitation}/accept/`)
  )
  return Promise.all(promises)
}

export const deleteInvitation = async (id: number) => {
  const API = createJoonAPIClient()
  return API.delete(`api/invitations/${id}/`)
}

export const rejectInvitations = async (invitations: number[]) => {
  const API = createJoonAPIClient()
  const promises = invitations.map((invitation) =>
    API.post(`api/invitations/${invitation}/reject/`)
  )
  return Promise.all(promises)
}

export const sendInviteReminder = async (invitationId: number) => {
  const API = createJoonAPIClient()
  return API.post(`api/invitations/${invitationId}/send-reminder/`)
}
