import {
  FlexBox,
  JoonColorExpanded,
  JoonUIColor,
  LearningModuleAssignment,
  LearningModuleAssignmentCompletion,
  ModuleType,
  MultipleChoiceLearningModuleAssignmentCompletion,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import { useState } from "react"

import useModuleDetailsModalStore from "./store/useModuleDetailsModalStore"
import NotificationDot from "../../../../components/notificationDot/NotificationDot"
import { useModulesToReviewQuery } from "../../../../networking/queries"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../../util/analytics"
import { ModuleIcon } from "../../../resources/resourceIcons"

const getCompletionStatus = (
  type: ModuleType,
  completion: LearningModuleAssignmentCompletion | undefined
): { isComplete: boolean; score: string } => {
  if (!completion || !completion.date_completed) {
    return { isComplete: false, score: "" }
  }

  if (type !== ModuleType.MULTIPLE_CHOICE) {
    return { isComplete: true, score: "" }
  }

  const multiChoiceCompletion =
    completion as MultipleChoiceLearningModuleAssignmentCompletion
  const correctAnswers = multiChoiceCompletion.multiple_choice_answers.filter(
    (answer) => answer.is_correct
  ).length
  const totalQuestions = multiChoiceCompletion.multiple_choice_answers.length

  return {
    isComplete: true,
    score: `${correctAnswers}/${totalQuestions}`,
  }
}

const TrainingModuleAssignmentItem = ({
  moduleAssignment,
}: {
  moduleAssignment: LearningModuleAssignment
}) => {
  const [isHovered, setHovered] = useState(false)
  const { onOpen } = useModuleDetailsModalStore()
  const { data: hasToReviewModule } = useModulesToReviewQuery(
    moduleAssignment.assignment_completion.id
  )

  const onClick = () => {
    onOpen(moduleAssignment.id)
    trackAnalyticEvent(ANALYTIC_EVENTS.view_module_details)
  }

  const completedStatus = getCompletionStatus(
    moduleAssignment.learning_module.type,
    moduleAssignment.assignment_completion
  )

  return (
    <button
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        border: `1px solid ${JoonUIColor.border.default}`,
        borderRadius: SPACING.space2,
        width: "100%",
        padding: `${SPACING.space2} ${SPACING.space3}`,
        background: JoonUIColor.background.primaryNeutral,
        outline: isHovered
          ? `2px solid ${JoonColorExpanded.indigo300}`
          : undefined,
        position: "relative",
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={onClick}
    >
      <FlexBox align="center" wrap={false} gap={SPACING.space2}>
        <ModuleIcon type={moduleAssignment.learning_module.type} size="small" />
        <Typography variant="caption" textAlign="left">
          {moduleAssignment.learning_module.title}
        </Typography>
      </FlexBox>
      {isHovered ? (
        completedStatus.isComplete ? (
          <Typography variant="bodySmall" color={JoonUIColor.semantic.primary}>
            Review
          </Typography>
        ) : (
          <Typography variant="bodySmall" color={JoonUIColor.semantic.primary}>
            Preview
          </Typography>
        )
      ) : (
        completedStatus.isComplete && (
          <Typography
            variant="bodySmall"
            style={{
              color: JoonUIColor.semantic.success,
              whiteSpace: "nowrap",
            }}
          >
            Completed
            {completedStatus.score && ` (${completedStatus.score})`}
          </Typography>
        )
      )}

      {!!hasToReviewModule && (
        <div
          style={{
            position: "absolute",
            right: `-${SPACING.space1}`,
            top: `-${SPACING.space1}`,
          }}
        >
          <NotificationDot />
        </div>
      )}
    </button>
  )
}

export default TrainingModuleAssignmentItem
