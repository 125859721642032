import { capitalizeFirstLetter, SPACING, Typography } from "@joonapp/web-shared"
import { useMediaQuery } from "@mui/material"

import { TargetBehaviorStatusColors } from "../../../constants"
import { TargetBehaviorStatus } from "../../../types"

type Props = {
  status: TargetBehaviorStatus
}

const BehaviorStatusTag = ({ status }: Props) => {
  const textColor = TargetBehaviorStatusColors[status]?.primary
  const backgroundColor = TargetBehaviorStatusColors[status]?.secondary

  const isMobile = useMediaQuery("(max-width: 800px)")

  return (
    <div
      style={{
        backgroundColor,
        padding: `${SPACING.space1} ${SPACING.space2}`,
        borderRadius: SPACING.space2,
        position: isMobile ? "absolute" : "relative",
        top: isMobile ? `-${SPACING.space6}` : undefined,
        left: isMobile ? `-${SPACING.space6}` : undefined,
      }}
    >
      <Typography
        variant={isMobile ? "bodyXSmall" : "caption"}
        color={textColor}
      >
        {capitalizeFirstLetter(status)}
      </Typography>
    </div>
  )
}

export default BehaviorStatusTag
