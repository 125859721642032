import {
  Button,
  FlexBox,
  IconProps,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import { useMediaQuery } from "@mui/material"
import { useShallow } from "zustand/react/shallow"

import BehaviorsIncidentsEmptyState from "./BehaviorsIncidentsEmptyState"
import ExportButton, { ExportType } from "./ExportButton"
import FreeformTargetBehaviorItem from "./FreeformTargetBehaviorItem"
import useAddEditTargetBehaviorStore from "./store/useAddEditTargetBehaviorStore"
import TargetBehaviorItem from "./TargetBehaviorItem"
import { QuickActionMobileButtonGroup } from "../../../components/buttons/QuickActionMobileButton"
import { SkeletonLoaderRow } from "../../../components/loading/SkeletonLoader"
import {
  useIncidentsQuery,
  useTargetBehaviorsQuery,
} from "../../../networking/queries"

const TargetBehaviorSection = () => {
  const { data: targetBehaviors, isLoading: isLoadingBehaviors } =
    useTargetBehaviorsQuery()
  const { data: incidents, isLoading: isLoadingIncidents } = useIncidentsQuery()
  const { onOpen: openAddTargetBehavior } = useAddEditTargetBehaviorStore(
    useShallow((state) => ({ onOpen: state.onOpen }))
  )
  const isMobile = useMediaQuery("(max-width: 800px)")

  return (
    <FlexBox direction="column" gap={SPACING.space6}>
      <FlexBox
        direction="row"
        gap={SPACING.space2}
        align="center"
        wrap={false}
        justify="space-between"
      >
        <FlexBox
          direction="row"
          gap={SPACING.space2}
          wrap={false}
          align="center"
          style={{ width: "fit-content" }}
        >
          <TargetIcon />
          <Typography variant="h3">
            Target behaviors & reported incidents
          </Typography>
        </FlexBox>
        {!isMobile && (
          <FlexBox
            direction="row"
            gap={SPACING.space2}
            wrap={false}
            style={{ width: "fit-content" }}
          >
            <ExportButton type={ExportType.BEHAVIOR_INCIDENTS} />
            <Button
              onClick={() => openAddTargetBehavior()}
              text="+ Add target behavior"
              style={{ whiteSpace: "nowrap" }}
              size={isMobile ? "small" : "medium"}
            />
          </FlexBox>
        )}
      </FlexBox>

      <FlexBox direction="column" gap={SPACING.space2}>
        {isLoadingBehaviors || isLoadingIncidents ? (
          <SkeletonLoaderRow count={3} />
        ) : targetBehaviors &&
          targetBehaviors.length === 0 &&
          incidents &&
          incidents.length === 0 ? (
          <BehaviorsIncidentsEmptyState />
        ) : (
          <>
            {targetBehaviors?.map((behavior) => (
              <TargetBehaviorItem key={behavior.id} targetBehavior={behavior} />
            ))}
            <FreeformTargetBehaviorItem />
          </>
        )}
        <QuickActionMobileButtonGroup
          buttons={[
            {
              text: "Add target behavior",
              onClick: () => openAddTargetBehavior(),
            },
          ]}
        />
      </FlexBox>
    </FlexBox>
  )
}

export default TargetBehaviorSection

export const TargetIcon = ({
  color = "#5E65E9",
  size = 16,
  ...props
}: IconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clip-path="url(#clip0_736_90401)">
      <g clip-path="url(#clip1_736_90401)">
        <path
          d="M14 8C14 7.21207 13.8448 6.43185 13.5433 5.7039C13.2417 4.97595 12.7998 4.31451 12.2426 3.75736C11.6855 3.20021 11.0241 2.75825 10.2961 2.45672C9.56815 2.15519 8.78793 2 8 2C7.21207 2 6.43185 2.15519 5.7039 2.45672C4.97595 2.75825 4.31451 3.20021 3.75736 3.75736C3.20021 4.31451 2.75825 4.97595 2.45672 5.7039C2.15519 6.43185 2 7.21207 2 8C2 8.78793 2.15519 9.56815 2.45672 10.2961C2.75825 11.0241 3.20021 11.6855 3.75736 12.2426C4.31451 12.7998 4.97595 13.2417 5.7039 13.5433C6.43185 13.8448 7.21207 14 8 14C8.78793 14 9.56815 13.8448 10.2961 13.5433C11.0241 13.2417 11.6855 12.7998 12.2426 12.2426C12.7998 11.6855 13.2417 11.0241 13.5433 10.2961C13.8448 9.56815 14 8.78793 14 8ZM0 8C0 5.87827 0.842855 3.84344 2.34315 2.34315C3.84344 0.842855 5.87827 0 8 0C10.1217 0 12.1566 0.842855 13.6569 2.34315C15.1571 3.84344 16 5.87827 16 8C16 10.1217 15.1571 12.1566 13.6569 13.6569C12.1566 15.1571 10.1217 16 8 16C5.87827 16 3.84344 15.1571 2.34315 13.6569C0.842855 12.1566 0 10.1217 0 8ZM8 10.5C8.66304 10.5 9.29893 10.2366 9.76777 9.76777C10.2366 9.29893 10.5 8.66304 10.5 8C10.5 7.33696 10.2366 6.70107 9.76777 6.23223C9.29893 5.76339 8.66304 5.5 8 5.5C7.33696 5.5 6.70107 5.76339 6.23223 6.23223C5.76339 6.70107 5.5 7.33696 5.5 8C5.5 8.66304 5.76339 9.29893 6.23223 9.76777C6.70107 10.2366 7.33696 10.5 8 10.5ZM8 3.5C9.19347 3.5 10.3381 3.97411 11.182 4.81802C12.0259 5.66193 12.5 6.80653 12.5 8C12.5 9.19347 12.0259 10.3381 11.182 11.182C10.3381 12.0259 9.19347 12.5 8 12.5C6.80653 12.5 5.66193 12.0259 4.81802 11.182C3.97411 10.3381 3.5 9.19347 3.5 8C3.5 6.80653 3.97411 5.66193 4.81802 4.81802C5.66193 3.97411 6.80653 3.5 8 3.5ZM7 8C7 7.73478 7.10536 7.48043 7.29289 7.29289C7.48043 7.10536 7.73478 7 8 7C8.26522 7 8.51957 7.10536 8.70711 7.29289C8.89464 7.48043 9 7.73478 9 8C9 8.26522 8.89464 8.51957 8.70711 8.70711C8.51957 8.89464 8.26522 9 8 9C7.73478 9 7.48043 8.89464 7.29289 8.70711C7.10536 8.51957 7 8.26522 7 8Z"
          fill={color}
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_736_90401">
        <rect width="16" height="16" fill="white" />
      </clipPath>
      <clipPath id="clip1_736_90401">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
