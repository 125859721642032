import {
  FlexBox,
  JoonColorExpanded,
  JoonUIColor,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import dayjs from "dayjs"
import { useMemo } from "react"
import {
  Area,
  AreaChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts"

import useIncidentLog from "./behaviorsIncidents/useIncidentLog"
import { Incident, IncidentLogDataType } from "../../types/incidents"

interface IncidentLogGraphProps {
  dataType: IncidentLogDataType
  id: number
  isDataTask: boolean
}

interface GraphDataPoint {
  date: number
  value: number | null
  accuracySuccesses?: number | null
  accuracyAttempts?: number | null
}

const IncidentLogGraph: React.FC<IncidentLogGraphProps> = ({
  id,
  isDataTask,
}) => {
  const { filteredIncidents, incidentLogDataType } = useIncidentLog(
    id,
    isDataTask
  )

  const graphData = useMemo<GraphDataPoint[]>(() => {
    if (!filteredIncidents.length || !incidentLogDataType) return []

    return filteredIncidents
      .filter((incident) => {
        const date = dayjs(incident.incident_datetime)
        return date.isValid() && !date.isAfter(dayjs())
      })
      .map((incident) => ({
        date: dayjs(incident.incident_datetime).valueOf(),
        value: getValue(incident, incidentLogDataType),
        accuracySuccesses: incident.accuracy_successes,
        accuracyAttempts: incident.accuracy_attempts,
      }))
  }, [filteredIncidents, incidentLogDataType])

  if (!filteredIncidents || !incidentLogDataType) return null

  return (
    <ResponsiveContainer width="100%" height={300}>
      <AreaChart
        data={graphData}
        margin={{ bottom: 0, right: 7, left: 0, top: 16 }}
      >
        <defs>
          <linearGradient id="colorOrange" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="#FFFFFF" stopOpacity={1} />
            <stop offset="20%" stopColor="#DD7B46" stopOpacity={0.2} />
            <stop offset="100%" stopColor="#DD7B46" stopOpacity={0} />
          </linearGradient>
        </defs>
        <XAxis
          dataKey="date"
          style={{ fontSize: "13px" }}
          tickFormatter={(date) => dayjs(date).format("MMM D")}
          scale="time"
          type="number"
          domain={["dataMin", "dataMax"]}
          axisLine={false}
          tickLine={false}
          interval="preserveEnd"
        />
        <YAxis
          style={{ fontSize: "13px" }}
          tickLine={false}
          axisLine={false}
          tickMargin={16}
          allowDecimals={false}
          interval="preserveStartEnd"
          domain={
            incidentLogDataType === IncidentLogDataType.ACCURACY
              ? [0, 100]
              : [0, "auto"]
          }
          tickFormatter={(value) => formatYAxisTick(value, incidentLogDataType)}
          label={{
            value: getYAxisLabel(incidentLogDataType),
            angle: -90,
            position: "insideLeft",
            style: { textAnchor: "middle", fontSize: "13px" },
          }}
        />
        <Tooltip content={<CustomTooltip dataType={incidentLogDataType} />} />
        <Area
          type="linear"
          dataKey="value"
          stroke={JoonColorExpanded.orange300}
          fill="url(#colorOrange)"
          connectNulls
          dot
        />
      </AreaChart>
    </ResponsiveContainer>
  )
}

const getValue = (incident: Incident, dataType: IncidentLogDataType) => {
  switch (dataType) {
    case IncidentLogDataType.DURATION:
      return incident.duration
        ? convertDurationToMinutes(incident.duration)
        : null
    case IncidentLogDataType.ACCURACY:
      return incident.accuracy_attempts && incident.accuracy_attempts > 0
        ? ((incident.accuracy_successes || 0) / incident.accuracy_attempts) *
            100
        : null
    case IncidentLogDataType.FREQUENCY:
      return incident.frequency !== null ? parseFloat(incident.frequency) : null
    case IncidentLogDataType.ANTECEDENT:
      return incident.antecedent ? 1 : 0 // Just to show occurrence
    default:
      return null
  }
}

// Helper function to convert hh:mm:ss to minutes
const convertDurationToMinutes = (duration: string): number => {
  const [hours, minutes, seconds] = duration.split(":").map(Number)
  return hours * 60 + minutes + seconds / 60
}

const formatYAxisTick = (value: number, dataType: IncidentLogDataType) => {
  switch (dataType) {
    case IncidentLogDataType.ACCURACY:
      return `${value.toFixed(0)}%`
    case IncidentLogDataType.DURATION:
      return `${Number(value.toFixed(1))}`
    default:
      return value.toString()
  }
}

interface CustomTooltipProps {
  active?: boolean
  payload?: Array<{
    value: number
    payload: GraphDataPoint
  }>
  label?: string
  dataType: IncidentLogDataType
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({
  active,
  payload,
  label,
  dataType,
}) => {
  if (!active || !payload || payload.length === 0) return null
  const data = payload[0].payload
  if (data.value === null) return null

  return (
    <FlexBox
      direction="column"
      gap={SPACING.space1}
      style={{
        background: JoonUIColor.background.inverted,
        borderRadius: SPACING.space2,
        padding: SPACING.space2,
      }}
    >
      <Typography
        variant="bodySmall"
        textAlign="left"
        color={JoonUIColor.text.inverted}
      >
        {dayjs(label).format("MMM D, YYYY")}
      </Typography>
      <Typography variant="body" color={JoonUIColor.text.inverted}>
        {formatTooltipValue(data.value, dataType, data)}
      </Typography>
    </FlexBox>
  )
}

const formatTooltipValue = (
  value: number,
  dataType: IncidentLogDataType,
  data: GraphDataPoint
): string => {
  switch (dataType) {
    case IncidentLogDataType.ACCURACY:
      return `${data.accuracySuccesses}/${
        data.accuracyAttempts
      } (${value.toFixed(1)}%)`
    case IncidentLogDataType.DURATION:
      return `${value.toFixed(1)} minutes`
    case IncidentLogDataType.FREQUENCY:
      return value.toFixed(0)
    default:
      return value.toString()
  }
}

const getYAxisLabel = (dataType: IncidentLogDataType): string => {
  switch (dataType) {
    case IncidentLogDataType.DURATION:
      return "Duration (minutes)"
    case IncidentLogDataType.ACCURACY:
      return "Accuracy (%)"
    case IncidentLogDataType.FREQUENCY:
      return "Frequency"
    case IncidentLogDataType.ANTECEDENT:
      return "Occurrence"
    default:
      return ""
  }
}

export default IncidentLogGraph
