import { useEffect } from "react"

import { useOverTimeProgressFilterStore } from "./OverTimeProgressFilters"
import { useOverTimeProgressStore } from "./useOverTimeProgressStore"

// This hook is used to toggle the first X quests on the OverTimeProgress page
// when the page is first loaded. We can change it up later if we want.
export const useInitialQuestToggle = () => {
  const { filteredSummaryData } = useOverTimeProgressFilterStore()

  const initialLoadTag = "isInitialLoad"

  const { toggleSelectedQuest } = useOverTimeProgressStore()

  // Set the tag when the page loads
  useEffect(() => {
    localStorage.setItem(initialLoadTag, "true")
  }, [])

  useEffect(() => {
    const isInitialLoad = localStorage.getItem(initialLoadTag)
    if (!filteredSummaryData?.[0] || !isInitialLoad) return
    localStorage.removeItem(initialLoadTag)

    const numRowsToToggle = 1
    const initialRows = filteredSummaryData.slice(0, numRowsToToggle)
    initialRows.forEach((row) =>
      toggleSelectedQuest({ id: row.id, name: row.quest })
    )
  }, [filteredSummaryData, toggleSelectedQuest])
}
