import { useQueryClient } from "@tanstack/react-query"
import { useNavigate } from "react-router-dom"
import { create } from "zustand"

import { usePaywallModalStore } from "../components/subscription/PaywallModal"
import { signin, signup } from "../networking/authentication"
import { OnboardingStore } from "../types"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../util/analytics"
import { createJoonAPIClient } from "../util/joon-api"
import { sessionManager } from "../util/storage"

export const OnboardingSteps = Object.freeze({
  userInfo: 0,
  emailpw: 1,
})

const useOnboarding = create<OnboardingStore>((set, get) => ({
  step: 0,
  submitting: false,
  hasClinic: null,

  formErrors: {},

  formInfo: {
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    profession: "",
    clinicName: "",
  },
  clinicCode: "",

  setClinicCode: (code) => set({ clinicCode: code }),
  setHasClinic: (bool) => set({ hasClinic: bool }),

  setSubmitting: (bool) => set({ submitting: bool }),
  setFormErrors: (step, error) =>
    set({ formErrors: { ...get().formErrors, [step]: error } }),

  setFormInfo: (fieldName, value) =>
    set((state) => ({ formInfo: { ...state.formInfo, [fieldName]: value } })),

  nextStep: () =>
    set((state) => {
      if (state.step === Object.keys(OnboardingSteps).length - 1) return {}

      return { step: state.step + 1 }
    }),
  previousStep: () =>
    set((state) => {
      if (state.step === 0) return {}
      return { step: state.step - 1 }
    }),
  setStep: (step) => set({ step }),
}))

export const useCreatePractice = () => {
  const { formInfo, hasClinic, setSubmitting, clinicCode } = useOnboarding()
  const navigate = useNavigate()
  const { onOpen: openPaywallModal } = usePaywallModalStore()
  const queryClient = useQueryClient()

  const createNoAuth = async () => {
    setSubmitting(true)
    try {
      if (hasClinic) await joinPractice()
      else await createPractice()
      sessionManager.setNewUserLocalStorageFlags()
    } catch (error) {
      return error
    } finally {
      setSubmitting(false)
      queryClient.invalidateQueries({ queryKey: ["practice"] })
      navigate("/patients")
    }
  }

  const signupAndCreatePractice = async () => {
    const { email, password, profession, clinicName, firstName, lastName } =
      formInfo

    // Signup and store tokens -----
    let authResponse = await signup(email, password)
    if (authResponse.data.error) throw new Error(authResponse.data.error)

    if (authResponse.data.refresh_token) {
      // Store the tokens
      const refresh = authResponse.data.refresh_token
      const id_token = authResponse.data.id_token
      sessionManager.storeTokens(refresh, id_token)

      // Create the client to set token headers, create a user
      const API = createJoonAPIClient()
      await API.post("api/users/", { name: firstName, last_name: lastName })

      // Sign in and store tokens -----
      // Is this to handle if they had signed up as a Therapist first
    } else if (authResponse.data.error) {
      authResponse = await signin(email, password)

      // Store the tokens
      const refresh = authResponse.data.refresh_token
      const id_token = authResponse.data.id_token
      sessionManager.storeTokens(refresh, id_token)
    }

    trackAnalyticEvent(ANALYTIC_EVENTS.therapist_signup, {
      email,
      profession,
      firstName,
      lastName,
      clinicName,
    })

    if (hasClinic) await joinPractice()
    else await createPractice()
    sessionManager.setNewUserLocalStorageFlags()
  }

  const createPractice = async () => {
    const { profession, clinicName, firstName, lastName } = formInfo

    try {
      const API = createJoonAPIClient()

      // Create  practice -----
      await API.post("api/practices/", {
        name: clinicName,
        nickname: `${firstName} ${lastName}`,
        profession,
        agreed_baa: true,
      })
    } catch (error: any) {
      if (
        error.response.data.non_field_errors?.includes(
          "Practice already exists."
        )
      ) {
        throw new Error("User is already part of a practice. Please sign in.")
      } else if (error.response.data.non_field_errors) {
        throw new Error(error.response.data.non_field_errors)
      } else {
        throw new Error("Something went wrong")
      }
    }

    openPaywallModal()
  }

  const joinPractice = async () => {
    const { profession, firstName, lastName } = formInfo

    try {
      const API = createJoonAPIClient()

      // Create  practice -----
      await API.post("api/practices/join/", {
        code: clinicCode,
        nickname: `${firstName} ${lastName}`,
        profession,
      })
    } catch (error: any) {
      throw new Error("Something went wrong")
    }
  }

  const createUserAndPractice = async () => {
    const { email, profession, firstName, lastName, clinicName } = formInfo

    try {
      // Create the client to set token headers, create a user
      const API = createJoonAPIClient()
      await API.post("api/users/", { name: firstName, last_name: lastName })

      trackAnalyticEvent(ANALYTIC_EVENTS.therapist_signup, {
        email,
        profession,
        firstName,
        lastName,
        clinicName,
      })

      if (hasClinic) await joinPractice()
      else await createPractice()
      sessionManager.setNewUserLocalStorageFlags()
      openPaywallModal()
    } catch (error: any) {
      if (
        error.response?.data?.non_field_errors?.includes(
          "Practice already exists."
        )
      ) {
        throw new Error("User is already part of a practice. Please sign in.")
      } else if (error.response?.data?.non_field_errors) {
        throw new Error(error.response.data.non_field_errors)
      } else if (error.response?.data?.error) {
        throw new Error(error.response?.data.error)
      } else {
        throw new Error(error)
      }
    }
  }

  return {
    createNoAuth,
    signupAndCreatePractice,
    createUserAndPractice,
  }
}

export default useOnboarding
