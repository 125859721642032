import { JoonUIColor, Typography, UserRole } from "@joonapp/web-shared"
import { useMediaQuery } from "@mui/material"
import dayjs from "dayjs"
import { CSSProperties } from "react"
import { useParams } from "react-router-dom"

import { usePatientGroupsQuery } from "../../../networking/queries"
import { getPatientGroupByUserId } from "../../../util/util"

const CliniciansTable = () => {
  const isMobile = useMediaQuery("(max-width: 800px)")

  const { userId } = useParams()
  const { data: patientGroups } = usePatientGroupsQuery()
  const patientGroup = getPatientGroupByUserId(patientGroups, Number(userId))

  let initialClinicians =
    patientGroup?.profiles?.filter(
      (member: any) => member.role === UserRole.THERAPIST
    ) || []
  const isExamplePatient = Number(userId) === 0
  if (isExamplePatient) initialClinicians = []

  const styles = {
    rowStyle: {
      borderBottom: `1px solid ${JoonUIColor.border.default}`,
      height: "40px",
      textAlign: "left",
    },
  } as { [key: string]: CSSProperties }

  return (
    <table style={{ width: "100%", borderCollapse: "collapse" }}>
      <thead>
        <tr style={styles.rowStyle}>
          <th>
            <Typography variant="caption">Name</Typography>
          </th>
          {!isMobile && (
            <th>
              <Typography variant="caption">Email</Typography>
            </th>
          )}
          <th>
            <Typography variant="caption">Last activity</Typography>
          </th>
        </tr>
      </thead>
      <tbody>
        {initialClinicians?.map((clinician, i: number) => {
          const isLastRow = i === initialClinicians.length - 1
          return (
            <tr
              style={{
                ...styles.rowStyle,
                borderBottom: isLastRow
                  ? "none"
                  : `1px solid ${JoonUIColor.border.default}`,
              }}
            >
              <td>
                <Typography variant="bodySmall">
                  {clinician?.user?.name}
                </Typography>
              </td>
              {!isMobile && (
                <td>
                  <Typography variant="bodySmall">
                    {clinician?.user?.email}
                  </Typography>
                </td>
              )}
              <td>
                <Typography variant="bodySmall">
                  {/* @ts-ignore TODO: need to add to type? */}
                  {dayjs(clinician?.user?.last_login_date).format(
                    "MMM D, YYYY"
                  )}
                </Typography>
              </td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

export default CliniciansTable
