import {
  Button,
  FlexBox,
  JoonUIColor,
  Modal,
  ModalHeader,
  SPACING,
  TextButton,
  Typography,
} from "@joonapp/web-shared"
import { useNavigate, useParams } from "react-router-dom"

import useDeleteTrainingMutation from "./mutations/useDeleteTrainingMutation"
import useDeleteTrainingStore from "./useDeleteTrainingStore"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../util/analytics"

const DeleteTrainingModal = () => {
  const { isOpen, onClose, trainingId } = useDeleteTrainingStore()

  const deleteTrainingMutation = useDeleteTrainingMutation()
  const navigate = useNavigate()
  const { userId } = useParams()
  const isExamplePatient = Number(userId) === 0

  const onClickConfirm = async () => {
    if (!trainingId) return
    deleteTrainingMutation.mutate(trainingId)
    onClose()
    navigate(`/patients/${userId}/parent-training`)
    trackAnalyticEvent(ANALYTIC_EVENTS.delete_parent_training)
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalHeader title="Are you sure?" onClose={onClose} showBorderBottom />
      <FlexBox
        direction="column"
        style={{
          padding: SPACING.space6,
          paddingTop: SPACING.space4,
          width: "min(510px, 95vw)",
        }}
        gap={SPACING.space6}
      >
        <Typography variant="body" textAlign="left">
          <Typography variant="bodyBold">
            This action will delete all training data and is irreversible.{" "}
          </Typography>
          Assigned parents will no longer able to access this training from
          their Joon app and you won’t be able to view any progress data.
        </Typography>
        <Typography variant="body" textAlign="left">
          If you no longer want the parent to see this training, but still want
          the keep their progress data, you can change the status to "Mastered"
          or "Inactive" instead.
        </Typography>
        <FlexBox direction="row" gap={SPACING.space4} justify="flex-end">
          <TextButton onClick={onClose}>
            <Typography variant="bodyBold" color={JoonUIColor.semantic.primary}>
              Cancel
            </Typography>
          </TextButton>
          <Button
            onClick={onClickConfirm}
            text="Delete training"
            buttonType="redPrimary"
            isLoading={deleteTrainingMutation.isLoading}
            disabled={isExamplePatient}
          />
        </FlexBox>
      </FlexBox>
    </Modal>
  )
}

export default DeleteTrainingModal
