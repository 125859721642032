import {
  FrequencyTargetPeriod,
  Button,
  CalloutBox,
  FlexBox,
  InfoIcon,
  InputSectionBox,
  JoonUIColor,
  ModalHeader,
  QuestType,
  SPACING,
  SelectInput,
  Slider,
  TextInput,
  Typography,
  UserRole,
  Modal,
} from "@joonapp/web-shared"
import { useQueryClient } from "@tanstack/react-query"
import dayjs from "dayjs"
import { useState } from "react"
import { useParams } from "react-router-dom"
import { RRule } from "rrule"
import { useShallow } from "zustand/react/shallow"

import { useAddEditQuestModalStore } from "./homeRoutines/weeklyProgress/useAddEditQuestModalStore"
import OpenPDFButton from "../../components/buttons/OpenPDFButton"
import { CoinRewardBadge } from "../../components/coinRewardBadge/CoinRewardBadge"
import { Toasts, createToast } from "../../components/toast/Toast"
import CustomTooltip from "../../components/tooltip/CustomTooltip"
import { targetFrequencyDrodownOptions } from "../../constants"
import { QUERY_KEYS, useCurrentPatientQuery } from "../../networking/queries"
import { addQuest, updateTarget } from "../../networking/quests"

const AddQuestModal = () => {
  const {
    isOpen,
    onClose,
    questName,
    setQuestName,
    reward,
    setReward,
    frequency,
    setFrequency,
  } = useAddEditQuestModalStore(
    useShallow((state) => ({
      isOpen: state.isOpenAddQuest,
      onClose: state.onClose,
      questName: state.questName,
      setQuestName: state.setQuestName,
      reward: state.reward,
      setReward: state.setReward,
      frequency: state.frequency,
      setFrequency: state.setFrequency,
    }))
  )

  const queryClient = useQueryClient()
  const [isSaving, setIsSaving] = useState(false)
  const { userId } = useParams()
  const { data: currentChild } = useCurrentPatientQuery()
  const isExamplePatient = Number(userId) === 0

  const onClickSaveBehavior = async () => {
    if (!userId) return
    if (!questName) return createToast(Toasts.TARGET_MISSING_NAME)
    if (reward === null) return createToast(Toasts.TARGET_MISSING_REWARD)
    const newQuest = {
      title: questName,
      redeemable_reward: reward,
      start_date: dayjs().format("YYYY-MM-DD"),
      recurrence: new RRule({
        freq: RRule.WEEKLY,
        byweekday: [
          RRule.SU,
          RRule.MO,
          RRule.TU,
          RRule.WE,
          RRule.TH,
          RRule.FR,
          RRule.SA,
        ],
      }).toString(),
      occurrence_limit: null,
      user_id: userId,
      type: QuestType.REPEATING,
    }
    const newTarget = {
      frequency: frequency.value as number,
      period: FrequencyTargetPeriod.SEVEN_DAYS,
      role: UserRole.THERAPIST,
    }

    setIsSaving(true)
    try {
      const res = await addQuest({ userId: +userId, questInfo: newQuest })
      const seriesId = res.data.id
      await updateTarget({ seriesId, target: newTarget })
      createToast(Toasts.ADD_BEHAVIOR_SUCCESS)
      queryClient.invalidateQueries([QUERY_KEYS.CHILD_QUEST_DATA])
      queryClient.invalidateQueries([QUERY_KEYS.PATIENT_GROUPS]) // for usage_notification
      onClose()
    } catch (error) {
      createToast(Toasts.GENERAL_ERROR)
    }
    setIsSaving(false)
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} mobileFull>
      <ModalHeader
        title={
          (
            <FlexBox direction="column" gap={SPACING.space1}>
              <Typography variant="h3">Create a new routine</Typography>
              <OpenPDFButton
                text="Preview parent experience"
                imageUrl="/images/frequencyTargetInfoSheet.pdf"
              />
            </FlexBox>
          ) as any
        }
        onClose={onClose}
        style={{
          width: "100%",
          borderBottom: `1px solid ${JoonUIColor.border.default}`,
        }}
      />

      <FlexBox
        style={{
          padding: `${SPACING.space4} ${SPACING.space6} ${SPACING.space6}`,
          width: "100%",
          overflowY: "scroll",
          height: "100%",
        }}
        direction="column"
        gap={SPACING.space6}
        wrap={false}
      >
        <div style={{ width: "100%" }}>
          <Typography
            variant="bodyBold"
            style={{ marginBottom: SPACING.space2 }}
            textAlign="left"
          >
            Routine name
          </Typography>
          <TextInput
            name="Routine name"
            value={questName}
            onChange={(e) => setQuestName(e.target.value)}
            placeholder="Talk about your feelings with mom..."
            fullWidth
            maxLength={100}
          />
        </div>
        <div>
          <Typography
            variant="bodyBold"
            style={{ marginBottom: SPACING.space2 }}
            textAlign="left"
          >
            Target frequency
          </Typography>
          <FlexBox align="center" gap={SPACING.space2}>
            <SelectInput
              isSearchable={false}
              name="Frequency"
              isMulti={false}
              selectedOption={frequency}
              setSelectedOption={setFrequency}
              options={targetFrequencyDrodownOptions}
              style={{ width: "85px" }}
            />
            <Typography variant="bodySmall">per 7 days</Typography>
          </FlexBox>
        </div>
        <div style={{ width: "100%" }}>
          <Typography
            variant="bodyBold"
            textAlign="left"
            style={{ marginBottom: SPACING.space2 }}
          >
            Difficulty
          </Typography>
          <Slider
            value={reward}
            setValue={setReward}
            minLabel="Not difficult"
            maxLabel="Very difficult"
          />
          {!!reward && (
            <InputSectionBox
              style={{ minHeight: "84px", marginTop: SPACING.space2 }}
            >
              <FlexBox
                direction="column"
                gap={SPACING.space2}
                align="center"
                justify="center"
              >
                <Typography variant="caption" textAlign="center">
                  For completing this, {currentChild?.user?.name} will earn:{" "}
                  <span data-tooltip-id="reward-tooltip">
                    <InfoIcon
                      color={JoonUIColor.text.primaryAccent}
                      size={14}
                    />
                  </span>
                  <CustomTooltip id="reward-tooltip">
                    <Typography
                      variant="bodySmall"
                      color={JoonUIColor.text.inverted}
                      style={{ maxWidth: "250px" }}
                      textAlign="center"
                    >
                      “Coins” are used for in-game items and parent custom
                      rewards.
                    </Typography>
                  </CustomTooltip>
                </Typography>
                <CoinRewardBadge coinReward={reward} />
              </FlexBox>
            </InputSectionBox>
          )}
        </div>
        <CalloutBox>
          <Typography variant="bodySmall">
            Once set, you will be able to track how often this specific skill
            occurs every 7 days.
          </Typography>
        </CalloutBox>
      </FlexBox>
      <div style={{ position: "sticky", bottom: 0, width: "100%" }}>
        <FlexBox
          direction="row"
          align="center"
          justify="flex-end"
          wrap={false}
          gap={SPACING.space2}
          style={{
            width: "100%",
            padding: SPACING.space6,
            whiteSpace: "nowrap",
          }}
        >
          <button onClick={onClose}>
            <Typography
              variant="bodyBold"
              color={JoonUIColor.text.primaryAccent}
            >
              Cancel
            </Typography>
          </button>
          <Button
            text="Save target"
            onClick={onClickSaveBehavior}
            isLoading={isSaving}
            disabled={isExamplePatient}
          />
        </FlexBox>
      </div>
    </Modal>
  )
}

export default AddQuestModal
