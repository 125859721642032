import {
  Button,
  Checkbox,
  FlexBox,
  JoonUIColor,
  Modal,
  ModalHeader,
  SPACING,
  TextButton,
  Typography,
  UserRole,
} from "@joonapp/web-shared"
import { useEffect, useMemo } from "react"
import { create } from "zustand"

import useAdminUser from "./useAdminUser"
import {
  usePatientGroupsQuery,
  usePracticeQuery,
} from "../../networking/queries"
import useUpdateCliniciansMutation from "../patientInfo/patientDetails/useUpdateCliniciansMutation"

type ManageClientModalStore = {
  isOpen: boolean
  onOpen: (patientGroupId: number) => void
  onClose: () => void
  patientGroupId: number | null
  selectedClinicianIds: number[]
  toggleClinicianId: (therapistId: number) => void
}

const initialState = {
  isOpen: false,
  patientGroupId: null,
  selectedClinicianIds: [],
}

export const useManageClientModalStore = create<ManageClientModalStore>(
  (set) => ({
    ...initialState,

    onOpen: (patientGroupId) => set({ isOpen: true, patientGroupId }),
    onClose: () => set(initialState),
    toggleClinicianId: (therapistId: number) => {
      set((state) => {
        const newSelectedClinicianIds = state.selectedClinicianIds.includes(
          therapistId
        )
          ? state.selectedClinicianIds.filter((id) => id !== therapistId)
          : [...state.selectedClinicianIds, therapistId]
        return { selectedClinicianIds: newSelectedClinicianIds }
      })
    },
  })
)

const ManageClientModal = () => {
  const {
    isOpen,
    onClose,
    patientGroupId,
    selectedClinicianIds,
    toggleClinicianId,
  } = useManageClientModalStore()
  const { practice } = usePracticeQuery()
  const userIsAdmin = useAdminUser()
  const { data: patientGroups } = usePatientGroupsQuery({
    admin_view: userIsAdmin,
  })

  const patientGroup = patientGroups?.find((g) => g.id === patientGroupId)

  const initialClinicians = useMemo(
    () =>
      patientGroup?.profiles
        .filter((profile) => profile.role === UserRole.THERAPIST)
        .map((profile) => profile.user.id) || [],
    [patientGroup]
  )

  const updateCliniciansMutation = useUpdateCliniciansMutation()

  const onClickSave = () => {
    if (!patientGroup) return

    updateCliniciansMutation.mutate({
      selectedClinicians: selectedClinicianIds,
      initialClinicians,
      patientGroupId: patientGroup?.id,
    })
    onClose()
  }

  useEffect(() => {
    initialClinicians.forEach(toggleClinicianId)
  }, [initialClinicians, toggleClinicianId])

  if (!patientGroup) return null

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      style={{ width: "min(400px, 95vw)" }}
    >
      <ModalHeader onClose={onClose} title="Manage Client" showBorderBottom />
      <FlexBox
        direction="column"
        gap={SPACING.space2}
        style={{ padding: SPACING.space6, paddingTop: SPACING.space4 }}
      >
        <Typography variant="bodyBold">Assigned clinicians</Typography>
        {practice?.profiles?.map((profile) => (
          <Checkbox
            name="practice member"
            selected={selectedClinicianIds.includes(profile.user.id)}
            onChange={() => toggleClinicianId(profile.user.id)}
            label={profile.user.name}
          />
        ))}
        <FlexBox
          direction="row"
          gap={SPACING.space2}
          wrap={false}
          justify="flex-end"
        >
          <TextButton onClick={onClose}>
            <Typography
              variant="bodyBold"
              color={JoonUIColor.text.primaryAccent}
            >
              Cancel
            </Typography>
          </TextButton>
          <Button
            text="Save"
            onClick={onClickSave}
            isLoading={updateCliniciansMutation.isLoading}
          />
        </FlexBox>
      </FlexBox>
    </Modal>
  )
}

export default ManageClientModal
