import { FlexBox, JoonColorExpanded, SPACING } from "@joonapp/web-shared"
import { useMediaQuery } from "@mui/material"
import { useParams } from "react-router-dom"

import ForgotPassword from "./ForgotPassword"
import SignIn from "./SignIn"
import PageWrapper from "../../components/pageWrapper/PageWrapper"
import OnboardBox from "../onboarding/OnboardBox"

function AuthSection() {
  const { type: authType } = useParams()
  const isMobile = useMediaQuery("(max-width: 800px)")

  return (
    <PageWrapper
      style={{
        background: `linear-gradient(180deg, rgba(255, 255, 255, 0.80) 2.83%, rgba(255, 255, 255, 0.00) 51.53%, rgba(255, 255, 255, 0.80) 99.81%), ${JoonColorExpanded.indigo100}`,
      }}
    >
      <FlexBox
        align="center"
        justify="center"
        direction="column"
        gap={isMobile ? SPACING.space2 : SPACING.space6}
        style={{ margin: isMobile ? `${SPACING.space4} 0` : "auto" }}
      >
        {authType === "signin" && (
          <img
            src="/images/logos/JoonHealth.svg"
            alt="Joon Health Logo"
            style={{ height: "30px" }}
          />
        )}
        <OnboardBox>
          {authType === "forgotpass" ? <ForgotPassword /> : <SignIn />}
        </OnboardBox>
      </FlexBox>
    </PageWrapper>
  )
}

export default AuthSection
