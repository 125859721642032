import { useEffect } from "react"
import { useParams } from "react-router-dom"

import useParentTrainingStore from "./useParentTrainingStore"
import { usePatientParentsQuery } from "../../../networking/queries"

// This hook is used to set the selected parent and task when the page loads
const useParentTraining = () => {
  const { data: parents } = usePatientParentsQuery()
  const { selectedParentId, setSelectedParentId } = useParentTrainingStore()
  const { userId } = useParams()

  const isExamplePatient = Number(userId) === 0

  // Set the first parent as selected when the list of parents is loaded
  useEffect(() => {
    if (selectedParentId) return
    if (parents && !selectedParentId) {
      setSelectedParentId(parents[0].user.id)
    }
  }, [parents, selectedParentId, setSelectedParentId, isExamplePatient])

  // When component unmounts, reset the selected task & parent
  useEffect(() => {
    return () => setSelectedParentId(null)
  }, [setSelectedParentId])
}

export default useParentTraining
