import { create } from "zustand"

interface PatientInfoSidePanelStore {
  isOpen: boolean
  onClose: () => void
  onOpen: (view: PatientInfoSidePanelView) => void
  view: PatientInfoSidePanelView | null
  setView: (view: PatientInfoSidePanelView) => void
}

export enum PatientInfoSidePanelView {
  ManageClinicians,
}

export const usePatientInfoSidePanelStore = create<PatientInfoSidePanelStore>(
  (set) => ({
    isOpen: false,
    onClose: () => set({ isOpen: false, view: null }),
    onOpen: (view) => set({ isOpen: true, view }),
    view: null,
    setView: (view: PatientInfoSidePanelView) => set({ view }),
  })
)
