import {
  Button,
  Checkbox,
  FlexBox,
  HeartIcon,
  JoonColorExpanded,
  JoonUIColor,
  ModalHeader,
  ModuleType,
  SPACING,
  TextInput,
  Typography,
} from "@joonapp/web-shared"
import { useMediaQuery } from "@mui/material"
import { create } from "zustand"
import { useShallow } from "zustand/react/shallow"

import { getModuleTheme, ModuleIcon } from "./resourceIcons"
import useResourcesFilterStore from "./useResourceFilterStore"
import { useResourceTagsQuery } from "../../networking/queries"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"
import SidePanel from "../patientInfo/SidePanel"

const ModuleFilterPanel = () => {
  const { searchValue, setSearchValue } = useResourcesFilterStore(
    useShallow((state) => ({
      searchValue: state.searchValue,
      setSearchValue: state.setSearchValue,
    }))
  )

  const isMobile = useMediaQuery("(max-width: 800px)")

  if (isMobile) return <MobileModuleFilters />

  return (
    <FlexBox
      direction="column"
      align="unset"
      wrap={false}
      gap={SPACING.space4}
      style={{
        minWidth: "270px",
        maxWidth: "300px",
        height: "100%",
        padding: SPACING.space6,
        background: JoonUIColor.background.primaryNeutral,
        overflowY: "auto",
      }}
    >
      <TextInput
        name="Search Resources"
        placeholder="Search resources"
        style={{ width: "min(450px, 100%)", margin: "0 auto" }}
        icon="search"
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
      />
      <ModuleFilters />
    </FlexBox>
  )
}

const ModuleFilters = () => {
  const {
    selectedTags,
    toggleTag,
    displayFavorites,
    setDisplayFavorites,
    selectedModuleTypes,
    toggleModuleType,
    displayMadeByYourClinic,
    setDisplayMadeByYourClinic,
  } = useResourcesFilterStore(
    useShallow((state) => ({
      selectedTags: state.selectedTags,
      toggleTag: state.toggleTag,
      displayFavorites: state.displayFavorites,
      setDisplayFavorites: state.setDisplayFavorites,
      selectedModuleTypes: state.selectedModuleTypes,
      toggleModuleType: state.toggleModuleType,
      displayMadeByYourClinic: state.displayMadeByYourClinic,
      setDisplayMadeByYourClinic: state.setDisplayMadeByYourClinic,
    }))
  )
  const { data: tags } = useResourceTagsQuery()

  return (
    <>
      <FlexBox direction="column" gap={SPACING.space2}>
        <Checkbox
          name="Favorites"
          label={
            (
              <FlexBox
                align="center"
                gap={SPACING.space1}
                wrap={false}
                style={{ marginLeft: SPACING.space1 }}
              >
                <FlexBox
                  align="center"
                  justify="center"
                  style={{
                    background: JoonColorExpanded.red100,
                    borderRadius: SPACING.space1,
                    width: SPACING.space5,
                    height: SPACING.space5,
                  }}
                >
                  <HeartIcon
                    color={JoonUIColor.semantic.destructive}
                    size={12}
                  />
                </FlexBox>
                <Typography variant="bodySmall">Favorites</Typography>
              </FlexBox>
            ) as any
          }
          selected={displayFavorites}
          onChange={() => setDisplayFavorites(!displayFavorites)}
          hideBorder
          style={{ fontSize: "14px" }}
        />
        <Checkbox
          label={
            (
              <FlexBox
                align="center"
                gap={SPACING.space1}
                wrap={false}
                style={{ marginLeft: SPACING.space1 }}
              >
                <Typography variant="bodySmall">My clinic resources</Typography>
              </FlexBox>
            ) as any
          }
          name="My clinic"
          selected={displayMadeByYourClinic}
          onChange={() => setDisplayMadeByYourClinic(!displayMadeByYourClinic)}
          hideBorder
          style={{ fontSize: "14px" }}
        />
        <Typography
          variant="caption"
          style={{
            borderBottom: `1px solid ${JoonUIColor.border.default}`,
            width: "100%",
            paddingBottom: SPACING.space1,
          }}
        >
          Type
        </Typography>
        {Object.values(ModuleType).map((moduleType) => (
          <Checkbox
            name={moduleType}
            key={moduleType}
            label={
              (
                <FlexBox
                  align="center"
                  wrap={false}
                  gap={SPACING.space1}
                  style={{ marginLeft: SPACING.space1 }}
                >
                  <ModuleIcon type={moduleType} size="small" />
                  <Typography
                    variant="bodySmall"
                    style={{ marginLeft: SPACING.space1 }}
                  >
                    {getModuleTheme(moduleType).moduleText}
                  </Typography>
                </FlexBox>
              ) as any
            }
            selected={selectedModuleTypes.includes(moduleType)}
            onChange={() => {
              toggleModuleType(moduleType)
              trackAnalyticEvent(ANALYTIC_EVENTS.filter_learning_modules, {
                filter: moduleType,
              })
            }}
            hideBorder
            fullWidth
          />
        ))}
      </FlexBox>

      <FlexBox direction="column" gap={SPACING.space2}>
        <Typography
          variant="caption"
          style={{
            borderBottom: `1px solid ${JoonUIColor.border.default}`,
            paddingBottom: SPACING.space1,
            width: "100%",
          }}
        >
          Topics
        </Typography>
        {tags?.map((tag) => (
          <Checkbox
            name={tag.title}
            key={tag.id}
            label={
              (
                <Typography
                  variant="bodySmall"
                  style={{ marginLeft: SPACING.space1 }}
                >
                  {tag.title}
                </Typography>
              ) as any
            }
            selected={selectedTags.includes(tag.id)}
            onChange={() => toggleTag(tag.id)}
            hideBorder
            fullWidth
          />
        ))}
      </FlexBox>
    </>
  )
}

export default ModuleFilterPanel

const MobileModuleFilters = () => {
  const { isOpen, onClose } = useMobileModuleFilterPanelStore()

  return (
    <SidePanel isOpen={isOpen} from="left">
      <ModalHeader
        title="Filter Resources"
        onClose={onClose}
        showBorderBottom
      />
      <FlexBox
        direction="column"
        wrap={false}
        style={{
          padding: SPACING.space6,
          overflowY: "auto",
          paddingBottom: "80px",
        }}
      >
        <ModuleFilters />
      </FlexBox>
      <FlexBox
        style={{
          position: "sticky",
          bottom: "0",
          marginTop: "auto",
          padding: SPACING.space4,
        }}
      >
        <Button text="Apply filters" onClick={onClose} fullWidth />
      </FlexBox>
    </SidePanel>
  )
}

type MobileModuleFilterPanelStore = {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
}

export const useMobileModuleFilterPanelStore =
  create<MobileModuleFilterPanelStore>((set) => ({
    isOpen: false,
    onOpen: () => set({ isOpen: true }),
    onClose: () => set({ isOpen: false }),
  }))
