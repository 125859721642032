import {
  FlexBox,
  IconProps,
  JoonColorExpanded,
  JoonUIColor,
  ModuleType,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import { useId } from "react"

import CustomTooltip from "../../components/tooltip/CustomTooltip"

export const PDFIcon = ({ size, color }: IconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 3C0 1.34531 1.34531 0 3 0H10.5V6C10.5 6.82969 11.1703 7.5 12 7.5H18V14.25H8.25C6.59531 14.25 5.25 15.5953 5.25 17.25V24H3C1.34531 24 0 22.6547 0 21V3ZM18 6H12V0L18 6ZM8.25 16.5H9.75C11.1984 16.5 12.375 17.6766 12.375 19.125C12.375 20.5734 11.1984 21.75 9.75 21.75H9V23.25C9 23.6625 8.6625 24 8.25 24C7.8375 24 7.5 23.6625 7.5 23.25V21V17.25C7.5 16.8375 7.8375 16.5 8.25 16.5ZM9.75 20.25C10.3734 20.25 10.875 19.7484 10.875 19.125C10.875 18.5016 10.3734 18 9.75 18H9V20.25H9.75ZM14.25 16.5H15.75C16.9922 16.5 18 17.5078 18 18.75V21.75C18 22.9922 16.9922 24 15.75 24H14.25C13.8375 24 13.5 23.6625 13.5 23.25V17.25C13.5 16.8375 13.8375 16.5 14.25 16.5ZM15.75 22.5C16.1625 22.5 16.5 22.1625 16.5 21.75V18.75C16.5 18.3375 16.1625 18 15.75 18H15V22.5H15.75ZM19.5 17.25C19.5 16.8375 19.8375 16.5 20.25 16.5H22.5C22.9125 16.5 23.25 16.8375 23.25 17.25C23.25 17.6625 22.9125 18 22.5 18H21V19.5H22.5C22.9125 19.5 23.25 19.8375 23.25 20.25C23.25 20.6625 22.9125 21 22.5 21H21V23.25C21 23.6625 20.6625 24 20.25 24C19.8375 24 19.5 23.6625 19.5 23.25V20.25V17.25Z"
      fill={color}
    />
  </svg>
)

export const BookIcon = ({ size, color }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.4 24.7852C10.85 24.993 11.3333 24.5609 11.3333 23.9375V3.29844C11.3333 3.06875 11.2667 2.83906 11.125 2.69687C10.3083 1.84375 8.43333 0.75 6 0.75C3.89583 0.75 1.92917 1.47734 0.754167 2.06797C0.283333 2.30859 0 2.92109 0 3.58281V23.8336C0 24.4844 0.533333 24.9383 1.00417 24.7359C2.31667 24.1617 4.39583 23.5 6 23.5C7.4125 23.5 9.29167 24.2656 10.4 24.7852ZM13.6 24.7852C14.7083 24.2656 16.5875 23.5 18 23.5C19.6042 23.5 21.6833 24.1617 22.9958 24.7359C23.4667 24.9438 24 24.4844 24 23.8336V3.58281C24 2.92109 23.7167 2.30859 23.2458 2.07344C22.0708 1.47734 20.1042 0.75 18 0.75C15.5667 0.75 13.6917 1.84375 12.875 2.69687C12.7375 2.83906 12.6667 3.06875 12.6667 3.29844V23.9375C12.6667 24.5609 13.1542 24.993 13.6 24.7852Z"
        fill={color}
      />
    </svg>
  )
}

export const VideoIcon = ({ size, color }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1746_48107)">
        <path
          d="M0 6C0 4.34531 1.19583 3 2.66667 3H13.3333C14.8042 3 16 4.34531 16 6V18C16 19.6547 14.8042 21 13.3333 21H2.66667C1.19583 21 0 19.6547 0 18V6ZM23.2958 4.67813C23.7292 4.94063 24 5.44687 24 6V18C24 18.5531 23.7292 19.0594 23.2958 19.3219C22.8625 19.5844 22.3375 19.5563 21.925 19.2469L17.925 16.2469L17.3333 15.8016V15V9V8.19844L17.925 7.75312L21.925 4.75313C22.3333 4.44844 22.8583 4.41563 23.2958 4.67813Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1746_48107">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export const ParagraphIcon = ({ size, color }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.2824 1.5H13.7109H22.2824C23.2306 1.5 23.9967 2.17031 23.9967 3C23.9967 3.82969 23.2306 4.5 22.2824 4.5H20.5681V21C20.5681 21.8297 19.802 22.5 18.8538 22.5C17.9056 22.5 17.1395 21.8297 17.1395 21V4.5H15.4252V21C15.4252 21.8297 14.6592 22.5 13.7109 22.5C12.7627 22.5 11.9967 21.8297 11.9967 21V16.5H10.2824C5.54665 16.5 1.71094 13.1438 1.71094 9C1.71094 4.85625 5.54665 1.5 10.2824 1.5Z"
        fill={color}
      />
    </svg>
  )
}

export const QuestionSquareIcon = ({ size, color }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 1.71429C0 0.76875 0.76875 0 1.71429 0H10.2857C11.2312 0 12 0.76875 12 1.71429V10.2857C12 11.2312 11.2312 12 10.2857 12H1.71429C0.76875 12 0 11.2312 0 10.2857V1.71429Z"
        fill={color}
      />
      <path
        d="M4.50078 4.5C4.50078 3.94844 5.03891 3.5 5.70078 3.5H6.30078C6.96266 3.5 7.50078 3.94844 7.50078 4.5V4.55625C7.50078 4.89688 7.29266 5.21406 6.94953 5.39687L6.15828 5.82031C5.68578 6.07344 5.40078 6.50938 5.40078 6.97656V7C5.40078 7.27656 5.66891 7.5 6.00078 7.5C6.33266 7.5 6.60078 7.27656 6.60078 7V6.97813C6.60078 6.85 6.67953 6.73125 6.80703 6.6625L7.59828 6.23906C8.28453 5.87031 8.70078 5.2375 8.70078 4.55625V4.5C8.70078 3.39531 7.62641 2.5 6.30078 2.5H5.70078C4.37516 2.5 3.30078 3.39531 3.30078 4.5C3.30078 4.77656 3.56891 5 3.90078 5C4.23266 5 4.50078 4.77656 4.50078 4.5ZM6.00078 9.5C6.19969 9.5 6.39046 9.43415 6.53111 9.31694C6.67176 9.19973 6.75078 9.04076 6.75078 8.875C6.75078 8.70924 6.67176 8.55027 6.53111 8.43306C6.39046 8.31585 6.19969 8.25 6.00078 8.25C5.80187 8.25 5.6111 8.31585 5.47045 8.43306C5.3298 8.55027 5.25078 8.70924 5.25078 8.875C5.25078 9.04076 5.3298 9.19973 5.47045 9.31694C5.6111 9.43415 5.80187 9.5 6.00078 9.5Z"
        fill="white"
      />
    </svg>
  )
}

export const getModuleTheme = (type: ModuleType) => {
  switch (type) {
    case ModuleType.PDF:
      return {
        primaryColor: JoonColorExpanded.viridian400,
        secondaryColor: JoonColorExpanded.viridian100,
        Icon: PDFIcon,
        moduleText: "PDF",
      }
    case ModuleType.READING_TEXTS:
      return {
        primaryColor: JoonColorExpanded.green300,
        secondaryColor: JoonColorExpanded.green100,
        Icon: BookIcon,
        moduleText: "Reading",
      }
    case ModuleType.VIDEO:
      return {
        primaryColor: JoonColorExpanded.pink400,
        secondaryColor: JoonColorExpanded.pink100,
        Icon: VideoIcon,
        moduleText: "Video",
      }
    case ModuleType.SHORT_ANSWERS:
      return {
        primaryColor: JoonColorExpanded.yellow400,
        secondaryColor: JoonColorExpanded.yellow100,
        Icon: ParagraphIcon,
        moduleText: "Short answer",
      }
    case ModuleType.MULTIPLE_CHOICE:
      return {
        primaryColor: JoonColorExpanded.orange300,
        secondaryColor: JoonColorExpanded.orange100,
        Icon: QuestionSquareIcon,
        moduleText: "Multiple choice",
      }
    default:
      return {
        primaryColor: JoonColorExpanded.viridian400,
        secondaryColor: JoonColorExpanded.viridian100,
        Icon: PDFIcon,
        moduleText: "PDF",
      }
  }
}

export const ModuleIcon = ({
  type,
  size,
  hideTooltip = false,
}: {
  type: ModuleType
  size: "large" | "small"
  hideTooltip?: boolean
}) => {
  const isLargeSize = size === "large"
  const iconTooltipId = useId()

  const { moduleText, primaryColor, secondaryColor, Icon } =
    getModuleTheme(type)

  return (
    <>
      <FlexBox
        align="center"
        justify="center"
        style={{
          height: isLargeSize ? SPACING.space12 : SPACING.space6,
          width: isLargeSize ? SPACING.space12 : SPACING.space6,
          minHeight: isLargeSize ? SPACING.space12 : SPACING.space6,
          minWidth: isLargeSize ? SPACING.space12 : SPACING.space6,
          borderRadius: isLargeSize ? SPACING.space2 : SPACING.space1,
          background: secondaryColor,
          border: isLargeSize
            ? `1px solid ${secondaryColor}`
            : `1px solid ${JoonUIColor.background.primaryNeutral}`,
        }}
        data-tooltip-id={iconTooltipId}
      >
        <Icon size={isLargeSize ? 24 : 12} color={primaryColor} />
      </FlexBox>
      {!hideTooltip && (
        <CustomTooltip id={iconTooltipId}>
          <Typography variant="bodySmall" color={JoonUIColor.text.inverted}>
            {moduleText}
          </Typography>
        </CustomTooltip>
      )}
    </>
  )
}
