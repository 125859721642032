import { FlexBox, JoonUIColor, SPACING, Typography } from "@joonapp/web-shared"

export const CoinRewardBadge = ({ coinReward }: { coinReward: number }) => {
  return (
    <FlexBox
      align="center"
      gap={SPACING.space2}
      style={{
        width: "fit-content",
        background: JoonUIColor.background.lightBlue,
        borderRadius: SPACING.space6,
        padding: `${SPACING.space1} ${SPACING.space2}`,
      }}
    >
      <Typography variant="caption" style={{ fontWeight: "600" }}>
        {coinReward}
      </Typography>
      <img
        style={{ height: "20px", width: "20px" }}
        src="/images/icons/coin-icon.png"
        alt="coin"
      />
    </FlexBox>
  )
}
