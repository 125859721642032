import { useCallback, useMemo } from "react"
import { useParams } from "react-router-dom"

import { useUnreadIncidentsQuery } from "../networking/queries"

const useUnreadIncidents = ({
  userId,
  dataTaskId,
  targetBehaviorId,
}: {
  userId?: number
  dataTaskId?: number
  targetBehaviorId?: number
}) => {
  const { userId: userIdParam } = useParams<{ userId: string }>()
  const { data, isLoading } = useUnreadIncidentsQuery(
    userId || Number(userIdParam)
  )

  const dataTaskIncidents =
    useMemo(() => {
      return data?.filter(
        (incident) => !!incident.training_data_collection_task
      )
    }, [data]) || []

  const targetBehaviorIncidents =
    useMemo(() => {
      return data?.filter(
        (incident) =>
          !!incident.target_behavior || !!incident.freeform_target_behavior
      )
    }, [data]) || []

  const targetBehaviorIncidentsById =
    useMemo(() => {
      return data?.filter(
        (incident) => incident.target_behavior?.id === targetBehaviorId
      )
    }, [data, targetBehaviorId]) || []

  const dataTaskIncidentsById =
    useMemo(() => {
      return data?.filter(
        (incident) => incident.training_data_collection_task?.id === dataTaskId
      )
    }, [data, dataTaskId]) || []

  const getIncidentByTaskOrBehaviorId = useCallback(
    (id: number | null) => {
      if (id === null) {
        return (
          data?.filter((incident) => !!incident.freeform_target_behavior) || []
        )
      }

      return (
        data?.filter(
          (incident) =>
            incident.training_data_collection_task?.id === id ||
            incident.target_behavior?.id === id
        ) || []
      )
    },
    [data]
  )

  return {
    data,
    isLoading,
    dataTaskIncidents,
    targetBehaviorIncidents,
    targetBehaviorIncidentsById,
    dataTaskIncidentsById,
    getIncidentByTaskOrBehaviorId,
  }
}

export default useUnreadIncidents
