import {
  IncidentLogDataType,
  TargetBehavior,
  TargetBehaviorStatus,
} from "../types"
import { createJoonAPIClient } from "../util/joon-api"

export const getTargetBehaviors = async ({ userId }: { userId: number }) => {
  const targetBehaviorStatusOrder = [
    TargetBehaviorStatus.REGRESSING,
    TargetBehaviorStatus.BASELINING,
    TargetBehaviorStatus.IMPROVING,
    TargetBehaviorStatus.STABLE,
    TargetBehaviorStatus.MASTERED,
  ]
  const API = createJoonAPIClient()
  return API.get<{ results: TargetBehavior[] }>("api/target-behaviors/", {
    params: { user_id: userId },
  }).then((res) =>
    res.data.results.sort((a, b) => {
      return (
        targetBehaviorStatusOrder.indexOf(a.status) -
        targetBehaviorStatusOrder.indexOf(b.status)
      )
    })
  )
}

export const addTargetBehavior = async ({
  userId,
  title,
  description,
  preventiveStrategies,
  status,
  usedInIncidentLogs,
  incidentLogDataType,
}: {
  userId: number
  title: string
  description: string
  preventiveStrategies: { title: string }[]
  status: TargetBehaviorStatus
  usedInIncidentLogs: boolean
  incidentLogDataType: IncidentLogDataType
}) => {
  const API = createJoonAPIClient()
  return API.post("api/target-behaviors/", {
    user_id: userId,
    title,
    description,
    preventive_strategies: preventiveStrategies,
    status,
    incident_log_data_type: usedInIncidentLogs ? incidentLogDataType : null,
  })
}

export const editTargetBehavior = async ({
  targetBehaviorId,
  title,
  description,
  preventiveStrategies,
  status,
  usedInIncidentLogs,
  incidentLogDataType,
}: {
  targetBehaviorId: number
  title: string
  description: string
  preventiveStrategies: { title: string }[]
  status: TargetBehaviorStatus
  usedInIncidentLogs: boolean
  incidentLogDataType: IncidentLogDataType
}) => {
  const API = createJoonAPIClient()
  return API.patch(`api/target-behaviors/${targetBehaviorId}/`, {
    title,
    description,
    preventive_strategies: preventiveStrategies,
    status,
    incident_log_data_type: usedInIncidentLogs ? incidentLogDataType : null,
  })
}

export const deleteTargetBehavior = async ({
  targetBehaviorId,
}: {
  targetBehaviorId: number
}) => {
  const API = createJoonAPIClient()
  return API.delete(`api/target-behaviors/${targetBehaviorId}/`)
}
