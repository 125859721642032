import {
  Button,
  FlexBox,
  JoonUIColor,
  Modal,
  ModalHeader,
  SPACING,
  TextButton,
  Typography,
} from "@joonapp/web-shared"
import { useParams } from "react-router-dom"

import useEditTrainingMutation from "./mutations/useEditTrainingMutation"
import { useTrainingStatusConfirmationModalStore } from "./useTrainingStatusModalStore"
import { ParentTrainingStatusText } from "../../../constants"
import { ParentTrainingStatus } from "../../../types"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../util/analytics"

const TrainingStatusConfirmationModal = () => {
  const { isOpen, onClose, selectedStatus } =
    useTrainingStatusConfirmationModalStore()
  const { trainingId, userId } = useParams()
  const isExamplePatient = Number(userId) === 0

  const editTrainingMutation = useEditTrainingMutation()

  if (!selectedStatus) return null

  const onSubmit = async () => {
    if (!trainingId) return
    editTrainingMutation.mutate({
      id: +trainingId,
      status: selectedStatus,
    })
    onClose()
    trackAnalyticEvent(ANALYTIC_EVENTS.change_parent_training_status, {
      status: selectedStatus,
    })
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalHeader
        onClose={onClose}
        title={`Mark training as ${ParentTrainingStatusText[selectedStatus]}?`}
        showBorderBottom
      />
      <FlexBox
        direction="column"
        gap={SPACING.space4}
        style={{
          width: "min(400px, 95vw)",
          padding: SPACING.space6,
          paddingTop: SPACING.space4,
        }}
      >
        <div>{TrainingStatusConfirmationText[selectedStatus]}</div>
        <Typography variant="body">
          You can always change this state later
        </Typography>
        <FlexBox
          direction="row"
          align="center"
          wrap={false}
          gap={SPACING.space4}
          justify="flex-end"
        >
          <TextButton onClick={onClose}>
            <Typography
              variant="bodyBold"
              color={JoonUIColor.text.primaryAccent}
            >
              Cancel
            </Typography>
          </TextButton>
          <Button
            onClick={onSubmit}
            text="Update status"
            isLoading={editTrainingMutation.isLoading}
            disabled={isExamplePatient}
          />
        </FlexBox>
      </FlexBox>
    </Modal>
  )
}

export default TrainingStatusConfirmationModal

const TrainingStatusConfirmationText = {
  [ParentTrainingStatus.INACTIVE]: (
    <>
      <Typography variant="bodyBold">
        This training will no longer be displayed to the parent.{" "}
      </Typography>
      <Typography variant="body">
        Assigned parents will no longer be able to access this training from
        their Joon app.
      </Typography>
    </>
  ),
  [ParentTrainingStatus.IN_PROGRESS]: (
    <>
      <Typography variant="bodyBold">
        This action will send this training to the parent.{" "}
      </Typography>
      <Typography variant="body">
        Assigned parents will be able to access this training directly from
        their Joon app.
      </Typography>
    </>
  ),
  [ParentTrainingStatus.MASTERED]: (
    <>
      <Typography variant="bodyBold">
        This training will be marked as completed for the parent.{" "}
      </Typography>
      <Typography variant="body">
        Assigned parents will be able to revisit completed training any time.
      </Typography>
    </>
  ),
}
