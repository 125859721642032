import { useMutation, useQueryClient } from "@tanstack/react-query"

import { createToast, Toasts } from "../../../../components/toast/Toast"
import { createDataTask } from "../../../../networking/dataTasks"
import {
  QUERY_KEYS,
  useCurrentTrainingQuery,
} from "../../../../networking/queries"
import { ParentTrainingDataCollectionTask } from "../../../../types"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../../util/analytics"

const useAddDataTaskMutation = () => {
  const queryClient = useQueryClient()
  const { data: currentTraining } = useCurrentTrainingQuery()

  const queryKey = [QUERY_KEYS.PARENT_TRAINING_DATA_TASKS, currentTraining?.id]

  return useMutation({
    mutationFn: createDataTask,
    onSuccess: (response) => {
      queryClient.invalidateQueries([QUERY_KEYS.PARENT_TRAINING_DATA_TASKS])
      trackAnalyticEvent(ANALYTIC_EVENTS.add_data_collection_task)

      const newTask = response.data
      const previousTasks =
        queryClient.getQueryData<ParentTrainingDataCollectionTask[]>(queryKey)

      if (!previousTasks) return

      queryClient.setQueryData<ParentTrainingDataCollectionTask[]>(queryKey, [
        ...previousTasks,
        newTask,
      ])

      createToast(Toasts.ADD_TASK_SUCCESS)
    },
    onSettled: () => {
      queryClient.invalidateQueries(queryKey)
    },
  })
}

export default useAddDataTaskMutation
