import {
  Button,
  FlexBox,
  IconProps,
  JoonUIColor,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import { useMediaQuery } from "@mui/material"

import HomeRoutinesEmptyState from "./HomeRoutinesEmptyState"
import HomeRoutineViewToggle from "./HomeRoutineViewToggle"
import OverTimeProgress from "./overTimeProgress/OverTimeProgress"
import {
  HomeRoutineView,
  useHomeRoutineViewStore,
} from "./useHomeRoutineViewToggleStore"
import AddEditQuestModal from "./weeklyProgress/EditQuestModal"
import QuestDetailsModal from "./weeklyProgress/QuestDetailsModal"
import { useAddEditQuestModalStore } from "./weeklyProgress/useAddEditQuestModalStore"
import WeeklyProgress from "./weeklyProgress/WeeklyProgress"
import { QuickActionMobileButtonGroup } from "../../../components/buttons/QuickActionMobileButton"
import PageContentWrapper from "../../../components/layout/PageContentWrapper"
import { SkeletonLoaderRow } from "../../../components/loading/SkeletonLoader"
import { usePatientWeeklyProgressQuery } from "../../../networking/queries"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../util/analytics"

const HomeRoutinesPage = () => {
  const { view } = useHomeRoutineViewStore()
  const { onOpen: openAddQuestModal } = useAddEditQuestModalStore()
  const isMobile = useMediaQuery("(max-width:800px)")
  const { data: weeklyProgressData, isLoading } =
    usePatientWeeklyProgressQuery()

  return (
    <>
      <PageContentWrapper>
        <FlexBox direction="column" gap={SPACING.space2}>
          <FlexBox
            direction="row"
            gap={SPACING.space2}
            justify="space-between"
            align="center"
            wrap={false}
          >
            <FlexBox
              direction="row"
              gap={SPACING.space2}
              align="center"
              style={{ width: "fit-content" }}
            >
              <HomeIcon size={16} color={JoonUIColor.icon.accent} />
              <Typography variant="h3">Home Routines</Typography>
            </FlexBox>
            {!isMobile && (
              <Button
                text="+ Add home routine"
                style={{ whiteSpace: "nowrap" }}
                onClick={() => {
                  openAddQuestModal()
                  trackAnalyticEvent(ANALYTIC_EVENTS.open_add_parent_training)
                }}
              />
            )}
          </FlexBox>
          <HomeRoutineViewToggle />
        </FlexBox>
        {isLoading ? (
          <SkeletonLoaderRow numRows={3} />
        ) : weeklyProgressData && weeklyProgressData.length === 0 ? (
          <HomeRoutinesEmptyState />
        ) : (
          <FlexBox direction="column" gap={SPACING.space2}>
            {view === HomeRoutineView.WEEKLY_PROGRESS ? (
              <WeeklyProgress />
            ) : (
              <OverTimeProgress />
            )}
          </FlexBox>
        )}
        <QuickActionMobileButtonGroup
          buttons={[
            {
              text: "Add home routine",
              onClick: () => {
                openAddQuestModal()
                trackAnalyticEvent(ANALYTIC_EVENTS.open_add_parent_training)
              },
            },
          ]}
        />
        <QuestDetailsModal />
        <AddEditQuestModal />
      </PageContentWrapper>
    </>
  )
}

export default HomeRoutinesPage

const HomeIcon = ({ size, color, ...props }: IconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clip-path="url(#clip0_5345_8846)">
      <g clip-path="url(#clip1_5345_8846)">
        <path
          d="M15.1056 8.98701C15.5778 8.98701 15.9944 8.54951 15.9944 7.98389C16.0222 7.70264 15.9111 7.45264 15.6889 7.23389L14.2222 5.78076V1.99951C14.2222 1.44639 13.825 0.999512 13.3333 0.999512H12.4444C11.9528 0.999512 11.5556 1.44639 11.5556 1.99951V3.14639L8.59722 0.218262C8.43056 0.0620117 8.20833 -0.000488281 8.01389 -0.000488281C7.81945 -0.000488281 7.59722 0.0307617 7.40278 0.249512L0.277778 7.23389C0.0833333 7.45264 0 7.70264 0 7.98389C0 8.54639 0.388889 8.98701 0.888889 8.98701H1.77778V11.1651C1.775 11.1933 1.775 11.2214 1.775 11.2526V14.7495C1.775 15.4401 2.27222 15.9995 2.88611 15.9995H3.33056C3.36389 15.9995 3.39722 15.9964 3.43056 15.9933C3.47222 15.9964 3.51389 15.9995 3.55556 15.9995H4.44444H5.11111C5.725 15.9995 6.22222 15.4401 6.22222 14.7495V13.9995V11.9995C6.22222 11.4464 6.61944 10.9995 7.11111 10.9995H8.88889C9.38056 10.9995 9.77778 11.4464 9.77778 11.9995V13.9995V14.7495C9.77778 15.4401 10.275 15.9995 10.8889 15.9995H11.5556H12.4583C12.4972 15.9995 12.5361 15.9995 12.575 15.9964C12.6056 15.9995 12.6361 15.9995 12.6667 15.9995H13.1111C13.725 15.9995 14.2222 15.4401 14.2222 14.7495V14.2433C14.2306 14.162 14.2361 14.0776 14.2361 13.9901L14.2167 8.98389H15.1056V8.98701Z"
          fill={color}
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_5345_8846">
        <rect width="16" height="16" fill="white" />
      </clipPath>
      <clipPath id="clip1_5345_8846">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
