import { ParentTraining } from "@joonapp/web-shared"
import { useMutation } from "@tanstack/react-query"
import { useParams } from "react-router-dom"

import { createToast } from "../../components/toast/Toast"
import { QUERY_KEYS, useCurrentTrainingQuery } from "../../networking/queries"
import { deleteQuest } from "../../networking/quests"
import { queryClient } from "../../queryClient"
import { ToastType } from "../../types"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"

const useDeleteTaskMutation = () => {
  const { userId } = useParams()
  const { data: currentTraining } = useCurrentTrainingQuery()

  const queryKey = [QUERY_KEYS.PARENT_TRAINING, currentTraining?.id]

  return useMutation({
    mutationFn: deleteQuest,
    onMutate: (seriesId) => {
      queryClient.cancelQueries(queryKey)
      queryClient.cancelQueries([QUERY_KEYS.PARENT_TRAININGS])
      // Optimistically update the parent training list
      // Snapshot the previous value
      const previousTraining =
        queryClient.getQueryData<ParentTraining>(queryKey)

      if (!currentTraining) throw new Error("No training selected")

      // Update the quest info
      const newTraining = {
        ...previousTraining,
        quests:
          previousTraining?.quests.filter((quest) => quest.id !== seriesId) ||
          [],
      } as ParentTraining

      // Optimistically update to the new value
      queryClient.setQueryData<ParentTraining>(queryKey, newTraining)
      createToast({
        title: "Task archived successfully",
        type: ToastType.SUCCESS,
      })

      return { previousTraining }
    },
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.PARENT_TRAININGS, userId])
      queryClient.invalidateQueries(queryKey)
      trackAnalyticEvent(ANALYTIC_EVENTS.archive_parent_task)
    },
    onError: (
      _err: any,
      _variables: any,
      context: { previousTraining: ParentTraining | undefined } | undefined
    ) => {
      // If the mutation fails,
      // use the context returned from onMutate to roll back
      if (context?.previousTraining) {
        queryClient.setQueryData<ParentTraining>(
          queryKey,
          context.previousTraining
        )
        createToast({
          title: "Failed to archive task",
          type: ToastType.ERROR,
        })
      }
    },
  })
}

export default useDeleteTaskMutation
