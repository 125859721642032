import {
  JoonColorExpanded,
  JoonUIColor,
  QuestInstance,
  QuestStatus,
} from "@joonapp/web-shared"
import dayjs from "dayjs"
import { RRule, Weekday } from "rrule"

import { getDayAbbreviations } from "./util"
import {
  DataFormat,
  DateQuest,
  QuestSeriesWithTargets,
} from "../pages/patientInfo/homeRoutines/overTimeProgress/data"

export interface QuestWithDates {
  quest: QuestSeriesWithTargets
  dates: { [key: string]: DateQuest }
}

export const getHighlightsFromData = (data: DataFormat): QuestWithDates[] => {
  const questsWithDates = {} as { [key: number]: QuestWithDates }

  data.quests.forEach((quest) => {
    if (!questsWithDates[quest.id]) {
      const blankQuest = { dates: {}, quest: {} } as QuestWithDates
      questsWithDates[quest.id] = blankQuest
    }
    questsWithDates[quest.id].quest = quest
  })

  Object.entries(data.dates).forEach(([date, completions]) => {
    completions.forEach((completion) => {
      questsWithDates[completion.series_id].dates[date] = completion
    })
  })

  return Object.values(questsWithDates)
}

export const getQuestStatusColor = (status?: string | null) => {
  switch (status) {
    case null:
    case undefined:
      return "transparent"
    case QuestStatus.REDEEMED:
    case QuestStatus.VERIFIED:
      return JoonColorExpanded.viridian100
    case QuestStatus.OPEN:
    case QuestStatus.REJECTED:
    case QuestStatus.SKIPPED:
    case QuestStatus.COMPLETED:
      return JoonUIColor.background.primaryNeutral
    default:
      return "transparent"
  }
}

// These next two functions convert repetition array to rrule representation and back
export const convertToByWeekday = (booleanArray: boolean[]) => {
  if (!booleanArray) return []
  const weekdays: Weekday[] = [
    RRule.SU,
    RRule.MO,
    RRule.TU,
    RRule.WE,
    RRule.TH,
    RRule.FR,
    RRule.SA,
  ]

  return booleanArray
    .map((value, index) => (value ? weekdays[index] : null))
    .filter((day): day is Weekday => day !== null)
}

export const getRepetitionArrayFromRrule = (rruleString: string): boolean[] => {
  const rrule = RRule.fromString(rruleString)
  const weekdays = [6, 0, 1, 2, 3, 4, 5] // Mapping to RRule's internal representation

  return weekdays.map((day) => rrule.options.byweekday?.includes(day) || false)
}

export const getRruleFromRepetitionArray = (repetitionArray: boolean[]) => {
  // Mapping array indices to RRule weekdays
  const rruleWeekdays = [
    RRule.SU,
    RRule.MO,
    RRule.TU,
    RRule.WE,
    RRule.TH,
    RRule.FR,
    RRule.SA,
  ]

  // Filter and map the days when the event occurs
  const days = repetitionArray
    .map((occurs, index) => (occurs ? rruleWeekdays[index] : null))
    .filter((day) => day !== null)
    .join(",")

  // Construct and return the RRule string, assuming a weekly frequency
  return `FREQ=WEEKLY;BYDAY=${days}`
}

export const getRelativeDay = (date: string) => {
  const day = dayjs(date).day()
  const today = dayjs().day()
  const diff = day - today
  return diff === 1
    ? "Tomorrow"
    : diff === 0
    ? "Today"
    : diff === -1
    ? "Yesterday"
    : dayjs(date).format("MMM DD, YYYY")
}

// Note - this is used in parent app as well
export const getNewRepetitionText = (
  repetition: boolean[] | null | undefined,
  startDate: string | null | undefined,
  repeatsFrequency: number | null | undefined
) => {
  let text = "This Quest will"
  // One off
  if (!repetition && !repeatsFrequency) {
    if (startDate) text += ` only show up ${getRelativeDay(startDate)}.`
    else text += ` show up everyday until completed.`
  } else if (repetition && repeatsFrequency) {
    if (startDate) text += ` start ${getRelativeDay(startDate)} and `
    text += ` repeat ${getDayAbbreviations(repetition)}`
    text += ` every ${repeatsFrequency} week${repeatsFrequency > 1 ? "s" : ""}`
  }
  return text
}

// Note - this is used in parent app as well
export const getRepetitionText = (
  repetition: boolean[] | null | undefined,
  startDate: string | null | undefined,
  repeatsFrequency: number | null | undefined
) => {
  let text = "This Quest will"

  if (!repetition?.some((day) => !!day)) {
    if (startDate) text += ` only show up ${getRelativeDay(startDate)}.`
    else text += ` show up everyday until completed.`
  } else if (repetition?.some((day) => !!day) && repeatsFrequency) {
    if (startDate) text += ` start ${getRelativeDay(startDate)} and `
    text += ` repeat ${getDayAbbreviations(repetition)}`
    text += ` every ${repeatsFrequency} week${repeatsFrequency > 1 ? "s" : ""}`
  }
  return text
}

export const getCompletedQuestInstances = (quests: QuestInstance[] = []) => {
  const completionStatuses = [
    QuestStatus.COMPLETED,
    QuestStatus.VERIFIED,
    QuestStatus.REDEEMED,
  ]
  return quests?.filter((instance) =>
    completionStatuses.includes(instance.status)
  )
}

export const getInReviewQuestInstances = (quests: QuestInstance[] = []) => {
  return quests?.filter((instance) => instance.status === QuestStatus.COMPLETED)
}

export const getSeriesInstances = (
  quests: QuestInstance[] = [],
  seriesId: number | null | undefined
) => {
  if (!seriesId) return quests
  return quests?.filter((instance) => instance.series.id === seriesId)
}
