import {
  ChevronLeftIcon,
  ChevronRightIcon,
  JoonUIColor,
  TextButton,
  Typography,
} from "@joonapp/web-shared"
import { Table } from "@tanstack/react-table"

import CustomTableHeader from "./CustomTableHeader"
import CustomTableRow from "./CustomTableRow"

const CustomTable = <TData,>({
  table,
  hoveredRowId,
  setHoveredRowId,
}: {
  table: Table<TData>
  hoveredRowId?: string | null
  setHoveredRowId?: (id: string | null) => void
}) => {
  const { pageIndex, pageSize } = table.getState().pagination
  const totalPages = table.getPageCount()

  return (
    <div style={{ width: "100%" }}>
      <table
        style={{ width: "100%", textAlign: "left", borderCollapse: "collapse" }}
      >
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <CustomTableHeader key={headerGroup.id} headerGroup={headerGroup} />
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row, index) => (
            <CustomTableRow
              key={row.id}
              row={row}
              isHovered={hoveredRowId === row.id}
              onMouseEnter={() => setHoveredRowId?.(row.id)}
              onMouseLeave={() => setHoveredRowId?.(null)}
              isLastRow={
                index === pageSize - 1 ||
                index === table.getRowModel().rows.length - 1
              }
            />
          ))}
        </tbody>
      </table>
      {totalPages > 1 && (
        <div
          style={{
            display: "flex",
            width: "150px",
            marginLeft: "auto",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <TextButton
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            <ChevronLeftIcon
              color={
                !table.getCanPreviousPage()
                  ? JoonUIColor.text.disabled
                  : JoonUIColor.text.primary
              }
            />
          </TextButton>
          <Typography variant="bodyXSmall" color={JoonUIColor.text.secondary}>
            {pageIndex + 1} of {totalPages}
          </Typography>
          <TextButton
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            <ChevronRightIcon
              color={
                !table.getCanNextPage()
                  ? JoonUIColor.text.disabled
                  : JoonUIColor.text.primary
              }
            />
          </TextButton>
        </div>
      )}
    </div>
  )
}

export default CustomTable
