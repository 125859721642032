import "./styles/index.scss"
import * as Sentry from "@sentry/react"
import React from "react"
import ReactDOM from "react-dom/client"
import { BrowserRouter } from "react-router-dom"

import App from "./App"

import.meta.env.PROD &&
  Sentry.init({
    dsn: "https://0ec65b3d7d154855b1711bfeed769fef@o4505077617524736.ingest.sentry.io/4505200806264832",
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    // Performance Monitoring
    tracesSampleRate: 1.0,
    // Session Replay
    replaysOnErrorSampleRate: 1.0,
  })

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
)
