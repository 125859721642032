import { ParentTraining, QuestSeries } from "@joonapp/web-shared"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useNavigate, useParams } from "react-router-dom"

import { useAddEditParentTaskStore } from "./useAddEditParentTaskStore"
import { createToast, Toasts } from "../../components/toast/Toast"
import { QUERY_KEYS } from "../../networking/queries"
import { addQuest } from "../../networking/quests"
import { ParentTrainingTab, PatientInfoTab } from "../../types"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"

const useAddTaskMutation = () => {
  const queryClient = useQueryClient()
  const { userId } = useParams()
  const navigate = useNavigate()
  const { onClose } = useAddEditParentTaskStore()

  return useMutation({
    mutationFn: addQuest,
    onSuccess: (response: { data: QuestSeries }) => {
      queryClient.invalidateQueries([QUERY_KEYS.PATIENT_GROUPS]) // for usage_notification
      queryClient.invalidateQueries([QUERY_KEYS.PARENT_TRAININGS, userId])

      trackAnalyticEvent(ANALYTIC_EVENTS.add_parent_task)

      const questInfo = response.data
      // @ts-ignore - parent_training_id usually not on quest series
      const newTrainingId = questInfo.parent_training_id
      const trainingQueryKey = [QUERY_KEYS.PARENT_TRAINING, newTrainingId]
      queryClient.invalidateQueries(trainingQueryKey)

      // Optimistically update the parent training list
      const previousTraining =
        queryClient.getQueryData<ParentTraining>(trainingQueryKey)

      if (!previousTraining) return

      const trainingWithNewTask = {
        ...previousTraining,
        quests: [...previousTraining?.quests, questInfo],
      }

      queryClient.setQueryData<ParentTraining>(
        trainingQueryKey,
        trainingWithNewTask
      )

      // Route to new training tasks tab
      if (newTrainingId) {
        navigate(
          `/patients/${userId}/${PatientInfoTab.PARENT_TRAINING}/${newTrainingId}/${ParentTrainingTab.TODO_TASKS}`
        )
      }
      onClose()

      createToast(Toasts.ADD_TASK_SUCCESS)
    },
    onError: (
      _err: any,
      variables: any,
      context: { previousTrainings: ParentTraining[] | undefined } | undefined
    ) => {
      // If the mutation fails,
      // use the context returned from onMutate to roll back
      const queryKey = [
        QUERY_KEYS.PARENT_TRAINING,
        variables.parent_training_id,
      ]
      if (context?.previousTrainings) {
        queryClient.setQueryData<ParentTraining[]>(
          queryKey,
          context.previousTrainings
        )
      }
    },
  })
}

export default useAddTaskMutation
