import {
  FlexBox,
  JoonUIColor,
  Loader,
  SPACING,
  TextButton,
  Typography,
} from "@joonapp/web-shared"
import { useMediaQuery } from "@mui/material"
import { useState } from "react"

import useSubscription from "./useSubscription"
import {
  getStripeBillingUrl,
  getStripeCheckoutUrl,
} from "../../networking/practice"
import { usePracticeQuery } from "../../networking/queries"
import useAdminUser from "../../pages/settings/useAdminUser"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"

const SubscriptionSection = () => {
  const { subscriptionText, isSubscribed, isFreeTrial } = useSubscription()

  const { practice } = usePracticeQuery()
  const [isLoading, setIsLoading] = useState(false)

  const isMobile = useMediaQuery("(max-width: 800px)")
  const isAdminUser = useAdminUser()

  const onClick = async () => {
    if (!practice || isLoading) return
    setIsLoading(true)
    if (isSubscribed || isFreeTrial) {
      const response = await getStripeBillingUrl(practice?.id)
      window.open(response, "_blank")
      trackAnalyticEvent(ANALYTIC_EVENTS.settings_billing_link)
    } else {
      const response = await getStripeCheckoutUrl(practice?.id)
      window.open(response, "_blank")
      trackAnalyticEvent(ANALYTIC_EVENTS.settings_checkout_link)
    }
    setIsLoading(false)
  }

  return (
    <FlexBox direction="column" gap={SPACING.space4}>
      <Typography variant="h3">Subscription</Typography>
      <FlexBox
        direction={isMobile ? "column" : "row"}
        align={isMobile ? "flex-start" : "center"}
        wrap={false}
        justify="space-between"
        style={{
          padding: SPACING.space4,
          borderRadius: SPACING.space4,
          background: JoonUIColor.background.primaryNeutral,
          width: "100%",
          border: `1px solid ${JoonUIColor.border.default}`,
        }}
      >
        <Typography variant="body">{subscriptionText}</Typography>
        {isAdminUser && (
          <TextButton onClick={onClick}>
            <Typography
              variant="bodyBold"
              color={JoonUIColor.text.primaryAccent}
            >
              Manage subscription
              {isLoading && (
                <Loader
                  size={12}
                  color={JoonUIColor.semantic.primary}
                  thickness={2}
                />
              )}
            </Typography>
          </TextButton>
        )}
      </FlexBox>
    </FlexBox>
  )
}

export default SubscriptionSection
