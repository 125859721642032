import {
  Button,
  FlexBox,
  SPACING,
  TextInput,
  Typography,
} from "@joonapp/web-shared"
import { Alert, Box } from "@mui/material"
import React, { useState } from "react"

import AuthFooter from "./AuthFooter"
import { resetPassword } from "../../networking/authentication"
import { AlertInterface } from "../../types"

const ForgotPassword = () => {
  const [formAlert, setFormAlert] = useState<AlertInterface | null>(null)
  const [email, setEmail] = useState("")

  const [pending, setPending] = useState(false)

  const hasFormError = (response: any) => {
    const errorMessage = response.error ?? response.data?.error
    if (errorMessage) {
      setPending(false)
      setFormAlert({ type: "error", message: errorMessage })
      return true
    } else {
      setFormAlert(null)
      return false
    }
  }

  function forgotpass(e: React.FormEvent) {
    e.preventDefault()
    resetPassword(email).then((response) => {
      if (hasFormError(response)) return

      // Show success alert message
      setFormAlert({ type: "success", message: "Password reset email sent" })
    })
  }

  return (
    <FlexBox direction="column" gap={SPACING.space6}>
      <Typography variant="h2" textAlign="center">
        Get a new password
      </Typography>

      {formAlert && (
        <Box mb={3}>
          <Alert severity={formAlert.type}>{formAlert.message}</Alert>
        </Box>
      )}

      <form onSubmit={forgotpass} style={{ width: "100%" }}>
        <FlexBox direction="column" gap={SPACING.space6}>
          <TextInput
            type="email"
            label="Email"
            icon="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            name="email"
            placeholder="user@example.com"
            fullWidth={true}
          />
          <Button
            isLoading={pending}
            text="Reset Password"
            type="submit"
            fullWidth
          />
        </FlexBox>
      </form>

      <AuthFooter type="forgotpass" />
    </FlexBox>
  )
}

export default ForgotPassword
