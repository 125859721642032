import { useMutation, useQueryClient } from "@tanstack/react-query"

import { createToast, Toasts } from "../../components/toast/Toast"
import { QUERY_KEYS } from "../../networking/queries"
import { changeUserRole } from "../../networking/user"
import { ToastType } from "../../types"

const useSwitchClinicianRoleMutation = () => {
  const queryClient = useQueryClient()
  return useMutation(changeUserRole, {
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.PRACTICE])
      createToast({
        title: "User role updated",
        type: ToastType.SUCCESS,
      })
    },
    onError: () => createToast(Toasts.GENERAL_ERROR),
  })
}

export default useSwitchClinicianRoleMutation
