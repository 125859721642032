import { useQuery } from "@tanstack/react-query"

import { getSubscriptionInfo } from "../../networking/practice"
import { QUERY_KEYS, usePracticeQuery } from "../../networking/queries"

const useSubscriptionInfoQuery = () => {
  const { practice } = usePracticeQuery()
  const practiceId = practice?.id

  return useQuery({
    queryKey: [QUERY_KEYS.SUBSCRIPTION_INFO, practiceId],
    queryFn: () => getSubscriptionInfo(practiceId!),
    enabled: !!practiceId,
  })
}

export default useSubscriptionInfoQuery
