import { useNavigate, useParams } from "react-router-dom"

import { useAddEditDataTaskStore } from "./useAddEditDataTaskStore"
import { ParentTrainingTab } from "../../../../types"
import { PatientInfoTab } from "../../../../types"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../../util/analytics"
import SummarySectionEmptyState from "../SummarySectionEmptyState"

const DataTasksEmptyState = () => {
  const { onOpen: openAddDataTaskModal } = useAddEditDataTaskStore()

  const navigate = useNavigate()
  const { userId, trainingId } = useParams()

  const onClickAdd = () => {
    navigate(
      `/patients/${userId}/${PatientInfoTab.PARENT_TRAINING}/${trainingId}/${ParentTrainingTab.DATA_TASKS}`
    )
    openAddDataTaskModal()
    trackAnalyticEvent(ANALYTIC_EVENTS.open_add_modules)
  }

  return (
    <SummarySectionEmptyState
      onClick={onClickAdd}
      title="Add practice tasks"
      description="Practicing key learnings and building a new habit at home. You can
            set a Goal and ask parents to track progress towards the goal as
            well."
    />
  )
}

export default DataTasksEmptyState
