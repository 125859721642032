import { useState, useRef, useEffect } from "react"

const DrawerContainer: React.FC<{
  children: React.ReactNode
  showDrawer: boolean
  resizeDependencies?: any[]
}> = ({ children, showDrawer, resizeDependencies = [] }) => {
  const [tableWidth, setTableWidth] = useState(300) // Initial width
  const contentRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.style.maxHeight = showDrawer
        ? `${contentRef.current.scrollHeight}px`
        : "0px"
    }
  }, [showDrawer, resizeDependencies])

  useEffect(() => {
    if (contentRef.current) {
      const containerWidth = contentRef.current.offsetWidth
      setTableWidth(containerWidth - 16)
    }
  }, [showDrawer])

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      for (const entry of entries) setTableWidth(entry.contentRect.width - 16)
    })
    if (contentRef.current) observer.observe(contentRef.current)
    return () => observer.disconnect()
  }, [])

  return (
    <div
      ref={contentRef}
      style={{
        transition: "max-height 0.2s ease-out",
        maxHeight: "0px",
        overflowY: "hidden",
        overflowX: "auto",
        scrollbarWidth: "thin",
      }}
    >
      <div style={{ width: `${tableWidth}px` }}>{children}</div>
    </div>
  )
}

export default DrawerContainer
