import { JoonUIColor, Typography } from "@joonapp/web-shared"
import { useId } from "react"

import CustomTooltip from "../tooltip/CustomTooltip"

const TherapistIconTooltip = ({
  hideTooltip = false,
  text = "Created by therapist",
}) => {
  const id = useId()

  return (
    <>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-tooltip-id={id}
        style={{ minWidth: "16px", minHeight: "16px" }}
      >
        <rect width="16" height="16" rx="8" fill="#5E65E9" />
        <g clip-path="url(#clip0_1149_43754)">
          <path
            d="M5.40317 3.82348C5.50304 4.12309 5.34075 4.44588 5.04293 4.54575L4.57568 4.7009V6.857C4.57568 7.80218 5.34253 8.56903 6.28772 8.56903C7.23291 8.56903 7.99976 7.80218 7.99976 6.857V4.7009L7.5343 4.54575C7.23469 4.44588 7.07419 4.12309 7.17406 3.82348C7.27392 3.52388 7.59671 3.36337 7.89632 3.46324L8.36178 3.61839C8.82724 3.77355 9.14111 4.20869 9.14111 4.7009V6.857C9.14111 8.23376 8.16739 9.38225 6.87088 9.65154C6.98323 10.6484 7.82855 11.4224 8.85577 11.4224C9.95968 11.4224 10.8532 10.529 10.8532 9.42505V8.16421C10.3485 7.94485 9.99713 7.44194 9.99713 6.857C9.99713 6.06875 10.6356 5.4303 11.4238 5.4303C12.2121 5.4303 12.8505 6.06875 12.8505 6.857C12.8505 7.44194 12.4992 7.94485 11.9945 8.16421V9.42505C11.9945 11.1585 10.5892 12.5638 8.85577 12.5638C7.19902 12.5638 5.84366 11.2815 5.72596 9.65511C4.41875 9.39295 3.43433 8.24089 3.43433 6.857V4.7009C3.43433 4.21047 3.7482 3.77355 4.21544 3.61839L4.6809 3.46324C4.98051 3.36337 5.3033 3.52566 5.40317 3.82348ZM11.4238 7.42768C11.5752 7.42768 11.7203 7.36755 11.8274 7.26053C11.9344 7.1535 11.9945 7.00835 11.9945 6.857C11.9945 6.70564 11.9344 6.56049 11.8274 6.45347C11.7203 6.34644 11.5752 6.28632 11.4238 6.28632C11.2725 6.28632 11.1273 6.34644 11.0203 6.45347C10.9133 6.56049 10.8532 6.70564 10.8532 6.857C10.8532 7.00835 10.9133 7.1535 11.0203 7.26053C11.1273 7.36755 11.2725 7.42768 11.4238 7.42768Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_1149_43754">
            <rect
              width="10.2722"
              height="9.13086"
              fill="white"
              transform="translate(2.86389 3.43359)"
            />
          </clipPath>
        </defs>
      </svg>

      {!hideTooltip && (
        <CustomTooltip id={id}>
          <Typography variant="bodySmall" color={JoonUIColor.text.inverted}>
            {text}
          </Typography>
        </CustomTooltip>
      )}
    </>
  )
}

export default TherapistIconTooltip
