import { FlexBox, JoonUIColor, SPACING, Typography } from "@joonapp/web-shared"
import { useNavigate, useParams } from "react-router-dom"

interface Props {
  type: "signin" | "forgotpass"
}

function AuthFooter({ type }: Props) {
  const navigate = useNavigate()

  const { type: authType } = useParams()
  const hideSignupButton = authType === "login"

  return (
    <FlexBox direction="column" gap={SPACING.space2}>
      <Typography variant="bodySmall" textAlign="center">
        {type === "signin" && (
          <button
            style={{ padding: "0" }}
            onClick={() => navigate("/auth/forgotpass")}
          >
            <Typography
              variant="caption"
              color={JoonUIColor.text.primaryAccent}
            >
              Forgot password?
            </Typography>
          </button>
        )}

        {type === "forgotpass" && (
          <>
            Remember it after all?{" "}
            <button
              style={{ padding: "0" }}
              onClick={() => navigate("/auth/signin")}
            >
              <Typography
                variant="caption"
                color={JoonUIColor.text.primaryAccent}
              >
                Sign In
              </Typography>
            </button>
          </>
        )}
      </Typography>

      {!hideSignupButton && (
        <Typography variant="bodySmall" textAlign="center">
          Don't have an account yet?{" "}
          <button style={{ padding: "0" }} onClick={() => navigate("/onboard")}>
            <Typography
              variant="caption"
              color={JoonUIColor.text.primaryAccent}
            >
              Click here
            </Typography>
          </button>{" "}
          to sign up.
        </Typography>
      )}
    </FlexBox>
  )
}

export default AuthFooter
