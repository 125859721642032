import {
  Button,
  CalloutBox,
  CheckIcon,
  CloseIcon,
  DropdownOption,
  FlexBox,
  JoonUIColor,
  Loader,
  SPACING,
  SelectInput,
  TextInput,
  Typography,
} from "@joonapp/web-shared"
import { useMediaQuery } from "@mui/material"
import { useEffect, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"

import useOnboarding, {
  OnboardingSteps,
  useCreatePractice,
} from "../../hooks/useOnboarding"
import { useUserQuery } from "../../networking/queries"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"
import { useAuth } from "../../util/auth"
import { createJoonAPIClient } from "../../util/joon-api"

const UserInfo = () => {
  const {
    setStep,
    formInfo,
    setFormInfo,
    submitting,
    hasClinic,
    setHasClinic,
    clinicCode,
    setClinicCode,
  } = useOnboarding()
  const [showFormError, setShowFormError] = useState(false)
  const navigate = useNavigate()
  const { createNoAuth } = useCreatePractice()
  const { signout } = useAuth()
  const { user } = useUserQuery()
  const isAuthenticated = !!user
  const isMobile = useMediaQuery("(max-width: 800px)")

  const [codeIsValid, setCodeIsValid] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (isAuthenticated) {
      setFormInfo("firstName", user?.name || "")
    }
  }, [])

  useEffect(() => {
    if (clinicCode.length !== 8) return setCodeIsValid(false)
    validateClinicCode(clinicCode)
  }, [clinicCode])

  const validateClinicCode = async (code: string) => {
    const API = createJoonAPIClient()
    setIsLoading(true)
    try {
      const requestBody = {
        params: { code, type: "group", group_type: "practice" },
      }
      await API.get("api/auth-codes/validate/", requestBody)
      setCodeIsValid(true)
      setHasClinic(true)
    } catch (error) {
      setCodeIsValid(false)
      return "Invalid clinic code"
    } finally {
      setIsLoading(false)
    }
  }

  const formErrors = useMemo(() => {
    const missingFields = []
    if (formInfo.firstName.length === 0) missingFields.push("first name")
    if (formInfo.lastName.length === 0) missingFields.push("last name")
    if (formInfo.profession.length === 0) missingFields.push("profession")
    if (formInfo.clinicName.length === 0 && !hasClinic)
      missingFields.push("clinic name")

    if (missingFields.length > 1)
      return `Please enter valid ${missingFields
        .slice(0, missingFields.length - 1)
        .join(", ")} and ${missingFields[missingFields.length - 1]}`
    else if (missingFields.length === 1)
      return `Please enter a valid ${missingFields[0]}`
    return ""
  }, [formInfo, hasClinic])

  const buttonIsDisabled = useMemo(() => {
    if (hasClinic === null) return true
    if (hasClinic) return !codeIsValid || clinicCode.length !== 8
    return submitting
  }, [hasClinic, codeIsValid, clinicCode, submitting])

  if (submitting)
    return (
      <FlexBox justify="center" align="center" style={{ minHeight: "400px" }}>
        <Loader color={JoonUIColor.semantic.primary} />
      </FlexBox>
    )

  return (
    <FlexBox direction="column" align="center">
      <Typography
        variant="h3"
        textAlign="center"
        style={{ marginBottom: SPACING.space3 }}
      >
        Tell us about yourself
      </Typography>
      {isAuthenticated && (
        <button>
          <Typography variant="bodySmall">Not {user?.email}? </Typography>
          <button style={{ textDecoration: "underline" }} onClick={signout}>
            <Typography
              variant="caption"
              color={JoonUIColor.text.primaryAccent}
            >
              Switch Accounts
            </Typography>
          </button>
        </button>
      )}
      <FlexBox
        direction="column"
        gap={SPACING.space4}
        style={{
          marginBottom: SPACING.space3,
          marginTop: SPACING.space3,
          textAlign: "left",
        }}
      >
        <FlexBox
          direction="row"
          wrap={false}
          align="center"
          justify="space-between"
        >
          <FlexBox style={{ width: "49%" }}>
            <Typography variant="caption">First Name</Typography>
            <TextInput
              name="First name"
              value={formInfo.firstName}
              placeholder="Jeff"
              fullWidth
              maxLength={30}
              onChange={(e) => setFormInfo("firstName", e.target.value)}
            />
          </FlexBox>

          <FlexBox style={{ width: "49%" }}>
            <Typography variant="caption">Last Name</Typography>
            <TextInput
              name="Last Name"
              value={formInfo.lastName}
              fullWidth
              placeholder="Goldblum"
              onChange={(e) => setFormInfo("lastName", e.target.value)}
              maxLength={50}
            />
          </FlexBox>
        </FlexBox>

        <FlexBox justify="space-between">
          <Typography variant="caption">Type of therapist</Typography>
          <SelectInput
            options={typesOfTherapists}
            selectedOption={
              typesOfTherapists.find(
                (option) => option.value === formInfo.profession
              ) as DropdownOption
            }
            name="Type of therapist"
            placeholder="Select your profession"
            setSelectedOption={(option) =>
              setFormInfo("profession", option.value as string)
            }
            isMulti={false}
            isSearchable={!isMobile}
            fullWidth
          />
        </FlexBox>
        <FlexBox direction="column" gap={SPACING.space1}>
          <Typography variant="caption">
            Are you joining an existing clinic?
          </Typography>
          <FlexBox
            gap={SPACING.space2}
            justify="space-between"
            align="center"
            wrap={false}
          >
            <YesNoButton
              text="Yes"
              isSelected={hasClinic}
              onClick={() => setHasClinic(true)}
            />
            <YesNoButton
              text="No (more common)"
              isSelected={hasClinic === false}
              onClick={() => setHasClinic(false)}
            />
          </FlexBox>
        </FlexBox>
        {hasClinic === true ? (
          <FlexBox>
            <Typography variant="caption">
              Enter the clinic invite code
            </Typography>
            <FlexBox style={{ position: "relative" }}>
              <TextInput
                name="Clinic code"
                value={clinicCode}
                fullWidth
                placeholder="ABCD1234"
                onChange={(e: any) => setClinicCode(e.target.value)}
                maxLength={8}
              />
              {clinicCode.length === 8 && (
                <div
                  style={{ position: "absolute", right: "12px", top: "12px" }}
                >
                  {isLoading ? (
                    <Loader size={16} color={JoonUIColor.icon.neutral} />
                  ) : codeIsValid ? (
                    <CheckIcon color={JoonUIColor.semantic.success} />
                  ) : (
                    <CloseIcon color={JoonUIColor.semantic.destructive} />
                  )}
                </div>
              )}
            </FlexBox>
          </FlexBox>
        ) : (
          hasClinic === false && (
            <>
              <FlexBox direction="column" gap={SPACING.space1}>
                <FlexBox>
                  <Typography variant="caption">Name of clinic</Typography>
                  <TextInput
                    name="Name of clinic"
                    fullWidth
                    value={formInfo.clinicName}
                    placeholder="Greenwood Therapy"
                    onChange={(e) => setFormInfo("clinicName", e.target.value)}
                    maxLength={30}
                  />
                </FlexBox>
              </FlexBox>
            </>
          )
        )}
      </FlexBox>

      {formErrors && showFormError && (
        <CalloutBox fullWidth type="error">
          <Typography variant="bodySmall">{formErrors}</Typography>
        </CalloutBox>
      )}

      <Button
        text="Next"
        isLoading={submitting}
        fullWidth
        style={{ marginTop: SPACING.space3 }}
        disabled={buttonIsDisabled}
        onClick={() => {
          if (formErrors.length > 0) return setShowFormError(true)
          trackAnalyticEvent(ANALYTIC_EVENTS.onboard_add_user_info)
          if (isAuthenticated) createNoAuth()
          else setStep(OnboardingSteps.emailpw)
        }}
      />

      {!isAuthenticated && (
        <div style={{ marginTop: SPACING.space6 }}>
          <Typography variant="bodySmall">Already have an account?</Typography>
          <button
            onClick={() => {
              trackAnalyticEvent(
                ANALYTIC_EVENTS.onboard_has_account_login_button_click
              )
              signout()
              navigate("/auth/signin")
            }}
            className="blue-text"
            style={{ textDecoration: "underline" }}
          >
            <Typography
              variant="caption"
              color={JoonUIColor.text.primaryAccent}
            >
              Login Here
            </Typography>
          </button>
        </div>
      )}
    </FlexBox>
  )
}

export default UserInfo

const YesNoButton = ({
  text,
  onClick,
  isSelected,
}: {
  text: string | JSX.Element
  onClick: () => void
  isSelected?: boolean | null
}) => {
  return (
    <button
      style={{
        height: SPACING.space10,
        border: isSelected
          ? `1px solid ${JoonUIColor.border.accent}`
          : `1px solid ${JoonUIColor.border.default}`,
        width: "100%",
        borderRadius: SPACING.space2,
        backgroundColor: "white",
        color: isSelected
          ? JoonUIColor.text.primaryAccent
          : JoonUIColor.text.secondary,
        fontWeight: isSelected ? "600" : "normal",
      }}
      onClick={onClick}
    >
      {text}
    </button>
  )
}

const typesOfTherapists = [
  {
    label: "Licensed Mental Health Counselor (LMHC)",
    value: "Licensed Mental Health Counselor (LMHC)",
  },
  {
    label: "Occupational Therapist (OT)",
    value: "Occupational Therapist (OT)",
  },
  {
    label: "Licensed Professional Counselor (LPC)",
    value: "Licensed Professional Counselor (LPC)",
  },
  { label: "Behavior Analyst (BCBA)", value: "Behavior Analyst (BCBA)" },
  {
    label: "Registered Behavior Technician (RBT)",
    value: "Registered Behavior Technician (RBT)",
  },
  {
    label: "Marriage and Family Therapist (LMFT)",
    value: "Marriage and Family Therapist (LMFT)",
  },
  {
    label: "Occupational Therapy Assistant (OTA)",
    value: "Occupational Therapy Assistant (OTA)",
  },
  { label: "Clinical Psychologist", value: "Clinical Psychologist" },
  { label: "Child Psychiatrist", value: "Child Psychiatrist" },
  { label: "Social Worker (LCSW)", value: "Social Worker (LCSW)" },
  {
    label: "Speech-Language Pathologist (SLP)",
    value: "Speech-Language Pathologist (SLP)",
  },
  {
    value: "Nurse Practitioner (NP)",
    label: "Nurse Practitioner (NP)",
  },
  { label: "School Psychologist", value: "School Psychologist" },
  { label: "School Counselor", value: "School Counselor" },
  {
    label: "Child and Adolescent Counselor",
    value: "Child and Adolescent Counselor",
  },
  { label: "Developmental Pediatrician", value: "Developmental Pediatrician" },
  { label: "Play Therapist", value: "Play Therapist" },
  { label: "Art Therapist", value: "Art Therapist" },
  { label: "Pediatrician", value: "Pediatrician" },
  { label: "Music Therapist", value: "Music Therapist" },
  { label: "Coach", value: "Coach" },
  { label: "Trainee (Masters)", value: "Trainee (Masters)" },
  { label: "Trainee (PhD)", value: "Trainee (PhD)" },
  {
    label: "Special Education Teacher (SPED)",
    value: "Special Education Teacher (SPED)",
  },
  { label: "Teacher (Gen. Ed)", value: "Teacher (Gen. Ed)" },
  { label: "Other", value: "Other" },
]
