import { JoonUIColor, SPACING, Typography } from "@joonapp/web-shared"
import { useNavigate } from "react-router-dom"

import { BackButton2 } from "../../components/buttons/BackButton2"
import PageContentWrapper from "../../components/layout/PageContentWrapper"
import PageWrapper from "../../components/pageWrapper/PageWrapper"
import { requireAuth } from "../../util/auth"

const HIPPACompliance = requireAuth(() => {
  const navigate = useNavigate()

  return (
    <PageWrapper>
      <PageContentWrapper>
        <BackButton2 text="Back" onClick={() => navigate("/security")} />
        <Typography variant="h2">HIPAA & Security Info</Typography>
        <div
          style={{
            background: JoonUIColor.background.primaryNeutral,
            border: `1px solid ${JoonUIColor.border.default}`,
            padding: SPACING.space4,
            borderRadius: SPACING.space4,
          }}
        >
          <div>
            The Health Insurance Portability and Accountability Act (HIPAA) and
            Health Information Technology for Economic and Clinical Health
            (HITECH) Act defines policies, procedures, and processes that are
            required for companies that store, process, or handle electronic
            protected health information (ePHI).
          </div>
          <br />
          <div className="body-text bold">
            At Joon, we take our responsibilities towards customer &
            patient/client confidentiality very seriously and have dedicated
            both resources and time to train our workforce and develop and
            implement all of the components of our HIPAA Compliance Program.
          </div>
          <br />
          <div className="body-text bold">
            To ensure we are compliant with HIPAA and HITECH Act, ensure that we
            have the required safeguards in place to protect ePHI, and
            demonstrate HIPAA compliance to our clients:
          </div>
          <ul>
            <li>
              Joon has developed and implemented a comprehensive HIPAA
              Compliance Program following the HIPAA Privacy and HIPAA Security
              Rule – focusing on the administrative, physical and technical
              requirements of the HIPAA Security Rule as it applies to any
              potential risk associated with the use of PHI in our business.
            </li>
            <li>
              Joon ensures technological protocols such as: tight access
              controls, integrity procedures, firewalls, information systems
              activity monitoring and other audit mechanisms to record access in
              information systems that use ePHI, use of encryption, automatic
              logoffs, password management procedures, and VPN tunnel.
            </li>
            <li>
              Joon has conducted a formal risk assessment to identify and
              document any area of risk associated with the storage,
              transmission, and processing of ePHI and have analyzed the use of
              our administrative, physical, and technical controls to eliminate
              or manage vulnerabilities that could be exploited by internal or
              external threats.
            </li>
            <li>Joon has limited access to ePHI.</li>
          </ul>
          <div className="body-text bold">We are Dedicated to:</div>
          <ul>
            <li>
              Ensuring we are compliant with the regulatory requirements of
              HIPAA/HITECH
            </li>
            <li>
              Continuing to develop our safeguards to prevent unauthorized
              access to PHI.
            </li>
            <li>Adhering to the requirement to encrypt PHI</li>
            <li>Maintaining PHI in a secure environment</li>
            <li>
              Monitoring access to both the secure environment and the data
            </li>
          </ul>

          <div className="body-text bold">
            We are Confident that Our Comprehensive HIPAA Policies and
            Procedures Will:
          </div>
          <ul>
            <li>
              Ensure the confidentiality, integrity, and availability of all
              e-PHI we receive, maintain or transmit
            </li>
            <li>
              Identify and protect against reasonably anticipated threats to the
              security or integrity of the information
            </li>
            <li>
              Protect against reasonably anticipated, impermissible uses or
              disclosures
            </li>
            <li>Ensure compliance of our workforce.</li>
          </ul>
          <br />
          <div>This Compliance Statement is valid from: 06/01/2023</div>
        </div>
      </PageContentWrapper>
    </PageWrapper>
  )
})

export default HIPPACompliance
