import { JoonUIColor } from "@joonapp/web-shared"
import { useMediaQuery } from "@mui/material"
import { useRef } from "react"

import useHiddenElement from "../../hooks/useHiddenElement"

type Props = {
  isOpen: boolean
  style?: React.CSSProperties
  children: React.ReactNode
}

const ColumnSidePanel = ({ isOpen, style, children }: Props) => {
  const isMobile = useMediaQuery("(max-width:800px)")
  const sidebarRef = useRef<HTMLDivElement>(null)

  useHiddenElement(sidebarRef, !isOpen)

  const width = isOpen
    ? isMobile
      ? style?.width || "min(450px, 100vw)"
      : style?.width || "min(375px, 100vw)"
    : "0px"

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flexWrap: "nowrap",
        overflow: "hidden",
        borderLeft: `1px solid ${JoonUIColor.border.default}`,
        background: JoonUIColor.background.primaryNeutral,
        zIndex: 960,
        transition:
          "right 0.2s ease, max-width 0.15s ease-in, min-width 0.15s ease-in",
        right: isOpen ? 0 : "-100%",
        minWidth: width,
        maxWidth: width,
        width: width,
        height: isMobile ? "100dvh" : "calc(100dvh - 77px)",
        position: isMobile ? "fixed" : "absolute",
        top: 0,
        ...style,
      }}
      ref={sidebarRef}
    >
      {children}
    </div>
  )
}

export default ColumnSidePanel
