import {
  Button,
  DownloadIcon,
  JoonUIColor,
  TextButton,
  Typography,
} from "@joonapp/web-shared"
import { useMediaQuery } from "@mui/material"
import { useId } from "react"
import { useParams } from "react-router-dom"

import CustomTooltip from "../../../components/tooltip/CustomTooltip"
import { getExportLink } from "../../../networking/incidents"
import { getMasteryProgressExportLink } from "../../../networking/parentTraining"

export enum ExportType {
  BEHAVIOR_INCIDENTS = "behavior_incidents",
  OBSERVATIONAL_DATA = "observational_data",
  DATA_TASKS = "data_tasks",
}

const ExportButton = ({
  type,
  parentTrainingId,
}: {
  type: ExportType
  parentTrainingId?: number
}) => {
  const { userId } = useParams()
  const tooltipId = useId()
  const isMobile = useMediaQuery("(max-width: 800px)")

  const onClick = async () => {
    if ([ExportType.DATA_TASKS, ExportType.BEHAVIOR_INCIDENTS].includes(type)) {
      const response = await getExportLink({
        userId: Number(userId),
        isDataTask: type === ExportType.DATA_TASKS,
        parentTrainingId,
      })
      window.open(response.file_url)
    } else if (type === ExportType.OBSERVATIONAL_DATA) {
      if (!parentTrainingId) return
      const response = await getMasteryProgressExportLink({
        trainingId: parentTrainingId,
      })
      window.open(response.file_url)
    }
  }

  const isExamplePatient = Number(userId) === 0

  return (
    <>
      <div data-tooltip-id={tooltipId}>
        {type === ExportType.DATA_TASKS ||
        type === ExportType.OBSERVATIONAL_DATA ? (
          <TextButton onClick={onClick}>
            <Typography
              variant="bodyBold"
              color={JoonUIColor.text.primaryAccent}
            >
              <DownloadIcon size={16} />
            </Typography>
          </TextButton>
        ) : (
          <Button
            buttonType="secondary"
            text="Export"
            onClick={onClick}
            disabled={isExamplePatient}
            size={isMobile ? "small" : "medium"}
          />
        )}
      </div>
      <CustomTooltip id={tooltipId}>
        <Typography variant="bodySmall" color={JoonUIColor.text.inverted}>
          {isExamplePatient
            ? "Data export is enabled on linked clients"
            : "Export data to CSV"}
        </Typography>
      </CustomTooltip>
    </>
  )
}

export default ExportButton
