import {
  EditIcon,
  Typography,
  TextButton,
  UserRole,
  TrashIcon,
} from "@joonapp/web-shared"
import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
  getPaginationRowModel,
} from "@tanstack/react-table"
import { useState } from "react"

import { useDeleteClinicianModalStore } from "./DeleteClinicianModal"
import { useEditClinicianModalStore } from "./EditClinicianModal"
import CustomTable from "../../components/table/CustomTable"
import TableHeaderCell from "../../components/table/TableHeaderCell"
import { usePracticeQuery, useUserQuery } from "../../networking/queries"
import { PracticeMemberProfile } from "../../types"
import {
  displayStringFromRole,
  getCurrentUserProfileInfo,
} from "../../util/util"

interface Props {
  members: PracticeMemberProfile[]
}

const columnHelper = createColumnHelper<PracticeMemberProfile>()

export default function ClinicMemberTable({ members }: Props) {
  const { user } = useUserQuery()
  const { practice } = usePracticeQuery()
  const [hoveredRowId, setHoveredRowId] = useState<string | null>(null)
  const { onOpen: openEditClinicianModal } = useEditClinicianModalStore()
  const { onOpen: openDeleteClinicianModal } = useDeleteClinicianModalStore()
  const [sorting, setSorting] = useState([{ id: "name", desc: false }])
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 })

  const currentUser = practice && getCurrentUserProfileInfo(practice, user)
  const userIsAdmin = currentUser?.role === UserRole.ADMIN

  const createColumns = () => [
    columnHelper.accessor((row) => row.user.name, {
      id: "name",
      cell: (info) => (
        <Typography variant="bodySmall">{info.getValue()}</Typography>
      ),
      header: () => <TableHeaderCell>Name</TableHeaderCell>,
      size: 150,
    }),
    columnHelper.accessor((row) => row.user.email, {
      id: "email",
      cell: (info) => (
        <Typography variant="bodySmall">{info.getValue()}</Typography>
      ),
      header: () => <TableHeaderCell>Email</TableHeaderCell>,
      size: 200,
    }),
    columnHelper.accessor((row) => row.role, {
      id: "role",
      cell: (info) => (
        <Typography variant="bodySmall">
          {displayStringFromRole(info.getValue())}
        </Typography>
      ),
      header: () => <TableHeaderCell>Role</TableHeaderCell>,
      size: 100,
    }),
    columnHelper.accessor((row) => row.user.id, {
      id: "actions",
      header: () => <TableHeaderCell></TableHeaderCell>,
      cell: (info) => {
        const member = info.row.original
        const userIsMember =
          member.user.id === user.id && member.role === UserRole.ADMIN
        const isHovered = hoveredRowId === info.row.id

        return (
          <div
            style={{
              visibility:
                !userIsMember && userIsAdmin && isHovered
                  ? "visible"
                  : "hidden",
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <TextButton onClick={() => openEditClinicianModal(member.user.id)}>
              <EditIcon size={14} />
            </TextButton>
            <TextButton
              onClick={() => openDeleteClinicianModal(member.user.id)}
            >
              <TrashIcon size={14} />
            </TextButton>
          </div>
        )
      },
      size: 50,
    }),
  ]

  const columns = createColumns()

  const table = useReactTable({
    data: members,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    state: { sorting, pagination },
  })

  return (
    <CustomTable
      table={table}
      hoveredRowId={hoveredRowId}
      setHoveredRowId={setHoveredRowId}
    />
  )
}
