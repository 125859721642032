import {
  FlexBox,
  JoonColorExpanded,
  JoonUIColor,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import { useMediaQuery } from "@mui/material"
import dayjs from "dayjs"
import { CSSProperties, useCallback } from "react"
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts"

import { ChartDataPoint } from "./data"
import { useOverTimeProgressStore } from "./useOverTimeProgressStore"
import DotsLoader from "../../../../components/loading/DotsLoader"
import { useOverTimeProgressQuery } from "../../../../networking/queries"

type Props = {
  data: ChartDataPoint[] | undefined
}

const QuestGraph = ({ data }: Props) => {
  const { period, selectedQuests, highlightedQuest, showTargets } =
    useOverTimeProgressStore()
  const isMobile = useMediaQuery("(max-width:800px)")

  return (
    <div style={{ position: "relative", width: "100%" }}>
      <GraphStatus />

      <ResponsiveContainer width="100%" height={isMobile ? 200 : 320}>
        <LineChart
          data={data}
          margin={{ bottom: 7, right: 7, left: isMobile ? -32 : -12, top: 5 }}
        >
          {!isMobile && (
            <Legend
              verticalAlign="top"
              iconType="circle"
              iconSize={8}
              wrapperStyle={{
                color: JoonUIColor.text.primary,
                padding: `0 0 ${SPACING.space2} 0`,
              }}
              content={CustomLegend}
              style={{ border: "1px solid red" }}
              formatter={(value) => (
                <Typography
                  variant="bodySmall"
                  style={{
                    marginRight: SPACING.space3,
                    border: "1px solid black",
                  }}
                >
                  {value}
                </Typography>
              )}
              align="center"
            />
          )}
          <CartesianGrid
            strokeOpacity={0.5}
            horizontal={true}
            vertical={true}
          />
          <XAxis
            dataKey="startDate"
            style={{ fontSize: "13px" }}
            tickCount={10}
            tickFormatter={(data) => dayjs(data).format("MMM D")}
            label={{
              value:
                period.value === "week"
                  ? "Week of"
                  : period.value === "month"
                  ? "Month"
                  : "Day",
              dy: 16,
              fontSize: "12px",
              color: JoonUIColor.text.primary,
            }}
            tickLine={false}
            axisLine={{ stroke: "none" }}
          />

          <YAxis
            domain={[0, 7]}
            axisLine={false}
            style={{ fontSize: "13px" }}
            label={
              !isMobile
                ? {
                    value: "Weekly count",
                    angle: -90,
                    fontSize: "12px",
                    dx: -5,
                  }
                : undefined
            }
            tickLine={false}
            ticks={[0, 1, 2, 3, 4, 5, 6, 7]}
          />
          <Tooltip
            content={<CustomTooltip />}
            cursor={{
              stroke: JoonColorExpanded.neutral400,
              strokeDasharray: 5,
            }}
          />
          {selectedQuests.map((quest) => {
            return (
              <Line
                type="monotone"
                dataKey={`quests.${quest.id}.completed`}
                stroke={quest.color}
                strokeWidth="2"
                name={quest.name}
                dot={<CustomDot />}
                activeDot={<CustomActiveDot />}
                key={quest.id}
                strokeOpacity={
                  !highlightedQuest || highlightedQuest === quest.id ? 1 : 0.3
                }
              />
            )
          })}
          {showTargets && selectedQuests && selectedQuests.length === 1 && (
            <Line
              type="stepAfter"
              dataKey={`quests.${selectedQuests[0].id}.target.frequency`}
              stroke={selectedQuests[0].color}
              strokeWidth="2"
              strokeDasharray={"8 4"}
              name={"Frequency target"}
              animationDuration={0}
              dot={<CustomTargetDot />}
              activeDot={<CustomActiveTargetDot />}
              strokeOpacity={
                !highlightedQuest || highlightedQuest === selectedQuests[0].id
                  ? 1
                  : 0.3
              }
            />
          )}
        </LineChart>
      </ResponsiveContainer>
    </div>
  )
}

export default QuestGraph

const CustomLegend = ({ payload }: any) => {
  const { highlightedQuest, setHighlightedQuest } = useOverTimeProgressStore()

  const onLegendMouseEnter = useCallback(
    (questId: number) => setHighlightedQuest(Number(questId)),
    [setHighlightedQuest]
  )

  const onLegendMouseLeave = useCallback(
    () => setHighlightedQuest(null),
    [setHighlightedQuest]
  )

  return (
    <FlexBox
      justify="center"
      direction="row"
      style={{
        width: "fit-content",
        margin: "0 auto",
        padding: `0 0 ${SPACING.space2} 0`,
      }}
    >
      {payload.map((entry: any, index: number) => {
        if (entry.dataKey.includes("frequency")) return null
        const questId = entry.dataKey.split(".")[1]
        const isHighlighted = highlightedQuest === Number(questId)
        return (
          <FlexBox
            gap={SPACING.space2}
            align="center"
            key={index}
            wrap={false}
            onMouseEnter={() => onLegendMouseEnter(questId)}
            onMouseLeave={onLegendMouseLeave}
            style={{
              width: "fit-content",
              background: isHighlighted
                ? JoonUIColor.background.lightGray
                : "none",
              borderRadius: SPACING.space2,
              padding: `${SPACING.space1} ${SPACING.space2}`,
            }}
          >
            <div
              style={{
                width: "10px",
                height: "10px",
                borderRadius: "50%",
                background: entry.color,
              }}
            />
            <Typography
              variant="bodyXSmall"
              color={JoonUIColor.text.primary}
              style={{ whiteSpace: "nowrap" }}
            >
              {entry.value}
            </Typography>
          </FlexBox>
        )
      })}
    </FlexBox>
  )
}

const CustomTooltip = ({ active, payload }: any) => {
  if (!active || !payload || !payload.length) return null

  const startDate = dayjs(payload[0].payload.startDate).format("MMM D")
  const endDate = dayjs(payload[0].payload.endDate).format("MMM D")
  const dateToShow =
    startDate === endDate ? startDate : `${startDate} - ${endDate}`
  return (
    <FlexBox
      direction="column"
      gap={SPACING.space1}
      style={{
        background: JoonUIColor.background.inverted,
        borderRadius: SPACING.space2,
        padding: SPACING.space2,
      }}
    >
      <Typography variant="bodyXSmall" color={JoonUIColor.text.inverted}>
        {dateToShow}
      </Typography>
      {payload
        ?.filter((quest: any) => !isNaN(quest.value))
        .map((quest: any) => {
          const isTarget = quest.dataKey.includes("frequency")
          return (
            <FlexBox gap={SPACING.space2} key={quest.dataKey} align="center">
              <div
                style={{
                  width: "10px",
                  height: isTarget ? "4px" : "10px",
                  borderRadius: isTarget ? "4px" : "50%",
                  background: quest.color,
                }}
              />
              <Typography
                variant="bodyXSmall"
                color={JoonUIColor.text.inverted}
                style={{ fontWeight: "bold" }}
              >
                {quest.value}
              </Typography>
              <Typography
                variant="bodyXSmall"
                color={JoonUIColor.text.inverted}
              >
                {quest.name}
              </Typography>
            </FlexBox>
          )
        })}
    </FlexBox>
  )
}

const CustomDot = (props: any) => {
  const { cx, cy } = props
  const consistency = props.value
  if (consistency === null || isNaN(consistency)) return <></>

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      x={cx - 6}
      y={cy - 6}
    >
      <circle
        cx="3.5"
        cy="3.5"
        r="3.5"
        transform="matrix(1 0 0 -1 2.60034 9.5)"
        fill={props.stroke}
        stroke={"white"}
        strokeWidth="4"
      />
      <circle
        cx="3.5"
        cy="3.5"
        r="3.5"
        transform="matrix(1 0 0 -1 2.60034 9.5)"
        fill={props.stroke}
        stroke={`${props.stroke}33`}
        strokeWidth="4"
      />
    </svg>
  )
}

const CustomActiveDot = (props: any) => {
  const { cx, cy } = props

  const consistency = props.value
  if (consistency === null || isNaN(consistency)) return <></>

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      x={cx - 6}
      y={cy - 6}
    >
      <circle
        cx="3.5"
        cy="3.5"
        r="4"
        transform="matrix(1 0 0 -1 2.60034 9.5)"
        stroke={props.fill}
        fill={"white"}
        strokeWidth="2"
      />
      <circle
        cx="3.5"
        cy="3.5"
        r="4"
        transform="matrix(1 0 0 -1 2.60034 9.5)"
        stroke={props.fill}
        fill={`${props.fill}33`}
        strokeWidth="2"
      />
    </svg>
  )
}

const CustomTargetDot = (props: any) => {
  const { cx, cy } = props
  const isCurrentPeriod =
    dayjs() < dayjs(props.payload.endDate) &&
    dayjs() > dayjs(props.payload.startDate)
  const hasFrequencyTarget = !!props.value

  if (!isCurrentPeriod || !hasFrequencyTarget) return <></>

  return (
    <svg
      width="13"
      height="14"
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      x={cx - 6}
      y={cy - 6}
    >
      <path
        d="M9.10507 9.39841L10.5356 8.79123C12.1604 8.1016 12.1604 5.79879 10.5356 5.10916L9.10507 4.50198L8.49789 3.07142C7.80826 1.44663 5.50545 1.44662 4.81582 3.07142L4.20864 4.50198L2.77808 5.10916C1.15329 5.79879 1.15328 8.1016 2.77808 8.79123L4.20864 9.39841L4.81582 10.829C5.50545 12.4538 7.80826 12.4538 8.49789 10.829L9.10507 9.39841Z"
        fill={props.stroke}
        stroke={`${props.stroke}33`}
        strokeWidth="4"
      />
    </svg>
  )
}

const CustomActiveTargetDot = (props: any) => {
  const { cx, cy } = props
  const isCurrentPeriod =
    dayjs() < dayjs(props.payload.endDate) &&
    dayjs() > dayjs(props.payload.startDate)
  const hasFrequencyTarget = !!props.value

  if (!isCurrentPeriod || !hasFrequencyTarget) return <></>

  return (
    <svg
      width="13"
      height="14"
      viewBox="0 0 13 14"
      fill={props.fill}
      xmlns="http://www.w3.org/2000/svg"
      x={cx - 6}
      y={cy - 6}
    >
      <path
        d="M9.10507 9.39841L10.5356 8.79123C12.1604 8.1016 12.1604 5.79879 10.5356 5.10916L9.10507 4.50198L8.49789 3.07142C7.80826 1.44663 5.50545 1.44662 4.81582 3.07142L4.20864 4.50198L2.77808 5.10916C1.15329 5.79879 1.15328 8.1016 2.77808 8.79123L4.20864 9.39841L4.81582 10.829C5.50545 12.4538 7.80826 12.4538 8.49789 10.829L9.10507 9.39841Z"
        fill={"#ffffffcc"}
        stroke={props.fill}
        strokeWidth="2"
      />
    </svg>
  )
}

const GraphStatus = () => {
  const { data, isLoading } = useOverTimeProgressQuery()
  const { selectedQuests } = useOverTimeProgressStore()

  const chartHasData = data?.chartData?.length

  const styles = {
    position: "absolute",
    top: "40%",
    left: "50%",
    transform: "translateX(-50%)",
    width: "fit-content",
    padding: SPACING.space2,
    borderRadius: SPACING.space2,
    background: JoonUIColor.background.lightAccent,
    zIndex: "50",
    gap: SPACING.space2,
  } as CSSProperties

  return isLoading ? (
    <FlexBox align="center" style={styles}>
      <DotsLoader size={16} />
      <Typography variant="bodyXSmall" color={JoonUIColor.text.secondary}>
        Loading...
      </Typography>
    </FlexBox>
  ) : chartHasData && selectedQuests.length === 0 ? (
    <FlexBox align="center" style={styles}>
      <Typography variant="bodyXSmall" color={JoonUIColor.text.secondary}>
        Select a routine to visualize
      </Typography>
    </FlexBox>
  ) : !chartHasData ? (
    <FlexBox align="center" style={styles}>
      <Typography variant="bodyXSmall" color={JoonUIColor.text.secondary}>
        No data available
      </Typography>
    </FlexBox>
  ) : (
    <></>
  )
}
