import {
  Button,
  FlexBox,
  JoonUIColor,
  ModalHeader,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import { useEffect } from "react"
import { create } from "zustand"

import FavoriteButton from "./FavoriteButton"
import { ModulePreview } from "./ModulePreview"
import ResourceTag from "./ResourceTag"
import useAssignModulesSidePanelStore from "./useAssignModulesSidePanelStore"
import ColumnSidePanel from "../../components/sidePanel/ColumnSidePanel"
import { useLearningModulesQuery } from "../../networking/queries"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"

interface ResourcesSidePanelStore {
  isOpen: boolean
  onOpen: (resourceId: number) => void
  onClose: () => void

  selectedModuleId: number | null
  setSelectedModuleId: (resourceId: number) => void
}

export const useResourcesSidePanelStore = create<ResourcesSidePanelStore>(
  (set) => ({
    isOpen: false,
    onOpen: (selectedModuleId) => set({ isOpen: true, selectedModuleId }),
    onClose: () => set({ isOpen: false }),

    selectedModuleId: null,
    setSelectedModuleId: (selectedModuleId) => set({ selectedModuleId }),
  })
)

const ResourcesSidePanel = () => {
  const { isOpen, onClose, selectedModuleId } = useResourcesSidePanelStore()
  const { onOpen: openAssignModulesSidePanel } =
    useAssignModulesSidePanelStore()
  const { data: learningModules } = useLearningModulesQuery()
  const selectedModule = learningModules?.find(
    (module) => module.id === selectedModuleId
  )

  const onClickAssign = () => {
    openAssignModulesSidePanel()
    onClose()
    trackAnalyticEvent(ANALYTIC_EVENTS.open_assign_module_to_training)
  }

  useEffect(() => {
    if (selectedModuleId && !selectedModule) {
      onClose()
    }
  }, [selectedModuleId, selectedModule, onClose])

  return (
    <ColumnSidePanel
      isOpen={isOpen}
      style={{
        height: "100vh",
        width: "min(500px, 100vw)",
        boxShadow:
          "-8px -6px 24px 0px rgba(123, 131, 142, 0.20), 6px 6px 24px 0px #F0F3F8",
      }}
    >
      {selectedModule && (
        <>
          <ModalHeader onClose={onClose} title={selectedModule?.title || ""} />
          <FlexBox
            direction="row"
            align="center"
            wrap={false}
            justify="space-between"
            style={{
              padding: `0 ${SPACING.space6}`,
              paddingBottom: SPACING.space4,
              borderBottom: `1px solid ${JoonUIColor.border.default}`,
            }}
          >
            <Button
              onClick={onClickAssign}
              text="Assign as parent training"
              style={{ whiteSpace: "nowrap" }}
            />
            <FlexBox
              align="center"
              gap={SPACING.space1}
              style={{ width: "fit-content" }}
            >
              <FavoriteButton
                resource={selectedModule}
                style={{
                  position: "relative",
                  transform: "scale(1.5)",
                  top: "unset",
                  right: "unset",
                }}
              />
            </FlexBox>
          </FlexBox>
          <FlexBox
            direction="column"
            style={{
              padding: SPACING.space6,
              flex: 1,
              overflowY: "auto",
              paddingBottom: SPACING.space6,
              width: "100%",
            }}
            gap={SPACING.space6}
            wrap={false}
          >
            <ModulePreview learningModule={selectedModule} />
            <FlexBox direction="column" gap={SPACING.space2}>
              <Typography variant="bodyBold" textAlign="left">
                Relevant tags
              </Typography>
              <FlexBox gap={SPACING.space2}>
                {selectedModule?.tags.map((tag) => (
                  <ResourceTag tag={tag} key={tag.id} />
                ))}
              </FlexBox>
            </FlexBox>
            <FlexBox direction="column" gap={SPACING.space2}>
              <Typography variant="bodyBold" textAlign="left">
                Description
              </Typography>
              <Typography variant="bodySmall" textAlign="left">
                {selectedModule?.description}
              </Typography>
            </FlexBox>
          </FlexBox>
        </>
      )}
    </ColumnSidePanel>
  )
}

export default ResourcesSidePanel
