import {
  CheckIcon,
  CloseIcon,
  DoubleDashIcon,
  FlexBox,
  JoonColorExpanded,
  JoonUIColor,
  QuestStatus,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import { Dayjs } from "dayjs"
import { useId, useState } from "react"

import CustomTooltip from "../../../../components/tooltip/CustomTooltip"
import { getQuestStatusColor } from "../../../../util/quests"
import { dateIsToday } from "../../../../util/util"

export const StatusIndicator = ({
  status,
  date,
}: {
  status: QuestStatus | null
  date?: Dayjs
}) => {
  const [isHovered, setIsHovered] = useState(false)

  const id = useId()

  const statusColor = getQuestStatusColor(status)
  const isCompleted =
    status === QuestStatus.VERIFIED ||
    status === QuestStatus.REDEEMED ||
    status === QuestStatus.COMPLETED
  const isSkipped = status === QuestStatus.SKIPPED
  const isRejected =
    status === QuestStatus.REJECTED ||
    status === QuestStatus.REJECTED_VIEWED ||
    (status === QuestStatus.OPEN && date && !dateIsToday(date))

  return (
    <FlexBox
      align="center"
      justify="center"
      style={{
        background: statusColor,
        width: SPACING.space6,
        height: SPACING.space6,
        minWidth: SPACING.space6,
        minHeight: SPACING.space6,
        borderRadius: SPACING.space1,
        border:
          (!status && (isHovered || !date)) ||
          (status && date && dateIsToday(date))
            ? `1px solid ${JoonUIColor.border.default}`
            : "none",
      }}
      data-tooltip-id={id}
      onMouseOver={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {isSkipped && (
        <DoubleDashIcon size={8} color={JoonUIColor.icon.neutral} />
      )}
      {isCompleted && (
        <CheckIcon size={16} color={JoonUIColor.semantic.success} />
      )}
      {isRejected && <CloseIcon size={16} color={JoonUIColor.icon.neutral} />}
      <CustomTooltip id={id}>
        <StatusTooltip status={status} date={date} />
      </CustomTooltip>
    </FlexBox>
  )
}

const StatusTooltip = ({
  status,
  date,
}: {
  status: string | null
  date?: Dayjs
}) => {
  switch (status) {
    case QuestStatus.COMPLETED:
      return (
        <Typography variant="bodyXSmall" color={JoonUIColor.text.inverted}>
          Not yet verified
        </Typography>
      )
    case QuestStatus.REDEEMED:
    case QuestStatus.VERIFIED:
      return (
        <FlexBox align="center" gap={SPACING.space2}>
          <FlexBox
            align="center"
            justify="center"
            style={{
              background: JoonColorExpanded.viridian100,
              width: SPACING.space4,
              height: SPACING.space4,
              borderRadius: SPACING.space1,
              zIndex: 1,
            }}
          >
            <CheckIcon size={16} color={JoonUIColor.semantic.success} />
          </FlexBox>
          <Typography variant="bodyXSmall" color={JoonUIColor.text.inverted}>
            Completed and verified by parent
          </Typography>
        </FlexBox>
      )
    case QuestStatus.OPEN:
      if (date && dateIsToday(date)) {
        return (
          <Typography variant="bodyXSmall" color={JoonUIColor.text.inverted}>
            Not yet completed today
          </Typography>
        )
      } else
        return (
          <FlexBox align="center" gap={SPACING.space2}>
            <FlexBox
              align="center"
              justify="center"
              style={{
                background: JoonUIColor.background.primaryNeutral,
                width: SPACING.space4,
                height: SPACING.space4,
                borderRadius: SPACING.space1,
                zIndex: 1,
              }}
            >
              <CloseIcon size={16} color={JoonUIColor.icon.neutral} />
            </FlexBox>
            <Typography variant="bodyXSmall" color={JoonUIColor.text.inverted}>
              Behavior not completed
            </Typography>
          </FlexBox>
        )
    case QuestStatus.REJECTED:
      return (
        <FlexBox align="center" gap={SPACING.space2}>
          <CloseIcon size={16} color={JoonUIColor.icon.neutral} />
          <Typography variant="bodyXSmall" color={JoonUIColor.text.inverted}>
            Rejected by parents
          </Typography>
        </FlexBox>
      )
    case QuestStatus.SKIPPED:
      return (
        <FlexBox align="center" gap={SPACING.space2}>
          <FlexBox
            align="center"
            justify="center"
            style={{
              background: JoonUIColor.background.primaryNeutral,
              width: SPACING.space4,
              height: SPACING.space4,
              borderRadius: SPACING.space1,
              zIndex: 1,
            }}
          >
            <DoubleDashIcon size={8} color={JoonUIColor.icon.neutral} />
          </FlexBox>
          <Typography variant="bodyXSmall" color={JoonUIColor.text.inverted}>
            Skipped by parent on this day
          </Typography>
        </FlexBox>
      )
    default:
      if (!date)
        return (
          <Typography variant="bodyXSmall" color={JoonUIColor.text.inverted}>
            Not yet completed
          </Typography>
        )
      return (
        <Typography variant="bodyXSmall" color={JoonUIColor.text.inverted}>
          Not assigned on this day
        </Typography>
      )
  }
}
