import {
  FlexBox,
  JoonColorExpanded,
  JoonUIColor,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import { useState } from "react"
import { useNavigate } from "react-router-dom"

import PageContentWrapper from "../../components/layout/PageContentWrapper"
import PageWrapper from "../../components/pageWrapper/PageWrapper"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"
import { requireAuth } from "../../util/auth"

const Security = requireAuth(() => {
  const navigate = useNavigate()

  return (
    <PageWrapper>
      <PageContentWrapper>
        <FlexBox
          direction="row"
          gap={SPACING.space2}
          wrap={false}
          align="center"
          style={{ width: "fit-content" }}
        >
          <Typography variant="h2">HIPAA & Security Info</Typography>
        </FlexBox>
        <FlexBox direction="column" gap={SPACING.space4} wrap={false}>
          <HIPAACard
            title="HIPAA Compliance"
            description="Learn about how Joon complies with the Health Insurance
              Portability and Accountability Act (HIPAA) when storing,
              processing, or handling electronic protected health information
              (ePHI)."
            onClick={() => {
              navigate("/security/hipaa")
              trackAnalyticEvent(ANALYTIC_EVENTS.hipaa_section_click, {
                section: "compliance",
              })
            }}
          />
          <HIPAACard
            title="Our Privacy Policy"
            description="Learn about what information Joon collects, how we store it on our
              encrypted database, how it is accessed, and why we collect certain
              information in the parent and child app."
            onClick={() => {
              navigate("/security/privacy")
              trackAnalyticEvent(ANALYTIC_EVENTS.hipaa_section_click, {
                section: "privacy-policy",
              })
            }}
          />
          <HIPAACard
            title="Personal Identifiable Information (PII) Policy"
            description="Learn about what personally identifiable information is collected
              in Joon and how you can limit the sharing of PII with Joon."
            onClick={() => {
              navigate("/security/pii")
              trackAnalyticEvent(ANALYTIC_EVENTS.hipaa_section_click, {
                section: "pii-policy",
              })
            }}
          />
        </FlexBox>
      </PageContentWrapper>
    </PageWrapper>
  )
})

const HIPAACard = ({
  title,
  description,
  onClick,
}: {
  title: string
  description: string
  onClick: () => void
}) => {
  const [isHovered, setIsHovered] = useState(false)
  return (
    <button
      onClick={onClick}
      style={{
        background: JoonUIColor.background.primaryNeutral,
        border: `1px solid ${JoonUIColor.border.default}`,
        borderRadius: "8px",
        padding: SPACING.space4,
        width: "100%",
        outline: isHovered
          ? `2px solid ${JoonColorExpanded.indigo300}`
          : "none",
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <FlexBox direction="column" gap={SPACING.space2}>
        <Typography variant="h3" textAlign="left">
          {title}
        </Typography>
        <Typography variant="bodySmall" textAlign="left">
          {description}
        </Typography>
        <Typography variant="caption" color={JoonUIColor.text.primaryAccent}>
          Read More
        </Typography>
      </FlexBox>
    </button>
  )
}

export default Security
