import axios, { AxiosHeaders } from "axios"

import { sessionManager } from "./storage"

// const baseUrl = "http://127.0.0.1:8000/"
const baseUrl = "https://app.joonapp.io/"

export const createJoonAPIClient = () => {
  const getHeaders = () => {
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions()
    const headers = {
      Timezone: timeZone,
      // We set the version to be the max integer since web will always be up-to-date.
      Version: Number.MAX_SAFE_INTEGER,
    } as Headers
    if (sessionManager.getRefreshToken()) {
      headers.Authorization = sessionManager.getRefreshToken()
    }
    if (sessionManager.getIDToken()) {
      headers.Token = sessionManager.getIDToken()
    }
    return headers
  }

  return axios.create({
    baseURL: baseUrl,
    headers: getHeaders(),
  })
}

interface Headers extends AxiosHeaders {
  Timezone: string
  Version: number
  Authorization?: string | null
  Token?: string | null
}
