import { ParentTraining } from "@joonapp/web-shared"
import { useMutation } from "@tanstack/react-query"
import { useParams } from "react-router-dom"

import { createToast } from "../../../../components/toast/Toast"
import { deleteParentTraining } from "../../../../networking/parentTraining"
import { QUERY_KEYS } from "../../../../networking/queries"
import { queryClient } from "../../../../queryClient"
import { ToastType } from "../../../../types"

const useDeleteTrainingMutation = () => {
  const { userId } = useParams()

  return useMutation({
    mutationFn: deleteParentTraining,
    onMutate: async (trainingId: number) => {
      await queryClient.cancelQueries([QUERY_KEYS.PARENT_TRAININGS])
      const snapshot = queryClient.getQueryData([
        QUERY_KEYS.PARENT_TRAININGS,
        userId,
      ])
      queryClient.setQueryData<ParentTraining[]>(
        [QUERY_KEYS.PARENT_TRAININGS, userId],
        (prev) => {
          return prev?.filter((training) => training.id !== trainingId)
        }
      )
      return { snapshot }
    },
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.PARENT_TRAININGS, userId])
      createToast({
        title: "Training deleted",
        type: ToastType.SUCCESS,
      })
    },
    onError: (_error, _variables, context) => {
      queryClient.setQueryData(
        [QUERY_KEYS.PARENT_TRAININGS, userId],
        context?.snapshot
      )
      createToast({
        title: "Failed to delete training",
        type: ToastType.ERROR,
      })
    },
  })
}

export default useDeleteTrainingMutation
