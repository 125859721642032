import {
  Button,
  FlexBox,
  JoonUIColor,
  Modal,
  ModalHeader,
  SPACING,
  TextArea,
  TextInput,
  Typography,
} from "@joonapp/web-shared"
import { useParams } from "react-router-dom"
import { RRule } from "rrule"

import { Toasts, createToast } from "../../../../components/toast/Toast"
import { useCurrentTrainingQuery } from "../../../../networking/queries"
import { ToastType } from "../../../../types"
import { useAddEditParentTaskStore } from "../../useAddEditParentTaskStore"
import useAddTaskMutation from "../../useAddTaskMutation"
import useEditTaskMutation from "../../useEditTaskMutation"

const AddEditTodoModal = () => {
  const {
    isOpen,
    onClose,
    title,
    setTitle,
    description,
    setDescription,
    taskToEdit,
  } = useAddEditParentTaskStore((state) => ({
    isOpen: state.isOpen,
    onClose: state.onClose,
    title: state.title,
    setTitle: state.setTitle,
    description: state.description,
    setDescription: state.setDescription,
    taskToEdit: state.taskToEdit,
  }))
  const { data: currentTraining } = useCurrentTrainingQuery()
  const { userId, trainingId: trainingIdParam } = useParams()
  const isExamplePatient = Number(userId) === 0
  const isEditing = !!taskToEdit

  const editQuestMutation = useEditTaskMutation()
  const addQuestMutation = useAddTaskMutation()

  const currentParentId = currentTraining?.user.id

  const onClickAddTodo = async () => {
    if (!title)
      return createToast({
        title: "Please enter a task name",
        type: ToastType.ERROR,
      })
    const newTask = {
      title: title,
      description: description,
      redeemable_reward: 5,
      recurrence: new RRule({
        freq: RRule.DAILY,
        byweekday: [
          RRule.SU,
          RRule.MO,
          RRule.TU,
          RRule.WE,
          RRule.TH,
          RRule.FR,
          RRule.SA,
        ],
        interval: 1,
      }).toString(),
      occurrence_limit: 1,
      parent_training_id: trainingIdParam, // If training ID exists, attach task to training
    }
    addQuestMutation.mutate({
      userId: currentTraining?.user.id || currentParentId || undefined,
      questInfo: newTask,
    })
  }

  const onClickEditTodo = async () => {
    if (!taskToEdit) return createToast(Toasts.GENERAL_ERROR)
    if (!title)
      return createToast({
        title: "Please enter a task name",
        type: ToastType.ERROR,
      })

    const newTask = {
      title: title,
      description: description,
      redeemable_reward: taskToEdit.redeemable_reward,
      recurrence: taskToEdit.recurrence,
      occurrence_limit: taskToEdit.occurrence_limit,
    }

    editQuestMutation.mutate({ seriesId: taskToEdit?.id, questInfo: newTask })
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalHeader
        title={isEditing ? "Edit to-do task" : "Add a to-do task"}
        onClose={onClose}
        style={{
          width: "100%",
          borderBottom: `1px solid ${JoonUIColor.border.default}`,
        }}
      />

      <FlexBox
        style={{
          padding: `${SPACING.space4} ${SPACING.space6} ${SPACING.space6}`,
          width: "min(500px, 95vw)",
          overflowY: "scroll",
          height: "100%",
        }}
        direction="column"
        gap={SPACING.space6}
        wrap={false}
      >
        <FlexBox direction="column" gap={SPACING.space1}>
          <Typography variant="bodyBold">Task title</Typography>
          <Typography variant="bodySmall">
            Think about the types of skills you'd like parents to practice at
            home.
          </Typography>
          <TextInput
            name="Task name"
            placeholder="Name"
            fullWidth
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            maxLength={100}
          />
        </FlexBox>
        <FlexBox gap={SPACING.space1} direction="column">
          <Typography variant="bodyBold">
            Description or helpful instructions for parents
          </Typography>
          <Typography variant="bodySmall">
            Add detail about when parents should practice this task or share
            step-by-step instructions.
          </Typography>
          <TextArea
            name="Task description"
            placeholder="Description"
            fullWidth
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            style={{ width: "100%" }}
            maxLength={255}
            showCounter
          />
        </FlexBox>
      </FlexBox>

      <div style={{ position: "sticky", bottom: 0, width: "100%" }}>
        <FlexBox
          direction="row"
          align="center"
          justify="flex-end"
          wrap={false}
          gap={SPACING.space2}
          style={{
            width: "100%",
            padding: SPACING.space6,
            whiteSpace: "nowrap",
          }}
        >
          <button onClick={onClose}>
            <Typography
              variant="bodyBold"
              color={JoonUIColor.text.primaryAccent}
            >
              Cancel
            </Typography>
          </button>
          <Button
            text={isEditing ? "Save changes" : "Add task"}
            onClick={isEditing ? onClickEditTodo : onClickAddTodo}
            isLoading={
              isEditing
                ? editQuestMutation.isLoading
                : addQuestMutation.isLoading
            }
            disabled={isExamplePatient}
          />
        </FlexBox>
      </div>
    </Modal>
  )
}

export default AddEditTodoModal
