import { ParentTrainingMasteryProgress } from "@joonapp/web-shared"
import { useMutation } from "@tanstack/react-query"

import { addObservationalData } from "../../../../../networking/parentTraining"
import {
  QUERY_KEYS,
  useCurrentTrainingQuery,
} from "../../../../../networking/queries"
import { queryClient } from "../../../../../queryClient"
import {
  ANALYTIC_EVENTS,
  trackAnalyticEvent,
} from "../../../../../util/analytics"

const useAddObservationalDataMutation = () => {
  const { data: currentTraining } = useCurrentTrainingQuery()
  const queryKey = [QUERY_KEYS.TRAINING_MASTERY_PROGRESS, currentTraining?.id]

  return useMutation({
    mutationFn: addObservationalData,
    onSuccess: (newData: ParentTrainingMasteryProgress) => {
      queryClient.cancelQueries(queryKey)
      queryClient.setQueryData<ParentTrainingMasteryProgress[]>(
        queryKey,
        (oldData = []) => [...oldData, newData]
      )

      trackAnalyticEvent(ANALYTIC_EVENTS.add_observational_data)
    },
  })
}

export default useAddObservationalDataMutation
