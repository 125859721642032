import { FlexBox, JoonUIColor, SPACING, Typography } from "@joonapp/web-shared"
import { useMediaQuery } from "@mui/material"

import useParentTrainingStore from "./useParentTrainingStore"
import OpenPDFButton from "../../../components/buttons/OpenPDFButton"
import Squiggle from "../../../components/squiggle/Squiggle"
import {
  useParentTrainingsQuery,
  usePatientParentsQuery,
} from "../../../networking/queries"

const TrainingsEmptyState = () => {
  const { data: parents } = usePatientParentsQuery()
  const { selectedParentId } = useParentTrainingStore()
  const selectedParent = parents?.find(
    (parent) => parent.user.id === selectedParentId
  )

  const { data: parentTrainings } = useParentTrainingsQuery()

  return (
    <FlexBox direction="column" gap={SPACING.space4} wrap={false}>
      <div
        style={{
          padding: SPACING.space4,
          background: JoonUIColor.background.lightAccent,
          borderRadius: SPACING.space4,
          margin: "0 auto",
          marginBottom: SPACING.space6,
        }}
      >
        {parentTrainings && parentTrainings?.length === 0 ? (
          <Typography variant="bodySmall">
            No parent trainings yet assigned to{" "}
            {selectedParent?.user.name ?? "selected parent"}
          </Typography>
        ) : (
          <Typography variant="bodySmall">
            We have sent {selectedParent?.user.name} a training to get started
            with Joon!
          </Typography>
        )}
      </div>
      <TrainingsHowItWorks />
    </FlexBox>
  )
}

export const TrainingsHowItWorks = () => {
  const isMobile = useMediaQuery("(max-width: 800px)")

  return (
    <FlexBox
      direction="column"
      align="center"
      justify="center"
      gap={SPACING.space6}
    >
      <FlexBox
        direction="row"
        align="center"
        gap={SPACING.space4}
        justify="center"
      >
        <Squiggle />
        <Typography variant="h3">How this works</Typography>
        <Squiggle />
      </FlexBox>
      <FlexBox
        direction={isMobile ? "column" : "row"}
        align={isMobile ? "center" : "flex-start"}
        justify={"center"}
        gap={isMobile ? SPACING.space6 : SPACING.space10}
        style={{ width: "min(900px, 100%)" }}
      >
        <FlexBox
          direction="column"
          gap={SPACING.space4}
          wrap={false}
          align="center"
          justify="center"
          style={{ width: "min(300px, 100%)" }}
        >
          <Typography variant="caption" textAlign="center">
            Find the best resource for your parents in our robust learning
            library
          </Typography>
          <img
            src={"/images/parentTrainingEmpty1.svg"}
            alt="Parent training library"
            style={{ width: "100%" }}
          />
          <OpenPDFButton text="Check out the library" imageUrl="/resources" />
        </FlexBox>
        <FlexBox
          direction="column"
          gap={SPACING.space4}
          wrap={false}
          style={{ width: "min(300px, 100%)" }}
          align="center"
        >
          <Typography variant="caption" textAlign="center">
            Send learning modules and assign practice tasks directly to parents
          </Typography>
          <img
            src={"/images/parentTrainingEmpty2.svg"}
            alt="Parent training modules parent view"
            style={{ width: "100%" }}
          />
          <OpenPDFButton
            text="Preview parent experience"
            imageUrl="/images/parentTraining.pdf"
          />
        </FlexBox>
      </FlexBox>
      <FlexBox
        direction="column"
        gap={SPACING.space4}
        justify="center"
        align="center"
      >
        <Typography variant="bodyBold" textAlign="center">
          Define meaningful mastery criteria and plot observational data to view
          parents' training progress over time
        </Typography>
        <img
          src={"/images/parentTrainingEmpty3.svg"}
          alt="Parent training mastery criteria plot"
          style={{ width: "min(1000px, 100%)" }}
        />
        <OpenPDFButton
          text="View in sample patient"
          imageUrl="/patients/0/parent-training/1/summary"
        />
      </FlexBox>
    </FlexBox>
  )
}

export default TrainingsEmptyState
