import {
  CheckIcon,
  CloseIcon,
  FlexBox,
  JoonUIColor,
  SPACING,
  TextButton,
  Typography,
} from "@joonapp/web-shared"
import { useState } from "react"
import { useNavigate } from "react-router-dom"

import { useAddPatientModalStore } from "../../../components/modals/AddPatientModal"
import { PatientInfoTab } from "../../../types"
import { localStorageItems } from "../../../util/storage"

const GetStarted = () => {
  const [, setForceUpdate] = useState(false)
  const navigate = useNavigate()
  const { onOpen: openAddPatientModal } = useAddPatientModalStore()

  if (!localStorage.getItem(localStorageItems.displayGetStarted)) return null

  const onClickViewPatient = () => {
    navigate(`/patients/0/${PatientInfoTab.PARENT_TRAINING}`)
  }

  const onClickInviteClient = () => {
    openAddPatientModal()
  }

  const onClickDismiss = () => {
    localStorage.removeItem(localStorageItems.displayGetStarted)
    setForceUpdate(true)
  }

  const step1Done = !localStorage.getItem(localStorageItems.needsToSeeTutorial)
  const step2Done = !localStorage.getItem(localStorageItems.needsToInviteClient)

  return (
    <FlexBox
      direction="column"
      gap={SPACING.space4}
      style={{
        background:
          "linear-gradient(270deg, rgba(255, 209, 154, 0.20) 42.39%, rgba(227, 201, 253, 0.20) 98.24%), #FFF",
        borderRadius: SPACING.space2,
        padding: SPACING.space6,
        border: `1px solid ${JoonUIColor.border.default}`,
      }}
    >
      <FlexBox
        direction="row"
        justify="space-between"
        wrap={false}
        align="flex-start"
      >
        <div>
          <Typography variant="h3" textAlign="left">
            Get started with Joon
          </Typography>
          <Typography variant="body" textAlign="left">
            Easy steps to turn you into a Joon Expert. If you have any questions
            about anything, contact{" "}
            <a href="mailto:brad@joonhealth.co">brad@joonhealth.co</a>
          </Typography>
        </div>
        {step1Done && step2Done && (
          <TextButton onClick={onClickDismiss}>
            <CloseIcon color={JoonUIColor.icon.accent} size={24} />
          </TextButton>
        )}
      </FlexBox>
      <FlexBox direction="column" gap={SPACING.space2}>
        <GetStartedStep
          isChecked={step1Done}
          onClick={onClickViewPatient}
          title="View a sample family’s data"
          subtitle="See the benefit of having your clients data easily accessible via Joon."
        />
        <GetStartedStep
          isChecked={step2Done}
          onClick={onClickInviteClient}
          title="Invite your first client"
          subtitle="You’re ready to use this with your clients! Let’s start with your first one."
        />
      </FlexBox>
    </FlexBox>
  )
}

export default GetStarted

const GetStartedStep = ({
  isChecked,
  onClick,
  title,
  subtitle,
}: {
  isChecked: boolean
  onClick: () => void
  title: string
  subtitle: string
}) => {
  const [isHovered, setIsHovered] = useState(false)
  const [isPressed, setIsPressed] = useState(false)

  return (
    <button onClick={onClick} style={{ padding: 0, width: "100%" }}>
      <FlexBox
        direction="row"
        wrap={false}
        justify="space-between"
        align="center"
        gap={SPACING.space2}
        style={{
          border: `1px solid ${JoonUIColor.border.default}`,
          padding: SPACING.space2,
          background: isHovered
            ? JoonUIColor.background.xlightGray
            : JoonUIColor.background.primaryNeutral,
          borderRadius: SPACING.space2,
          cursor: "pointer",
          transform: isPressed ? "translateY(1px)" : "translateY(0px)",
          transition: "0.1s ease-in-out",
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => {
          setIsHovered(false)
          setIsPressed(false)
        }}
        onMouseDown={() => setIsPressed(true)}
        onMouseUp={() => setIsPressed(false)}
      >
        <FlexBox
          align="center"
          justify="center"
          style={{
            background: isChecked
              ? JoonUIColor.background.accent
              : "transparent",
            borderRadius: SPACING.space2,
            width: SPACING.space5,
            height: SPACING.space5,
            minWidth: SPACING.space5,
            minHeight: SPACING.space5,
            border: `2px solid ${JoonUIColor.border.accent}`,
          }}
        >
          <CheckIcon color={JoonUIColor.icon.inverted} size={16} />
        </FlexBox>
        <FlexBox direction="column">
          <Typography variant="bodyBold" textAlign="left">
            {title}
          </Typography>
          <Typography variant="bodySmall" textAlign="left">
            {subtitle}
          </Typography>
        </FlexBox>
      </FlexBox>
    </button>
  )
}
