import {
  ArchiveIcon,
  EditIcon,
  FlexBox,
  JoonUIColor,
  QuestSeries,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import dayjs from "dayjs"
import { useParams } from "react-router-dom"

import ArchiveTaskModal, { useArchiveTaskModalStore } from "./ArchiveTaskModal"
import useTaskDetailsModalStore from "./store/useTaskDetailsModalStore"
import { ActionButton } from "../../../../components/buttons/ActionButton"
import {
  useParentTaskInstancesQuery,
  useCurrentTrainingQuery,
} from "../../../../networking/queries"
import { getCompletedQuestInstances } from "../../../../util/quests"
import { exampleParentTasks } from "../../../patientsDashboard/exampleChild"
import { useAddEditParentTaskStore } from "../../useAddEditParentTaskStore"
import useParentTrainingStore from "../useParentTrainingStore"

const ParentTaskDetailsBar = () => {
  const { onOpen: onOpenAddParentTask } = useAddEditParentTaskStore()
  const { selectedParentId } = useParentTrainingStore()
  const { selectedTaskId } = useTaskDetailsModalStore()
  const { onOpen: openArchiveTaskModal } = useArchiveTaskModalStore()
  const { data: currentTraining } = useCurrentTrainingQuery()
  const parentTasks = currentTraining?.quests
  const { data: questInstances } = useParentTaskInstancesQuery({
    userIds: selectedParentId ? [selectedParentId] : [],
    seriesId: selectedTaskId || undefined,
    maxDate: dayjs().format("YYYY-MM-DD"),
  })
  const selectedTask = parentTasks?.find((task) => task.id === selectedTaskId)

  const { userId } = useParams()
  const isExamplePatient = Number(userId) === 0

  const completedInstances = getCompletedQuestInstances(
    isExamplePatient ? exampleParentTasks : questInstances
  )

  return (
    <div
      style={{
        width: "100%",
        background: "white",
        height: "fit-content",
        padding: `${SPACING.space4} ${SPACING.space6}`,
        zIndex: 1,
        display: "flex",
        flexDirection: "column",
        gap: SPACING.space2,
      }}
    >
      <Typography variant="h3" textAlign="left">
        {selectedTask?.title}
      </Typography>
      {selectedTask?.description && (
        <Typography variant="body" textAlign="left">
          {selectedTask?.description}
        </Typography>
      )}
      <FlexBox direction="column" wrap={false}>
        <Typography variant="caption" textAlign="left">
          Result:
        </Typography>
        {completedInstances.length > 0 &&
          !!completedInstances?.[0]?.completion_date && (
            <Typography variant="bodyBold">
              Completed on{" "}
              {dayjs
                .unix(+completedInstances[0].completion_date)
                .format("MM/DD/YYYY")}
            </Typography>
          )}
      </FlexBox>

      <FlexBox direction="row" gap={SPACING.space2} wrap={false}>
        <ActionButton
          text="Edit"
          icon={<EditIcon size={16} color={JoonUIColor.icon.neutral} />}
          onClick={() => onOpenAddParentTask(selectedTask as QuestSeries)}
        />
        <ActionButton
          text="Archive"
          icon={<ArchiveIcon size={16} color={JoonUIColor.icon.neutral} />}
          onClick={() => openArchiveTaskModal()}
        />
        <ArchiveTaskModal />
      </FlexBox>
    </div>
  )
}

export default ParentTaskDetailsBar
