import {
  Button,
  DateInput,
  FlexBox,
  Modal,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import dayjs, { Dayjs } from "dayjs"
import { useState } from "react"
import { create } from "zustand"

import { useOverTimeProgressStore } from "../../pages/patientInfo/homeRoutines/overTimeProgress/useOverTimeProgressStore"
import { DateRange } from "../../types"

interface DatePickerModalStore {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
}

export const useDatePickerModal = create<DatePickerModalStore>((set) => ({
  isOpen: false,
  onOpen: () => set({ isOpen: true }),
  onClose: () => set({ isOpen: false }),
}))

const DatePickerModal = () => {
  const { isOpen, onClose } = useDatePickerModal()
  const { dateRange, setDateRange } = useOverTimeProgressStore()
  const [newStartDate, setNewStartDate] = useState<Dayjs | null>(
    dayjs(dateRange.startDate)
  )
  const [newEndDate, setNewEndDate] = useState<Dayjs | null>(
    dayjs(dateRange.endDate)
  )

  const updateDate = () => {
    setDateRange({
      startDate: newStartDate,
      endDate: newEndDate,
      label: "Custom",
      value: "custom",
    } as DateRange)
    onClose()
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} displayCloseIcon={false}>
      <FlexBox
        direction="column"
        style={{ padding: SPACING.space6, gap: SPACING.space4 }}
      >
        <Typography variant="h2">Custom Date Range</Typography>
        <div>
          <div className="body-text2 bold">Start Date</div>
          <DateInput
            name="Start Date"
            modalTitle="Start Date"
            date={newStartDate?.toDate() || null}
            setDate={(val) => setNewStartDate(dayjs(val))}
          />
        </div>
        <div>
          <div className="body-text2 bold">End Date</div>
          <DateInput
            name="End Date"
            modalTitle="End Date"
            date={newEndDate?.toDate() || null}
            setDate={(val) => setNewEndDate(dayjs(val))}
          />
        </div>
        <Button text="Update" onClick={updateDate} fullWidth>
          Update
        </Button>
      </FlexBox>
    </Modal>
  )
}

export default DatePickerModal
