import {
  FlexBox,
  FrequencyTarget,
  IconProps,
  JoonColorExpanded,
  JoonUIColor,
  SPACING,
  Typography,
} from "@joonapp/web-shared"

import CustomTooltip from "../../../../components/tooltip/CustomTooltip"
import { getMostRecentTarget } from "../../../../util/util"
import { QuestSeriesWithTargets } from "../overTimeProgress/data"

type Props = {
  questInfo: QuestSeriesWithTargets

  totalThisWeek: number
  totalLastWeek: number
  completedThisWeek: number
  completedLastWeek: number
}

const WeeklyFrequencyCount = ({
  questInfo,
  totalThisWeek,
  totalLastWeek,
  completedThisWeek,
  completedLastWeek,
}: Props) => {
  const comparedToLastWeek = completedThisWeek - completedLastWeek

  const getTargetProgressColor = (
    target: FrequencyTarget | null,
    completedThisWeek: number
  ) => {
    if (!target)
      return {
        primaryColor: JoonUIColor.icon.neutral,
        backgroundColor: JoonUIColor.background.primaryNeutral,
      }

    const completionRate = completedThisWeek / target.frequency
    if (completionRate >= 0.6)
      return {
        primaryColor: JoonUIColor.semantic.success,
        backgroundColor: JoonColorExpanded.viridian100,
      }
    else if (completionRate >= 0.2)
      return {
        primaryColor: JoonColorExpanded.orange300,
        backgroundColor: JoonColorExpanded.orange100,
      }
    else
      return {
        primaryColor: JoonUIColor.semantic.destructive,
        backgroundColor: JoonColorExpanded.red100,
      }
  }

  const getTrendIcon = (comparedToLastWeek: number) => {
    const isTrendingDown = comparedToLastWeek < 0
    const isTrendingUp = comparedToLastWeek > 0

    if (isTrendingDown) {
      return <TrendDownIcon size={8} color={JoonUIColor.semantic.destructive} />
    } else if (isTrendingUp) {
      return <TrendUpIcon size={8} color={JoonUIColor.semantic.success} />
    } else {
      return <TrendEqualIcon size={8} color={JoonUIColor.icon.neutral} />
    }
  }
  const trendIcon = getTrendIcon(comparedToLastWeek)
  const tooltipID = `${questInfo.id}`
  const currentTarget = getMostRecentTarget(questInfo.frequency_targets)
  const { backgroundColor, primaryColor } = getTargetProgressColor(
    currentTarget,
    completedThisWeek
  )

  return (
    <FlexBox
      align="center"
      justify="center"
      wrap={false}
      gap={SPACING.space1}
      style={{
        borderRadius: SPACING.space2,
        background: backgroundColor,
        width: "fit-content",
        margin: "0 auto",
        padding: `${SPACING.space1} ${SPACING.space2}`,
      }}
      data-tooltip-id={tooltipID}
    >
      {!!currentTarget && <StatusDot color={primaryColor} />}
      <Typography variant="bodySmall" textAlign="center">
        {totalThisWeek >= 0 && `${completedThisWeek}x`}
      </Typography>
      <FlexBox align="center" justify="center">
        {!!totalLastWeek && trendIcon}
      </FlexBox>
      {(!!currentTarget || !!totalLastWeek) && (
        <CustomTooltip id={tooltipID}>
          <FlexBox direction="column" gap={SPACING.space1} wrap={false}>
            {currentTarget && (
              <FlexBox align="center" gap={SPACING.space1} wrap={false}>
                <StatusDot color={primaryColor} />
                <Typography
                  variant="bodyXSmall"
                  color={JoonUIColor.text.inverted}
                >
                  <strong>{`${completedThisWeek} / ${currentTarget?.frequency}`}</strong>{" "}
                  current target progress
                </Typography>
              </FlexBox>
            )}
            {!!totalLastWeek && (
              <FlexBox align="center" gap={SPACING.space1} wrap={false}>
                {!!totalLastWeek && trendIcon}
                <Typography
                  variant="bodyXSmall"
                  color={JoonUIColor.text.inverted}
                >
                  {comparedToLastWeek >= 0 && "+"}
                  <strong>{comparedToLastWeek}</strong>
                  {comparedToLastWeek >= 0 ? " more" : " less"} progress vs
                  previous 7 days
                </Typography>
              </FlexBox>
            )}
          </FlexBox>
        </CustomTooltip>
      )}
    </FlexBox>
  )
}

export default WeeklyFrequencyCount

const StatusDot = ({ color }: { color: string }) => {
  return (
    <div
      style={{
        minWidth: SPACING.space2,
        minHeight: SPACING.space2,
        background: color,
        borderRadius: "50%",
      }}
    />
  )
}

const TrendUpIcon = ({ size = 8, color }: IconProps) => {
  return (
    <svg
      width={size}
      height={size * (5 / 8)}
      viewBox="0 0 8 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.43541 0.219921C3.74791 -0.0730475 4.25541 -0.0730475 4.56791 0.219921L7.76791 3.21992C7.99791 3.43555 8.06541 3.75664 7.94041 4.03789C7.81541 4.31914 7.52541 4.50195 7.20041 4.50195L0.800415 4.49961C0.477915 4.49961 0.185415 4.3168 0.0604152 4.03555C-0.0645848 3.7543 0.00541518 3.4332 0.232915 3.21758L3.43291 0.217577L3.43541 0.219921Z"
        fill={color}
      />
    </svg>
  )
}

const TrendEqualIcon = ({ size = 8, color }: IconProps) => {
  return (
    <svg
      width={size}
      height={size * (2 / 8)}
      viewBox="0 0 8 2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.71373 1C7.71373 1.41484 7.45837 1.75 7.1423 1.75L0.856585 1.75C0.540513 1.75 0.285156 1.41484 0.285156 1C0.285156 0.585156 0.540513 0.25 0.856585 0.25L7.1423 0.25C7.45837 0.25 7.71373 0.585156 7.71373 1Z"
        fill={color}
      />
    </svg>
  )
}

const TrendDownIcon = ({ size = 8, color }: IconProps) => {
  return (
    <svg
      width={size}
      height={size * (5 / 8)}
      viewBox="0 0 8 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.43541 4.78008C3.74791 5.07305 4.25541 5.07305 4.56791 4.78008L7.76791 1.78008C7.99791 1.56445 8.06541 1.24336 7.94041 0.962109C7.81541 0.680859 7.52541 0.498047 7.20041 0.498047L0.800415 0.500391C0.477915 0.500391 0.185415 0.683203 0.0604152 0.964453C-0.0645848 1.2457 0.00541518 1.5668 0.232915 1.78242L3.43291 4.78242L3.43541 4.78008Z"
        fill={color}
      />
    </svg>
  )
}
