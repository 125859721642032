import {
  Button,
  JoonUIColor,
  Modal,
  ModalHeader,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import { useParams } from "react-router-dom"
import { create } from "zustand"

import useTaskDetailsModalStore from "./store/useTaskDetailsModalStore"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../../util/analytics"
import useDeleteTaskMutation from "../../useDeleteTaskMutation"

interface ArchiveTaskModalStore {
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
}

export const useArchiveTaskModalStore = create<ArchiveTaskModalStore>(
  (set) => ({
    isOpen: false,
    onClose: () => set({ isOpen: false }),
    onOpen: () => {
      trackAnalyticEvent(ANALYTIC_EVENTS.open_archive_parent_task)
      set({ isOpen: true })
    },
  })
)

const ArchiveTaskModal = () => {
  const { isOpen, onClose: closeArchiveTaskModal } = useArchiveTaskModalStore()

  const { userId } = useParams()
  const { selectedTaskId, onClose: closeTaskDetailsModal } =
    useTaskDetailsModalStore()

  const isExamplePatient = Number(userId) === 0
  const deleteTaskMutation = useDeleteTaskMutation()

  const onClickArchiveTask = async () => {
    if (!selectedTaskId) return
    deleteTaskMutation.mutate(selectedTaskId)
    closeArchiveTaskModal()
    closeTaskDetailsModal()
  }

  return (
    <Modal isOpen={isOpen} onClose={closeArchiveTaskModal}>
      <ModalHeader
        title="Confirm archival?"
        onClose={closeArchiveTaskModal}
        style={{ borderBottom: `1px solid ${JoonUIColor.border.default}` }}
      />
      <div style={{ padding: SPACING.space6, width: "min(500px, 95vw)" }}>
        <Typography variant="body">
          Once archived, this task will no longer show up on the parent
          experience nor on this page.
        </Typography>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: SPACING.space6,
          }}
        >
          <button
            style={{ marginRight: SPACING.space2 }}
            onClick={closeArchiveTaskModal}
          >
            <Typography
              variant="bodyBold"
              color={JoonUIColor.text.primaryAccent}
            >
              Cancel
            </Typography>
          </button>
          <Button
            text="Archive"
            isLoading={deleteTaskMutation.isLoading}
            onClick={onClickArchiveTask}
            disabled={isExamplePatient}
          />
        </div>
      </div>
    </Modal>
  )
}

export default ArchiveTaskModal
