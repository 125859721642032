import {
  Button,
  Checkbox,
  FlexBox,
  JoonUIColor,
  ModalContent,
  ModalHeader,
  SPACING,
  TextButton,
  Typography,
  UserRole,
} from "@joonapp/web-shared"
import { useState } from "react"
import { useNavigate, useParams } from "react-router-dom"

import {
  PatientInfoSidePanelView,
  usePatientInfoSidePanelStore,
} from "./usePatientInfoSidePanelStore"
import useUpdateCliniciansMutation from "./useUpdateCliniciansMutation"
import ColumnSidePanel from "../../../components/sidePanel/ColumnSidePanel"
import { createToast } from "../../../components/toast/Toast"
import {
  usePatientGroupsQuery,
  usePracticeQuery,
  useUserQuery,
} from "../../../networking/queries"
import { ToastType } from "../../../types"
import { getPatientGroupByUserId } from "../../../util/util"

const PatientInfoSidePanel = () => {
  const { isOpen, view } = usePatientInfoSidePanelStore()

  const getView = () => {
    switch (view) {
      case PatientInfoSidePanelView.ManageClinicians:
        return <ManageClinicians />
      default:
        return null
    }
  }

  return (
    <ColumnSidePanel isOpen={isOpen} style={{ height: "100vh" }}>
      {getView()}
    </ColumnSidePanel>
  )
}

export default PatientInfoSidePanel

const ManageClinicians = () => {
  const { onClose } = usePatientInfoSidePanelStore()
  const { user } = useUserQuery()
  const { userId } = useParams()
  const { practice } = usePracticeQuery()
  const navigate = useNavigate()
  const { data: patientGroups } = usePatientGroupsQuery()
  const patientGroup = getPatientGroupByUserId(patientGroups, Number(userId))
  const initialClinicians: number[] =
    patientGroup?.profiles
      ?.filter((member) => member.role === UserRole.THERAPIST)
      ?.map((therapist) => therapist.user.id) || []
  const [selectedClinicians, setSelectedClinicians] = useState<number[]>(
    initialClinicians || []
  )

  const isExamplePatient = Number(userId) === 0

  // TODO: probably make a reusable toggle function
  const toggleClinician = (therapistId: number) => {
    if (selectedClinicians.includes(therapistId)) {
      setSelectedClinicians(
        selectedClinicians.filter((id) => id !== therapistId)
      )
    } else {
      setSelectedClinicians([...selectedClinicians, therapistId])
    }
  }

  const updateCliniciansMutation = useUpdateCliniciansMutation()
  const onClickSave = () => {
    if (!patientGroup) return
    if (selectedClinicians.length === 0) {
      return createToast({
        title: "Please select at least one clinician",
        type: ToastType.ERROR,
      })
    }
    updateCliniciansMutation.mutate({
      selectedClinicians,
      initialClinicians,
      patientGroupId: patientGroup?.id,
    })

    const isRemovingSelf = !selectedClinicians.includes(user?.id)
    if (isRemovingSelf) navigate("/patients")
  }

  return (
    <>
      <ModalHeader
        title="Manage clinicians"
        onClose={onClose}
        showBorderBottom
      />
      <ModalContent
        style={{
          flex: 1,
          padding: SPACING.space6,
          paddingTop: SPACING.space4,
          gap: SPACING.space4,
        }}
      >
        <Typography variant="bodySmall">
          Clinicians will be able to view this client's data, assign behaviors,
          set targets, and add parent tasks.
        </Typography>

        <FlexBox direction="column" gap={SPACING.space2}>
          <Typography variant="bodyBold">Linked Clinicians:</Typography>

          <FlexBox direction="column" gap={SPACING.space3}>
            {practice?.profiles?.map((profile: any, i: number) => (
              <Checkbox
                label={profile.user.name + " " + profile.user.last_name}
                name={profile.user.name}
                selected={
                  selectedClinicians.includes(profile.user.id) ? true : false
                }
                onChange={() => toggleClinician(profile.user.id)}
                key={i}
                hideBorder
              />
            ))}
          </FlexBox>
        </FlexBox>
      </ModalContent>
      <FlexBox
        wrap={false}
        justify="space-between"
        style={{
          position: "sticky",
          bottom: 0,
          padding: SPACING.space6,
        }}
      >
        <TextButton onClick={onClose}>
          <Typography variant="bodyBold" color={JoonUIColor.text.primaryAccent}>
            Cancel
          </Typography>
        </TextButton>
        <Button
          text="Save"
          disabled={
            isExamplePatient ||
            (initialClinicians.every((item) =>
              selectedClinicians.includes(item)
            ) &&
              selectedClinicians.every((item) =>
                initialClinicians.includes(item)
              ))
          }
          onClick={onClickSave}
          isLoading={updateCliniciansMutation.isLoading}
        />
      </FlexBox>
    </>
  )
}
