import { ParentTrainingMasteryProgress } from "@joonapp/web-shared"
import { useMutation } from "@tanstack/react-query"
import { useParams } from "react-router-dom"

import { createToast } from "../../../../../components/toast/Toast"
import { editObservationalData } from "../../../../../networking/parentTraining"
import { QUERY_KEYS } from "../../../../../networking/queries"
import { queryClient } from "../../../../../queryClient"
import { ToastType } from "../../../../../types"
import {
  ANALYTIC_EVENTS,
  trackAnalyticEvent,
} from "../../../../../util/analytics"

const useEditObservationalDataMutation = () => {
  const { trainingId } = useParams()

  const queryKey = [QUERY_KEYS.TRAINING_MASTERY_PROGRESS, Number(trainingId)]

  return useMutation({
    mutationFn: editObservationalData,
    onMutate: async (updatedData) => {
      await queryClient.cancelQueries(queryKey)

      const previousProgress =
        queryClient.getQueryData<ParentTrainingMasteryProgress[]>(queryKey)

      queryClient.setQueryData<ParentTrainingMasteryProgress[]>(
        queryKey,
        (old) =>
          old?.map((item) =>
            item.id === updatedData.id ? { ...item, ...updatedData } : item
          )
      )

      return { previousProgress }
    },
    onSuccess: () => {
      createToast({
        title: "Observational data updated successfully",
        type: ToastType.SUCCESS,
      })
      trackAnalyticEvent(ANALYTIC_EVENTS.edit_observational_data)
    },
    onError: (_err, _variables, context) => {
      if (context?.previousProgress) {
        queryClient.setQueryData<ParentTrainingMasteryProgress[]>(
          queryKey,
          context.previousProgress
        )
      }
      createToast({
        title: "Failed to update observational data",
        type: ToastType.ERROR,
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries(queryKey)
    },
  })
}

export default useEditObservationalDataMutation
