import {
  FlexBox,
  JoonUIColor,
  SPACING,
  TextButton,
  Typography,
} from "@joonapp/web-shared"

import AddEditDataTaskModal from "./AddEditDataTaskModal"
import DataTaskItem from "./DataTaskItem"
import DataTasksEmptyState from "./DataTasksEmptyState"
import DeleteDataTaskModal from "./DeleteDataTaskModal"
import { useAddEditDataTaskStore } from "./useAddEditDataTaskStore"
import useDataTasksQuery from "./useDataTasksQuery"
import PageContentWrapper from "../../../../components/layout/PageContentWrapper"
import { SkeletonLoaderRow } from "../../../../components/loading/SkeletonLoader"
import PageWrapper from "../../../../components/pageWrapper/PageWrapper"
import { useCurrentTrainingQuery } from "../../../../networking/queries"
import ExportButton, { ExportType } from "../../behaviorsIncidents/ExportButton"

const DataTasksTab = () => {
  const { data: dataTasks, isLoading } = useDataTasksQuery()
  const { data: currentTraining } = useCurrentTrainingQuery()
  const { onOpen: onOpenAddDataTask } = useAddEditDataTaskStore()

  return (
    <PageWrapper>
      <PageContentWrapper>
        <FlexBox direction="column" wrap={false} gap={SPACING.space2}>
          {isLoading ? (
            <SkeletonLoaderRow numRows={3} />
          ) : dataTasks && dataTasks.length > 0 ? (
            <>
              <FlexBox
                direction="row"
                justify="space-between"
                wrap={false}
                align="center"
              >
                <Typography variant="bodyBold" style={{ whiteSpace: "nowrap" }}>
                  {dataTasks.length} practice task
                  {dataTasks.length > 1 ? "s" : ""}
                </Typography>
                <FlexBox direction="row" wrap={false}>
                  <TextButton
                    style={{ marginLeft: "auto", whiteSpace: "nowrap" }}
                    onClick={() => onOpenAddDataTask()}
                  >
                    <Typography
                      variant="bodyBold"
                      color={JoonUIColor.text.primaryAccent}
                    >
                      + Add task
                    </Typography>
                  </TextButton>
                  <ExportButton
                    type={ExportType.DATA_TASKS}
                    parentTrainingId={currentTraining?.id}
                  />
                </FlexBox>
              </FlexBox>
              <FlexBox direction="column" gap={SPACING.space2}>
                {dataTasks?.map((task) => (
                  <DataTaskItem key={task.id} task={task} />
                ))}
              </FlexBox>
            </>
          ) : (
            <DataTasksEmptyState />
          )}
        </FlexBox>
      </PageContentWrapper>
      <AddEditDataTaskModal />
      <DeleteDataTaskModal />
    </PageWrapper>
  )
}

export default DataTasksTab
