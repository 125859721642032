export enum IncidentLogDataType {
  DURATION = "duration",
  ACCURACY = "accuracy",
  FREQUENCY = "frequency",
  ANTECEDENT = "antecedent",
}

export interface Incident {
  date_created: string
  date_read: string | null
  freeform_preventive_strategy: string | null
  freeform_target_behavior: string | null
  id: number
  incident_datetime: string
  intensity: 0 | 1 | 2 | 3 | 4 | 5 | 6
  preventive_strategy: {
    id: number
    title: string
  } | null
  reflection: string | null // no char limit
  reporter: {
    id: number
    date_joined?: string
    email?: string
    name: string
    last_name: string | null
  }
  response_intensity: 0 | 1 | 2 | 3 | 4 | 5 | 6
  target_behavior: {
    id: number
    title: string
  } | null
  user: {
    id: number
    name: string
    last_name?: string | null
    email?: string | null
    active_doter_id?: number
    coins?: number
    username?: string
  }

  // Different types
  frequency: string | null
  duration: string | null
  accuracy_successes: number | null
  accuracy_attempts: number | null
  antecedent: string | null
  consequence: string | null

  training_data_collection_task: {
    id: number
    title: string
  } | null
}
