import {
  Button,
  FlexBox,
  JoonUIColor,
  LearningModuleAssignment,
  Shadow,
  SPACING,
  TextButton,
  Typography,
} from "@joonapp/web-shared"
import { useMediaQuery } from "@mui/material"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"

import TrainingModuleAssignmentItem from "./TrainingModuleAssignmentItem"
import { SortableList } from "../../../../components/dnd/SortableList"
import { useCurrentTrainingQuery } from "../../../../networking/queries"
import useEditTrainingMutation from "../mutations/useEditTrainingMutation"

const ModulesDndList = () => {
  const { data: currentTraining } = useCurrentTrainingQuery()
  const [items, setItems] = useState<LearningModuleAssignment[]>([])

  const { userId } = useParams()
  const isExampleClient = Number(userId) === 0

  const isMobile = useMediaQuery("(max-width: 800px)")

  useEffect(() => {
    if (!currentTraining) return
    setItems(currentTraining?.assignments)
  }, [currentTraining])

  const userHasMadeChanges =
    currentTraining &&
    items.length > 0 &&
    items !== currentTraining?.assignments

  const editTrainingMutation = useEditTrainingMutation()

  const onSave = () => {
    if (!currentTraining) return

    editTrainingMutation.mutate({
      id: currentTraining.id,
      module_ids: items.map((item) => item.learning_module.id),
    })
  }

  return (
    <FlexBox direction="column" gap={SPACING.space2}>
      <SortableList
        items={items}
        onChange={setItems}
        renderItem={(moduleAssignment) => (
          <SortableList.Item id={moduleAssignment.id}>
            <FlexBox
              direction="row"
              wrap={false}
              align="center"
              gap={SPACING.space2}
            >
              <SortableList.DragHandle />
              <TrainingModuleAssignmentItem
                moduleAssignment={moduleAssignment}
              />
            </FlexBox>
          </SortableList.Item>
        )}
      />

      {userHasMadeChanges && (
        <FlexBox
          direction="row"
          justify="space-between"
          align="center"
          wrap={false}
          gap={SPACING.space2}
          style={{
            position: "fixed",
            bottom: isMobile ? "100px" : SPACING.space6,
            left: "50%",
            transform: "translateX(-50%)",
            width: `min(700px, 100%)`,
            padding: `${SPACING.space4} ${SPACING.space6}`,
            background: JoonUIColor.background.primaryNeutral,
            boxShadow: Shadow.low,
            borderRadius: SPACING.space10,
            border: `1px solid ${JoonUIColor.border.default}`,
          }}
        >
          <FlexBox direction="column" wrap={false}>
            <Typography variant="bodyBold" color={JoonUIColor.text.primary}>
              Updating module ordering
            </Typography>
            {!isMobile && (
              <Typography variant="bodySmall">
                This will change how the modules are ordered on the parent side
              </Typography>
            )}
          </FlexBox>
          <FlexBox
            align="center"
            wrap={false}
            style={{ width: "fit-content" }}
            gap={SPACING.space2}
          >
            <TextButton onClick={() => setItems(currentTraining?.assignments)}>
              <Typography
                variant="bodyBold"
                color={JoonUIColor.text.primaryAccent}
              >
                Cancel
              </Typography>
            </TextButton>
            <Button
              text="Update"
              onClick={onSave}
              isLoading={editTrainingMutation.isLoading}
              disabled={isExampleClient}
            />
          </FlexBox>
        </FlexBox>
      )}
    </FlexBox>
  )
}

export default ModulesDndList
