import {
  Button,
  FlexBox,
  JoonColorExpanded,
  JoonUIColor,
  Shadow,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import { useState } from "react"

import useAddEditMasteryCriteriaModalStore from "./store/useAddEditMasteryCriteriaModalStore"
import { useCurrentTrainingQuery } from "../../../../networking/queries"

const AddMasteryCriteriaButton = () => {
  const { data: currentTraining } = useCurrentTrainingQuery()
  const [isHovered, setIsHovered] = useState(false)
  const { onOpen } = useAddEditMasteryCriteriaModalStore()

  if (
    !currentTraining ||
    (currentTraining?.mastery_criteria &&
      currentTraining?.mastery_criteria_measurement)
  )
    return null

  const onClick = () => {
    onOpen(currentTraining)
  }

  return (
    <div style={{ position: "absolute" }}>
      <button
        style={{ padding: 0, width: "min(600px, 95vw)" }}
        onClick={onClick}
      >
        <FlexBox
          direction="column"
          gap={SPACING.space2}
          style={{
            padding: SPACING.space4,
            border: `1px solid ${JoonUIColor.border.default}`,
            borderRadius: SPACING.space2,
            boxShadow: Shadow.high,
            background: JoonUIColor.background.primaryNeutral,
            outline: isHovered
              ? `2px solid ${JoonColorExpanded.indigo300}`
              : "none",
          }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <Typography variant="bodyBold">
            {!currentTraining.mastery_criteria_measurement
              ? "Add measurement type to start tracking"
              : "Add mastery criteria"}
          </Typography>
          <Typography variant="bodySmall">
            {!currentTraining.mastery_criteria_measurement
              ? "Once set, you'll be able to plot observational data for this training"
              : "Define the criteria for mastery of this goal"}
          </Typography>
          <Button
            text="Add mastery criteria"
            buttonType="secondary"
            onClick={onClick}
          />
        </FlexBox>
      </button>
    </div>
  )
}

export default AddMasteryCriteriaButton
