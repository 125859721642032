import {
  ChevronLeftIcon,
  ChevronRightIcon,
  CircleCheckboxChecked,
  CloseCircleIcon,
  FlexBox,
  JoonColorExpanded,
  JoonUIColor,
  MultipleChoiceLearningModuleAssignmentCompletion,
  MultipleChoiceModule,
  MultipleChoiceOption,
  SPACING,
  TextButton,
  Typography,
} from "@joonapp/web-shared"
import { useMemo, useState } from "react"

const QuizModule = ({
  learningModule,
  completion,
}: {
  learningModule: MultipleChoiceModule
  completion?: MultipleChoiceLearningModuleAssignmentCompletion
}) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
  const currentQuestion =
    learningModule.multiple_choice_questions[currentQuestionIndex]
  const numberOfQuestions = learningModule.multiple_choice_questions.length
  const isOnFirstQuestion = currentQuestionIndex === 0
  const isOnLastQuestion = currentQuestionIndex === numberOfQuestions - 1

  const onClickPrevQuestion = () => {
    if (isOnFirstQuestion) return
    setCurrentQuestionIndex((prev) => prev - 1)
  }

  const onClickNextQuestion = () => {
    if (isOnLastQuestion) return
    setCurrentQuestionIndex((prev) => prev + 1)
  }

  return (
    <FlexBox direction="column">
      <FlexBox align="center" direction="row" wrap={false} gap={SPACING.space1}>
        <TextButton onClick={onClickPrevQuestion}>
          <ChevronLeftIcon
            size={24}
            color={
              isOnFirstQuestion
                ? JoonUIColor.icon.light
                : JoonUIColor.icon.neutral
            }
          />
        </TextButton>
        <FlexBox
          direction="column"
          justify="center"
          style={{
            borderRadius: SPACING.space4,
            boxShadow: `-8px -6px 24px 0px rgba(123, 131, 142, 0.20), 6px 6px 24px 0px ${JoonUIColor.background.lightGray}`,
            padding: SPACING.space6,
            background: JoonUIColor.background.primaryNeutral,
          }}
          gap={SPACING.space4}
        >
          <Typography variant="bodySmall" textAlign="center">
            Question {currentQuestionIndex + 1} of {numberOfQuestions}
          </Typography>
          <Typography variant="bodyBold" textAlign="center">
            {currentQuestion.text}
          </Typography>
          <FlexBox direction="column" gap={SPACING.space2}>
            {currentQuestion.options.map((answer) => (
              <AnswerItem
                key={answer.id}
                answer={answer}
                isUserSelected={completion?.multiple_choice_answers.some(
                  (a) => a.answer_id === answer.id
                )}
              />
            ))}
          </FlexBox>
        </FlexBox>
        <TextButton onClick={onClickNextQuestion}>
          <ChevronRightIcon
            size={20}
            color={
              isOnLastQuestion
                ? JoonUIColor.icon.light
                : JoonUIColor.icon.neutral
            }
          />
        </TextButton>
      </FlexBox>
      <FlexBox
        align="center"
        justify="center"
        gap={SPACING.space1}
        style={{ marginTop: SPACING.space4 }}
      >
        {Array.from({ length: numberOfQuestions }).map((_, i) => (
          <button
            style={{
              borderRadius: "50%",
              background:
                i === currentQuestionIndex
                  ? JoonUIColor.background.accent
                  : JoonUIColor.background.lightAccent,
              minWidth: SPACING.space2,
              minHeight: SPACING.space2,
              padding: SPACING.space0,
            }}
            onClick={() => setCurrentQuestionIndex(i)}
          />
        ))}
      </FlexBox>
    </FlexBox>
  )
}

export default QuizModule

const AnswerItem = ({
  answer,
  isUserSelected,
}: {
  answer: MultipleChoiceOption
  isUserSelected?: boolean
}) => {
  const isSystemCorrect = answer.is_correct

  const isCorrectSelected = isSystemCorrect && isUserSelected
  const isCorrectNotSelected = isSystemCorrect && !isUserSelected
  const isIncorrectSelected = !isSystemCorrect && isUserSelected

  const colors = useMemo(() => {
    if (isCorrectSelected) {
      return {
        primary: JoonUIColor.semantic.success,
        secondary: JoonColorExpanded.viridian100,
      }
    }
    if (isCorrectNotSelected) {
      return {
        primary: JoonColorExpanded.blue400,
        secondary: JoonColorExpanded.blue100,
      }
    }
    if (isIncorrectSelected) {
      return {
        primary: JoonColorExpanded.red400,
        secondary: JoonColorExpanded.red100,
      }
    }
    return {
      secondary: JoonUIColor.background.primaryNeutral,
      primary: JoonUIColor.text.primary,
    }
  }, [isCorrectSelected, isCorrectNotSelected, isIncorrectSelected])

  return (
    <button
      style={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        justifyContent: "space-between",
        borderRadius: SPACING.space4,
        padding: SPACING.space4,
        background: colors.secondary,
        border: `1px solid ${JoonUIColor.border.default}`,
      }}
    >
      <Typography variant="bodySmall" textAlign="left">
        {answer.text}
      </Typography>
      {answer.is_correct && (
        <CircleCheckboxChecked color={colors.primary} size={16} />
      )}
      {isUserSelected && !answer.is_correct && (
        <CloseCircleIcon color={colors.primary} />
      )}
    </button>
  )
}
