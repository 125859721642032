import {
  ChatIcon,
  CheckIcon,
  FlexBox,
  JoonColorExpanded,
  JoonUIColor,
  QuestSeries,
  QuestType,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import dayjs from "dayjs"
import { useState } from "react"
import { useParams } from "react-router-dom"

import useTaskDetailsModalStore from "./store/useTaskDetailsModalStore"
import NotificationDot from "../../../../components/notificationDot/NotificationDot"
import {
  useParentTaskInstancesQuery,
  usePatientParentsQuery,
} from "../../../../networking/queries"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../../util/analytics"
import {
  getCompletedQuestInstances,
  getInReviewQuestInstances,
} from "../../../../util/quests"
import { exampleParentTasks } from "../../../patientsDashboard/exampleChild"

type Props = {
  task: QuestSeries
}

const ParentTaskCard = ({ task }: Props) => {
  const [isHovered, setIsHovered] = useState(false)
  const { userId } = useParams()
  const { onOpen: openTaskDetailsModal } = useTaskDetailsModalStore()

  const { data: parents } = usePatientParentsQuery()
  const userIds = parents?.map((parent) => parent.user.id) || []

  const { data: oneOffQuestInstances, isLoading } = useParentTaskInstancesQuery(
    {
      userIds: userIds,
      seriesId: task.id || undefined,
      maxDate: dayjs().format("YYYY-MM-DD"),
    }
  )

  const seriesInstances =
    Number(userId) === 0
      ? exampleParentTasks.filter((instance) => instance.series.id === task.id)
      : oneOffQuestInstances

  const completedInstances = getCompletedQuestInstances(seriesInstances)
  const unviewedInstances = getInReviewQuestInstances(seriesInstances)

  const isRecurring = task.type === QuestType.REPEATING ? true : false
  const isCompleted = completedInstances && completedInstances?.length > 0

  const onClickParentTaskCard = () => {
    openTaskDetailsModal(task.id)
    trackAnalyticEvent(ANALYTIC_EVENTS.view_parent_task_details)
  }

  return (
    <button
      style={{
        borderRadius: SPACING.space2,
        padding: `${SPACING.space3} ${SPACING.space4}`,
        border: `1px solid ${JoonUIColor.border.default}`,
        width: "100%",
        outline: isHovered
          ? `2px solid ${JoonColorExpanded.indigo300}`
          : undefined,
        background: JoonUIColor.background.primaryNeutral,
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={onClickParentTaskCard}
    >
      <FlexBox
        direction="row"
        justify="space-between"
        wrap={false}
        align="center"
      >
        <FlexBox direction="column">
          <Typography variant="bodyXSmall" color={JoonUIColor.text.secondary}>
            {isRecurring
              ? ""
              : isCompleted && completedInstances?.[0]?.completion_date
              ? `Completed on ${dayjs
                  .unix(+completedInstances[0].completion_date)
                  .format("MM/DD/YYYY")}`
              : isLoading
              ? ""
              : "Not completed"}
          </Typography>
          <Typography variant="caption" textAlign="left">
            {task.title}
          </Typography>
          {isRecurring && (
            <FlexBox align="center" gap={SPACING.space2} wrap={false}>
              <div
                style={{ position: "relative", transform: "translateY(2px)" }}
              >
                {unviewedInstances.length > 0 && (
                  <NotificationDot
                    style={{ position: "absolute", top: "-3", right: "-5" }}
                  />
                )}
                <ChatIcon size={14} color={JoonUIColor.semantic.primary} />
              </div>
              <Typography variant="bodyXSmall" color={JoonUIColor.text.primary}>
                {unviewedInstances.length > 0
                  ? `${unviewedInstances.length} new reflections`
                  : "No new reflections"}
              </Typography>
            </FlexBox>
          )}
        </FlexBox>
        {!isRecurring && isCompleted && (
          <CheckIcon color={JoonUIColor.semantic.success} size={24} />
        )}
      </FlexBox>
    </button>
  )
}

export default ParentTaskCard
