import {
  FlexBox,
  JoonUIColor,
  Shadow,
  SPACING,
  Typography,
} from "@joonapp/web-shared"

const IncidentLogEmptyState = ({ isDataTask }: { isDataTask: boolean }) => {
  return (
    <FlexBox align="center" justify="center" style={{ height: "100px" }}>
      <Typography
        variant="bodySmall"
        style={{
          border: `1px solid ${JoonUIColor.border.default}`,
          borderRadius: SPACING.space2,
          padding: `${SPACING.space4} ${SPACING.space6}`,
          width: "fit-content",
          margin: SPACING.space2,
          boxShadow: Shadow.high,
        }}
      >
        {isDataTask
          ? "No implementation tasks logged yet."
          : "No incidents logged yet."}
      </Typography>
    </FlexBox>
  )
}

export default IncidentLogEmptyState
