import {
  FlexBox,
  JoonColorExpanded,
  JoonUIColor,
  LearningModule,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import { useId, useState } from "react"

import FavoriteButton from "./FavoriteButton"
import { getModuleTheme, ModuleIcon } from "./resourceIcons"
import { useResourcesSidePanelStore } from "./ResourcesSidePanel"
import CustomTooltip from "../../components/tooltip/CustomTooltip"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"

const ResourceItem = ({
  resource,
  style,
}: {
  resource: LearningModule
  style?: React.CSSProperties
}) => {
  const [isHovered, setIsHovered] = useState(false)
  const iconTooltipId = useId()

  const { moduleText } = getModuleTheme(resource.type)

  const { onOpen } = useResourcesSidePanelStore()

  const openResourceDetails = () => {
    onOpen(resource.id)
    trackAnalyticEvent(ANALYTIC_EVENTS.view_resource, {
      location: "resources",
    })
  }

  return (
    <button
      style={{
        display: "flex",
        width: "100%",
        alignItems: "center",
        borderRadius: SPACING.space2,
        overflow: "hidden",
        padding: SPACING.space4,
        background: JoonUIColor.background.primaryNeutral,
        border: `1px solid ${JoonUIColor.border.default}`,
        outline: isHovered
          ? `2px solid ${JoonColorExpanded.indigo300}`
          : undefined,
        ...style,
        position: "relative",
        maxWidth: "1200px",
        margin: "0 auto",
        gap: SPACING.space2,
      }}
      onClick={openResourceDetails}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div data-tooltip-id={iconTooltipId}>
        <ModuleIcon type={resource.type} size="large" />
        <CustomTooltip id={iconTooltipId}>
          <Typography variant="bodySmall" color={JoonUIColor.text.inverted}>
            {moduleText}
          </Typography>
        </CustomTooltip>
      </div>

      <FavoriteButton
        resource={resource}
        style={{
          position: "absolute",
          top: SPACING.space4,
          right: SPACING.space4,
        }}
      />
      <FlexBox direction="column" align="flex-start" wrap={false}>
        <FlexBox direction="column" style={{ width: "calc(100% - 90px)" }}>
          <Typography
            variant="bodyBold"
            textAlign="left"
            style={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              minWidth: "0",
            }}
          >
            {resource.title}
          </Typography>
          <Typography
            variant="bodySmall"
            textAlign="left"
            style={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              minWidth: "0",
              marginBottom: SPACING.space1,
            }}
          >
            {resource.description}
          </Typography>
        </FlexBox>
      </FlexBox>
    </button>
  )
}

export default ResourceItem
