import {
  Button,
  FlexBox,
  JoonUIColor,
  Modal,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { create } from "zustand"

import { QUERY_KEYS, usePracticeQuery } from "../../networking/queries"
import { createJoonAPIClient } from "../../util/joon-api"

interface BAAModalStore {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
}

export const useBAAModalStore = create<BAAModalStore>((set) => ({
  isOpen: true,
  onOpen: () => set({ isOpen: true }),
  onClose: () => set({ isOpen: false }),
}))

const agreeToBAA = async (practiceId: number) => {
  const API = createJoonAPIClient()
  return API.post(`api/practices/${practiceId}/agree-to-baa/`)
}

const BAAModal = () => {
  const { isOpen, onClose } = useBAAModalStore()
  const { practice } = usePracticeQuery()
  const queryClient = useQueryClient()

  const baaAgreementMutation = useMutation({
    mutationFn: agreeToBAA,
    onSuccess: () => {
      onClose()
      queryClient.refetchQueries([QUERY_KEYS.PRACTICE])
    },
    onError: (error: any) => {
      const errorMsg = error?.response.data?.non_field_errors[0]
      alert(errorMsg || "An error occurred")
    },
  })

  const onClickAgree = () => {
    if (!practice) return
    baaAgreementMutation.mutate(practice.id)
  }

  if (!practice || practice.has_agreed_baa) return <></>

  return (
    <Modal isOpen={isOpen} onClose={onClose} mandatory={true}>
      <div style={{ width: "min(95vw, 475px)" }}>
        <FlexBox
          style={{
            padding: `${SPACING.space4} ${SPACING.space6}`,
            borderBottom: `1px solid ${JoonUIColor.border.default}`,
          }}
          justify="space-between"
          align="center"
          wrap={false}
        >
          <Typography variant="h3">
            Business Associate Agreement (BAA)
          </Typography>
        </FlexBox>
        <FlexBox
          style={{
            padding: `${SPACING.space4} ${SPACING.space6} ${SPACING.space6} ${SPACING.space6}`,
          }}
          direction="column"
          gap={SPACING.space6}
        >
          <FlexBox direction="column" gap={SPACING.space4}>
            <Typography variant="bodyBold" textAlign="left">
              We have updated our Business Associate Agreement and you need to
              accept it to view your clients' data.
            </Typography>
            <div>
              <Typography variant="bodyBold" textAlign="left">
                Summary of change:
              </Typography>
              <Typography variant="body" textAlign="left">
                Joon’s Business Associate Agreement is now publicly available on
                our website for you and your clients to view.
              </Typography>
            </div>
            <Typography variant="body" textAlign="left">
              Please read the full{" "}
              <a
                href="https://www.joonapp.io/baa"
                target="_blank"
                rel="noreferrer"
              >
                <Typography
                  variant="body"
                  color={JoonUIColor.text.primaryAccent}
                >
                  Business Associate Agreement.
                </Typography>
              </a>
            </Typography>
          </FlexBox>
          <FlexBox direction="column" gap={SPACING.space2}>
            <Typography variant="bodyXSmall" textAlign="left">
              By clicking “Agree” you agree to Joon's Business Associate
              Agreement. Email contact@joonapp.io if you have any questions.
            </Typography>

            <Button
              text="Agree"
              isLoading={baaAgreementMutation.isLoading}
              onClick={onClickAgree}
              fullWidth
            >
              Agree
            </Button>
          </FlexBox>
        </FlexBox>
      </div>
    </Modal>
  )
}

export default BAAModal
