import CssBaseline from "@mui/material/CssBaseline"
import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
  StyledEngineProvider,
} from "@mui/material/styles"
import { ReactNode } from "react"

export const JoonColor = {
  purple: "#878EFB",
  secondaryPurple: "#9DA3FF",
  additionalPurple: "#C865EB",
  additionalDarkPurple: "#5E65E9",
  purpleGray: "#9C9EC7",

  blue: "#0B98D4",
  secondaryBlue: "#D7EAFC",
  additionalBlue: "#6FC2DC",
  additionalDarkBlue: "#6AA4E9",

  green: "#8BD073",
  secondaryGreen: "#E0FCD7",

  orange: "#FF8454",
  secondaryOrange: "#FFDCCE",
  additionalOrange: "#F39F3D",

  red: "#FD4646",
  secondaryRed: "#FFD1D1",

  additionalPink: "#EB657C",

  text01: "#2C2C2C",
  text02: "#3A3A3C",
  text03: "#48484A",
  text04: "#636366",
  text05: "#8E8E93",
  text06: "#C7C7CC",
  input: "#F5F5F5",
  gray: "#D1DCEC",
  blueGray: "#EBF3FF",
  white: "#FFFFFF",
}

const buttonBaseStyles = {
  width: "100%",
  height: "45px",
  fontSize: "16px",
  textTransform: "none" as const,
  transition: "0s",
  border: "none",
  boxShadow: "none",
  padding: "8px 24px",
  cursor: "pointer",
  whiteSpace: "nowrap" as const,
}

export const JoonButtonStyle = {
  bluePrimary: {
    ...buttonBaseStyles,
    backgroundColor: JoonColor.blue,
    color: JoonColor.white,
    "&:hover": {
      backgroundColor: JoonColor.blue,
    },
  },
  orangePrimary: {
    ...buttonBaseStyles,
    backgroundColor: JoonColor.orange,
    color: JoonColor.white,
  },
  answerPrimary: {
    ...buttonBaseStyles,
    borderRadius: "16px",
    color: JoonColor.text01,
    background: JoonColor.input,
  },
  answerSecondary: {
    ...buttonBaseStyles,
    borderRadius: "16px",
    color: JoonColor.white,
    border: `4px solid ${JoonColor.purple}`,
    background: JoonColor.secondaryPurple,
  },
  redPrimary: {
    ...buttonBaseStyles,
    backgroundColor: JoonColor.red,
    color: JoonColor.white,
    "&:hover": {
      backgroundColor: JoonColor.red,
    },
  },
  greenPrimary: {
    ...buttonBaseStyles,
    backgroundColor: JoonColor.green,
    color: JoonColor.white,
  },
  cancel: {
    ...buttonBaseStyles,
    border: "1px solid #ccc",
    backgroundColor: JoonColor.white,
    color: "#555",
  },
}

const themeConfig = {
  // Light theme
  palette: {
    primary: {
      main: "#0B98D4",
    },
    secondary: {
      main: "#ffffff",
    },
    background: {
      default: "#fff",
      paper: "#fff",
    },
    success: {
      main: "#8AC293",
    },
    warning: {
      main: "#F39F3D",
    },
    error: {
      main: "#F3483D",
    },
  },
  typography: {
    fontSize: 14,
    fontFamily: '"Urbanist", "Arial", sans-serif',
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1200,
      xl: 1920,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            background: "#D9D9D9 !important",
          },
        },
      },
    },
  },
}

export const theme = createTheme(themeConfig)

export const ThemeProvider = ({ children }: { children: ReactNode }) => {
  return (
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider theme={theme}>
        {/* Set global MUI styles */}
        <CssBaseline />
        {children}
      </MuiThemeProvider>
    </StyledEngineProvider>
  )
}
