import { FlexBox } from "@joonapp/web-shared"

import AddQuestModal from "./AddQuestModal"
import AddEditTargetBehaviorSidePanel from "./behaviorsIncidents/AddTargetBehaviorModal"
import PatientInfoContent from "./PatientInfoContent"
import PatientInfoSidebar from "./PatientInfoSidebar"
import PageWrapper from "../../components/pageWrapper/PageWrapper"
import { withSubscriptionCheck } from "../../components/subscription/PaywallModal"
import OnboardTutorial from "../../components/tutorial/OnboardTutorial"
import { requireAuth } from "../../util/auth"

const PatientInfo = withSubscriptionCheck(
  requireAuth(() => {
    return (
      <PageWrapper>
        <FlexBox direction="row" wrap={false}>
          <PatientInfoSidebar />
          <PatientInfoContent />
          <AddQuestModal />
          <AddEditTargetBehaviorSidePanel />
        </FlexBox>
        <OnboardTutorial />
      </PageWrapper>
    )
  })
)

export default PatientInfo
