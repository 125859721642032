import {
  QuestStatus,
  QuestCompletionDifficulty,
  UserRole,
  QuestSeries,
  FrequencyTarget,
} from "@joonapp/web-shared"
import dayjs, { Dayjs } from "dayjs"

export type QuestSeriesWithTargets = QuestSeries & {
  frequency_targets: FrequencyTarget[]
}

export interface DateQuest {
  series_id: number
  status: QuestStatus
  completion_difficulty?: QuestCompletionDifficulty | null
  reward: number | null
  completion_notes: string | null
  completion_photo_url: string | null
  verification_notes: string | null
}

export interface DataFormat {
  quests: QuestSeriesWithTargets[]
  dates: {
    [key: string]: DateQuest[]
  }
}

export interface SummarizedDataFormat {
  chartData: ChartDataPoint[]
  summary: SummaryTableRow[]
  quests: QuestSeriesWithTargets[]
}

export interface ChartDataPoint {
  startDate: string
  endDate: string
  completed: number
  total: number
  consistency: number
  quests: {
    [key: number]: {
      completed: number
      total: number
      consistency: number
    }
  }
}

export interface SummaryTableRow {
  id: number
  quest: string
  skill: string
  completed: number
  remaining: number
  missed: number
  total: number
  reward: number
  routine: string
  creator: UserRole.PARENT | UserRole.THERAPIST
  hasTarget: boolean
  isTherapistCreated: boolean
}

export const queryDataResponse = (
  data: DataFormat,
  selectedQuestIds: number[],
  period: "week" | "month" | "day"
): SummarizedDataFormat => {
  const chartData = {} as {
    [key: string]: {
      startDate: string
      endDate: string
      quests: {
        [key: number]: {
          completed: number
          total: number
          consistency: number | null
          target: FrequencyTarget | null
        }
      }
      completed: number
      total: number
      consistency: number | null
    }
  }
  const summaryData = {} as { [key: number]: SummaryTableRow }

  const datesData = data.dates
  const questsMeta = data.quests

  Object.keys(datesData).forEach((date) => {
    const dayjsDate = dayjs(date, "MM-DD-YYYY")

    const dateQuests = datesData[date].map((quest) => ({
      ...quest,
      id: quest.series_id,
    }))

    const periodStart = dayjsDate.startOf(period).format("YYYY-MM-DD")
    const periodEnd = dayjsDate.endOf(period).format("YYYY-MM-DD")
    const isAfterToday = dayjsDate.isAfter(dayjs(), "day")

    // GET CHART DATA -------------
    // For the chart data, I want to group and count all quests by period
    if (!chartData[periodStart]) {
      chartData[periodStart] = {
        startDate: periodStart,
        endDate: periodEnd,
        quests: {},
        completed: 0,
        total: 0,
        consistency: null,
      }
      // If no quests passed in, I just want to tally up everything
      if (selectedQuestIds.length === 0)
        selectedQuestIds = dateQuests.map((q) => q.id)

      selectedQuestIds.forEach((id) => {
        chartData[periodStart].quests[id] = {
          completed: 0,
          total: 0,
          consistency: null,
          target: null,
        }
      })
    }

    dateQuests.forEach((quest) => {
      const questStatus = getQuestStatus(quest.status, dayjsDate)
      const questMeta = questsMeta.find(
        (q) => q.id === quest.id
      ) as QuestSeriesWithTargets

      // CHART DATA ------------ for the selected quests
      const chartDataPeriod = chartData[periodStart]
      const isSelectedQuest = selectedQuestIds.includes(quest.id)

      if (!isAfterToday && isSelectedQuest) {
        // Add up for individual quests and for total
        const questPeriodSummaryStats = chartDataPeriod.quests[quest.id]

        // look at the quest target frequencies and date_created / if they have a date_deleted
        // Find the most recently created target for this time period

        // If the quest has a target, and the target is within periodStart and periodEnd
        // then set the target frequency
        // Find the most recently created goal
        for (let target of questMeta?.frequency_targets || []) {
          const isCreatedBeforeOrDuringPeriod =
            dayjs(target.date_created) <= dayjs(periodEnd)
          const isNotDeletedBeforePeriodStart =
            !target.date_deleted ||
            dayjs(target.date_deleted) >= dayjs(periodStart)
          const isValidTarge =
            isCreatedBeforeOrDuringPeriod && isNotDeletedBeforePeriodStart

          if (isValidTarge) {
            questPeriodSummaryStats.target = target
            break
          }
        }

        if (questStatus === "completed") {
          questPeriodSummaryStats.completed += 1
          chartDataPeriod.completed += 1
        }
        questPeriodSummaryStats.total += 1
        chartDataPeriod.total += 1
      }

      // GET SUMMARY DATA ------------ for all quests
      // Just want to summarize all these based on the questId itself.
      // For the difficulty, I want to sum up the total as well as
      // count how many times it was marked, so I can get average later.
      if (!summaryData[quest.id]) {
        if (!questMeta) return
        summaryData[quest.id] = {
          quest: questMeta.title,
          id: quest.id,
          completed: questStatus === "completed" ? 1 : 0,
          remaining: questStatus === "remaining" ? 1 : 0,
          missed: questStatus === "missed" ? 1 : 0,
          total: 1,
          reward: quest.reward || 0,
          skill: (questMeta?.skill || null) as any,
          routine: questMeta?.routine || null,
          creator:
            (questMeta?.assigner_profile?.role as any) || UserRole.PARENT,
          hasTarget: !!questMeta?.frequency_targets?.length,
          isTherapistCreated:
            questMeta?.assigner_profile?.role === UserRole.THERAPIST,
        }
      } else {
        const questSummary = summaryData[quest.id]
        if (questStatus === "completed") questSummary.completed += 1
        if (questStatus === "remaining") questSummary.remaining += 1
        if (questStatus === "missed") questSummary.missed += 1
        questSummary.total += 1
        questSummary.reward += quest.reward || 0
      }
    })
  })

  const chartDataWithConsistency = Object.values(chartData).map((data) => {
    for (const questId in data.quests) {
      const quest = data.quests[questId]
      quest.consistency = quest.completed / quest.total
    }
    if (data.total === 0) data.consistency = null
    else data.consistency = data.completed / data.total
    return data
  })

  const output = {
    chartData: sortByDate(chartDataWithConsistency) as ChartDataPoint[],
    summary: oldSortByRoutine(Object.values(summaryData)),
    quests: data.quests,
  }

  return output
}

const getQuestStatus = (questStatus: QuestStatus, date: Dayjs) => {
  const isBeforeToday = date.isBefore(dayjs(), "day")
  const statusMapping: { [key: string]: string } = {
    completed: "completed",
    verified: "completed",
    redeemed: "completed",
    skipped: "completed",
    rejected: "missed",
    rejected_viewed: "missed",
    retry: isBeforeToday ? "missed" : "remaining",
    open: isBeforeToday ? "missed" : "remaining",
  }
  return statusMapping[questStatus]
}

const sortByDate = (
  data: {
    startDate: string
    [key: string]: any
  }[]
) =>
  data.sort(
    (a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
  )

export const oldSortByRoutine = (data: SummaryTableRow[]) => {
  const routineOrder = {
    morning: 0,
    afternoon: 1,
    night: 2,
    anytime: 3,
  } as { [key: string]: number }
  return data.sort((a, b) => {
    return routineOrder[a.routine] - routineOrder[b.routine]
  })
}
