import { FlexBox, JoonUIColor, SPACING } from "@joonapp/web-shared"
import { useMediaQuery } from "@mui/material"

import { PAGE_CONTENT_WIDTH } from "../../constants"

export const PageContentWrapper = ({
  children,
  scrollable = true,
}: {
  children: React.ReactNode
  scrollable?: boolean
}) => {
  const isMobile = useMediaQuery("(max-width: 800px)")

  return (
    <FlexBox
      justify="center"
      style={{
        flex: 1,
        overflowX: "hidden",
        overflowY: scrollable ? "auto" : undefined,
        background: JoonUIColor.background.xlightGray,
        padding: isMobile ? SPACING.space4 : SPACING.space6,
        paddingBottom: "200px",
      }}
    >
      <FlexBox
        direction="column"
        gap={SPACING.space6}
        style={{ width: `min(${PAGE_CONTENT_WIDTH}, 100vw)` }}
        wrap={false}
      >
        {children}
      </FlexBox>
    </FlexBox>
  )
}

export default PageContentWrapper
