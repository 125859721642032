import {
  Button,
  Checkbox,
  FlexBox,
  JoonUIColor,
  Modal,
  ModalHeader,
  SPACING,
  TextButton,
  TextInput,
  Typography,
} from "@joonapp/web-shared"
import { useMediaQuery } from "@mui/material"
import { useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"

import { useAddTrainingMutation } from "./mutations/useAddTrainingMutation"
import useEditTrainingMutation from "./mutations/useEditTrainingMutation"
import { useAddEditParentTrainingStore } from "./useAddEditParentTrainingStore"
import useParentTrainingStore from "./useParentTrainingStore"
import OpenPDFButton from "../../../components/buttons/OpenPDFButton"
import { createToast } from "../../../components/toast/Toast"
import { usePatientParentsQuery } from "../../../networking/queries"
import { ParentTrainingTab, PatientInfoTab, ToastType } from "../../../types"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../util/analytics"

const AddEditParentTrainingModal = () => {
  const { data: parents } = usePatientParentsQuery()
  const navigate = useNavigate()
  const { userId } = useParams()

  const {
    isOpen,
    onClose,
    selectedParentId,
    setSelectedParentId,
    title,
    setTitle,
    masteryCriteria,
    selectedParentTrainingId,
  } = useAddEditParentTrainingStore()
  const { selectedParentId: selectedParentToggleId } = useParentTrainingStore()

  const addParentTrainingMutation = useAddTrainingMutation()
  const editTrainingMutation = useEditTrainingMutation()
  const isMobile = useMediaQuery("(max-width: 800px)")
  const isEditing = !!selectedParentTrainingId

  const isExamplePatient = Number(userId) === 0

  // When opened, set toggle to previously selected parent
  useEffect(() => {
    if (!isOpen || isEditing) return
    if (!selectedParentId && selectedParentToggleId) {
      setSelectedParentId(selectedParentToggleId)
    }
  }, [
    setSelectedParentId,
    selectedParentId,
    selectedParentToggleId,
    isOpen,
    isEditing,
  ])

  const onClickCreateTraining = async () => {
    if (!selectedParentId) return
    if (!title)
      return createToast({
        title: "Please enter a title for the training",
        type: ToastType.ERROR,
      })
    const training = await addParentTrainingMutation.mutateAsync({
      user_id: selectedParentId,
      title,
      mastery_criteria: "",
      child_id: Number(userId),
    })
    onClose()
    navigate(
      `/patients/${userId}/${PatientInfoTab.PARENT_TRAINING}/${training.id}/${ParentTrainingTab.SUMMARY}`
    )
    trackAnalyticEvent(ANALYTIC_EVENTS.edit_parent_training)
  }

  const onClickEditTraining = async () => {
    if (!selectedParentTrainingId) return
    if (!title)
      createToast({
        title: "Please enter a title for the training",
        type: ToastType.ERROR,
      })
    editTrainingMutation.mutate({
      id: selectedParentTrainingId,
      title,
      mastery_criteria: masteryCriteria,
    })
    onClose()
  }

  if (isMobile)
    return (
      <Modal isOpen={isOpen} onClose={onClose}>
        <FlexBox
          direction="column"
          gap={SPACING.space6}
          style={{ padding: SPACING.space4, width: "min(340px, 95vw)" }}
        >
          <Typography variant="h3" textAlign="left">
            Parent training set up is currently only available in desktop.
          </Typography>
          <Typography variant="body" textAlign="left">
            Come back to Joon on your computer to set up parent training.
          </Typography>
          <Button
            style={{ marginLeft: "auto" }}
            text="Okay"
            fullWidth
            onClick={onClose}
          />
        </FlexBox>
      </Modal>
    )

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalHeader
        title={
          isEditing
            ? "Edit training"
            : ((
                <FlexBox direction="column" gap={SPACING.space1}>
                  <Typography variant="h3">Create a new training</Typography>
                  <OpenPDFButton
                    text="Preview parent experience"
                    imageUrl="/images/parentTraining.pdf"
                  />
                </FlexBox>
              ) as any)
        }
        onClose={onClose}
        showBorderBottom
      />

      <FlexBox
        direction="column"
        gap={SPACING.space4}
        style={{
          padding: `${SPACING.space4} ${SPACING.space6}`,
          width: "min(600px, 95vw)",
        }}
      >
        {!isEditing && (
          <Typography variant="body" textAlign="left">
            Once created, you’ll be able to assign tasks and learning modules
            directly to parents.
          </Typography>
        )}
        {!isEditing && (
          <FlexBox direction="column" gap={SPACING.space2}>
            <Typography variant="bodyBold" textAlign="left">
              Who is this training for?
            </Typography>
            {parents?.map((parent) => (
              <Checkbox
                hideBorder
                inputType="radio"
                label={parent.user.name}
                name="parentSelection"
                selected={parent.user.id === selectedParentId}
                onChange={() => setSelectedParentId(parent.user.id)}
              />
            ))}
          </FlexBox>
        )}
        <FlexBox direction="column" gap={SPACING.space1}>
          <div>
            <Typography variant="bodyBold" textAlign="left">
              Training goal
            </Typography>
            <Typography variant="bodySmall">
              What is the desired outcome for this training? Check our our{" "}
              <a
                href="https://www.joonhealth.co/bcba-tools/parent-goal-generator"
                target="_blank"
                rel="noopener noreferrer"
              >
                brainstorming tool
              </a>{" "}
              for ideas!
            </Typography>
          </div>
          <TextInput
            placeholder="ie: Parent can independently set up and manage a token economy system."
            onChange={(e) => setTitle(e.target.value)}
            fullWidth
            name="trainingName"
            value={title}
            maxLength={100}
          />
        </FlexBox>
        <FlexBox
          direction="row"
          align="center"
          gap={SPACING.space2}
          justify="flex-end"
        >
          <TextButton onClick={onClose}>
            <Typography variant="bodyBold" color={JoonUIColor.semantic.primary}>
              Cancel
            </Typography>
          </TextButton>
          <Button
            text={isEditing ? "Save" : "Next"}
            onClick={isEditing ? onClickEditTraining : onClickCreateTraining}
            isLoading={
              addParentTrainingMutation.isLoading ||
              editTrainingMutation.isLoading
            }
            disabled={(!selectedParentId && !isEditing) || isExamplePatient}
          />
        </FlexBox>
      </FlexBox>
    </Modal>
  )
}

export default AddEditParentTrainingModal
