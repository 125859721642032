import { IncidentLogDataType, ParentTrainingDataCollectionTask } from "../types"
import { createJoonAPIClient } from "../util/joon-api"

export const getDataTasks = async (trainingId?: number) => {
  const API = createJoonAPIClient()
  return API.get<{ results: ParentTrainingDataCollectionTask[] }>(
    `api/parent-training-data-collection-tasks/`,
    { params: { training_id: trainingId } }
  ).then((res) => res.data.results)
}

export const createDataTask = async ({
  trainingId,
  title,
  description,
  dataType,
}: {
  trainingId: number
  title: string
  description: string | null
  dataType: IncidentLogDataType | null
}) => {
  const API = createJoonAPIClient()
  return API.post(`api/parent-training-data-collection-tasks/`, {
    training_id: trainingId,
    title,
    description,
    data_type: dataType,
  })
}

export const updateDataTask = async ({
  dataTaskId,
  title,
  description,
  dataType,
  trainingId,
}: {
  dataTaskId: number
  title: string
  description: string | null
  dataType: IncidentLogDataType | null
  trainingId: number
}) => {
  const API = createJoonAPIClient()
  return API.put(`api/parent-training-data-collection-tasks/${dataTaskId}/`, {
    title,
    description,
    data_type: dataType,
    training_id: trainingId,
  })
}

export const deleteDataTask = async ({
  dataTaskId,
}: {
  dataTaskId: number
}) => {
  const API = createJoonAPIClient()
  return API.delete(`api/parent-training-data-collection-tasks/${dataTaskId}/`)
}
