import { ParentTraining } from "@joonapp/web-shared"
import { useMutation, useQueryClient } from "@tanstack/react-query"

import { useAddEditParentTaskStore } from "./useAddEditParentTaskStore"
import { createToast, Toasts } from "../../components/toast/Toast"
import { QUERY_KEYS, useCurrentTrainingQuery } from "../../networking/queries"
import { editQuest } from "../../networking/quests"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"

const useEditTaskMutation = () => {
  const queryClient = useQueryClient()
  const { onClose } = useAddEditParentTaskStore()
  const { data: currentTraining } = useCurrentTrainingQuery()

  const queryKey = [QUERY_KEYS.PARENT_TRAINING, currentTraining?.id]

  return useMutation({
    mutationFn: editQuest,
    onMutate: ({ seriesId, questInfo }) => {
      queryClient.cancelQueries(queryKey)
      queryClient.cancelQueries([QUERY_KEYS.PARENT_TRAININGS])
      // Optimistically update the parent training list
      // Snapshot the previous value

      if (!currentTraining) throw new Error("No training selected")

      const previousTraining =
        queryClient.getQueryData<ParentTraining>(queryKey)

      // Update the quest info
      const newTraining = {
        ...previousTraining,
        quests: previousTraining?.quests.map((quest) =>
          quest.id === seriesId ? { ...quest, ...questInfo } : quest
        ),
      } as ParentTraining

      // Optimistically update to the new value
      queryClient.setQueryData<ParentTraining>(queryKey, newTraining)

      onClose()
      createToast(Toasts.EDIT_TASK_SUCCESS)

      return { previousTraining }
    },
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.PATIENT_GROUPS]) // for usage_notification
      queryClient.invalidateQueries([QUERY_KEYS.PARENT_TRAININGS])
      queryClient.invalidateQueries(queryKey)
      trackAnalyticEvent(ANALYTIC_EVENTS.edit_parent_task)
    },
    onError: (
      _err: any,
      _variables: any,
      context: { previousTraining: ParentTraining | undefined } | undefined
    ) => {
      // If the mutation fails,
      // use the context returned from onMutate to roll back
      if (context?.previousTraining) {
        queryClient.setQueryData<ParentTraining>(
          queryKey,
          context.previousTraining
        )
      }
    },
  })
}

export default useEditTaskMutation
