import { FlexBox, SPACING, Typography } from "@joonapp/web-shared"
import { useMediaQuery } from "@mui/material"
import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table"
import dayjs from "dayjs"

import DeleteInviteButton from "./DeleteInviteButton"
import SendReminderButton from "./SendReminderButton"
import CustomTable from "../../../components/table/CustomTable"
import TableHeaderCell from "../../../components/table/TableHeaderCell"
import { useSentInvitesQuery } from "../../../networking/queries"
import { InvitationGroup } from "../../../types"

const SentInvitesTable = () => {
  const { data: sentInvites } = useSentInvitesQuery()
  const columnHelper = createColumnHelper<InvitationGroup>()
  const isMobile = useMediaQuery("(max-width:800px)")

  const columns = [
    columnHelper.accessor((group) => group[0].invitee_email, {
      id: "inviteeEmail",
      header: () => <TableHeaderCell>To</TableHeaderCell>,
      cell: (info) => (
        <Typography variant="bodySmall">{info.getValue()}</Typography>
      ),
    }),
    ...(!isMobile
      ? [
          columnHelper.accessor(
            (group) => dayjs(group[0].date_created).format("MMM D, YYYY"),
            {
              id: "dateCreated",
              header: () => <TableHeaderCell>Invited On</TableHeaderCell>,
              cell: (info) => (
                <Typography variant="bodySmall">{info.getValue()}</Typography>
              ),
            }
          ),
        ]
      : []),
    columnHelper.display({
      id: "actions",
      cell: (info) => (
        <FlexBox
          direction="row"
          gap={SPACING.space2}
          wrap={false}
          align="center"
        >
          {!isMobile && (
            <DeleteInviteButton invitationGroup={info.row.original} />
          )}
          <SendReminderButton invitationGroup={info.row.original} />
        </FlexBox>
      ),
    }),
  ]

  const table = useReactTable({
    data: sentInvites || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  })

  return <CustomTable table={table} />
}

export default SentInvitesTable
