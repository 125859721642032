import {
  Button,
  Checkbox,
  CloseIcon,
  FlexBox,
  JoonUIColor,
  ModalHeader,
  SPACING,
  TextArea,
  TextButton,
  TextInput,
  Typography,
} from "@joonapp/web-shared"
import { useMutation, useQueryClient } from "@tanstack/react-query"

import UploadArea from "./UploadArea"
import useAddEditResourceSidePanelStore from "./useAddEditResourcesSidePanelStore"
import { createToast } from "../../components/toast/Toast"
import { QUERY_KEYS, useResourceTagsQuery } from "../../networking/queries"
import { addEditResource } from "../../networking/resources"
import { ToastType } from "../../types"
import SidePanel from "../patientInfo/SidePanel"

const AddEditResourceSidePanel = () => {
  const {
    isOpen,
    onClose,
    title,
    description,
    selectedTags,
    setTitle,
    setDescription,
    toggleSelectedTag,
    files,
    setFiles,
    resource,
    showExistingResource,
    setShowExistingResource,
  } = useAddEditResourceSidePanelStore()
  const { data: tags } = useResourceTagsQuery()

  const queryClient = useQueryClient()

  const isEditing = !!resource

  const useAddEditResourceMutation = useMutation({
    mutationFn: () =>
      addEditResource({
        title,
        description,
        selectedTags,
        files,
        resource,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.RESOURCES])
      onClose()
    },
  })

  const onClickAddEditResource = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()

    if (!files?.length && (!isEditing || !showExistingResource)) {
      throw createToast({
        title: "Please upload a file.",
        type: ToastType.ERROR,
      })
    }
    if (!title) {
      throw createToast({
        title: "Please enter a title.",
        type: ToastType.ERROR,
      })
    }
    if (!description) {
      throw createToast({
        title: "Please enter a description.",
        type: ToastType.ERROR,
      })
    }
    useAddEditResourceMutation.mutate()
  }

  return (
    <SidePanel isOpen={isOpen}>
      <ModalHeader
        title={isEditing ? "Edit resource" : "Add a new resource"}
        onClose={onClose}
        showBorderBottom
      />

      <FlexBox
        direction="column"
        style={{ flex: 1, padding: SPACING.space6, overflowY: "auto" }}
        align="flex-start"
        gap={SPACING.space4}
        wrap={false}
      >
        <div style={{ position: "relative", width: "100%" }}>
          {isEditing && showExistingResource && (
            <FlexBox
              align="center"
              gap={SPACING.space2}
              justify="space-between"
              wrap={false}
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                background: "white",
                borderRadius: SPACING.space2,
                padding: SPACING.space3,
                border: `1px solid ${JoonUIColor.border.default}`,
              }}
            >
              <Typography
                variant="caption"
                color={JoonUIColor.text.primary}
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {resource?.title}
              </Typography>
              <button
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "50%",
                  background: JoonUIColor.icon.neutral,
                  width: SPACING.space4,
                  height: SPACING.space4,
                  padding: SPACING.space05,
                }}
                onClick={() => setShowExistingResource(false)}
              >
                <CloseIcon color={JoonUIColor.icon.inverted} size={16} />
              </button>
            </FlexBox>
          )}
          <UploadArea files={files} setFiles={setFiles} />
        </div>

        <FlexBox direction="column" gap={SPACING.space2}>
          <Typography variant="bodyBold">Title</Typography>
          <TextInput
            name="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            type="text"
            placeholder="Enter title"
            fullWidth
          />
        </FlexBox>
        <FlexBox direction="column" gap={SPACING.space2}>
          <Typography variant="bodyBold">Description</Typography>
          <TextArea
            name="description"
            placeholder="Enter description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            fullWidth
            maxLength={255}
            showCounter
          />
        </FlexBox>
        <FlexBox direction="column" gap={SPACING.space2}>
          <Typography variant="bodyBold">Tags</Typography>
          <FlexBox gap={SPACING.space2} direction="column" wrap={false}>
            {tags
              ?.sort((a, b) => a.title.localeCompare(b.title))
              .map((tag) => (
                <Checkbox
                  key={tag.id}
                  name={tag.title}
                  selected={selectedTags.includes(tag.id)}
                  label={tag.title}
                  onChange={() => toggleSelectedTag(tag.id)}
                  hideBorder
                />
              ))}
          </FlexBox>
        </FlexBox>
      </FlexBox>
      <div style={{ position: "sticky", bottom: 0, width: "100%" }}>
        <FlexBox
          direction="row"
          align="center"
          justify="space-between"
          wrap={false}
          gap={SPACING.space2}
          style={{
            width: "100%",
            padding: SPACING.space6,
            whiteSpace: "nowrap",
          }}
        >
          <TextButton onClick={onClose}>
            <Typography
              variant="bodyBold"
              color={JoonUIColor.text.primaryAccent}
            >
              Cancel
            </Typography>
          </TextButton>
          <Button
            text="Upload"
            onClick={onClickAddEditResource}
            isLoading={useAddEditResourceMutation.isLoading}
            disabled={false}
          />
        </FlexBox>
      </div>
    </SidePanel>
  )
}

export default AddEditResourceSidePanel
