import {
  FlexBox,
  JoonUIColor,
  SPACING,
  TextButton,
  Typography,
} from "@joonapp/web-shared"
import { useMediaQuery } from "@mui/material"
import dayjs from "dayjs"
import { useParams } from "react-router-dom"

import CliniciansTable from "./ClinicianTable"
import PatientInfoSidePanel from "./PatientInfoSidePanel"
import {
  PatientInfoSidePanelView,
  usePatientInfoSidePanelStore,
} from "./usePatientInfoSidePanelStore"
import PageContentWrapper from "../../../components/layout/PageContentWrapper"
import {
  useCurrentPatientQuery,
  usePatientParentsQuery,
} from "../../../networking/queries"

const PatientDetails = () => {
  const isMobile = useMediaQuery("(max-width: 800px)")
  const { onOpen } = usePatientInfoSidePanelStore()
  const { userId } = useParams()
  const { data: parents } = usePatientParentsQuery()
  const { data: currentChild } = useCurrentPatientQuery()

  const isExamplePatient = Number(userId) === 0

  return (
    <PageContentWrapper>
      <FlexBox direction="column" gap={SPACING.space4}>
        <Typography variant="h3">Client Information</Typography>
        <DetailsSection style={{ width: "min(300px, 100%)" }}>
          <FlexBox direction="column" gap={SPACING.space2}>
            <Typography variant="caption">First name</Typography>
            <Typography variant="bodySmall">
              {isExamplePatient ? "Kevin" : currentChild?.user.name}
            </Typography>
          </FlexBox>
        </DetailsSection>
        <FlexBox direction="column" gap={SPACING.space2}>
          <FlexBox align="center" wrap={false} justify="space-between">
            <Typography variant="bodyBold">
              Parent and caregiver contact infomation
            </Typography>
          </FlexBox>
          <DetailsSection>
            <FlexBox
              direction={isMobile ? "column" : "row"}
              gap={SPACING.space4}
              wrap={false}
            >
              {isExamplePatient ? (
                <FlexBox direction="column" gap={SPACING.space0}>
                  <FlexBox
                    gap={SPACING.space2}
                    wrap={false}
                    align="center"
                    justify="unset"
                  >
                    <Typography variant="caption">Renee Liang</Typography>
                  </FlexBox>
                  <Typography
                    variant="bodySmall"
                    color={JoonUIColor.text.primaryAccent}
                  >
                    reneeliang@joonapp.io
                  </Typography>
                  <Typography variant="bodySmall">
                    Last activity: Yesterday
                  </Typography>
                </FlexBox>
              ) : (
                parents?.map((parent) => (
                  <FlexBox direction="column" gap={SPACING.space0}>
                    <FlexBox
                      gap={SPACING.space2}
                      wrap={false}
                      align="center"
                      justify="unset"
                    >
                      <Typography variant="caption">
                        {parent.user.name} {parent.user.last_name}
                      </Typography>
                    </FlexBox>
                    <Typography
                      variant="bodySmall"
                      color={JoonUIColor.text.primaryAccent}
                    >
                      {parent.user.email}
                    </Typography>
                    <Typography variant="bodySmall">
                      Last activity: {/* @ts-ignore */}
                      {parent.user.last_login_date
                        ? // @ts-ignore
                          dayjs(parent.user.last_login_date).format(
                            "MMM D, YYYY"
                          )
                        : "Never"}
                    </Typography>
                  </FlexBox>
                ))
              )}
            </FlexBox>
          </DetailsSection>
        </FlexBox>
        <FlexBox
          direction="column"
          gap={SPACING.space2}
          style={{ marginTop: SPACING.space6 }}
        >
          <FlexBox align="center" wrap={false} justify="space-between">
            <Typography variant="h3">Clinicians</Typography>
            <TextButton
              onClick={() => onOpen(PatientInfoSidePanelView.ManageClinicians)}
              style={{ padding: SPACING.space1 }}
            >
              <Typography
                variant="bodyBold"
                style={{ color: JoonUIColor.text.primaryAccent }}
              >
                Manage clinicians
              </Typography>
            </TextButton>
          </FlexBox>
          <DetailsSection>
            <CliniciansTable />
          </DetailsSection>
        </FlexBox>
      </FlexBox>
      <PatientInfoSidePanel />
    </PageContentWrapper>
  )
}

export default PatientDetails

const DetailsSection = ({
  children,
  style,
}: {
  children: React.ReactNode
  style?: React.CSSProperties
}) => {
  return (
    <FlexBox
      wrap={false}
      style={{
        border: `1px solid ${JoonUIColor.border.default}`,
        padding: SPACING.space4,
        borderRadius: SPACING.space2,
        backgroundColor: JoonUIColor.background.primaryNeutral,
        ...style,
      }}
    >
      {children}
    </FlexBox>
  )
}
