import {
  CaretDownIcon,
  FlexBox,
  GearIcon,
  JoonUIColor,
  SPACING,
  TextButton,
  Typography,
} from "@joonapp/web-shared"
import { useMediaQuery } from "@mui/material"
import dayjs from "dayjs"
import { useState } from "react"

import BehaviorStatusTag from "./BehaviorStatusTag"
import IncidentLogDataContainer from "./IncidentLogDataContainer"
import NewIncidentNotification from "./NewIncidentNotification"
import useAddEditTargetBehaviorStore from "./store/useAddEditTargetBehaviorStore"
import useIncidentLog from "./useIncidentLog"
import DataViewSegmentedControl from "../../../components/dataViewSegmentedControl/DataViewSegmentedControl"
import { DataSCView, IncidentLogDataType, TargetBehavior } from "../../../types"

const TargetBehaviorItem = ({
  targetBehavior,
}: {
  targetBehavior: TargetBehavior
}) => {
  const [showIncidentLog, setShowIncidentLog] = useState(false)
  const { onOpen: openEditBehaviorModal } = useAddEditTargetBehaviorStore()
  const [dataView, setDataView] = useState<DataSCView>(DataSCView.TABLE)
  const { incidentLogDataType } = useIncidentLog(targetBehavior.id, false)
  const isMobile = useMediaQuery("(max-width: 800px)")

  const toggleView = (view: DataSCView) => {
    setDataView(view)
    setShowIncidentLog(true)
  }

  return (
    <FlexBox
      direction="column"
      align="unset"
      style={{
        width: "100%",
        padding: SPACING.space4,
        borderRadius: SPACING.space2,
        border: `1px solid ${JoonUIColor.border.default}`,
        background: JoonUIColor.background.primaryNeutral,
      }}
    >
      <FlexBox
        direction="row"
        justify="space-between"
        wrap={false}
        align="center"
        gap={SPACING.space2}
      >
        <button
          onClick={() => setShowIncidentLog(!showIncidentLog)}
          style={{
            display: "flex",
            gap: SPACING.space2,
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            position: "relative",
          }}
        >
          <CaretDownIcon
            size={9}
            color={JoonUIColor.icon.neutral}
            style={{
              transition: "transform 0.2s ease-out",
              transform: showIncidentLog ? "rotate(90deg)" : "rotate(0deg)",
            }}
          />
          <BehaviorStatusTag status={targetBehavior.status} />
          <FlexBox
            align={isMobile ? "flex-start" : "center"}
            direction={isMobile ? "column" : "row"}
            wrap={false}
          >
            <FlexBox
              direction="column"
              style={{ overflow: "hidden" }}
              wrap={false}
            >
              <Typography
                variant="bodyBold"
                style={{
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              >
                {targetBehavior.title}
              </Typography>
              <Typography variant="bodyXSmall">
                Started on:{" "}
                {dayjs(targetBehavior.date_created).format("MMM D, YYYY")}
              </Typography>
            </FlexBox>
            <NewIncidentNotification id={targetBehavior.id} />
          </FlexBox>
        </button>
        {incidentLogDataType !== IncidentLogDataType.ANTECEDENT && (
          <DataViewSegmentedControl
            view={dataView}
            setView={toggleView}
            order={[DataSCView.TABLE, DataSCView.GRAPH]}
          />
        )}
        <TextButton onClick={() => openEditBehaviorModal(targetBehavior)}>
          <GearIcon size={16} color={JoonUIColor.icon.neutral} />
        </TextButton>
      </FlexBox>
      <IncidentLogDataContainer
        showIncidentLog={showIncidentLog}
        dataView={dataView}
        id={targetBehavior.id}
        isDataTask={false}
      />
    </FlexBox>
  )
}

export default TargetBehaviorItem
