import { Family } from "@joonapp/web-shared"
import { AxiosResponse } from "axios"

import { createJoonAPIClient } from "../util/joon-api"

export const loadFamily = async (
  familyId: number
): Promise<AxiosResponse<Family>> => {
  const API = createJoonAPIClient()
  return API.get(`api/families/${familyId}`)
}
