import {
  EditIcon,
  FlexBox,
  JoonUIColor,
  MasteryCriteriaMeasurement,
  ParentTrainingMasteryProgress,
  Shadow,
  SPACING,
  TextButton,
  TrashIcon,
  Typography,
} from "@joonapp/web-shared"
import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table"
import dayjs from "dayjs"
import { useState } from "react"

import { useDeleteObservationalDataModalStore } from "./mutations/useDeleteObservationalDataModalStore"
import useAddEditObservationalDataModalStore from "./store/useAddEditObservationalDataModalStore"
import CustomTable from "../../../../components/table/CustomTable"
import TableHeaderCell from "../../../../components/table/TableHeaderCell"
import {
  useCurrentTrainingQuery,
  useTrainingMasteryProgressQuery,
} from "../../../../networking/queries"

const columnHelper = createColumnHelper<ParentTrainingMasteryProgress>()

const ObservationalDataTable = () => {
  const { data: progress } = useTrainingMasteryProgressQuery()
  const { data: currentTraining } = useCurrentTrainingQuery()
  const { onOpen: onOpenAddEditObservationalData } =
    useAddEditObservationalDataModalStore()
  const [sorting] = useState([{ id: "date", desc: true }])
  const [hoveredRowId, setHoveredRowId] = useState<string | null>(null)
  const { onOpen: onOpenDeleteObservationalData } =
    useDeleteObservationalDataModalStore()

  const isPercentageMeasurement =
    currentTraining?.mastery_criteria_measurement ===
    MasteryCriteriaMeasurement.PERCENTAGE

  const column = [
    columnHelper.accessor("date", {
      cell: (info) => (
        <Typography variant="bodySmall">
          {dayjs(info.getValue()).format("MMM D")}
        </Typography>
      ),
      header: () => <TableHeaderCell>Date</TableHeaderCell>,
    }),
    columnHelper.accessor("frequency", {
      cell: (info) => (
        <Typography variant="bodySmall">{info.getValue()}</Typography>
      ),
      header: () => <TableHeaderCell>Count</TableHeaderCell>,
    }),
    ...(isPercentageMeasurement
      ? [
          columnHelper.accessor("base_count", {
            cell: (info) => (
              <Typography variant="bodySmall">{info.getValue()}</Typography>
            ),
            header: () => <TableHeaderCell>Attempts</TableHeaderCell>,
          }),
          columnHelper.accessor(
            (row) => {
              if (!row.base_count) return "0%"
              const correct = row.frequency
              const total = row.base_count
              if (total === 0) return "0%"
              const percentage = (correct / total) * 100
              return `${percentage.toFixed(1)}%`
            },
            {
              id: "percentageCorrect",
              cell: (info) => (
                <Typography variant="bodySmall">{info.getValue()}</Typography>
              ),
              header: () => <TableHeaderCell>%</TableHeaderCell>,
            }
          ),
        ]
      : []),
    columnHelper.accessor("observation_notes", {
      cell: (info) => (
        <Typography variant="bodySmall">{info.getValue()}</Typography>
      ),
      header: () => <TableHeaderCell>Notes</TableHeaderCell>,
    }),
    columnHelper.accessor("id", {
      id: "actions",
      cell: (info) => (
        <div
          style={{
            visibility: hoveredRowId === info.row.id ? "visible" : "hidden",
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
          }}
        >
          <TextButton
            onClick={() => onOpenAddEditObservationalData(info.row.original)}
          >
            <EditIcon size={16} color={JoonUIColor.icon.neutral} />
          </TextButton>
          <TextButton
            onClick={() => onOpenDeleteObservationalData(info.row.original.id)}
          >
            <TrashIcon size={16} color={JoonUIColor.icon.neutral} />
          </TextButton>
        </div>
      ),
      header: () => null,
    }),
  ]

  const table = useReactTable({
    data: progress || [],
    columns: column,
    initialState: { sorting },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  })

  if (!progress) return null

  if (progress && progress.length === 0) {
    return <ObservationalDataTableEmptyState />
  }

  return (
    <CustomTable
      table={table}
      hoveredRowId={hoveredRowId}
      setHoveredRowId={setHoveredRowId}
    />
  )
}

export default ObservationalDataTable

const ObservationalDataTableEmptyState = () => {
  return (
    <FlexBox
      justify="center"
      align="center"
      style={{ width: "100%", minHeight: "300px" }}
    >
      <div
        style={{
          padding: SPACING.space2,
          borderRadius: SPACING.space2,
          border: `1px solid ${JoonUIColor.border.default}`,
          boxShadow: Shadow.high,
          background: JoonUIColor.background.primaryNeutral,
        }}
      >
        <Typography variant="bodySmall">
          Add data to track mastery progress
        </Typography>
      </div>
    </FlexBox>
  )
}
