import {
  Button,
  FlexBox,
  JoonUIColor,
  Modal,
  ModalContent,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import dayjs from "dayjs"
import { create } from "zustand"

import { useUserQuery } from "../../networking/queries"
import { localStorageItems } from "../../util/storage"

const currentReleaseFlag = localStorageItems.hasSeenReleaseNotes_20240919

interface ReleaseNotesModalStore {
  onClose: () => void
}

const useReleaseNotesModalStore = create<ReleaseNotesModalStore>((set) => ({
  onClose: () => {
    localStorage.setItem(currentReleaseFlag, "true")
    // Technically don't need to change anything in the store, but this is a workaround
    // localStorage changes are not reactive, so we need to store this state to trigger a re-render
    set({})
  },
}))

const ReleaseNotesModal = () => {
  const { onClose } = useReleaseNotesModalStore()
  const { user } = useUserQuery()

  const userJoinedMoreThanADayAgo =
    user && dayjs().diff(dayjs(user?.date_joined), "day") > 1
  const hasSeenReleaseNotes = localStorage.getItem(currentReleaseFlag)
  const displayModal = userJoinedMoreThanADayAgo && !hasSeenReleaseNotes

  return (
    <Modal
      isOpen={displayModal}
      onClose={onClose}
      style={{
        maxWidth: "min(684px, 95vw)",
        maxHeight: "80vh",
      }}
    >
      <ModalContent>
        <img src={"/images/navUpdates.svg"} alt="" />
        <FlexBox
          direction="column"
          wrap={false}
          gap={SPACING.space2}
          style={{
            padding: SPACING.space6,
            marginTop: `max(-4.5vw, -${SPACING.space10})`,
            background: JoonUIColor.background.primaryNeutral,
            borderTop: `1px solid ${JoonUIColor.border.default}`,
          }}
        >
          <Typography variant="h3">Welcome back to Joon Health!</Typography>
          <Typography variant="body">
            This month we released an{" "}
            <Typography variant="bodyBold">
              update to our Navigation.{" "}
            </Typography>
            With this new update, you can now{" "}
            <Typography variant="bodyBold">
              easily toggle across all of your Clients
            </Typography>
            , making Client management a breeze while creating a new focused{" "}
            <Typography variant="bodyBold">
              space for you to browse the Module library.
            </Typography>
          </Typography>
          <Button
            onClick={onClose}
            text="Start exploring"
            style={{ marginLeft: "auto", marginTop: SPACING.space4 }}
          />
        </FlexBox>
      </ModalContent>
    </Modal>
  )
}

export default ReleaseNotesModal
