import {
  AuthCode,
  AuthCodeType,
  Button,
  FlexBox,
  JoonUIColor,
  Shadow,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import { useMediaQuery } from "@mui/material"
import { useEffect } from "react"

import ClientPageToggle, {
  ClientPageToggleOption,
  useClientPageToggleStore,
} from "./ClientPageToggle"
import GetStarted from "./getStarted/GetStarted"
import Invitations from "./invitations/Invitations"
import PatientsTable from "./PatientsTable"
import { QuickActionMobileButtonGroup } from "../../components/buttons/QuickActionMobileButton"
import PageContentWrapper from "../../components/layout/PageContentWrapper"
import { useAddPatientModalStore } from "../../components/modals/AddPatientModal"
import PageWrapper from "../../components/pageWrapper/PageWrapper"
import {
  useAuthCodeQuery,
  usePatientGroupsQuery,
} from "../../networking/queries"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"
import { requireAuth } from "../../util/auth"

const PatientsDashboard = requireAuth(() => {
  const { data: patientGroups, isLoading } = usePatientGroupsQuery()
  usePatientGroupsQuery()
  const { selectedOption: pageView } = useClientPageToggleStore()

  const { onOpen: openAddPatientModal } = useAddPatientModalStore()
  const isMobile = useMediaQuery("(max-width:800px)")
  const { data: authCode } = useAuthCodeQuery(AuthCodeType.ADMIT_PATIENT)

  // Analytic event so we know how many families a therapist has linked
  useEffect(() => {
    if (!patientGroups) return
    trackAnalyticEvent(ANALYTIC_EVENTS.load_linked_patients, {
      count: patientGroups?.length,
    })
    // eslint-disable-next-line
  }, [patientGroups])

  return (
    <PageWrapper>
      <PageContentWrapper>
        <FlexBox
          direction="row"
          justify="space-between"
          align="center"
          gap={SPACING.space4}
          wrap={false}
        >
          <ClientPageToggle />
          {!isMobile && (
            <FlexBox
              align="center"
              wrap={false}
              justify="flex-end"
              style={{ width: "fit-content" }}
            >
              <FlexBox
                align="center"
                wrap={false}
                style={{
                  marginRight: SPACING.space6,
                  border: `1px solid ${JoonUIColor.border.default}`,
                  width: "fit-content",
                  padding: `${SPACING.space2} ${SPACING.space4}`,
                  borderRadius: SPACING.space8,
                  boxShadow: Shadow.sunken,
                  background: JoonUIColor.background.lightGray,
                }}
              >
                <Typography variant="caption" style={{ whiteSpace: "nowrap" }}>
                  Client Invite Code:
                </Typography>
                <AuthCode code={authCode?.code || ""} />
              </FlexBox>
              <Button
                text="Invite clients"
                style={{ whiteSpace: "nowrap" }}
                onClick={openAddPatientModal}
              />
            </FlexBox>
          )}
        </FlexBox>
        <GetStarted />

        {pageView === ClientPageToggleOption.CLIENTS ? (
          <FlexBox direction="column" gap={SPACING.space6}>
            <div>
              <Typography variant="h2" style={{ marginBottom: SPACING.space2 }}>
                {isLoading
                  ? "Loading clients..."
                  : patientGroups && patientGroups?.length > 0
                  ? ` ${patientGroups.length} active Joon clients`
                  : "You don't have any active Joon clients yet!"}
              </Typography>
            </div>
            <PatientsTable />
          </FlexBox>
        ) : (
          <Invitations />
        )}
        <QuickActionMobileButtonGroup
          buttons={[
            {
              text: "Invite clients",
              onClick: openAddPatientModal,
            },
          ]}
        />
      </PageContentWrapper>
    </PageWrapper>
  )
})

export default PatientsDashboard
