import {
  ArchiveIcon,
  CheckIcon,
  FlexBox,
  JoonUIColor,
  Modal,
  ModalHeader,
  ModuleType,
  MultipleChoiceLearningModuleAssignmentCompletion,
  ShortAnswerLearningModuleAssignmentCompletion,
  SPACING,
  toPercentage,
  Typography,
} from "@joonapp/web-shared"
import dayjs from "dayjs"
import { useEffect } from "react"
import ReactPlayer from "react-player"

import ArchiveModuleModal from "./ArchiveModuleModal"
import QuizModule from "./QuizModule"
import ReadingModulePreview from "./ReadingModule"
import ShortAnswerModulePreview from "./ShortAnswerModule"
import { useArchiveModuleModalStore } from "./store/useArchiveModuleModalStore"
import useModuleDetailsModalStore from "./store/useModuleDetailsModalStore"
import { ActionButton } from "../../../../components/buttons/ActionButton"
import {
  useCurrentTrainingQuery,
  useModulesToReviewQuery,
} from "../../../../networking/queries"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../../util/analytics"
import PDFViewer from "../../../resources/PDFViewer"
import useReviewModuleCompletionMutation from "../mutations/useReviewModuleCompletionMutation"

const ModuleAssignmentDetailsModal = () => {
  const { isOpen, onClose, selectedModuleAssignmentId } =
    useModuleDetailsModalStore()
  const { data: currentTraining } = useCurrentTrainingQuery()
  const { onOpen: openArchiveModuleModal } = useArchiveModuleModalStore()
  const selectedModule = currentTraining?.assignments.find(
    (module) => module.id === selectedModuleAssignmentId
  )
  const assignmentCompletionId = selectedModule?.assignment_completion?.id
  const { data: hasToReviewModule } = useModulesToReviewQuery(
    assignmentCompletionId ?? undefined
  )

  const { mutate: reviewModule } = useReviewModuleCompletionMutation()

  useEffect(() => {
    if (isOpen && hasToReviewModule && assignmentCompletionId) {
      reviewModule(assignmentCompletionId)
    }
  }, [isOpen])

  const multiChoiceAnswers = (
    selectedModule?.assignment_completion as MultipleChoiceLearningModuleAssignmentCompletion
  )?.multiple_choice_answers
  const multiChoiceCorrect =
    multiChoiceAnswers?.filter((answer) => answer.is_correct) || []

  const ModulePreview = () => {
    switch (selectedModule?.learning_module.type) {
      case ModuleType.MULTIPLE_CHOICE:
        return (
          <QuizModule
            learningModule={selectedModule.learning_module}
            completion={
              selectedModule.assignment_completion as MultipleChoiceLearningModuleAssignmentCompletion
            }
          />
        )
      case ModuleType.PDF:
        return <PDFViewer pdfUrl={selectedModule?.learning_module?.pdf_url} />
      case ModuleType.READING_TEXTS:
        return (
          <ReadingModulePreview
            learningModule={selectedModule?.learning_module}
          />
        )
      case ModuleType.VIDEO:
        return (
          <ReactPlayer
            url={selectedModule?.learning_module?.video_url}
            width={"100%"}
          />
        )
      case ModuleType.SHORT_ANSWERS:
        return (
          <ShortAnswerModulePreview
            learningModule={selectedModule?.learning_module}
            completion={
              selectedModule?.assignment_completion as ShortAnswerLearningModuleAssignmentCompletion
            }
          />
        )
      default:
        return <></>
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      style={{
        borderRight: `1px solid ${JoonUIColor.border.default}`,
        width: "min(600px, 95vw)",
      }}
    >
      <ModalHeader
        title="Review interactive module"
        onClose={onClose}
        showBorderBottom
      />
      <FlexBox
        direction="column"
        wrap={false}
        gap={SPACING.space2}
        style={{
          padding: SPACING.space6,
          background: JoonUIColor.background.primaryNeutral,
        }}
      >
        <Typography variant="h3" textAlign="left">
          {selectedModule?.learning_module?.title}
        </Typography>

        {selectedModule?.assignment_completion?.date_completed && (
          <div>
            <Typography variant="caption" textAlign="left">
              Result:
            </Typography>
            <FlexBox align="center" wrap={false} gap={SPACING.space2}>
              <CheckIcon size={20} color={JoonUIColor.semantic.success} />
              <Typography variant="bodyBold" textAlign="left">
                Completed on{" "}
                {dayjs(
                  selectedModule?.assignment_completion?.date_completed
                ).format("MMM DD, YYYY")}
              </Typography>
            </FlexBox>
            {multiChoiceAnswers && (
              <Typography variant="bodyBold" textAlign="left">
                {toPercentage(
                  multiChoiceCorrect?.length / multiChoiceAnswers.length
                )}
                % ({multiChoiceCorrect?.length} out of{" "}
                {multiChoiceAnswers.length})
              </Typography>
            )}
          </div>
        )}
        <ActionButton
          text="Archive"
          icon={<ArchiveIcon size={16} />}
          onClick={() => {
            if (!selectedModuleAssignmentId) return
            openArchiveModuleModal(selectedModuleAssignmentId)
            trackAnalyticEvent(ANALYTIC_EVENTS.open_archive_module_assignment)
          }}
        />
        <ArchiveModuleModal />
      </FlexBox>
      <div
        style={{
          width: "100%",
          padding: SPACING.space6,
          background: JoonUIColor.background.lightGray,
          boxShadow: "2px 2px 6px 0px rgba(209, 220, 236, 0.60) inset",
          flex: 1,
          display: "flex",
          gap: SPACING.space4,
          flexDirection: "column",
          position: "relative",
          alignItems: "center",
          height: "100%",
        }}
      >
        <div style={{ width: "min(500px, 100%)" }}>
          <ModulePreview />
        </div>
      </div>
    </Modal>
  )
}

export default ModuleAssignmentDetailsModal
