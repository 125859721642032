import { QuestSeries } from "@joonapp/web-shared"
import { RRule } from "rrule"
import { create } from "zustand"

import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"

interface AddEditParentTaskStore {
  isOpen: boolean
  onOpen: (taskSeries?: QuestSeries) => void
  onClose: () => void
  taskToEdit: QuestSeries | null

  // Add/Edit Parent Task
  title: string
  setTitle: (title: string) => void
  description: string
  setDescription: (description: string) => void
  selectedParentId: number | null
  setSelectedParentId: (selectedParentId: number) => void

  frequency: FrequencyOption
  setFrequency: (frequency: FrequencyOption) => void

  setEditingQuestDetails: (quest: QuestSeries) => void
  clearQuestDetails: () => void
}

export enum FrequencyOption {
  Recurring = "Recurring",
  OneTime = "One time only",
}

export const useAddEditParentTaskStore = create<AddEditParentTaskStore>(
  (set, get) => ({
    isOpen: false,
    onOpen: (taskSeries) => {
      const isEditing = !!taskSeries
      set({ isOpen: true })
      if (isEditing) {
        get().setEditingQuestDetails(taskSeries)
        trackAnalyticEvent(ANALYTIC_EVENTS.open_edit_parent_task)
      } else {
        trackAnalyticEvent(ANALYTIC_EVENTS.open_add_parent_task)
      }
    },
    onClose: () => {
      set({ isOpen: false })
      get().clearQuestDetails()
    },
    taskToEdit: null,

    title: "",
    setTitle: (title) => set({ title }),
    description: "",
    setDescription: (description) => set({ description }),
    selectedParentId: null,
    setSelectedParentId: (selectedParentId) => set({ selectedParentId }),
    frequency: FrequencyOption.Recurring,
    setFrequency: (frequency) => set({ frequency }),

    setEditingQuestDetails: (quest) => {
      const frequency = RRule.fromString(quest.recurrence).options.interval
        ? FrequencyOption.Recurring
        : FrequencyOption.OneTime
      set({
        title: quest.title,
        description: quest.description || "",
        selectedParentId: quest.user_id,
        frequency: frequency,
        taskToEdit: quest,
      })
    },
    clearQuestDetails: () => {
      set({
        title: "",
        description: "",
        selectedParentId: null,
        frequency: FrequencyOption.Recurring,
        taskToEdit: null,
      })
    },
  })
)
