import {
  JoonColorExpanded,
  JoonUIColor,
  LearningModule,
  SPACING,
  TextButton,
  Typography,
} from "@joonapp/web-shared"
import { useState } from "react"

import { ModuleIcon } from "./resourceIcons"
import useAddModuleStore from "./useAddModuleStore"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"

type Props = {
  learningModule: LearningModule
}

const ModuleSearchResultItem = ({ learningModule }: Props) => {
  const [isHovered, setHovered] = useState(false)
  const {
    selectedModuleId,
    setSelectedModuleId,
    modulesIdsToAdd,
    toggleModuleIdToAdd,
  } = useAddModuleStore()

  const isSelected = selectedModuleId === learningModule.id

  const onClick = () => {
    setSelectedModuleId(learningModule.id)
  }

  return (
    <button
      style={{
        display: "flex",
        width: "100%",
        alignItems: "center",
        borderRadius: SPACING.space2,
        padding: SPACING.space2,
        gap: SPACING.space2,
        background: isSelected ? JoonUIColor.background.lightBlue : undefined,
        outline: isHovered
          ? `2px solid ${JoonColorExpanded.indigo300}`
          : undefined,
        zIndex: isHovered ? 1 : 0,
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={onClick}
    >
      <ModuleIcon type={learningModule.type} size="small" />
      <Typography variant="caption" textAlign="left">
        {learningModule.title}
      </Typography>
      <TextButton
        // @ts-ignore
        onClick={(e) => {
          e.stopPropagation()
          toggleModuleIdToAdd(learningModule.id)
          if (modulesIdsToAdd.includes(learningModule.id)) {
            trackAnalyticEvent(ANALYTIC_EVENTS.remove_module_from_list)
          } else {
            trackAnalyticEvent(ANALYTIC_EVENTS.add_module_to_list)
          }
        }}
        style={{ padding: SPACING.space1 }}
      >
        <Typography
          variant="caption"
          textAlign="left"
          color={JoonUIColor.text.primaryAccent}
        >
          {modulesIdsToAdd.includes(learningModule.id) ? "Remove" : "Select"}
        </Typography>
      </TextButton>
    </button>
  )
}

export default ModuleSearchResultItem
