import { LearningModule, ModuleType } from "@joonapp/web-shared"
import ReactPlayer from "react-player"

import PDFViewer from "./PDFViewer"
import QuizModule from "../patientInfo/parentTraining/modules/QuizModule"
import ReadingModulePreview from "../patientInfo/parentTraining/modules/ReadingModule"
import ShortAnswerModulePreview from "../patientInfo/parentTraining/modules/ShortAnswerModule"

export const ModulePreview = ({
  learningModule,
}: {
  learningModule: LearningModule | undefined
}) => {
  switch (learningModule?.type) {
    case ModuleType.MULTIPLE_CHOICE:
      return <QuizModule learningModule={learningModule} />
    case ModuleType.PDF:
      return <PDFViewer pdfUrl={learningModule?.pdf_url} />
    case ModuleType.SHORT_ANSWERS:
      return <ShortAnswerModulePreview learningModule={learningModule} />
    case ModuleType.READING_TEXTS:
      return <ReadingModulePreview learningModule={learningModule} />
    case ModuleType.VIDEO:
      return <ReactPlayer url={learningModule?.video_url} width={"100%"} />
    default:
      return <></>
  }
}
