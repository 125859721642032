import { UserRole } from "@joonapp/web-shared"

import { usePracticeQuery, useUserQuery } from "../../networking/queries"
import { getCurrentUserProfileInfo } from "../../util/util"

const useAdminUser = () => {
  const { practice } = usePracticeQuery()
  const { user } = useUserQuery()

  if (!practice || !user) return false
  return getCurrentUserProfileInfo(practice, user)?.role === UserRole.ADMIN
}

export default useAdminUser
