import { DropdownOption } from "@joonapp/web-shared"
import { create } from "zustand"

import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../../util/analytics"
import { getMostRecentTarget } from "../../../../util/util"
import { QuestSeriesWithTargets } from "../overTimeProgress/data"

interface AddEditQuestModalStore {
  // Modal view
  isOpenAddQuest: boolean
  isOpenEditQuest: boolean
  onOpen: (selectedQuest?: QuestSeriesWithTargets) => void
  onClose: () => void

  questName: string
  setQuestName: (name: string) => void

  frequency: DropdownOption
  setFrequency: (frequency: DropdownOption) => void

  reward: number | null
  setReward: (reward: number) => void

  selectedQuestId: number | null
}

const initialState = {
  isOpenAddQuest: false,
  isOpenEditQuest: false,
  questName: "",
  frequency: { label: "1x", value: 1 },
  reward: null,
  selectedQuestId: null,
}

export const useAddEditQuestModalStore = create<AddEditQuestModalStore>(
  (set) => ({
    ...initialState,

    onOpen: (selectedQuest) => {
      if (selectedQuest) {
        set({
          isOpenEditQuest: true,
          selectedQuestId: selectedQuest?.id,
          // values used to populate editing
          questName: selectedQuest?.title,
          reward: selectedQuest?.redeemable_reward,
        })
        const mostRecentTarget = getMostRecentTarget(
          selectedQuest.frequency_targets
        )
        if (!!mostRecentTarget) {
          set({
            frequency: {
              label: `${mostRecentTarget.frequency}x`,
              value: mostRecentTarget.frequency,
            },
          })
        }
        trackAnalyticEvent(ANALYTIC_EVENTS.open_add_target)
      } else {
        set({ isOpenAddQuest: true })
        trackAnalyticEvent(ANALYTIC_EVENTS.open_add_behavior)
      }
    },
    onClose: () => set(initialState),

    setQuestName: (name) => set({ questName: name }),
    setFrequency: (frequency) => set({ frequency }),
    setReward: (reward) => set({ reward }),
  })
)
