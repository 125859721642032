import { create } from "zustand"

import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../../util/analytics"

interface DeleteDataTaskModalStore {
  isOpen: boolean
  onOpen: (dataTaskId: number) => void
  onClose: () => void
  dataTaskId: number
}

export const useDeleteDataTaskModalStore = create<DeleteDataTaskModalStore>(
  (set) => ({
    isOpen: false,
    onOpen: (dataTaskId: number) => {
      trackAnalyticEvent(ANALYTIC_EVENTS.open_delete_data_task)
      set({ isOpen: true, dataTaskId })
    },
    onClose: () => set({ isOpen: false }),
    dataTaskId: 0,
  })
)
