import {
  FlexBox,
  Modal,
  ModalHeader,
  SPACING,
  TextArea,
  Typography,
  Checkbox,
  JoonUIColor,
  TextButton,
  Button,
  MasteryCriteriaMeasurement,
} from "@joonapp/web-shared"
import { useParams } from "react-router-dom"

import useAddEditMasteryCriteriaModalStore from "./store/useAddEditMasteryCriteriaModalStore"
import { useCurrentTrainingQuery } from "../../../../networking/queries"
import useEditTrainingMutation from "../mutations/useEditTrainingMutation"

const AddEditMasteryCriteriaModal = () => {
  const {
    isOpen,
    onClose,
    measurementType,
    setMeasurementType,
    masteryCriteria,
    setMasteryCriteria,
  } = useAddEditMasteryCriteriaModalStore()
  const { data: currentTraining } = useCurrentTrainingQuery()

  const editTrainingMutation = useEditTrainingMutation()

  const { userId } = useParams()
  const isExampleClient = Number(userId) === 0

  const onClickSave = async () => {
    if (!currentTraining) return

    editTrainingMutation.mutate({
      id: currentTraining?.id,
      mastery_criteria: masteryCriteria,
      mastery_criteria_measurement: hasExistingMasteryCriteriaMeasurement
        ? undefined
        : measurementType,
    })
    onClose()
  }

  if (!currentTraining) return null

  const hasExistingMasteryCriteriaMeasurement =
    !!currentTraining.mastery_criteria_measurement

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      style={{ width: "min(700px, 95vw)" }}
    >
      <ModalHeader
        title={`${
          hasExistingMasteryCriteriaMeasurement ? "Edit" : "Add"
        } Mastery Criteria`}
        onClose={onClose}
        showBorderBottom
      />
      <FlexBox
        direction="column"
        style={{ padding: SPACING.space6, paddingTop: SPACING.space4 }}
        gap={SPACING.space2}
      >
        <FlexBox direction="column" gap={SPACING.space1}>
          <Typography variant="bodyBold">Mastery Criteria</Typography>
          <TextArea
            name="Mastery Criteria"
            placeholder="ie. Parent demonstrates correct implementation of token economy in 90% of opportunities across 3 consecutive sessions"
            value={masteryCriteria}
            onChange={(e) => setMasteryCriteria(e.target.value)}
            fullWidth
          />
        </FlexBox>
        {!hasExistingMasteryCriteriaMeasurement && (
          <FlexBox direction="column" gap={SPACING.space1}>
            <div>
              <Typography variant="bodyBold" textAlign="left">
                Measured by
              </Typography>
              <Typography variant="bodySmall">
                How would you like to track progress toward this goal?
              </Typography>
            </div>
            <FlexBox direction="row" wrap={false} gap={SPACING.space2}>
              <Checkbox
                style={{
                  padding: SPACING.space2,
                  borderRadius: SPACING.space2,
                }}
                label={
                  <FlexBox
                    direction="column"
                    style={{ marginLeft: SPACING.space1 }}
                  >
                    <Typography variant="caption">
                      Percentage of accuracy
                    </Typography>
                    <Typography variant="bodySmall">
                      Plot 0-100% to demonstrate mastery
                    </Typography>
                  </FlexBox>
                }
                selected={
                  measurementType === MasteryCriteriaMeasurement.PERCENTAGE
                }
                fullWidth
                name="percentage"
                onChange={() =>
                  setMeasurementType(MasteryCriteriaMeasurement.PERCENTAGE)
                }
                inputType="radio"
              />
              <Checkbox
                style={{
                  padding: SPACING.space2,
                  borderRadius: SPACING.space2,
                }}
                fullWidth
                label={
                  <FlexBox
                    direction="column"
                    style={{ marginLeft: SPACING.space1 }}
                  >
                    <Typography variant="caption">Frequency</Typography>
                    <Typography variant="bodySmall">
                      Plot the number of times the behavior occurs
                    </Typography>
                  </FlexBox>
                }
                selected={
                  measurementType === MasteryCriteriaMeasurement.FREQUENCY
                }
                name="frequency"
                onChange={() =>
                  setMeasurementType(MasteryCriteriaMeasurement.FREQUENCY)
                }
                inputType="radio"
              />
            </FlexBox>
          </FlexBox>
        )}
        <FlexBox
          direction="row"
          gap={SPACING.space2}
          align="center"
          justify="flex-end"
          wrap={false}
          style={{ marginTop: SPACING.space6 }}
        >
          <TextButton onClick={onClose}>
            <Typography
              variant="bodyBold"
              color={JoonUIColor.text.primaryAccent}
            >
              Cancel
            </Typography>
          </TextButton>
          <Button
            text="Save mastery criteria"
            onClick={onClickSave}
            isLoading={editTrainingMutation.isLoading}
            disabled={isExampleClient}
          />
        </FlexBox>
      </FlexBox>
    </Modal>
  )
}

export default AddEditMasteryCriteriaModal
