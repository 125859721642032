import {
  Button,
  JoonUIColor,
  Modal,
  ModalHeader,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import { useParams } from "react-router-dom"

import { useArchiveModuleModalStore } from "./store/useArchiveModuleModalStore"
import useModuleDetailsModalStore from "./store/useModuleDetailsModalStore"
import { useCurrentTrainingQuery } from "../../../../networking/queries"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../../util/analytics"
import useEditTrainingMutation from "../mutations/useEditTrainingMutation"

const ArchiveModuleModal = () => {
  const { isOpen, onClose: closeArchiveModuleModal } =
    useArchiveModuleModalStore()
  const { onClose: closeModuleDetailsModal } = useModuleDetailsModalStore()
  const { userId } = useParams()
  const { selectedModuleAssignmentId } = useArchiveModuleModalStore()

  const isExamplePatient = Number(userId) === 0
  const editTrainingMutation = useEditTrainingMutation()
  const { data: currentTraining } = useCurrentTrainingQuery()
  const { trainingId } = useParams()

  const onClickArchiveTask = async () => {
    if (!selectedModuleAssignmentId) return

    const currentLearningModules = currentTraining?.assignments
    const newModuleIds = currentLearningModules
      ?.filter((assignment) => assignment.id !== selectedModuleAssignmentId)
      .map((assignment) => assignment.learning_module.id)

    await editTrainingMutation.mutateAsync({
      module_ids: newModuleIds,
      id: Number(trainingId),
    })
    closeArchiveModuleModal()
    closeModuleDetailsModal()
    trackAnalyticEvent(ANALYTIC_EVENTS.archive_module_assignment)
  }

  return (
    <Modal isOpen={isOpen} onClose={closeArchiveModuleModal}>
      <ModalHeader
        title="Confirm archival?"
        onClose={closeArchiveModuleModal}
        style={{ borderBottom: `1px solid ${JoonUIColor.border.default}` }}
      />
      <div style={{ padding: SPACING.space6, width: "min(500px, 95vw)" }}>
        <Typography variant="body">
          Once archived, this module will no longer show up on the parent
          experience nor on this page.
        </Typography>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: SPACING.space6,
          }}
        >
          <button
            style={{ marginRight: SPACING.space2 }}
            onClick={closeArchiveModuleModal}
          >
            <Typography
              variant="bodyBold"
              color={JoonUIColor.text.primaryAccent}
            >
              Cancel
            </Typography>
          </button>
          <Button
            text="Archive"
            isLoading={editTrainingMutation.isLoading}
            onClick={onClickArchiveTask}
            disabled={isExamplePatient}
          />
        </div>
      </div>
    </Modal>
  )
}

export default ArchiveModuleModal
