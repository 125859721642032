import {
  Button,
  FlexBox,
  JoonUIColor,
  ModalHeader,
  Modal,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import { useMutation } from "@tanstack/react-query"
import { create } from "zustand"

import { createToast, Toasts } from "../../components/toast/Toast"
import { QUERY_KEYS, usePracticeQuery } from "../../networking/queries"
import { removeMember } from "../../networking/user"
import { queryClient } from "../../queryClient"
import { ToastType } from "../../types"

interface DeleteClinicianModalStore {
  isOpen: boolean
  onClose: () => void
  onOpen: (selectedClinicianId: number) => void
  selectedClinicianId: number | null
}

export const useDeleteClinicianModalStore = create<DeleteClinicianModalStore>(
  (set) => ({
    isOpen: false,
    onClose: () => set({ isOpen: false, selectedClinicianId: null }),
    onOpen: (selectedClinicianId: number) =>
      set({ isOpen: true, selectedClinicianId }),
    selectedClinicianId: null,
  })
)

const DeleteClinicianModal = () => {
  const { isOpen, onClose, selectedClinicianId } =
    useDeleteClinicianModalStore()
  const { practice } = usePracticeQuery()

  const removeMemberMutation = useMutation({
    mutationFn: removeMember,
    onSuccess: () => {
      onClose()
      queryClient.invalidateQueries([QUERY_KEYS.PRACTICE])
      queryClient.invalidateQueries([QUERY_KEYS.PATIENT_GROUPS, null])
      queryClient.invalidateQueries([QUERY_KEYS.PATIENT_GROUPS, true])
      createToast({
        title: "Member removed",
        type: ToastType.SUCCESS,
      })
    },
    onError: () => createToast(Toasts.GENERAL_ERROR),
  })

  const onClickRemoveClinician = () => {
    if (!practice || !selectedClinicianId) return
    removeMemberMutation.mutate({
      practiceId: practice.id,
      userId: selectedClinicianId,
    })
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalHeader title="Remove clinic member" onClose={onClose} />
      <div
        style={{
          width: "min(100vw, 400px)",
          padding: SPACING.space6,
          paddingTop: "0",
        }}
      >
        <Typography variant="body">
          Are you sure you want to remove this member from the clinic group?
        </Typography>

        <FlexBox
          justify="flex-end"
          align="center"
          gap={SPACING.space2}
          style={{ marginTop: SPACING.space2 }}
        >
          <button onClick={onClose}>
            <Typography
              variant="bodyBold"
              color={JoonUIColor.text.primaryAccent}
            >
              Cancel
            </Typography>
          </button>
          <Button
            onClick={onClickRemoveClinician}
            buttonType="redPrimary"
            isLoading={removeMemberMutation.isLoading}
            text="Remove"
          />
        </FlexBox>
      </div>
    </Modal>
  )
}

export default DeleteClinicianModal
