import {
  DropdownOption,
  DropdownSelect,
  FlexBox,
  SPACING,
  TextInput,
} from "@joonapp/web-shared"
import { useEffect } from "react"
import { create } from "zustand"

import { SummaryTableRow } from "./data"
import { useOverTimeProgressStore } from "./useOverTimeProgressStore"
import {
  categoryDropdownOptions,
  creatorDropdownOptions,
  timeOfDayDropdownOptions,
} from "../../../../constants"
import { useOverTimeProgressQuery } from "../../../../networking/queries"

interface OverTimeProgressFilterStore {
  selectedCreators: DropdownOption[]
  setSelectedCreators: (creators: DropdownOption[]) => void
  selectedTimeOfDay: DropdownOption[]
  setSelectedTimeOfDay: (timeOfDay: DropdownOption[]) => void
  selectedCategories: DropdownOption[]
  setSelectedCategories: (categories: DropdownOption[]) => void
  searchValue: string
  setSearchValue: (searchValue: string) => void

  filteredSummaryData: SummaryTableRow[]
  setFilteredSummaryData: (filteredSummaryData: SummaryTableRow[]) => void
}

export const useOverTimeProgressFilterStore =
  create<OverTimeProgressFilterStore>((set) => ({
    selectedCreators: creatorDropdownOptions,
    setSelectedCreators: (creators) => set({ selectedCreators: creators }),
    selectedTimeOfDay: timeOfDayDropdownOptions,
    setSelectedTimeOfDay: (timeOfDay) => set({ selectedTimeOfDay: timeOfDay }),
    searchValue: "",
    setSearchValue: (searchValue) => set({ searchValue }),
    selectedCategories: categoryDropdownOptions,
    setSelectedCategories: (categories) =>
      set({ selectedCategories: categories }),

    filteredSummaryData: [],
    setFilteredSummaryData: (filteredSummaryData: any) =>
      set({ filteredSummaryData }),
  }))

export const useOverTimeProgressFilters = () => {
  const {
    selectedCreators,
    selectedTimeOfDay,
    selectedCategories,
    searchValue,
    setFilteredSummaryData,
  } = useOverTimeProgressFilterStore()
  const { selectedQuests, toggleSelectedQuest } = useOverTimeProgressStore()
  const { data } = useOverTimeProgressQuery()

  useEffect(() => {
    let filteredQuests = [...(data?.summary || [])] as SummaryTableRow[]

    filteredQuests = filteredQuests.filter((quest) =>
      selectedCreators.some(
        (creator) =>
          creator.value === quest.creator ||
          (creator.value === "none" && !quest.creator)
      )
    )

    filteredQuests = filteredQuests.filter((quest) =>
      selectedTimeOfDay.some((time) => time.value === quest.routine)
    )

    filteredQuests = filteredQuests.filter((quest) =>
      selectedCategories.some(
        (category) =>
          category.value === quest.skill ||
          (category.value === "none" && !quest.skill)
      )
    )

    filteredQuests = filteredQuests.filter((quest) =>
      quest.quest.toLowerCase().includes(searchValue.toLowerCase())
    )

    filteredQuests = filteredQuests.sort((a) => {
      if (a.hasTarget) return -1
      return 1
    })

    // If there are selected quests that are not in new filters
    // then unselecte these so they don't show on graph
    selectedQuests.forEach(({ id: questId, name }) => {
      if (!filteredQuests.some((quest) => quest.id === questId)) {
        toggleSelectedQuest({ id: questId, name: name })
      }
    })
    setFilteredSummaryData(filteredQuests)
  }, [
    selectedCreators,
    selectedTimeOfDay,
    selectedCategories,
    searchValue,
    selectedQuests,
    toggleSelectedQuest,
    setFilteredSummaryData,
    data?.summary,
  ])
}

const OverTimeProgressFilters = () => {
  const {
    selectedCreators,
    setSelectedCreators,
    selectedTimeOfDay,
    setSelectedTimeOfDay,
    selectedCategories,
    setSelectedCategories,
    searchValue,
    setSearchValue,
  } = useOverTimeProgressFilterStore()

  return (
    <FlexBox
      align="center"
      justify="space-between"
      wrap={false}
      gap={SPACING.space2}
      style={{ width: "100%", paddingTop: SPACING.space4 }}
    >
      <FlexBox align="center" wrap={false} gap={SPACING.space2}>
        <DropdownSelect
          label="Creator"
          options={creatorDropdownOptions}
          labelPlacement="inside"
          type="multi"
          size="small"
          style={{ width: "200px" }}
          selectedOptions={selectedCreators}
          setSelectedOptions={setSelectedCreators}
        />
        <DropdownSelect
          label="Time of day"
          options={timeOfDayDropdownOptions}
          labelPlacement="inside"
          type="multi"
          size="small"
          style={{ width: "200px" }}
          selectedOptions={selectedTimeOfDay}
          setSelectedOptions={setSelectedTimeOfDay}
        />
        <DropdownSelect
          label="Category"
          options={categoryDropdownOptions}
          labelPlacement="inside"
          type="multi"
          size="small"
          style={{ width: "200px" }}
          selectedOptions={selectedCategories}
          setSelectedOptions={setSelectedCategories}
        />
      </FlexBox>
      <div style={{ width: "fit-content" }}>
        <TextInput
          placeholder="Search"
          style={{ width: "200px", height: "38px", fontSize: "16px" }}
          name="search"
          onChange={(e) => setSearchValue(e.target.value)}
          value={searchValue}
          icon="search"
        />
      </div>
    </FlexBox>
  )
}

export default OverTimeProgressFilters
