import {
  FlexBox,
  IconProps,
  JoonUIColor,
  SPACING,
  TextButton,
} from "@joonapp/web-shared"
import { useMediaQuery } from "@mui/material"
import { useNavigate } from "react-router-dom"

import useMobileSidebarStore from "./useMobileSidebarStore"
import { UserInitials } from "./UserInitials"

const MobileNavbar = () => {
  const isMobile = useMediaQuery("(max-width: 800px)")
  const { onOpen } = useMobileSidebarStore()
  const navigate = useNavigate()

  return (
    <FlexBox
      direction="row"
      justify="space-between"
      align="center"
      wrap={false}
      style={{
        padding: SPACING.space2,
        paddingLeft: isMobile ? SPACING.space4 : SPACING.space6,
        paddingRight: isMobile ? SPACING.space4 : SPACING.space6,
        backgroundColor: JoonUIColor.background.primaryNeutral,
        borderBottom: `1px solid ${JoonUIColor.border.default}`,
        zIndex: 10,
      }}
    >
      <TextButton onClick={onOpen}>
        <MenuIcon color={JoonUIColor.text.primary} size={20} />
      </TextButton>
      <button style={{ padding: 0 }} onClick={() => navigate("/patients")}>
        <img
          src={"/images/logos/JoonHealth.svg"}
          alt="Joon Logo"
          style={{ height: SPACING.space6 }}
        />
      </button>
      <button style={{ padding: 0 }} onClick={() => navigate("/settings")}>
        <UserInitials />
      </button>
    </FlexBox>
  )
}

export default MobileNavbar

const MenuIcon = ({ color, size, ...props }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clip-path="url(#clip0_5793_14115)">
        <path
          d="M0 3.37793C0 2.8248 0.510714 2.37793 1.14286 2.37793H14.8571C15.4893 2.37793 16 2.8248 16 3.37793C16 3.93105 15.4893 4.37793 14.8571 4.37793H1.14286C0.510714 4.37793 0 3.93105 0 3.37793ZM0 8.37793C0 7.8248 0.510714 7.37793 1.14286 7.37793H14.8571C15.4893 7.37793 16 7.8248 16 8.37793C16 8.93105 15.4893 9.37793 14.8571 9.37793H1.14286C0.510714 9.37793 0 8.93105 0 8.37793ZM16 13.3779C16 13.9311 15.4893 14.3779 14.8571 14.3779H1.14286C0.510714 14.3779 0 13.9311 0 13.3779C0 12.8248 0.510714 12.3779 1.14286 12.3779H14.8571C15.4893 12.3779 16 12.8248 16 13.3779Z"
          fill="#7B838E"
        />
      </g>
      <defs>
        <clipPath id="clip0_5793_14115">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0 0.37793)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
