import { FlexBox, JoonUIColor, SPACING } from "@joonapp/web-shared"
import { useMediaQuery } from "@mui/material"
import { PropsWithChildren } from "react"

const PageHeader2 = ({ children }: PropsWithChildren) => {
  const isMobile = useMediaQuery("(max-width:800px)")

  return (
    <FlexBox
      align="center"
      wrap={false}
      justify="space-between"
      style={{
        padding: isMobile
          ? `${SPACING.space4} ${SPACING.space4} ${SPACING.space0} ${SPACING.space4}`
          : `${SPACING.space4} ${SPACING.space6} ${SPACING.space0} ${SPACING.space6}`,
        boxShadow: `0px 4px 4px rgba(0, 0, 0, 0.05)`,
        background: JoonUIColor.background.primaryNeutral,
        zIndex: 1,
      }}
      gap={SPACING.space4}
      fullWidth
    >
      {children}
    </FlexBox>
  )
}

export default PageHeader2
