import { create } from "zustand"

export enum HomeRoutineView {
  WEEKLY_PROGRESS = "weekly-progress",
  OVER_TIME = "progress-over-time",
}

export interface HomeRoutineViewStore {
  view: HomeRoutineView
  setView: (view: HomeRoutineView) => void
}

export const useHomeRoutineViewStore = create<HomeRoutineViewStore>((set) => ({
  view: HomeRoutineView.WEEKLY_PROGRESS,
  setView: (view) => set({ view }),
}))
