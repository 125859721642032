import create from "zustand"

interface DeleteTrainingStore {
  isOpen: boolean
  onOpen: (trainingId: number) => void
  onClose: () => void
  trainingId: number | null
}

const useDeleteTrainingStore = create<DeleteTrainingStore>((set) => ({
  isOpen: false,
  onOpen: (trainingId) => set({ isOpen: true, trainingId }),
  onClose: () => set({ isOpen: false, trainingId: null }),
  trainingId: null,
}))

export default useDeleteTrainingStore
