import dayjs from "dayjs"
import { useMemo } from "react"

import {
  useIncidentsQuery,
  useTargetBehaviorsQuery,
} from "../../../networking/queries"
import useDataTasksQuery from "../parentTraining/dataTasks/useDataTasksQuery"

const useIncidentLog = (id: number | null | undefined, isDataTask: boolean) => {
  const { data: targetBehaviors } = useTargetBehaviorsQuery()
  const { data: dataTasks } = useDataTasksQuery()
  const { data: incidents } = useIncidentsQuery()

  const targetBehavior = targetBehaviors?.find((behavior) => behavior.id === id)
  const dataTask = dataTasks?.find((task) => task.id === id)

  const incidentLogDataType = isDataTask
    ? dataTask?.data_type
    : targetBehavior?.incident_log_data_type

  const filteredIncidents = useMemo(() => {
    if (!incidents) return []
    return (
      incidents
        // eslint-disable-next-line array-callback-return
        .filter((incident) => {
          if (isDataTask) {
            return incident.training_data_collection_task?.id === id
          } else {
            const behaviorId = targetBehavior?.id
            if (id === null) return !!incident.freeform_target_behavior
            else if (behaviorId)
              return incident.target_behavior?.id === behaviorId
          }
        })
        .sort((a, b) =>
          dayjs(b.incident_datetime).diff(dayjs(a.incident_datetime))
        )
    )
  }, [incidents, isDataTask, id, targetBehavior])

  return { filteredIncidents, incidentLogDataType }
}

export default useIncidentLog
