import {
  FlexBox,
  JoonColorExpanded,
  JoonUIColor,
  MasteryCriteriaMeasurement,
  Shadow,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import dayjs from "dayjs"
import { useMemo, useState } from "react"
import {
  Area,
  AreaChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  ReferenceLine,
} from "recharts"

import {
  useCurrentTrainingQuery,
  useTrainingMasteryProgressQuery,
} from "../../../../networking/queries"
import { TrainingMasteryProgressGraphDataPoint } from "../../../../types"
import {
  addDatesToProgressData,
  calculateTrendLine,
  createExampleMasteryProgressData,
} from "../../../../util/util"

const ObservationalDataGraph = () => {
  const { data: currentTraining } = useCurrentTrainingQuery()
  const { data: progress, isLoading } = useTrainingMasteryProgressQuery()
  const [showTrendLine] = useState(false)

  const isPercentageMeasurement =
    currentTraining?.mastery_criteria_measurement ===
    MasteryCriteriaMeasurement.PERCENTAGE

  const defaultChartData = useMemo(() => {
    return Array.from({ length: 13 }, (_, index) => {
      const date = dayjs()
        .subtract(3, "month")
        .add(index * 7, "day")
      return {
        date: date.valueOf(),
        frequency: null,
      }
    })
  }, [])

  if (isLoading || progress?.length === 0)
    return (
      <GhostGraph
        text={isLoading ? "Loading..." : "Add data to track mastery progress"}
      />
    )

  if (!progress) return null

  const graphData =
    progress.length > 0 ? addDatesToProgressData(progress) : defaultChartData

  const trendLine = calculateTrendLine(
    graphData as TrainingMasteryProgressGraphDataPoint[]
  )

  return (
    <ResponsiveContainer width="100%" height={300}>
      <AreaChart
        data={graphData}
        margin={{ bottom: 0, right: 7, left: -16, top: 5 }}
      >
        <defs>
          <linearGradient id="colorOrange" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="#FFFFFF" stopOpacity={1} />
            <stop offset="20%" stopColor="#DD7B46" stopOpacity={0.2} />
            <stop offset="100%" stopColor="#DD7B46" stopOpacity={0} />
          </linearGradient>
        </defs>
        <XAxis
          dataKey="date"
          style={{ fontSize: "13px" }}
          tickFormatter={(date) => dayjs(date).format("MMM D")}
          scale="time"
          type="number"
          domain={["dataMin", "dataMax"]}
          axisLine={false}
          tickLine={false}
          interval="preserveEnd"
        />
        <YAxis
          style={{ fontSize: "13px" }}
          tickLine={false}
          axisLine={false}
          yAxisId="main"
          tickMargin={10}
          allowDecimals={false}
          interval="preserveStartEnd"
          tickFormatter={(value) =>
            isPercentageMeasurement ? `${value.toFixed()}%` : value
          }
          domain={
            isPercentageMeasurement
              ? [0, 100]
              : progress.length > 0
              ? [0, "dataMax"]
              : [0, 10]
          }
        />
        <Tooltip content={<CustomTooltip {...{ isPercentageMeasurement }} />} />
        <Area
          type="linear"
          dataKey={isPercentageMeasurement ? "percentage" : "frequency"}
          stroke={JoonColorExpanded.orange300}
          fill="url(#colorOrange)"
          connectNulls
          yAxisId="main"
          dot
        />
        {showTrendLine && (
          <ReferenceLine
            stroke="#DD7B46"
            strokeDasharray="3 3"
            segment={[
              { x: trendLine.start.x, y: trendLine.start.y },
              { x: trendLine.end.x, y: trendLine.end.y },
            ]}
            yAxisId="main"
          />
        )}
      </AreaChart>
    </ResponsiveContainer>
  )
}

const CustomTooltip = ({
  active,
  payload,
  label,
  isPercentageMeasurement,
}: any) => {
  if (active && payload && payload.length) {
    return (
      <FlexBox
        direction="column"
        gap={SPACING.space1}
        style={{
          background: JoonUIColor.background.inverted,
          borderRadius: SPACING.space2,
          padding: SPACING.space2,
        }}
      >
        <Typography
          variant="bodySmall"
          textAlign="left"
          color={JoonUIColor.text.inverted}
        >
          {dayjs(label).format("MMM D, YYYY")}
        </Typography>
        <Typography variant="body" color={JoonUIColor.text.inverted}>
          {/* if percentage also show the frequency and base count */}
          {isPercentageMeasurement
            ? `${payload[0].value.toFixed(1)}% (${
                payload[0].payload.frequency
              }/${payload[0].payload.base_count}) `
            : payload[0].value}
        </Typography>
      </FlexBox>
    )
  }
  return null
}

const GhostGraph = ({ text }: { text?: string }) => {
  const ghostData = useMemo(() => createExampleMasteryProgressData(), [])

  return (
    <div style={{ position: "relative", width: "100%", height: "300px" }}>
      <ResponsiveContainer width="100%" height={300}>
        <AreaChart
          data={ghostData}
          margin={{ bottom: 7, right: 7, left: -12, top: 5 }}
        >
          <defs>
            <linearGradient id="colorGray" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="#D3D3D3" stopOpacity={0.8} />
              <stop offset="100%" stopColor="#D3D3D3" stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis
            dataKey="date"
            style={{ fontSize: "13px", fill: "#A9A9A9" }}
            tickFormatter={(data) => dayjs(data).format("MMM D")}
            axisLine={false}
            tickLine={false}
            interval="equidistantPreserveStart"
          />
          <YAxis
            style={{ fontSize: "13px", fill: "#A9A9A9" }}
            tickLine={false}
            axisLine={false}
          />
          <Area
            type="linear"
            dataKey="frequency"
            stroke="#A9A9A9"
            fill="url(#colorGray)"
            connectNulls
            isAnimationActive={false}
            activeDot={false}
          />
        </AreaChart>
      </ResponsiveContainer>
      {text && (
        <div
          style={{
            position: "absolute",
            padding: SPACING.space2,
            borderRadius: SPACING.space2,
            border: `1px solid ${JoonUIColor.border.default}`,
            boxShadow: Shadow.high,
            background: JoonUIColor.background.primaryNeutral,
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Typography variant="bodySmall">{text}</Typography>
        </div>
      )}
    </div>
  )
}

export default ObservationalDataGraph
