const Squiggle = () => {
  return (
    <svg
      width="min(300px, 20%)"
      height="14"
      viewBox="0 0 250 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 9C2 9 21.2009 2 33.875 2C46.5491 2 52.0759 12 64.75 12C77.4241 12 82.9509 2 95.625 2C108.299 2 113.826 12 126.5 12C139.174 12 144.701 2 157.375 2C170.049 2 175.576 12 188.25 12C200.924 12 206.451 2 219.125 2C231.799 2 248.5 9 248.5 9"
        stroke="#5E65E9"
        stroke-opacity="0.6"
        stroke-width="3"
        stroke-linecap="round"
      />
    </svg>
  )
}

export default Squiggle
