import { FlexBox, JoonUIColor, SPACING, Typography } from "@joonapp/web-shared"

import NotificationDot from "../../../components/notificationDot/NotificationDot"
import useUnreadIncidents from "../../../hooks/useUnreadIncidents"

const NewIncidentNotification = ({ id }: { id: number | null }) => {
  const { getIncidentByTaskOrBehaviorId } = useUnreadIncidents({})
  const filteredIncidents = getIncidentByTaskOrBehaviorId(id)
  const numNewIncidents = filteredIncidents.length

  if (numNewIncidents === 0) return null

  return (
    <FlexBox
      direction="row"
      align="center"
      gap={SPACING.space2}
      style={{ width: "fit-content" }}
      wrap={false}
    >
      <NotificationDot />
      <Typography
        variant="bodySmall"
        textAlign="left"
        style={{ whiteSpace: "nowrap" }}
        color={JoonUIColor.semantic.destructive}
      >
        {numNewIncidents} new {numNewIncidents === 1 ? "incident" : "incidents"}
      </Typography>
    </FlexBox>
  )
}

export default NewIncidentNotification
