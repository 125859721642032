import {
  FlexBox,
  JoonColorExpanded,
  JoonUIColor,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import { useMediaQuery } from "@mui/material"

export const QuickActionMobileButton = ({
  text,
  onClick,
}: {
  text: string
  onClick: () => void
}) => {
  return (
    <FlexBox
      direction="column"
      align="center"
      wrap={false}
      onClick={onClick}
      gap={SPACING.space2}
      style={{
        flex: 1,
        padding: `${SPACING.space2} ${SPACING.space2}`,
        border: `1px solid ${JoonUIColor.border.default}`,
        borderRadius: SPACING.space2,
        cursor: "pointer",
        boxShadow: "4px 6px 6px 0px rgba(0, 0, 0, 0.08)",
        background: JoonUIColor.background.primaryNeutral,
      }}
    >
      <Typography variant="caption" color={JoonColorExpanded.indigo700}>
        {text}
      </Typography>
    </FlexBox>
  )
}

export const QuickActionMobileButtonGroup = ({
  buttons,
}: {
  buttons: { text: string; onClick: () => void }[]
}) => {
  const isMobile = useMediaQuery("(max-width: 800px)")

  if (!isMobile) return null

  return (
    <FlexBox
      direction="row"
      wrap={false}
      gap={SPACING.space2}
      style={{
        position: "fixed",
        zIndex: 400,
        width: `calc(100% - ${SPACING.space4})`,
        left: 0,
        bottom: 0,
        margin: SPACING.space2,
        borderRadius: SPACING.space2,
        background: "rgba(13, 5, 105, 0.12)",
        padding: SPACING.space2,
      }}
    >
      {buttons.map((button) => (
        <QuickActionMobileButton
          key={button.text}
          text={button.text}
          onClick={button.onClick}
        />
      ))}
    </FlexBox>
  )
}
