import { SegmentedControl } from "@joonapp/web-shared"
import { useMediaQuery } from "@mui/material"

import {
  HomeRoutineView,
  useHomeRoutineViewStore,
} from "./useHomeRoutineViewToggleStore"

const HomeRoutineViewToggle = () => {
  const { view, setView } = useHomeRoutineViewStore()

  const isMobile = useMediaQuery("(max-width: 800px)")

  const viewOptions = [
    { label: "Weekly Progress", value: HomeRoutineView.WEEKLY_PROGRESS },
    { label: "Progress Over Time", value: HomeRoutineView.OVER_TIME },
  ]

  return (
    <SegmentedControl
      options={viewOptions}
      value={view}
      setValue={(value) => setView(value as HomeRoutineView)}
      fullWidth={isMobile}
    />
  )
}

export default HomeRoutineViewToggle
