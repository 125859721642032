import { create } from "zustand"

interface QuestDetailsModalStore {
  isOpen: boolean
  onOpen: (questId: number) => void
  onClose: () => void

  questId: number | null
  setQuestId: (questId: number | null) => void
}

export const useQuestDetailsModalStore = create<QuestDetailsModalStore>(
  (set) => ({
    isOpen: false,
    onOpen: (questId: number) => set({ isOpen: true, questId }),
    onClose: () => set({ isOpen: false, questId: null }),

    questId: null,
    setQuestId: (questId: number | null) => set({ questId }),
  })
)
