import { FlexBox, JoonUIColor, Loader } from "@joonapp/web-shared"

interface Props {
  height?: number
  children?: JSX.Element
}

function PageLoader({ height = 350, children }: Props) {
  return (
    <FlexBox justify="center" align="center" style={{ height }}>
      {!children && (
        <Loader size={32} color={JoonUIColor.semantic.primary} thickness={3} />
      )}
      {children && <>{children}</>}
    </FlexBox>
  )
}

export default PageLoader
