import {
  Button,
  FlexBox,
  JoonUIColor,
  Modal,
  ModalHeader,
  SPACING,
  TextButton,
  Typography,
} from "@joonapp/web-shared"
import { useMediaQuery } from "@mui/material"
import { useParams } from "react-router-dom"
import { useShallow } from "zustand/react/shallow"

import { AddEditTargetBehaviorContent } from "./AddTargetBehaviorModal"
import DeleteTargetBehaviorModal from "./DeleteTargetBehaviorModal"
import useEditTargetBehaviorMutation from "./mutations/useEditTargetBehaviorMutation"
import useAddEditTargetBehaviorStore from "./store/useAddEditTargetBehaviorStore"
import useDeleteTargetBehaviorModalStore from "./store/useDeleteTargetBehaviorModalStore"
import OpenPDFButton from "../../../components/buttons/OpenPDFButton"
import { Toasts, createToast } from "../../../components/toast/Toast"
import { QUERY_KEYS } from "../../../networking/queries"
import { queryClient } from "../../../queryClient"
import { ToastType } from "../../../types"

const EditTargetBehaviorModal = () => {
  const { onOpen: openDeleteTargetBehaviorModal } =
    useDeleteTargetBehaviorModalStore()
  const {
    isOpen,
    onClose,
    title,
    description,
    preventiveStrategies,
    status,
    resetTargetBehaviorData,
    selectedTargetBehaviorId,
    usedInIncidentLogs,
    incidentLogDataType,
  } = useAddEditTargetBehaviorStore(
    useShallow((state) => ({
      isOpen: state.isOpenEditTargetBehavior,
      onClose: state.onClose,
      title: state.title,
      description: state.description,
      preventiveStrategies: state.preventiveStrategies,
      status: state.status,
      resetTargetBehaviorData: state.resetTargetBehaviorData,
      selectedTargetBehaviorId: state.selectedTargetBehaviorId,
      usedInIncidentLogs: state.usedInIncidentLogs,
      incidentLogDataType: state.incidentLogDataType,
    }))
  )

  const { userId } = useParams()
  const isMobile = useMediaQuery("(max-width: 800px)")
  const editTargetBehaviorMutation = useEditTargetBehaviorMutation()

  const onClickSaveTargetBehavior = async () => {
    if (!title) {
      return createToast({
        title: "Please enter a title",
        type: ToastType.ERROR,
      })
    }
    if (!status) {
      return createToast({
        title: "Please select a status",
        type: ToastType.ERROR,
      })
    }
    if (!userId || !selectedTargetBehaviorId) {
      return createToast(Toasts.GENERAL_ERROR)
    }

    const fitleredPreventiveStrategies = preventiveStrategies.filter(
      (strategy) => strategy.title.length > 0
    )

    if (!fitleredPreventiveStrategies.length) {
      return createToast({
        title: "Please add at least one preventive strategy",
        type: ToastType.ERROR,
      })
    }

    await editTargetBehaviorMutation.mutateAsync({
      targetBehaviorId: selectedTargetBehaviorId,
      title,
      description,
      preventiveStrategies: fitleredPreventiveStrategies,
      status,
      usedInIncidentLogs,
      incidentLogDataType,
    })
    queryClient.invalidateQueries([QUERY_KEYS.TARGET_BEHAVIORS])
    resetTargetBehaviorData()
    onClose()
  }

  const onClickDeleteButton = () => {
    if (!selectedTargetBehaviorId) return
    openDeleteTargetBehaviorModal(selectedTargetBehaviorId)
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      style={{ height: isMobile ? undefined : "min(95vh, 750px)" }}
      mobileFull
    >
      <ModalHeader
        title={
          (
            <FlexBox direction="column" gap={SPACING.space1}>
              <Typography variant="h3">Target behavior settings</Typography>
              <OpenPDFButton
                text="Preview parent experience"
                imageUrl="/images/targetbehavior-preview.pdf"
              />
            </FlexBox>
          ) as any
        }
        onClose={onClose}
        showBorderBottom
      />
      <AddEditTargetBehaviorContent />
      <FlexBox
        direction="row"
        justify="space-between"
        style={{ padding: SPACING.space4 }}
        wrap={false}
      >
        <TextButton onClick={onClickDeleteButton}>
          <Typography
            variant="bodyBold"
            color={JoonUIColor.semantic.destructive}
          >
            Delete
          </Typography>
        </TextButton>
        <FlexBox align="center" justify="flex-end" gap={SPACING.space4}>
          <TextButton onClick={onClose}>
            <Typography variant="bodyBold" color={JoonUIColor.semantic.primary}>
              Cancel
            </Typography>
          </TextButton>
          <Button
            text="Save"
            onClick={onClickSaveTargetBehavior}
            isLoading={editTargetBehaviorMutation.isLoading}
          />
        </FlexBox>
      </FlexBox>
      <DeleteTargetBehaviorModal />
    </Modal>
  )
}

export default EditTargetBehaviorModal
