import { CSSProperties } from "react"

type Props = {
  size?: number
  color?: string
  colorSecondary?: string
  style?: CSSProperties
}

const NotificationDot = ({
  color = "#EC5D5D",
  colorSecondary = "#FFE9E9",
  size = 11,
  style,
}: Props) => (
  <svg
    width={size}
    height={size + 1}
    viewBox="0 0 11 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <circle
      cx="3.5"
      cy="3.5"
      r="4.5"
      transform="matrix(1 0 0 -1 2 9.86084)"
      fill={color}
      stroke={colorSecondary}
      strokeWidth="2"
    />
  </svg>
)

export default NotificationDot
