import CloseIcon from "@mui/icons-material/Close"
import { Modal } from "@mui/material"

type Props = {
  handleClose: () => void
  isOpen: boolean
  children: JSX.Element
  theme?: "light" | "dark"
  p?: number
  canClose?: boolean
  type?: "" | "mobile-full"
}

const CustomModal = ({
  isOpen,
  handleClose,
  theme = "light",
  children,
  canClose = true,
  type = "",
}: Props) => {
  return (
    <Modal open={isOpen} onClose={handleClose}>
      <div className={`custom-modal ${type}`}>
        {canClose && (
          <CloseIcon
            fontSize="large"
            className="exit-button"
            color={theme === "light" ? "primary" : "secondary"}
            onClick={handleClose}
          />
        )}
        {children}
      </div>
    </Modal>
  )
}

export default CustomModal
