import { JoonUIColor, SPACING, Typography } from "@joonapp/web-shared"
import { useNavigate } from "react-router-dom"

import { BackButton2 } from "../../components/buttons/BackButton2"
import PageContentWrapper from "../../components/layout/PageContentWrapper"
import PageWrapper from "../../components/pageWrapper/PageWrapper"
import { requireAuth } from "../../util/auth"

const PIIPolicy = requireAuth(() => {
  const navigate = useNavigate()

  return (
    <PageWrapper>
      <PageContentWrapper>
        <BackButton2 text="Back" onClick={() => navigate("/security")} />
        <Typography variant="h2">
          Personal Identifiable Information (PII) Policy
        </Typography>
        <div
          style={{
            background: JoonUIColor.background.primaryNeutral,
            border: `1px solid ${JoonUIColor.border.default}`,
            padding: SPACING.space4,
            borderRadius: SPACING.space4,
          }}
        >
          <div>
            <div className="bold">
              1. What PII does Joon collect about the Parent?
            </div>
            <br />
            <div>
              In Short: We collect personal information that parents voluntarily
              provide to us. The personal information that we collect depends on
              the context of the parent’s interactions with Joon, the choices
              the parent makes and the products and features the parent uses.
              The personal information we collect may include the following (but
              is not required for use of app):‍
            </div>
            <br />
            <div>
              Account Information. When a parent creates an account on Joon, we
              collect their first name, nickname, email address, and password.
              Additionally, parents are required to complete a Basic
              Questionnaire and detailed Intake Questionnaire. Both
              Questionnaires request personal information but if parents are not
              comfortable sharing the requested information, they may select
              "Prefer not to answer." The personal information that parents
              voluntarily provide to us does not need to be true, complete or
              accurate.
            </div>
            <br />
            <div className="bold">
              2. What PII does Joon collect about the Child?
            </div>
            <br />
            <div>
              Children are not permitted to create an account by themselves but
              instead must be invited to Joon by a parent, guardian, therapist,
              or educator. Where children have permission to use Joon, Joon only
              collects the child's first name as that is the only personally
              identifiable information that is necessary to provide the Service.
              A nickname may be used as an adequate substitute for the child's
              first name. A parent, guardian, or educator may also enter a
              child's date of birth, but note that this information is optional
              and not required for the Service.
            </div>

            <br />
            <div>
              If a clinician, educator or parent is not comfortable with sharing
              a child’s first name and or birthday, we highly recommend adding a
              unique nickname for the child (or initials) and leaving the
              birthday field blank as that is not a required input.
            </div>
          </div>
        </div>
      </PageContentWrapper>
    </PageWrapper>
  )
})

export default PIIPolicy
