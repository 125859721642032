import {
  EditIcon,
  FlexBox,
  JoonUIColor,
  SPACING,
  TextButton,
  Typography,
} from "@joonapp/web-shared"
import { useMediaQuery } from "@mui/material"

import { useEditPersonalInfoModalStore } from "./EditPersonalInfoModal"
import { usePracticeQuery, useUserQuery } from "../../networking/queries"
import { getCurrentUserProfileInfo } from "../../util/util"

const PersonalInformation = () => {
  const { user } = useUserQuery()
  const { practice } = usePracticeQuery()
  const currentUser = practice && getCurrentUserProfileInfo(practice, user)
  const isMobile = useMediaQuery("(max-width:800px)")
  const { onOpen: openEditPersonalInfoModal } = useEditPersonalInfoModalStore()

  return (
    <FlexBox direction="column" wrap={false} gap={SPACING.space4}>
      <Typography variant="h3">Personal Information</Typography>
      <FlexBox
        justify="space-between"
        align="center"
        gap={SPACING.space2}
        wrap={false}
        style={{
          padding: SPACING.space4,
          border: `1px solid ${JoonUIColor.border.default}`,
          borderRadius: SPACING.space2,
          background: JoonUIColor.background.primaryNeutral,
        }}
      >
        <FlexBox
          gap={SPACING.space3}
          direction={isMobile ? "column" : "row"}
          justify="space-between"
          wrap={false}
        >
          <FlexBox direction="column">
            <Typography variant="bodyBold" textAlign="left">
              First name
            </Typography>
            <Typography variant="body" textAlign="left">
              {user?.name || "-"}
            </Typography>
          </FlexBox>
          <FlexBox direction="column">
            <Typography variant="bodyBold" textAlign="left">
              Last name
            </Typography>
            <Typography variant="body" textAlign="left">
              {user?.last_name || "-"}
            </Typography>
          </FlexBox>
          <FlexBox direction="column">
            <Typography variant="bodyBold" textAlign="left">
              Name your clients call you
            </Typography>

            <Typography variant="body" textAlign="left">
              {currentUser?.nickname || "-"}
            </Typography>
          </FlexBox>
        </FlexBox>
        <TextButton onClick={openEditPersonalInfoModal}>
          <EditIcon size={14} />
        </TextButton>
      </FlexBox>
    </FlexBox>
  )
}

export default PersonalInformation
