import { useNavigate, useParams } from "react-router-dom"

import { ParentTrainingTab, PatientInfoTab } from "../../../../types"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../../util/analytics"
import useAddModuleStore from "../../../resources/useAddModuleStore"
import SummarySectionEmptyState from "../SummarySectionEmptyState"

const ModulesEmptyState = () => {
  const { onOpen: openAddTrainingModuleModal } = useAddModuleStore()

  const navigate = useNavigate()
  const { userId, trainingId } = useParams()

  const onClickAdd = () => {
    navigate(
      `/patients/${userId}/${PatientInfoTab.PARENT_TRAINING}/${trainingId}/${ParentTrainingTab.MODULES}`
    )
    openAddTrainingModuleModal()
    trackAnalyticEvent(ANALYTIC_EVENTS.open_add_modules)
  }

  return (
    <SummarySectionEmptyState
      onClick={onClickAdd}
      title="Add interactive learning module"
      description="Introduction to concepts. You can add various types of resources from our library filled with PDFs, reading materials, and videos."
    />
  )
}

export default ModulesEmptyState
