import { FlexBox, JoonUIColor, SPACING, Typography } from "@joonapp/web-shared"
import { useEffect } from "react"

import DeleteInvitationModal from "./DeleteInvitationModal"
import ReceivedInvitesTable from "./ReceivedInvitesTable"
import RejectInvitationsModal from "./RejectInvitationsModal"
import SentInvitesTable from "./SentInvitesTable"
import { useAddPatientModalStore } from "../../../components/modals/AddPatientModal"
import { useInvitationAcceptModalStore } from "../../../components/modals/InvitationAcceptModal"
import {
  useReceivedInvitesQuery,
  useSentInvitesQuery,
} from "../../../networking/queries"
import { localStorageItems } from "../../../util/storage"

const Invitations = () => {
  const { data: parentInvites } = useReceivedInvitesQuery()
  const { data: sentInvites } = useSentInvitesQuery()

  const { onOpen } = useInvitationAcceptModalStore()
  const { isOpen: isAddPatientOpen } = useAddPatientModalStore()

  const hasSentInvitations = sentInvites && sentInvites.length > 0
  const hasReceivedInvitations = parentInvites && parentInvites.length > 0

  useEffect(() => {
    if (
      parentInvites &&
      parentInvites.length === 1 &&
      !localStorage.getItem(localStorageItems.hasSeenInviteModal) &&
      !isAddPatientOpen
    ) {
      onOpen(parentInvites[0])
      localStorage.setItem(localStorageItems.hasSeenInviteModal, "true")
    }
  }, [parentInvites, onOpen, isAddPatientOpen])

  if (!hasSentInvitations && !hasReceivedInvitations) return <></>

  return (
    <FlexBox direction="column" gap={SPACING.space6}>
      {hasReceivedInvitations && (
        <FlexBox
          direction="column"
          gap={SPACING.space1}
          style={{
            padding: SPACING.space6,
            background: JoonUIColor.background.primaryNeutral,
            borderRadius: SPACING.space2,
            border: `1px solid ${JoonUIColor.border.default}`,
          }}
        >
          <Typography variant="h3" textAlign="left">
            Invitations from parents
          </Typography>
          <FlexBox gap={SPACING.space2} direction="column">
            <Typography variant="bodySmall">
              The following parents have invited you to join their Joon account.
              View them to accept or reject their invitations.
            </Typography>
            <ReceivedInvitesTable />
          </FlexBox>
        </FlexBox>
      )}
      {hasSentInvitations && (
        <FlexBox
          direction="column"
          gap={SPACING.space1}
          style={{
            padding: SPACING.space6,
            background: JoonUIColor.background.primaryNeutral,
            borderRadius: SPACING.space2,
            border: `1px solid ${JoonUIColor.border.default}`,
          }}
        >
          <Typography variant="h3" textAlign="left">
            Invitations sent to parents
          </Typography>
          <FlexBox gap={SPACING.space2} direction="column">
            <Typography variant="bodySmall">
              You’re still waiting for the following parents to accept your Joon
              invitation.
            </Typography>

            <SentInvitesTable />
          </FlexBox>
        </FlexBox>
      )}
      <DeleteInvitationModal />
      <RejectInvitationsModal />
    </FlexBox>
  )
}

export default Invitations
