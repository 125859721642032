import { FlexBox, JoonUIColor, SPACING, Typography } from "@joonapp/web-shared"
import { useLocation, useNavigate, useParams } from "react-router-dom"

import NotificationDot from "../../../components/notificationDot/NotificationDot"
import useUnreadIncidents from "../../../hooks/useUnreadIncidents"
import {
  useCurrentTrainingQuery,
  useInReviewTodosQuery,
  useModulesToReviewQuery,
} from "../../../networking/queries"
import { ParentTrainingTab, PatientInfoTab } from "../../../types"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../util/analytics"

const tabs = [
  { label: "Summary", value: ParentTrainingTab.SUMMARY },
  { label: "Learning modules", value: ParentTrainingTab.MODULES },
  { label: "Implementation tasks", value: ParentTrainingTab.DATA_TASKS },
  { label: "Quick to-dos", value: ParentTrainingTab.TODO_TASKS },
]

const ParentTrainingTabNav = () => {
  const { data: currentTraining } = useCurrentTrainingQuery()
  const { dataTaskIncidents } = useUnreadIncidents({})
  const { data: numNewModules } = useModulesToReviewQuery()
  const numNewIncidents = dataTaskIncidents?.filter(
    (incident) =>
      // @ts-ignore - TODO: Add other fields to incident
      incident.training_data_collection_task.training.id === currentTraining?.id
  ).length
  const { data: inReviewTodos } = useInReviewTodosQuery()
  const hasInReviewTodos = !!inReviewTodos?.some(
    (todo) => todo.series.parent_training_id === currentTraining?.id
  )

  return (
    <FlexBox
      wrap={false}
      gap={SPACING.space2}
      style={{
        maxWidth: "100vw",
        overflow: "auto",
        scrollbarWidth: "thin",
      }}
    >
      {tabs.map((tab, i) => (
        <ParentTrainingNavButton
          tab={tab}
          key={i}
          showNotificationDot={
            (tab.value === ParentTrainingTab.DATA_TASKS && !!numNewIncidents) ||
            (tab.value === ParentTrainingTab.MODULES && !!numNewModules) ||
            (tab.value === ParentTrainingTab.TODO_TASKS && hasInReviewTodos)
          }
        />
      ))}
    </FlexBox>
  )
}

const ParentTrainingNavButton = ({
  tab,
  showNotificationDot,
}: {
  tab: (typeof tabs)[number]
  showNotificationDot: boolean
}) => {
  const location = useLocation()
  const params = useParams()
  const navigate = useNavigate()

  const isActive = location.pathname.includes(tab.value)

  const onClick = () => {
    if (!isActive) {
      navigate(
        `/patients/${params.userId}/${PatientInfoTab.PARENT_TRAINING}/${params.trainingId}/${tab.value}`
      )
      trackAnalyticEvent(ANALYTIC_EVENTS.patient_info_tab_click, { tab })
    }
  }

  return (
    <button onClick={onClick} style={{ padding: "0" }}>
      <FlexBox
        align="center"
        gap={SPACING.space1}
        style={{
          padding: `${SPACING.space0} ${SPACING.space2} ${SPACING.space2} ${SPACING.space2}`,
          borderBottom: isActive
            ? `4px solid ${JoonUIColor.border.accent}`
            : "4px solid transparent",
          width: "fit-content",
          cursor: "pointer",
          whiteSpace: "nowrap",
          position: "relative",
        }}
        fullWidth
      >
        <Typography
          variant="caption"
          color={
            isActive ? JoonUIColor.text.primary : JoonUIColor.text.secondary
          }
        >
          {tab.label}
        </Typography>
        {showNotificationDot && <NotificationDot />}
      </FlexBox>
    </button>
  )
}

export default ParentTrainingTabNav
