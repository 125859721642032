import {
  ChevronLeftIcon,
  ChevronRightIcon,
  FlexBox,
  JoonUIColor,
  ShortAnswerLearningModuleAssignmentCompletion,
  ShortAnswerModule,
  SPACING,
  TextButton,
  Typography,
} from "@joonapp/web-shared"
import { useState } from "react"

const ShortAnswerModulePreview = ({
  learningModule,
  completion,
}: {
  learningModule: ShortAnswerModule
  completion?: ShortAnswerLearningModuleAssignmentCompletion
}) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
  const currentQuestion =
    learningModule.short_answer_questions[currentQuestionIndex]
  const numberOfQuestions = learningModule.short_answer_questions.length
  const isOnFirstQuestion = currentQuestionIndex === 0
  const isOnLastQuestion = currentQuestionIndex === numberOfQuestions - 1

  const userAnswer =
    completion?.short_answer_answers?.[currentQuestionIndex]?.answer

  const onClickPrevQuestion = () => {
    if (isOnFirstQuestion) return
    setCurrentQuestionIndex((prev) => prev - 1)
  }

  const onClickNextQuestion = () => {
    if (isOnLastQuestion) return
    setCurrentQuestionIndex((prev) => prev + 1)
  }

  return (
    <FlexBox direction="column">
      <FlexBox align="center" direction="row" wrap={false} gap={SPACING.space1}>
        <TextButton onClick={onClickPrevQuestion}>
          <ChevronLeftIcon
            size={24}
            color={
              isOnFirstQuestion
                ? JoonUIColor.icon.light
                : JoonUIColor.icon.neutral
            }
          />
        </TextButton>
        <FlexBox
          direction="column"
          justify="center"
          style={{
            borderRadius: SPACING.space4,
            boxShadow: `-8px -6px 24px 0px rgba(123, 131, 142, 0.20), 6px 6px 24px 0px ${JoonUIColor.background.lightGray}`,
            padding: SPACING.space6,
            background: JoonUIColor.background.primaryNeutral,
          }}
          gap={SPACING.space4}
        >
          <Typography variant="bodySmall" textAlign="center">
            Question {currentQuestionIndex + 1} of {numberOfQuestions}
          </Typography>
          <Typography variant="bodyBold" textAlign="center">
            {currentQuestion?.text}
          </Typography>

          <FlexBox
            direction="column"
            style={{
              padding: SPACING.space4,
              borderRadius: SPACING.space4,
              background: !!userAnswer
                ? JoonUIColor.background.lightBlue
                : JoonUIColor.background.lightGray,
            }}
          >
            <Typography
              variant="bodySmall"
              textAlign="left"
              style={{ minHeight: "20px" }}
              color={
                !!userAnswer
                  ? JoonUIColor.text.primary
                  : JoonUIColor.text.secondary
              }
            >
              {userAnswer || "Responses will appear here"}
            </Typography>
          </FlexBox>
        </FlexBox>
        <TextButton onClick={onClickNextQuestion}>
          <ChevronRightIcon
            size={20}
            color={
              isOnLastQuestion
                ? JoonUIColor.icon.light
                : JoonUIColor.icon.neutral
            }
          />
        </TextButton>
      </FlexBox>
      <FlexBox
        align="center"
        justify="center"
        gap={SPACING.space1}
        style={{ marginTop: SPACING.space4 }}
      >
        {Array.from({ length: numberOfQuestions }).map((_, i) => (
          <button
            style={{
              borderRadius: "50%",
              background:
                i === currentQuestionIndex
                  ? JoonUIColor.background.accent
                  : JoonUIColor.background.lightAccent,
              minWidth: SPACING.space2,
              minHeight: SPACING.space2,
              padding: SPACING.space0,
            }}
            key={i}
            onClick={() => setCurrentQuestionIndex(i)}
          />
        ))}
      </FlexBox>
    </FlexBox>
  )
}

export default ShortAnswerModulePreview
