import {
  CheckIcon,
  CloseIcon,
  DoubleDashIcon,
  FlexBox,
  JoonColorExpanded,
  JoonUIColor,
  SPACING,
  Typography,
} from "@joonapp/web-shared"

const RecurringTableLegend = () => {
  return (
    <FlexBox
      style={{ marginLeft: "auto", width: "fit-content" }}
      align="center"
      direction="row"
      wrap={false}
      gap={SPACING.space2}
    >
      <FlexBox align="center" gap={SPACING.space1} wrap={false}>
        <CheckIcon size={16} color={JoonUIColor.semantic.success} />
        <Typography variant="bodyXSmall" style={{ whiteSpace: "nowrap" }}>
          Completed
        </Typography>
      </FlexBox>
      <FlexBox align="center" gap={SPACING.space1} wrap={false}>
        <div
          style={{
            background: JoonColorExpanded.viridian100,
            width: SPACING.space4,
            height: SPACING.space4,
            borderRadius: SPACING.space1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CheckIcon size={14} color={JoonUIColor.semantic.success} />
        </div>
        <Typography variant="bodyXSmall" style={{ whiteSpace: "nowrap" }}>
          Verified by parent
        </Typography>
      </FlexBox>
      <FlexBox align="center" gap={SPACING.space1} wrap={false}>
        <CloseIcon size={16} color={JoonUIColor.icon.neutral} />
        <Typography variant="bodyXSmall" style={{ whiteSpace: "nowrap" }}>
          Did not complete
        </Typography>
      </FlexBox>
      <FlexBox align="center" gap={SPACING.space1} wrap={false}>
        <FlexBox
          align="center"
          justify="center"
          style={{
            background: JoonUIColor.background.primaryNeutral,
            width: SPACING.space4,
            height: SPACING.space4,
            borderRadius: SPACING.space1,
          }}
        >
          <DoubleDashIcon size={8} color={JoonUIColor.icon.neutral} />
        </FlexBox>
        <Typography variant="bodyXSmall" style={{ whiteSpace: "nowrap" }}>
          Skipped
        </Typography>
      </FlexBox>
    </FlexBox>
  )
}

export default RecurringTableLegend
