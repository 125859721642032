import { Button, FlexBox, SPACING, Typography } from "@joonapp/web-shared"
import { useMediaQuery } from "@mui/material"

import useAddEditTargetBehaviorStore from "./store/useAddEditTargetBehaviorStore"
import OpenPDFButton from "../../../components/buttons/OpenPDFButton"
import Squiggle from "../../../components/squiggle/Squiggle"
import { PatientInfoTab } from "../../../types"

const BehaviorsIncidentsEmptyState = () => {
  const { onOpen } = useAddEditTargetBehaviorStore()

  const isMobile = useMediaQuery("(max-width: 800px)")

  return (
    <FlexBox
      direction="column"
      align="center"
      justify="center"
      gap={SPACING.space12}
      style={{ marginTop: SPACING.space2 }}
    >
      <FlexBox
        direction="column"
        align="center"
        justify="center"
        gap={SPACING.space6}
      >
        <Typography
          variant={isMobile ? "h2" : "h1"}
          textAlign="center"
          style={{ width: "min(600px, 90%)" }}
        >
          Target behaviors & reported incidents
        </Typography>
        <Typography
          variant="bodySmall"
          style={{ width: "min(600px, 90%)" }}
          textAlign="center"
        >
          Client behaviors you are focused on decreasing or increasing through
          your work with parents. Once added, parents will be able to report
          when those behaviors occur outside of sessions.
        </Typography>
        <Button text="+ Add target behavior" onClick={() => onOpen()}>
          Add behavior
        </Button>
      </FlexBox>

      <FlexBox
        direction="column"
        align="center"
        justify="center"
        gap={SPACING.space8}
      >
        <FlexBox
          direction="row"
          align="center"
          gap={SPACING.space4}
          justify="center"
        >
          <Squiggle />
          <Typography variant="h3">How this works</Typography>
          <Squiggle />
        </FlexBox>
        <FlexBox
          direction="column"
          align="center"
          justify="center"
          gap={SPACING.space4}
        >
          <Typography variant="bodyBold">
            View how often specific behaviors are occurring outside of session
            and assess its frequency over time
          </Typography>
          <img
            src="/images/behaviorIncidentEmpty1.svg"
            alt="Therapist view for incident logs"
            style={{ width: "min(800px, 100%)" }}
          />
          <OpenPDFButton
            text="Preview in sample patient"
            imageUrl={`/patients/0/${PatientInfoTab.BEHAVIORS}`}
          />
        </FlexBox>
        <FlexBox
          direction="column"
          align="center"
          justify="center"
          gap={SPACING.space4}
        >
          <Typography variant="bodyBold">
            Parents have a simple method to report behaviors that occurs at home
          </Typography>
          <img
            src="/images/behaviorIncidentEmpty2.svg"
            alt="Parent view for incident logging"
            style={{ width: "min(600px, 90%)" }}
          />
          <OpenPDFButton
            text="Preview parent experience"
            imageUrl="/images/targetbehavior-preview.pdf"
          />
        </FlexBox>
      </FlexBox>
    </FlexBox>
  )
}

export default BehaviorsIncidentsEmptyState
