import {
  Button,
  Checkbox,
  FlexBox,
  JoonUIColor,
  Modal,
  ModalContent,
  ModalHeader,
  SPACING,
  TextButton,
  Typography,
  UserRole,
} from "@joonapp/web-shared"
import { useEffect } from "react"
import { create } from "zustand"

import useSwitchClinicianRoleMutation from "./useSwitchClinicianRoleMutation"
import { usePracticeQuery } from "../../networking/queries"
import { displayStringFromRole } from "../../util/util"

interface EditClinicianModalStore {
  isOpen: boolean
  onClose: () => void
  onOpen: (selectedClinicianId: number) => void
  selectedClinicianId: number | null
  userRole: UserRole.ADMIN | UserRole.MEMBER
  setUserRole: (userRole: UserRole.ADMIN | UserRole.MEMBER) => void
}

export const useEditClinicianModalStore = create<EditClinicianModalStore>(
  (set) => ({
    isOpen: false,
    onClose: () => set({ isOpen: false, selectedClinicianId: null }),
    onOpen: (selectedClinicianId) => set({ isOpen: true, selectedClinicianId }),
    selectedClinicianId: null,
    userRole: UserRole.MEMBER,
    setUserRole: (userRole: UserRole.ADMIN | UserRole.MEMBER) =>
      set({ userRole }),
  })
)

const EditClinicianModal = () => {
  const {
    isOpen,
    onClose: closeEditClinicianModal,
    selectedClinicianId,
    userRole,
    setUserRole,
  } = useEditClinicianModalStore()
  const { practice } = usePracticeQuery()
  const selectedClinician = practice?.profiles?.find(
    (profile: any) => profile.user.id === selectedClinicianId
  )

  const switchUserRoleMutation = useSwitchClinicianRoleMutation()

  const onClickSaveUserRole = async () => {
    if (!practice || !selectedClinician) return
    await switchUserRoleMutation.mutateAsync({
      practiceId: practice.id,
      userId: selectedClinician.user.id,
      newRole: userRole,
    })
    closeEditClinicianModal()
  }

  useEffect(() => {
    if (selectedClinician) {
      setUserRole(selectedClinician.role as UserRole.ADMIN | UserRole.MEMBER)
    }
  }, [selectedClinician, setUserRole])

  if (!selectedClinician) return null

  return (
    <Modal isOpen={isOpen} onClose={closeEditClinicianModal}>
      <ModalHeader
        title={`Edit: ${selectedClinician.user.name} ${
          selectedClinician.user.last_name ?? ""
        }`}
        onClose={closeEditClinicianModal}
        showBorderBottom
      />
      <ModalContent
        style={{ padding: SPACING.space6, paddingTop: SPACING.space4 }}
      >
        <FlexBox direction="column" gap={SPACING.space2}>
          <Typography variant="bodyBold">User role</Typography>
          <Checkbox
            label={displayStringFromRole(UserRole.ADMIN)}
            name="role"
            inputType="radio"
            selected={userRole === UserRole.ADMIN}
            onChange={() => setUserRole(UserRole.ADMIN)}
            hideBorder
          />
          <Checkbox
            label={displayStringFromRole(UserRole.MEMBER)}
            name="role"
            inputType="radio"
            selected={userRole === UserRole.MEMBER}
            onChange={() => setUserRole(UserRole.MEMBER)}
            hideBorder
          />
        </FlexBox>
      </ModalContent>
      <FlexBox
        wrap={false}
        justify="space-between"
        style={{
          position: "sticky",
          bottom: 0,
          padding: SPACING.space6,
          marginTop: "auto",
          width: "100%",
        }}
      >
        <FlexBox align="center" justify="flex-end" gap={SPACING.space2}>
          <TextButton onClick={closeEditClinicianModal}>
            <Typography
              variant="bodyBold"
              color={JoonUIColor.text.primaryAccent}
            >
              Cancel
            </Typography>
          </TextButton>
          <Button
            text="Save"
            isLoading={switchUserRoleMutation.isLoading}
            onClick={onClickSaveUserRole}
          />
        </FlexBox>
      </FlexBox>
    </Modal>
  )
}

export default EditClinicianModal
