import { useQueryClient } from "@tanstack/react-query"
import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"

import { analyticsUserDidLogout } from "./analytics"
import { localStorageItems, sessionManager } from "./storage"
import PageLoader from "../components/loading/PageLoader"
import { usePracticeQuery, useUserQuery } from "../networking/queries"

export const useAuth = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  function signout() {
    analyticsUserDidLogout()
    sessionManager.clearAuthTokens()
    localStorage.removeItem(localStorageItems.hasSeenInviteModal)
    navigate("/auth/signin")
    queryClient.removeQueries()
  }

  return { signout }
}

export const requireAuth = (Component: React.FC) => (props: any) => {
  const { userStatus } = useUserQuery()
  const { practiceStatus } = usePracticeQuery()
  const navigate = useNavigate()

  useEffect(() => {
    const hasRefreshToken = sessionManager.getRefreshToken()
    if (!hasRefreshToken) return navigate("/auth/signin")
  }, [navigate])

  if ([userStatus, practiceStatus].includes("loading")) return <PageLoader />

  return <Component {...props} />
}

export const requireNoAuth = (Component: React.FC) => (props: any) => {
  const { userStatus } = useUserQuery()
  const { practiceStatus } = usePracticeQuery()
  const navigate = useNavigate()

  useEffect(() => {
    const hasRefreshToken = sessionManager.getRefreshToken()
    if (hasRefreshToken) return navigate("/patients")
  }, [navigate, userStatus, practiceStatus])

  return <Component {...props} />
}
