import {
  FlexBox,
  JoonColorExpanded,
  JoonUIColor,
  ParentTrainingStatus,
  SPACING,
  Typography,
} from "@joonapp/web-shared"

import { useTrainingStatusConfirmationModalStore } from "./useTrainingStatusModalStore"
import { PAGE_CONTENT_WIDTH } from "../../../constants"
import { useCurrentTrainingQuery } from "../../../networking/queries"

const ParentTrainingStatusBanner = () => {
  const { data: currentTraining } = useCurrentTrainingQuery()
  const { onOpen: openTrainingStatusConfirmationModal } =
    useTrainingStatusConfirmationModalStore()

  if (!currentTraining) return <></>
  if (currentTraining.status !== ParentTrainingStatus.INACTIVE) return <></>

  return (
    <div
      style={{
        background: JoonColorExpanded.yellow200,
        width: "100%",
        padding: `0 ${SPACING.space6}`,
      }}
    >
      <FlexBox
        direction="row"
        align="center"
        gap={SPACING.space1}
        style={{
          width: `min(${PAGE_CONTENT_WIDTH}, 100vw)`,
          padding: `${SPACING.space2} 0`,
          margin: "0 auto",
        }}
      >
        <Typography variant="bodySmall">
          This training is still in Draft mode and not yet visible to{" "}
          {currentTraining.user?.name}.{" "}
        </Typography>
        <span>
          <button
            onClick={() =>
              openTrainingStatusConfirmationModal(
                ParentTrainingStatus.IN_PROGRESS
              )
            }
            style={{ padding: 0 }}
          >
            <Typography
              variant="caption"
              color={JoonUIColor.text.primaryAccent}
            >
              Change status to Active
            </Typography>
          </button>
          <Typography variant="bodySmall"> to make it visible.</Typography>
        </span>
      </FlexBox>
    </div>
  )
}

export default ParentTrainingStatusBanner
