import { create } from "zustand"

interface AssignModulesSidePanelStore {
  isOpen: boolean
  onClose: () => void
  onOpen: () => void

  selectedPatientId: number | null
  setSelectedPatientId: (patientId: number | null) => void
  selectedParentId: number | null
  setSelectedParentId: (id: number) => void
  selectedTrainingId: number | null
  setSelectedTrainingId: (id: number) => void
}

const useAssignModulesSidePanelStore = create<AssignModulesSidePanelStore>(
  (set) => ({
    isOpen: false,
    onClose: () => set({ isOpen: false }),
    onOpen: () => set({ isOpen: true }),

    selectedPatientId: null,
    setSelectedPatientId: (selectedPatientId) =>
      set({
        selectedPatientId,
        selectedParentId: null,
        selectedTrainingId: null,
      }),
    selectedParentId: null,
    setSelectedParentId: (selectedParentId) =>
      set({ selectedParentId, selectedTrainingId: null }),
    selectedTrainingId: null,
    setSelectedTrainingId: (selectedTrainingId) => set({ selectedTrainingId }),
  })
)

export default useAssignModulesSidePanelStore
