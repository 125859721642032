import { FlexBox, JoonUIColor, SPACING, Typography } from "@joonapp/web-shared"

type Props = {
  imageUrl: string
  text: string
}

const OpenPDFButton = ({ imageUrl, text }: Props) => (
  <button style={{ padding: 0 }} onClick={() => window.open(imageUrl)}>
    <FlexBox direction="row" gap={SPACING.space1} align="center">
      <Typography variant="caption" color={JoonUIColor.text.primaryAccent}>
        {text}
      </Typography>
      <svg
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.5 6.10352e-05C7.08516 6.10352e-05 6.75 0.335217 6.75 0.750061C6.75 1.1649 7.08516 1.50006 7.5 1.50006H9.43828L4.72031 6.22037C4.42734 6.51334 4.42734 6.98912 4.72031 7.28209C5.01328 7.57506 5.48906 7.57506 5.78203 7.28209L10.5 2.56178V4.50006C10.5 4.9149 10.8352 5.25006 11.25 5.25006C11.6648 5.25006 12 4.9149 12 4.50006V0.750061C12 0.335217 11.6648 6.10352e-05 11.25 6.10352e-05H7.5ZM1.875 0.750061C0.839062 0.750061 0 1.58912 0 2.62506V10.1251C0 11.161 0.839062 12.0001 1.875 12.0001H9.375C10.4109 12.0001 11.25 11.161 11.25 10.1251V7.50006C11.25 7.08522 10.9148 6.75006 10.5 6.75006C10.0852 6.75006 9.75 7.08522 9.75 7.50006V10.1251C9.75 10.3313 9.58125 10.5001 9.375 10.5001H1.875C1.66875 10.5001 1.5 10.3313 1.5 10.1251V2.62506C1.5 2.41881 1.66875 2.25006 1.875 2.25006H4.5C4.91484 2.25006 5.25 1.9149 5.25 1.50006C5.25 1.08522 4.91484 0.750061 4.5 0.750061H1.875Z"
          fill="#5E65E9"
        />
      </svg>
    </FlexBox>
  </button>
)
export default OpenPDFButton
