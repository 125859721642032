import { LearningModule, ModuleType } from "@joonapp/web-shared"

import { createJoonAPIClient } from "../util/joon-api"

export const getLearningModules = async ({
  favorited,
  title,
  types,
  tag_ids,
  custom,
}: {
  favorited?: boolean
  title?: string
  types?: ModuleType[]
  tag_ids?: number[]
  custom?: boolean
}) => {
  const API = createJoonAPIClient()
  return API.get<{ results: LearningModule[] }>("api/learning-modules/", {
    params: {
      favorited,
      title,
      types: types?.join(","),
      tag_ids: tag_ids?.join(","),
      custom,
    },
  }).then((res) => res.data.results)
}

export const favoriteLearningModule = async (resourceId: number) => {
  const API = createJoonAPIClient()
  const response = await API.post(
    `api/learning-modules/${resourceId}/favorite/`
  )
  return response.data
}

export const unfavoriteLearningModule = async (resourceId: number) => {
  const API = createJoonAPIClient()
  const response = await API.post(
    `api/learning-modules/${resourceId}/unfavorite/`
  )
  return response.data
}

export const reviewModule = async (assignmentCompletionId: number) => {
  const API = createJoonAPIClient()
  return API.post(
    `api/learning-module-assignment-completions/${assignmentCompletionId}/review/`
  )
}
