import { MasteryCriteriaMeasurement, ParentTraining } from "@joonapp/web-shared"
import { create } from "zustand"

import {
  ANALYTIC_EVENTS,
  trackAnalyticEvent,
} from "../../../../../util/analytics"

export interface AddEditMasteryCriteriaModalStore {
  isOpen: boolean
  onClose: () => void
  onOpen: (training: ParentTraining) => void

  masteryCriteria: string
  setMasteryCriteria: (masteryCriteria: string) => void

  measurementType: MasteryCriteriaMeasurement
  setMeasurementType: (measurementType: MasteryCriteriaMeasurement) => void
}

const useAddEditMasteryCriteriaModalStore =
  create<AddEditMasteryCriteriaModalStore>((set) => ({
    isOpen: false,
    onClose: () =>
      set({
        isOpen: false,
        masteryCriteria: "",
        measurementType: MasteryCriteriaMeasurement.PERCENTAGE,
      }),
    onOpen: (training) => {
      if (!!training.mastery_criteria) {
        set({
          masteryCriteria: training.mastery_criteria,
          measurementType:
            training.mastery_criteria_measurement ||
            MasteryCriteriaMeasurement.PERCENTAGE,
        })
        trackAnalyticEvent(ANALYTIC_EVENTS.open_edit_mastery_criteria)
      } else {
        trackAnalyticEvent(ANALYTIC_EVENTS.open_add_mastery_criteria)
      }
      set({ isOpen: true })
    },

    masteryCriteria: "",
    setMasteryCriteria: (masteryCriteria) => set({ masteryCriteria }),

    measurementType: MasteryCriteriaMeasurement.PERCENTAGE,
    setMeasurementType: (measurementType) => set({ measurementType }),
  }))

export default useAddEditMasteryCriteriaModalStore
