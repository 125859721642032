import { JoonUIColor } from "@joonapp/web-shared"
import Skeleton, { SkeletonProps } from "react-loading-skeleton"

const SKELETON_BASE_COLOR = JoonUIColor.background.lightAccent
const SKELETON_HIGHLIGHT_COLOR = JoonUIColor.background.primaryNeutral

export const SkeletonLoaderTable = ({
  numRows,
  numCols,
  ...props
}: {
  numRows: number
  numCols: number
} & SkeletonProps) => {
  const rowInitiator = Array(numRows).fill(0)
  const colInitiator = Array(numCols).fill(0)
  return (
    <tbody>
      {rowInitiator.map((_, rowIndex) => (
        <tr key={rowIndex}>
          {colInitiator.map((_, colIndex) => (
            <td key={colIndex}>
              <SkeletonLoader
                {...props}
                style={{
                  opacity: `calc(1 - ((1 / ${numRows}) * ${rowIndex})`,
                  ...props.style,
                }}
              />
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  )
}

export const SkeletonLoader = (props: SkeletonProps) => {
  return (
    <Skeleton
      height={28}
      baseColor={SKELETON_BASE_COLOR}
      highlightColor={SKELETON_HIGHLIGHT_COLOR}
      {...props}
    />
  )
}

export const SkeletonLoaderRow = ({
  numRows = 1,
  ...props
}: { numRows?: number } & SkeletonProps) => {
  const rowInitiator = Array(numRows).fill(0)
  return (
    <div style={{ width: "100%" }}>
      {rowInitiator.map((_, rowIndex) => (
        <Skeleton
          height={85}
          baseColor={SKELETON_BASE_COLOR}
          highlightColor={SKELETON_HIGHLIGHT_COLOR}
          {...props}
          style={{
            opacity: `calc(1 - ((1 / ${numRows}) * ${rowIndex})`,
            ...props.style,
          }}
        />
      ))}
    </div>
  )
}
