import dayjs from "dayjs"

import useSubscriptionInfoQuery from "./useSubscriptionInfoQuery"
import { usePracticeQuery } from "../../networking/queries"
import { SubscriptionInfo, SubscriptionStatus } from "../../types"

const useSubscription = () => {
  const { data: subscriptionInfo, isLoading } = useSubscriptionInfoQuery()
  const { practice } = usePracticeQuery()

  if (!subscriptionInfo) return {}

  const getSubscriptionText = (subscriptionInfo: SubscriptionInfo) => {
    const {
      active_period_end_date,
      seat_quantity,
      price_per_seat,
      status,
      set_to_cancel,
    } = subscriptionInfo
    const numMembers = seat_quantity || practice?.profiles.length

    switch (status) {
      case SubscriptionStatus.TRIAL:
        return set_to_cancel
          ? `14-day free trial ending ${dayjs(active_period_end_date).format(
              "MMM D, YYYY"
            )}`
          : `14-day free trial renewing ${dayjs(active_period_end_date).format(
              "MMM D, YYYY"
            )}, then ${numMembers} clinician${
              numMembers === 1 ? "" : "s"
            } at $${price_per_seat} per clinicians per month`
      case SubscriptionStatus.PAYING:
        return `${numMembers} clinician${
          numMembers === 1 ? "" : "s"
        } at $${price_per_seat} per clinicians per month, ${
          set_to_cancel ? "expiring" : "renewing"
        } ${dayjs(active_period_end_date).format("MMM D, YYYY")}`
      case SubscriptionStatus.EXPIRED:
        return "Your subscription is expired!"
      case SubscriptionStatus.FREE:
        return "You are not subscribed to Joon Health"
    }
  }

  const isExpiredSubscription =
    subscriptionInfo.status === SubscriptionStatus.EXPIRED
  const isSubscribed = subscriptionInfo.status === SubscriptionStatus.PAYING
  const isNotSubscribed = subscriptionInfo.status === SubscriptionStatus.FREE
  const isFreeTrial = subscriptionInfo.status === SubscriptionStatus.TRIAL

  return {
    subscriptionInfo,
    subscriptionText: getSubscriptionText(subscriptionInfo),
    isExpiredSubscription,
    isSubscribed,
    isLoadingSubscription: isLoading,
    isNotSubscribed,
    isFreeTrial,
  }
}

export default useSubscription
