import {
  FlexBox,
  JoonUIColor,
  SPACING,
  TextButton,
  Typography,
} from "@joonapp/web-shared"

import AddEditTodoModal from "./AddEditTodoModal"
import ParentTaskCard from "./ParentTaskCard"
import ParentTaskDetailsModal from "./ParentTaskDetailsModal"
import TodosEmptyState from "./TodosEmptyState"
import PageContentWrapper from "../../../../components/layout/PageContentWrapper"
import { SkeletonLoaderRow } from "../../../../components/loading/SkeletonLoader"
import PageWrapper from "../../../../components/pageWrapper/PageWrapper"
import { useCurrentTrainingQuery } from "../../../../networking/queries"
import { useAddEditParentTaskStore } from "../../useAddEditParentTaskStore"

const TodoTasksTab = () => {
  const { data: currentTraining, isLoading: isLoadingParentTraining } =
    useCurrentTrainingQuery()
  const { onOpen: onOpenAddParentTask } = useAddEditParentTaskStore()

  if (!currentTraining) return <></>

  const parentTasks = currentTraining?.quests || []

  return (
    <PageWrapper>
      <PageContentWrapper>
        {isLoadingParentTraining ? (
          <SkeletonLoaderRow numRows={3} />
        ) : parentTasks && parentTasks.length > 0 ? (
          <FlexBox direction="column" wrap={false} gap={SPACING.space2}>
            <FlexBox
              direction="row"
              justify="space-between"
              wrap={false}
              align="center"
            >
              <Typography variant="bodyBold">
                {parentTasks.length} quick to-do
                {parentTasks?.length > 1 ? "s" : ""}
              </Typography>
              <TextButton
                style={{ marginLeft: "auto", whiteSpace: "nowrap" }}
                onClick={() => onOpenAddParentTask()}
              >
                <Typography
                  variant="bodyBold"
                  color={JoonUIColor.text.primaryAccent}
                >
                  + Add task
                </Typography>
              </TextButton>
            </FlexBox>
            <FlexBox direction="column" gap={SPACING.space2}>
              {parentTasks?.map((task) => (
                <ParentTaskCard key={task.id} task={task} />
              ))}
            </FlexBox>
          </FlexBox>
        ) : (
          <TodosEmptyState />
        )}
      </PageContentWrapper>
      <AddEditTodoModal />
      <ParentTaskDetailsModal />
    </PageWrapper>
  )
}

export default TodoTasksTab
