import {
  AuthCodeType,
  Button,
  CalloutBox,
  FlexBox,
  JoonUIColor,
  Modal,
  ModalHeader,
  SPACING,
  TextButton,
  TextInput,
  Typography,
} from "@joonapp/web-shared"
import { useState } from "react"

import useAddClinicMemberModalStore from "./useAddClinicMemberModalStore"
import useAdminUser from "./useAdminUser"
import { createToast } from "../../components/toast/Toast"
import { sendEmailInvite } from "../../networking/authentication"
import { useAuthCodeQuery } from "../../networking/queries"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"

const AddClinicMemberModal = () => {
  const { isOpen, onClose } = useAddClinicMemberModalStore()

  const [email, setEmail] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const { data: authCode } = useAuthCodeQuery(AuthCodeType.GROUP)

  const isAdmin = useAdminUser()

  const emailInstructions = async () => {
    const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
    if (!isValidEmail) {
      createToast({ title: "Please enter a valid email", type: "error" as any })
      return
    }
    try {
      setIsLoading(true)
      await sendEmailInvite(email, authCode.id)
      trackAnalyticEvent(ANALYTIC_EVENTS.settings_invite_clinic_member)
      createToast({ title: "Email invite sent!", type: "success" as any })
      onClose()
    } catch (error) {
      createToast({
        title: "Failed to send email invite",
        type: "error" as any,
      })
    }
    setIsLoading(false)
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalHeader onClose={onClose} title="Add clinic member" showBorderBottom>
        Invite a new clinic member
      </ModalHeader>
      <FlexBox
        style={{
          padding: SPACING.space6,
          paddingTop: SPACING.space4,
          width: "min(95vw, 400px)",
        }}
        gap={SPACING.space4}
      >
        <TextInput
          name="Email"
          label="Email"
          placeholder="example@gmail.com"
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <CalloutBox fullWidth>
          <div className="font-footnote">
            When they accept the invitation and create a Joon account, an
            additional clinician seat will be added to your subscription.
            {isAdmin ? "" : " We recommend you giving your admin a heads up!"}
          </div>
        </CalloutBox>
        <FlexBox
          direction="row"
          align="center"
          wrap={false}
          justify="flex-end"
          gap={SPACING.space4}
        >
          <TextButton onClick={onClose}>
            <Typography
              variant="bodyBold"
              color={JoonUIColor.text.primaryAccent}
            >
              Cancel
            </Typography>
          </TextButton>
          <Button
            text="Send invite"
            isLoading={isLoading}
            onClick={emailInstructions}
          />
        </FlexBox>
      </FlexBox>
    </Modal>
  )
}

export default AddClinicMemberModal
